import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import { AutoCompleteDropDown, DraggableAppTable, FlexColumn, FlexRow, FlexRowBetween, HeaderTitle, PageHeader } from 'components'
import { DangerButton, PrimaryButton, FormTextFormField, FormSelectionField } from 'elements'
import { Pencil, Trash } from '@phosphor-icons/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DesktopMode, MobileMode, GetVitalSignTypeList } from 'hooks'
import * as yup from 'yup'
import { useState, useEffect } from 'react'
import { Check, Eye, EyeSlash, Plus } from 'phosphor-react'
import { AboutUsIconList, AboutUsIcons, Roles, VitalSignsTypesService } from 'utility'
import { useAuth } from 'context'

const VitalSignTypeSchema = yup.object().shape({
    title: yup.string().required('title is required').trim(),
    icon: yup.string().required('icon is required').trim(),
    unit: yup.string().required('unit is required').trim(),
    max: yup.string().required('maxvalue is required').trim(),
    min: yup.string().required('minvalue is required').trim(),
})

export const AddVitalSignsTypesModal = ({ open, data, editMode, onClose, loading, onSubmit }) => {
    const { reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(VitalSignTypeSchema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                    ...data,
                }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={editMode ? t('form-fields.edit-vitalsignstypes') : t('form-fields.add-vitalsignstypes')}
            visible={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="col-4 mb-0">
                        <FormTextFormField
                            control={control}
                            name="title"
                            label={t('form-fields.title')}
                            placeholder="title"
                            errors={errors?.title || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-4 mb-0">
                        <FormSelectionField
                            control={control}
                            name="icon"
                            label={t('form-fields.icon')}
                            placeholder="icon"
                            options={AboutUsIconList}
                            errors={errors?.icon || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-4 mb-0">
                        <FormTextFormField
                            control={control}
                            name="unit"
                            label={t("form-fields.unit")}
                            placeholder="unit"
                            errors={errors?.unit || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormTextFormField
                            control={control}
                            name="max"
                            label={t('form-fields.maxvalue')}
                            placeholder="max"
                            errors={errors?.max || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormTextFormField
                            control={control}
                            name="min"
                            label={t('form-fields.minvalue')}
                            placeholder="min"
                            errors={errors?.min || ''}
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('form-fields.edit-vitalsignstypes')
                            : t('form-fields.add-vitalsignstypes')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    );
};
export const VitalSignsTypes = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const { user } = useAuth()
    const [processing, setProcessing] = useState(false)
    const {
        data,
        setData,
        filterChanged,
        refetch,
        total = { page: 1 },
        filter,
        pageChanged,
    } = GetVitalSignTypeList()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await VitalSignsTypesService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await VitalSignsTypesService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const removeDiagnostic = async (id) => {
        try {
            await VitalSignsTypesService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const validateType = async (id) => {
        try {
            await VitalSignsTypesService.update(id, { admin: true })
            notification.success({
                message: t('notif-message.type-validated'),
            })
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const toggleVisibilty = async (id,ele) => {
        try {
            await VitalSignsTypesService.hideShow(id,!ele?.hideusers?.includes(user._id))
            notification.success({
                message: t('notif-message.type-updated'),
            })
            refetch()
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const getActions = (data) => {
        return (
            <Space size="middle">
                {((user.role === Roles.doctor && !data.admin) || user.role === Roles.admin) && <><PrimaryButton
                    icon={<Pencil onClick={() => onOpen(data)} />}
                />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeDiagnostic(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </>}
                {!data.admin && user.role === Roles.admin && <PrimaryButton
                    onClick={() => validateType(data._id)}
                    icon={<Check />}
                >{t("validate")}</PrimaryButton>}
                {user.role === Roles.doctor && <PrimaryButton
                    onClick={() => toggleVisibilty(data._id, data)}
                    icon={!data?.hideusers?.includes(user._id) ? <Eye size={20} /> : <EyeSlash size={20} />}
                />}
            </Space>
        )
    }
    const columns = [
        {
            title: t('form-fields.title'),
            dataIndex: 'title',
            key: 'title',
            width: 600,
        },
        {
            title: t('form-fields.icon'),
            dataIndex: 'icon',
            key: 'icon',
            width: 600,
            render: (_, data) => (<>{AboutUsIcons?.[data?.icon] || ""}</>),
        },
        {
            title: t('form-fields.unit'),
            dataIndex: 'unit',
            key: 'unit',
            width: 600,
        },
        {
            title: t('form-fields.maxvalue'),
            dataIndex: 'max',
            key: 'max',
            width: 600,
        },
        {
            title: t('form-fields.minvalue'),
            dataIndex: 'min',
            key: 'min',
            width: 600,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            width: 200,
            render: (_, data) => getActions(data),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await VitalSignsTypesService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('vital-signs-types')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'vital-signs-types',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) => filterChanged('search', { type: data })}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.Types-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'vital-signs-types',
                            )}`}
                            options={[]}
                            className="mb-0 ms-1"
                            onSearch={(data) => filterChanged('search', { name: data })}
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <AddVitalSignsTypesModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="mt-sm-2 mt-md-0 overflow-auto mt-3">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
