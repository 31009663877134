import { usePatientHistory } from 'context'
import { useState, useEffect, useMemo } from 'react'
import { CommonConstant, PatientService } from 'utility'

export const GetPatientList = (intialFilter = {}) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>{item.firstName} {item.lastName}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const params = {
                    ...intialFilter,
                    ...filter,
                    ...filter.search,
                    search: filter.search.search ?? null,
                }
                const result = await PatientService.get(params)
                setData(result.data)
                setTotal({
                    ...total,
                    count: result.count,
                    page: filter.skip / filter.limit + 1,
                })
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            limit: CommonConstant.defaultPageSize,
            search,
        }
        setFilter({ ...temp })
    }

    const sortChanged = (obj) => {
        const temp = {
            ...filter,
            ...obj,
            skip: 0,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip, pageSize) => {
        const temp = {
            ...filter,
            limit: pageSize || CommonConstant.defaultPageSize,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const fetchAll = () => {
        setFilter({ ...filter,limit: 100000000000 })
    }

    return {
        data,
        error,
        loading,
        filter,
        total,
        optionList,
        setData,
        filterChanged,
        pageChanged,
        fetchAll,
        sortChanged,
    }
}

export const GetPatientDetail = (id) => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const { refreshData } = usePatientHistory()

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await PatientService.getById(id)
                setData(result)
                refreshData()
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
        setData,
    }
}

export const GetSearchPatientList = (search) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        search,
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                if (search) {
                    const result = await PatientService.get({ ...filter })
                    setData(result?.data || [])
                } else {
                    setData([])
                }
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [search])

    const filterChanged = (data) => {
        setFilter({
            ...filter,
            ...data,
        })
    }

    return {
        data,
        error,
        loading,
        setData,
        filterChanged,
    }
}
