import {
    DateFormat,
    DateUtility,
    FileTypes,
    PatientLaboratoryService,
    Seconds,
} from 'utility'
import {
    DatePicker,
    Dropdown,
    Popconfirm,
    Popover,
    Select,
    Space,
    Tag,
    notification,
    Checkbox,
} from 'antd'
import { AppTable, FilePreview, HeaderTitle, PageHeader } from 'components'
import { PrimaryButton, ShadowBox } from 'elements'
import { Plus } from '@phosphor-icons/react'
import { useState, useMemo, useEffect } from 'react'
import {
    DesktopMode,
    GetPatientLaboratory,
    GetPatientList,
    MobileMode,
    useDebounce,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { Info } from 'phosphor-react'
import { AddEditLaboratoryModal } from './add-edit'
import { useAuth } from 'context'
import { HL7TableModal } from 'page-components'
import styled from 'styled-components'

const DropButton = styled(Dropdown.Button)`
    .ant-btn-compact-first-item {
        display: none;
    }
    .ant-btn-compact-last-item {
        border-radius: 6px !important;
    }
`

export const LaboratoryScreen = ({ add = true }) => {
    const { patient, setPatient, user } = useAuth()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState(false)
    const [visible, setVisible] = useState(false)
    const [search, setSearch] = useState('')
    const finalSearch = useDebounce(search, 500)
    const { optionList: patientList, filterChanged: patientfilterChanged } =
        GetPatientList({})
    const {
        data,
        setData,
        filterChanged,
        filter,
        refetch,
        total,
        pageChanged,
        loading,
    } = GetPatientLaboratory()
    const { t } = useTranslation()
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(
        DateUtility.dayJSFormat(
            DateUtility.addSeconds(new Date(), Seconds.ONE_WEEK),
        ),
    )
    useEffect(() => {
        patientfilterChanged('search', { search: finalSearch })
    }, [finalSearch])
    const onRemove = async (document) => {
        try {
            await PatientLaboratoryService.remove(document._id)
            notification.success({
                message: t('notif-message.document-removed'),
            })
            setData(data.filter((x) => x._id !== document._id))
        } catch {
            notification.error({ message: t('notif-message.remove-unsucess') })
        }
    }

    const onOpen = (data) => {
        setOpen(true)
        if (data) {
            setModalData(data)
        }
    }

    useEffect(() => {
        filterChanged('search', {
            startdate: startDate?.toISOString(),
            enddate: endDate?.toISOString(),
        })
    }, [endDate, startDate])

    useEffect(() => {
        filterChanged('search', {
            patient: patient?._id,
        })
    }, [patient])

    const validateBy = async (id, status) => {
        try {
            await PatientLaboratoryService.validatedBy(id, status)
            notification.success({
                message: t('notif-message.category-updated'),
            })
            refetch()
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const ValidateUserList = ({ list }) => {
        return (
            <ul className="my-0">
                {list.map((ele) => (
                    <li className="mb-1">
                        {ele?.user?.firstName} {ele?.user?.lastName} -{' '}
                        {DateUtility.dateToString(
                            new Date(ele.date),
                            DateFormat.euDate,
                        )}
                        <Tag
                            color={ele.status ? 'green' : 'red'}
                            className="mx-2"
                        >
                            {ele.status ? t('validated') : t('rejected')}
                        </Tag>
                    </li>
                ))}
            </ul>
        )
    }

    const columns = useMemo(
        () => [
            {
                title: t('form-fields.patient'),
                dataIndex: 'patient',
                key: 'patient',
                width: 300,
                render: (text) => (
                    <>
                        {text?.firstName} {text?.lastName}
                    </>
                ),
            },
            {
                title: t('form-fields.content'),
                dataIndex: 'content',
                key: 'content',
                width: 300,
            },
            {
                title: t('form-fields.validatedby'),
                dataIndex: 'validatedBy',
                key: 'validatedBy',
                render: (_, data) => (
                    <div className="d-flex align-items-center">
                        <spna>
                            {data.validatedBy ? data.validatedBy.length : 0}
                        </spna>
                        <Popover
                            placement="bottomRight"
                            content={
                                <ValidateUserList list={data.validatedBy} />
                            }
                        >
                            <Info className="ms-2" />
                        </Popover>
                    </div>
                ),
            },
            {
                title: t('form-fields.actions'),
                key: 'action',
                width: 300,
                render: (_, data) => {
                    const roleAcess = () => {
                        return (
                            data?.doctor === user?._id ||
                            data?.validationAccess?.includes(user._id)
                        )
                    }
                    return (
                        <Space size="middle">
                            <>
                                <DropButton
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            setVisible({
                                                                url: data.files?.find(
                                                                    (ele) =>
                                                                        ele.type ===
                                                                        FileTypes.PDF,
                                                                )?.url,
                                                                title: data.files?.find(
                                                                    (ele) =>
                                                                        ele.type ===
                                                                        FileTypes.PDF,
                                                                )?.name,
                                                            })}
                                                        className="option"
                                                    >
                                                        {t('view-document')}
                                                    </div>
                                                ),
                                                key: '1',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            onOpen(data)}
                                                        className={`${
                                                            !roleAcess() &&
                                                            'disabled'
                                                        } option`}
                                                    >
                                                        {t(
                                                            'add-edit.edit-document',
                                                        )}
                                                    </div>
                                                ),
                                                key: '2',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            validateBy(
                                                                data._id,
                                                                true,
                                                            )}
                                                        className={`${
                                                            !roleAcess() &&
                                                            'disabled'
                                                        } option`}
                                                    >
                                                        {t('validate-document')}
                                                    </div>
                                                ),
                                                key: '3',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            validateBy(
                                                                data._id,
                                                                false,
                                                            )}
                                                        className={`${
                                                            !roleAcess() &&
                                                            'disabled'
                                                        } option`}
                                                    >
                                                        {t('reject-document')}{' '}
                                                    </div>
                                                ),
                                                key: '4',
                                            },
                                            {
                                                label: (
                                                    <Popconfirm
                                                        title={t('delete')}
                                                        description={t(
                                                            'message.delete-message',
                                                            {
                                                                name: `Document`,
                                                            },
                                                        )}
                                                        onConfirm={() =>
                                                            onRemove(data)}
                                                        okButtonProps={{
                                                            disabled: false,
                                                        }}
                                                        cancelButtonProps={{
                                                            disabled: false,
                                                        }}
                                                    >
                                                        <div
                                                            className={`${
                                                                !roleAcess() &&
                                                                'disabled'
                                                            } option`}
                                                        >
                                                            {t(
                                                                'remove-document',
                                                            )}{' '}
                                                        </div>
                                                    </Popconfirm>
                                                ),
                                                key: '5',
                                            },
                                        ],
                                    }}
                                />
                            </>
                        </Space>
                    )
                },
            },
        ],
        [data],
    )

    const onClose = (data) => {
        if (data) {
            refetch()
        }
        setOpen(false)
        setModalData({})
    }

    return (
        <>
            <>
                <DesktopMode>
                    <PageHeader
                        left={<HeaderTitle>Laboratory</HeaderTitle>}
                        right={
                            <Space className="d-flex row col-7">
                                <div className="d-flex justify-content-end">
                                    <div className="col-1 d-flex align-items-center">
                                        {t('validated')}{' '}
                                        <Checkbox
                                            onClick={(e) => {
                                                filterChanged('search', {
                                                    validated:
                                                        e.target.checked.toString(),
                                                })
                                            }}
                                            className="ms-1"
                                        />
                                    </div>
                                    <div className="col-1 d-flex align-items-center">
                                        {t('rejected')}{' '}
                                        <Checkbox
                                            onClick={(e) => {
                                                filterChanged('search', {
                                                    rejected:
                                                        e.target.checked.toString(),
                                                })
                                            }}
                                            className="ms-1"
                                        />
                                    </div>
                                    <div className="col-3 mr-2">
                                        <DatePicker
                                            format={DateFormat.inputEuDate}
                                            onChange={setStartDate}
                                            value={startDate}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className="col-3 mr-2">
                                        <DatePicker
                                            format={DateFormat.inputEuDate}
                                            onChange={setEndDate}
                                            value={endDate}
                                            style={{ width: '100%' }}
                                        />
                                    </div>
                                    <div className="col-4">
                                        <Select
                                            name="search"
                                            filterOption={false}
                                            placeholder={t('search')}
                                            options={patientList}
                                            className="mb-0 col-12"
                                            onSearch={(data) => {
                                                setSearch(data)
                                            }}
                                            showSearch
                                            onSelect={(id, data) => {
                                                setPatient(data)
                                                filterChanged('search', {
                                                    patient: id,
                                                })
                                            }}
                                        />
                                    </div>
                                    {add && (
                                        <PrimaryButton
                                            onClick={() => setOpen(true)}
                                            className="ms-2 d-flex align-items-center"
                                        >
                                            {t('add')}&nbsp;
                                            <Plus />
                                        </PrimaryButton>
                                    )}
                                </div>
                            </Space>
                        }
                    />
                    <ShadowBox className="">
                        <div className="row">
                            <AppTable
                                data={data}
                                columns={columns}
                                keyField="_id"
                                size="small"
                                currentPage={total.page}
                                total={total.count}
                                pageChanged={pageChanged}
                                pageSize={filter?.limit}
                                loading={loading}
                                rowKey={(record) => record._id}
                                expandable={{
                                    onExpand: (_, ele) => ({
                                        patient: ele.patient,
                                    }),
                                    expandedRowRender: (ele) => (
                                        <HL7TableModal
                                            data={
                                                ele?.files?.find(
                                                    (ele) =>
                                                        ele.type ===
                                                        FileTypes.HL7,
                                                )?.data
                                            }
                                        />
                                    ),
                                }}
                            />
                            <AddEditLaboratoryModal
                                onClose={onClose}
                                open={open}
                                data={modalData}
                                patientId={patient._id}
                            />
                            <FilePreview
                                url={visible?.url}
                                title={visible?.title}
                                onClose={setVisible}
                            />
                        </div>
                    </ShadowBox>
                </DesktopMode>
                <MobileMode>
                    <PageHeader
                        left={<HeaderTitle>Laboratory</HeaderTitle>}
                        right={
                            <Space className="d-flex row col-7">
                                <div className="d-flex justify-content-end">
                                    <div className="col-6 d-flex align-items-center">
                                        {t('validated')}{' '}
                                        <Checkbox
                                            onClick={(e) => {
                                                filterChanged('search', {
                                                    validated:
                                                        e.target.checked.toString(),
                                                })
                                            }}
                                            className="ms-1"
                                        />
                                    </div>
                                    <div className="col-6 d-flex align-items-center">
                                        {t('rejected')}{' '}
                                        <Checkbox
                                            onClick={(e) => {
                                                filterChanged('search', {
                                                    rejected:
                                                        e.target.checked.toString(),
                                                })
                                            }}
                                            className="ms-1"
                                        />
                                    </div>
                                </div>
                            </Space>
                        }
                    />
                    <div className="col-12 d-flex py-2">
                        <div className="col-6 mr-2">
                            <DatePicker
                                format={DateFormat.inputEuDate}
                                onChange={setStartDate}
                                value={startDate}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-6 mr-2">
                            <DatePicker
                                format={DateFormat.inputEuDate}
                                onChange={setEndDate}
                                value={endDate}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <div className="col-12 d-flex">
                    <div className="col-9">
                        <Select
                            name="search"
                            filterOption={false}
                            placeholder={t('search')}
                            options={patientList}
                            className="mb-0 col-12"
                            onSearch={(data) => {
                                setSearch(data)
                            }}
                            showSearch
                            onSelect={(id, data) => {
                                setPatient(data)
                                filterChanged('search', { patient: id })
                            }}
                        />
                    </div>
                    {add && (
                        <PrimaryButton
                            onClick={() => setOpen(true)}
                            className="ms-2 d-flex align-items-center"
                        >
                            {t('add')}&nbsp;
                            <Plus />
                        </PrimaryButton>
                    )}
                    </div>
                    <ShadowBox className="mt-3">
                        <div className="row">
                            <AppTable
                                data={data}
                                columns={columns}
                                keyField="_id"
                                size="small"
                                currentPage={total.page}
                                total={total.count}
                                pageChanged={pageChanged}
                                pageSize={filter?.limit}
                                loading={loading}
                                rowKey={(record) => record._id}
                                expandable={{
                                    onExpand: (_, ele) => ({
                                        patient: ele.patient,
                                    }),
                                    expandedRowRender: (ele) => (
                                        <HL7TableModal
                                            data={
                                                ele?.files?.find(
                                                    (ele) =>
                                                        ele.type ===
                                                        FileTypes.HL7,
                                                )?.data
                                            }
                                        />
                                    ),
                                }}
                            />
                            <AddEditLaboratoryModal
                                onClose={onClose}
                                open={open}
                                data={modalData}
                                patientId={patient._id}
                            />
                            <FilePreview
                                url={visible?.url}
                                title={visible?.title}
                                onClose={setVisible}
                            />
                        </div>
                    </ShadowBox>
                </MobileMode>
            </>
        </>
    )
}
