import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'

const fallbackLng = ['en']
const availableLanguages = ['en', 'fr']

const savedLanguage = localStorage.getItem('language')
const defaultLanguage = savedLanguage || 'en'

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng,
        detection: {
            checkWhitelist: true,
        },
        debug: false,
        whitelist: availableLanguages,
        interpolation: {
            escapeValue: false,
        },
        lng: defaultLanguage, // Use the retrieved or default language
        ns: ['translation', 'logs'],
        defaultNS: 'translation',
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json', // Custom load path
        },
    })
// // Set the language in localStorage when it changes
i18n.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng)
})
export default i18n
