import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class UserAllergies extends CRUDService {
    constructor() {
        super(APIPath.userallergies)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.userallergies}/order-by`, data)
    }

    byAppointment(id,params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(`${APIPath.userallergies}/appointment/${id}?${data}`)
    }

    byPatient(id,params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(`${APIPath.userallergies}/patient/${id}?${data}`)
    }
}

const UserAllergiesService = new UserAllergies()
Object.freeze(UserAllergiesService)
export { UserAllergiesService }
