import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class Questions extends CRUDService {
    constructor() {
        super(APIPath.questions)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.questions}/order-by`, data)
    }

    hideShow(id,status) {
        return BaseService.put(`${APIPath.questions}/hide/${id}/${status}`)
    }
}

const QuestionsService = new Questions()
Object.freeze(QuestionsService)
export { QuestionsService }
