import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useQueryString = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search),[search]);
}

export const getRouterState = () => {
    const { state } = useLocation()
    return state
}