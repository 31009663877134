import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Select, Space, notification } from 'antd'
import { AppTable, AutoCompleteDropDown, HeaderTitle, PageHeader } from 'components'
import { DangerButton, FormDateField, FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetStateRateList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState,useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { ConsulationOptions, ConsulationTypes, DateFormat, DateUtility, StateList, StateRateService } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const StateRateShema = yup.object().shape({
    state: yup.string().required('*state is required'),
    startdate: yup.string().required("startdate is required"),
    value: yup.string().required("value is required"),
    enddate: yup.string().required("enddate is required"),
})

const AddStateRateModal = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(StateRateShema),
    })
    const { t } = useTranslation()
    useEffect(() => {
        reset(editMode ? { ...data,startdate: DateUtility.dayJSFormat(data.startdate),enddate: DateUtility.dayJSFormat(data.enddate) } : {})
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-state-rate")}` : `${t("add-edit.add-state-rate")}`}
            open={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical">
                <FormSelectionField
                    control={control}
                    name="state"
                    label={t("form-fields.statename")}
                    placeholder="state Name"
                    errors={errors?.state}
                    showSearch
                    defaultValue=""
                    options={StateList}
                />
                <FormSelectionField
                    control={control}
                    name="consultation"
                    label={t("form-fields.consultation")}
                    placeholder="consultation"
                    errors={errors?.consultation}
                    showSearch
                    defaultValue=""
                    options={ConsulationOptions}
                />
                <FormDateField
                    control={control}
                    name="startdate"
                    label={`${t("form-fields.startdate")}`}
                    placeholder={`${t("form-fields.startdate")}`}
                    errors={errors?.startdate}
                    defaultValue={DateUtility.dayJSFormat(new Date())}
                />
                <FormDateField
                    control={control}
                    name="enddate"
                    label={`${t("form-fields.enddate")}`}
                    placeholder={`${t("form-fields.enddate")}`}
                    errors={errors?.enddate}
                />
                <FormTextFormField
                    control={control}
                    name="value"
                    label={t("form-fields.value")}
                    placeholder="value"
                    type="number"
                    errors={errors?.value}
                />
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? `${t("edit")}` : `${t("create")}`} {t("settings.state-rate")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

export const StateRateTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t, i18n: { language } } = useTranslation()
    const { data, setData, refetch, filterChanged, total, filter, pageChanged } = GetStateRateList()
    const [state,setState] = useState("")
    const onStateChange = (data) => {
        setState(data)
        filterChanged("search", { state: data })
    }
    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await StateRateService.add(formData)
                notification.success({ message: t("notif-message.category-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await StateRateService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.category-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeStateRate = async (id) => {
        try {
            await StateRateService.remove(id)
            notification.success({ message: t("notif-message.category-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const columns = useMemo(() => [
        {
            title: t("form-fields.state"),
            dataIndex: 'state',
            key: 'state',
            render: (text) => StateList.find(ele => ele.value === text).label,
            width: 700,
        },
        {
            title: t("form-fields.consultation"),
            dataIndex: 'consultation',
            key: 'consultation',
            render: (text) => t(`${ConsulationTypes[text]?.toLowerCase() || "-"}`),
            width: 700,
        },
        {
            title: t("form-fields.startdate"),
            dataIndex: 'startdate',
            key: 'startdate',
            width: 700,
            render: (text) => DateUtility.dateToString(text,DateFormat.euDate),
        },
        {
            title: t("form-fields.enddate"),
            dataIndex: 'enddate',
            key: 'enddate',
            width: 700,
            render: (text) => DateUtility.dateToString(text,DateFormat.euDate),
        },
        {
            title: t("form-fields.value"),
            dataIndex: 'value',
            key: 'value',
            width: 700,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.category")}` })}
                        onConfirm={() => removeStateRate(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >

                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ],[language]);

    const orderChanged = async (items) => {
        try {
            await StateRateService.OrderBy({ items: items.map(item => item._id), skip: filter.skip })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    const StateOptions = useMemo(() => [{ label: "All",value: "" },...StateList])

    return (
        <>
        <DesktopMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-4">
                    {t("settings.state-rate")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onStateChange} className="mx-2 col-2" defaultValue={state} options={StateOptions} />
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")}`}
                        options={[]}
                        className="mb-0 ms-5"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton></>}
            />
            <AddStateRateModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </DesktopMode>
        <MobileMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-4">
                    {t("settings.state-rate")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onStateChange} className="mx-2 col-4" defaultValue={state} options={StateOptions} />
                    </>}
            />
            <div className="col-12 d-flex py-1">
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")}`}
                        options={[]}
                        className="mb-0 ms-1"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                    </div>
            <AddStateRateModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="overflow-auto py-1">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </MobileMode>
        </>
    )
}
