import React, { useState, useEffect } from 'react'
import { Upload } from 'antd';
import { UploadSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton } from 'elements';
import { CommonUtility } from 'utility';

const { Dragger } = Upload;

const CustomeHiddenDragger = styled(Dragger)`
.ant-upload-drag {
    border: none !important;
    background: none;
}
`

export const FileUpload = ({ fileList = [], onNewFile, onRemoveModel,acceptedString, ...rest }) => {
    const { t } = useTranslation()
    return (
        <Dragger
            fileList={fileList}
            customRequest={onNewFile}
            onRemove={onRemoveModel}
            {...rest}
        >
            <p className="ant-upload-drag-icon">
                <UploadSimple />
            </p>
            {acceptedString && <p className="ant-upload-hint">
                {t(acceptedString)}
            </p>}
            <p className="ant-upload-text">
                {t("message.click-to-drag-file-upload")}
            </p>
            <p className="ant-upload-hint">
                {t("message.support-single-upload")}
            </p>
        </Dragger>
    )
}

export const HiddenFileUpload = ({ fileList = [], onNewFile, onRemoveModel, children, ...rest }) => {
    return (
        <CustomeHiddenDragger
            fileList={fileList}
            openFileDialogOnClick={false}
            customRequest={onNewFile}
            onRemove={onRemoveModel}
            {...rest}
        >
            {children}
        </CustomeHiddenDragger>
    )
}

export const PdfImagePreview = ({ file }) => {
    const [preview, setPreview] = useState(null);

    useEffect(() => {
        if (!file?.url) {
        if (file.type.includes('image')) {
            setPreview(URL.createObjectURL(file));
        } else if (file.type === 'application/pdf') {
            setPreview(file);
        } else {
            setPreview(null);
        }
    } else {
        setPreview(file.url)
    }
    }, [file]);
    return (
        <div>
            {file && (
                <div>
                    {preview && (
                        <>
                            {(file?.type?.includes('image') || CommonUtility.isURLImage(file.url)) && (
                                <img src={file.url} alt={file.name} style={{ maxWidth: '100%' }} />
                            )}
                            {(file?.type?.includes('application/pdf') || CommonUtility.isURLFile(preview)) && (
                                <embed
                                    src={file.url || URL.createObjectURL(preview)}
                                    type="application/pdf"
                                    width="100%"
                                    height="500px"
                                />
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
export const UploadButton = ({ onAdd }) => {
    const handleAdd = ({ file }) => {
        onAdd(file);
    };
    return (
        <div>
            <Upload
                maxCount={1}
                customRequest={handleAdd}
                itemRender={() => {}}
            >
                <PrimaryButton icon={<UploadSimple />}>
                    Upload
                </PrimaryButton>
            </Upload>
            {/* {files?.map((file) => (
                <PdfImagePreview key={file.uid} file={file} />
            ))} */}
        </div>
    );
};