import {
    DateFormat,
    DateUtility,
    PatientDocumentsType,
    PatientDocumentService,
    Seconds,
    StatusIcons,
    DocumentMode,
} from 'utility'
import {
    Checkbox,
    DatePicker,
    Dropdown,
    Popover,
    Select,
    Space,
    Tag,
    notification,
} from 'antd'
import {
    AppTable,
    FilePreview,
    HeaderTitle,
    HiddenFileUpload,
    PageHeader,
} from 'components'
import { ShadowBox } from 'elements'
import { useState, useMemo, useEffect } from 'react'
import {
    DesktopMode,
    GetDoctorsList,
    GetDocumentsCategory,
    GetPatientDocumentsList,
    GetPatientList,
    MobileMode,
    useDebounce,
} from 'hooks'
import { useTranslation } from 'react-i18next'
import { ArrowDown, ArrowUp, Check, Info, X } from 'phosphor-react'
import { AddEditDocumentModal } from './add-edit'
import { useAuth } from 'context'
import styled from 'styled-components'
import { User } from '@phosphor-icons/react'

const UserAccessConatiner = styled.div`
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
    opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
`

const DropButton = styled(Dropdown.Button)`
    .ant-btn-compact-first-item {
        display: none;
    }
    .ant-btn-compact-last-item {
        border-radius: 6px !important;
    }
`

const ValidateAcessUserList = ({ data, list }) => {
    const { user } = useAuth()
    return (
        <UserAccessConatiner
            className="my-0"
            disabled={data.doctor !== user._id}
        >
            {list.map((ele) => (
                <li className="mb-1">
                    {ele?.firstName} {ele?.lastName}
                    <Tag
                        color={
                            data.validationAccess.includes(ele._id)
                                ? 'green'
                                : 'red'
                        }
                        className="mx-2"
                    >
                        {data.validationAccess.includes(ele._id) ? (
                            <Check />
                        ) : (
                            <X />
                        )}
                    </Tag>
                </li>
            ))}
        </UserAccessConatiner>
    )
}

export const DocumentScreen = () => {
    const { patient, user, setPatient } = useAuth()
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [startDate, setStartDate] = useState(
        DateUtility.dayJSFormat(new Date()),
    )
    const [modalData, setModalData] = useState([])
    const [files, setFiles] = useState([])
    const [visible, setVisible] = useState(false)
    const [endDate, setEndDate] = useState(
        DateUtility.dayJSFormat(
            DateUtility.addSeconds(new Date(), Seconds.TEN_DAYS),
        ),
    )

    const { t } = useTranslation()
    const finalSearch = useDebounce(search)
    const { optionList: patientList, filterChanged } =
        GetPatientList(finalSearch)
    const { data: doctorList } = GetDoctorsList()
    const {
        optionList: documentsCategoryOptions,
        filterChanged: documentsCategoryFilterChange,
    } = GetDocumentsCategory()
    const {
        data,
        filterChanged: patientDocumentsFilterChanged,
        refetch,
        filter,
        total,
        pageChanged,
        loading,
    } = GetPatientDocumentsList(PatientDocumentsType.PATIENT, {
        startdate: startDate,
        enddate: endDate,
    })

    useEffect(() => {
        patientDocumentsFilterChanged('search', { patient: patient?._id })
    }, [patient])

    useEffect(() => {
        patientDocumentsFilterChanged('search', {
            startdate: startDate?.toISOString(),
            enddate: endDate?.toISOString(),
        })
    }, [endDate, startDate])

    useEffect(() => {
        filterChanged({ search })
    }, [search])

    const onClose = (data) => {
        if (data) {
            refetch()
        }
        setOpen(false)
        setModalData({})
    }

    const onNewFile = (file) => {
        setOpen(true)
        setFiles([file.file])
    }

    const onEdit = (data) => {
        setOpen(true)
        setModalData(data)
    }

    const handleOpenChange = () => {
        // setOpen();
    }

    const validateBy = async (id, status) => {
        try {
            await PatientDocumentService.validatedBy(id, status)
            notification.success({
                message: t('notif-message.category-updated'),
            })
            refetch()
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const ValidateUserList = ({ list }) => {
        return (
            <ul className="my-0">
                {list.map((ele) => (
                    <li className="mb-1">
                        {ele?.user?.firstName} {ele?.user?.lastName} -{' '}
                        {DateUtility.dateToString(
                            new Date(ele.date),
                            DateFormat.euDate,
                        )}
                        <Tag
                            color={ele.status ? 'green' : 'red'}
                            className="mx-2"
                        >
                            {ele.status ? t('validated') : t('rejected')}
                        </Tag>
                    </li>
                ))}
            </ul>
        )
    }

    const columns = useMemo(
        () => [
            {
                title: t('form-fields.patient'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) => (
                    <div>
                        {text?.firstName} {text?.lastName}
                    </div>
                ),
            },
            {
                title: t('form-fields.name'),
                dataIndex: 'name',
                key: 'name',
                render: (_, data) => <div>{data.name}</div>,
            },
            {
                title: t('form-fields.category'),
                dataIndex: 'category',
                key: 'category',
                render: (_, data) => <div>{data.category.name}</div>,
            },
            {
                title: t('form-fields.encounter'),
                dataIndex: 'encounter',
                key: 'encounter',
                render: (_, data) => (
                    <div>
                        {data.encounter?.name} -{' '}
                        {DateUtility.dateToString(
                            new Date(data?.encounter?.createdAt),
                            DateFormat.euDate,
                        )}
                    </div>
                ),
            },
            {
                title: t('form-fields.created-date'),
                dataIndex: 'date',
                key: 'date',
                render: (_, data) => (
                    <div>
                        {DateUtility.dateToString(
                            data.createdAt,
                            DateFormat.euDate,
                        )}
                    </div>
                ),
            },
            {
                title: t('form-fields.date-modified'),
                dataIndex: 'date',
                key: 'date',
                render: (_, data) => (
                    <div>
                        {DateUtility.dateToString(
                            data.updatedAt,
                            DateFormat.euDate,
                        )}
                    </div>
                ),
            },
            {
                title: t('form-fields.status'),
                dataIndex: 'status',
                key: 'status',
                width: 200,
                render: (status) => (
                    <div className="d-flex align-items-center">
                        {status && (
                            <>
                                {StatusIcons?.[status?.icon]}
                                {status?.status}
                            </>
                        )}
                    </div>
                ),
            },
            {
                title: t('form-fields.mode'),
                dataIndex: 'mode',
                key: 'mode',
                width: 200,
                render: (mode) => (
                    <div className="d-flex align-items-center">
                        <Tag
                            color={
                                mode === DocumentMode.INCOMING ? 'green' : 'red'
                            }
                            className="mx-2"
                        >
                            {mode === DocumentMode.INCOMING ? (
                                <ArrowDown />
                            ) : (
                                <ArrowUp />
                            )}
                        </Tag>
                    </div>
                ),
            },
            {
                title: t('form-fields.user'),
                dataIndex: 'validationAccess',
                key: 'validationAccess',
                render: (text, data) => (
                    <>
                        {text.length || ''}
                        <Popover
                            placement="bottomRight"
                            content={
                                <ValidateAcessUserList
                                    data={data}
                                    list={doctorList}
                                />
                            }
                        >
                            <User className="ms-2" />
                        </Popover>
                    </>
                ),
            },
            {
                title: t('form-fields.validatedby'),
                dataIndex: 'validatedBy',
                key: 'validatedBy',
                render: (_, data) => (
                    <div className="d-flex align-items-center">
                        <spna>
                            {data.validatedBy ? data.validatedBy.length : 0}
                        </spna>
                        <Popover
                            placement="bottomRight"
                            content={
                                <ValidateUserList list={data.validatedBy} />
                            }
                        >
                            <Info className="ms-2" />
                        </Popover>
                    </div>
                ),
            },
            {
                title: t('form-fields.validateddate'),
                dataIndex: 'validatedBy',
                key: 'validateddate',
                render: (_, data) => (
                    <div>
                        {data.validatedBy.length > 0
                            ? DateUtility.dateToString(
                                  data.validatedBy[0].date,
                                  DateFormat.euDate,
                              )
                            : ''}
                    </div>
                ),
            },
            {
                title: t('form-fields.actions'),
                key: 'action',
                render: (_, data) => {
                    const roleAcess = () => {
                        return (
                            data.doctor === user._id ||
                            data.validationAccess.includes(user._id)
                        )
                    }
                    return (
                        <Space size="middle">
                            <>
                                <DropButton
                                    onOpenChange={handleOpenChange}
                                    menu={{
                                        items: [
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            setVisible({
                                                                url: data.url,
                                                                title: data.name,
                                                            })}
                                                        className="option"
                                                    >
                                                        {t('view-document')}
                                                    </div>
                                                ),
                                                key: '1',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            onEdit(data)}
                                                        className={`${
                                                            !roleAcess() &&
                                                            'disabled'
                                                        } option`}
                                                    >
                                                        {t(
                                                            'add-edit.edit-document',
                                                        )}
                                                    </div>
                                                ),
                                                key: '2',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            validateBy(
                                                                data._id,
                                                                true,
                                                            )}
                                                        className={`${
                                                            !roleAcess() &&
                                                            'disabled'
                                                        } option`}
                                                    >
                                                        {t('validate-document')}
                                                    </div>
                                                ),
                                                key: '3',
                                            },
                                            {
                                                label: (
                                                    <div
                                                        onClick={() =>
                                                            validateBy(
                                                                data._id,
                                                                false,
                                                            )}
                                                        className={`${
                                                            !roleAcess() &&
                                                            'disabled'
                                                        } option`}
                                                    >
                                                        {t('reject-document')}{' '}
                                                    </div>
                                                ),
                                                key: '4',
                                            },
                                        ],
                                    }}
                                />
                            </>
                        </Space>
                    )
                },
            },
        ],
        [data, doctorList, user],
    )

    return (
        <>
            <DesktopMode>
                <HiddenFileUpload onNewFile={onNewFile}>
                    <PageHeader
                        left={<HeaderTitle>Documents</HeaderTitle>}
                        right={
                            <div className="d-flex row col-7">
                                <div className="col-1 d-flex align-items-center">
                                    {t('validated')}{' '}
                                    <Checkbox
                                        onClick={(e) => {
                                            patientDocumentsFilterChanged(
                                                'search',
                                                {
                                                    validated:
                                                        e.target.checked.toString(),
                                                },
                                            )
                                        }}
                                        className="ms-1"
                                    />
                                </div>
                                <div className="col-1 d-flex align-items-center">
                                    {t('rejected')}{' '}
                                    <Checkbox
                                        onClick={(e) => {
                                            patientDocumentsFilterChanged(
                                                'search',
                                                {
                                                    rejected:
                                                        e.target.checked.toString(),
                                                },
                                            )
                                        }}
                                        className="ms-1"
                                    />
                                </div>
                                <div className="col-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setStartDate}
                                        value={startDate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={endDate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Select
                                        name="search"
                                        filterOption={false}
                                        placeholder={t('search')}
                                        options={patientList}
                                        className="mb-0 col-12"
                                        onSearch={(data) => {
                                            setSearch(data)
                                        }}
                                        showSearch
                                        onSelect={(id, data) => {
                                            setPatient(data)
                                            filterChanged('search', {
                                                patient: id,
                                            })
                                        }}
                                    />
                                </div>
                                <div className="col-3">
                                    <Select
                                        name="search"
                                        required
                                        placeholder={`${t('search')} ${t(
                                            'category',
                                        )}`}
                                        options={documentsCategoryOptions}
                                        className="mb-0 col-12"
                                        showSearch
                                        onSearch={(data) => {
                                            documentsCategoryFilterChange(
                                                'search',
                                                { name: data },
                                            )
                                        }}
                                        onSelect={(data) =>
                                            patientDocumentsFilterChanged(
                                                'search',
                                                { category: data },
                                            )}
                                    />
                                </div>
                            </div>
                        }
                    />
                    <ShadowBox className="mt-3">
                        <div className="row py-3">
                            <AppTable
                                data={data}
                                columns={columns}
                                keyField="_id"
                                size="small"
                                currentPage={total.page}
                                total={total.count}
                                pageChanged={pageChanged}
                                pageSize={filter?.limit}
                                loading={loading}
                            />
                        </div>
                        <AddEditDocumentModal
                            onClose={onClose}
                            open={open}
                            patientId={patient._id}
                            documentType={PatientDocumentsType.PATIENT}
                            data={modalData}
                            files={files}
                            patientBox
                        />
                        <FilePreview
                            url={visible?.url}
                            title={visible?.title}
                            onClose={setVisible}
                            rightAlign
                        />
                    </ShadowBox>
                </HiddenFileUpload>
            </DesktopMode>
            <MobileMode>
                <HiddenFileUpload onNewFile={onNewFile}>
                    <PageHeader
                        left={<HeaderTitle>Documents</HeaderTitle>}
                        right={
                            <div className="d-flex row col-7">
                                <div className="col-6 d-flex align-items-center">
                                    {t('validated')}{' '}
                                    <Checkbox
                                        onClick={(e) => {
                                            patientDocumentsFilterChanged(
                                                'search',
                                                {
                                                    validated:
                                                        e.target.checked.toString(),
                                                },
                                            )
                                        }}
                                        className="ms-1"
                                    />
                                </div>
                                <div className="col-6 d-flex align-items-center">
                                    {t('rejected')}{' '}
                                    <Checkbox
                                        onClick={(e) => {
                                            patientDocumentsFilterChanged(
                                                'search',
                                                {
                                                    rejected:
                                                        e.target.checked.toString(),
                                                },
                                            )
                                        }}
                                        className="ms-1"
                                    />
                                </div>
                            </div>
                        }
                    />
                    <div className="col-12 d-flex">
                    <div className="col-6 mr-2">
                        <DatePicker
                            format={DateFormat.inputEuDate}
                            onChange={setStartDate}
                            value={startDate}
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="col-6 mr-2">
                        <DatePicker
                            format={DateFormat.inputEuDate}
                            onChange={setEndDate}
                            value={endDate}
                            style={{ width: '100%' }}
                        />
                    </div>
                    </div>
                    <div className="col-12 d-flex py-1">
                    <div className="col-6 mr-2">
                        <Select
                            name="search"
                            filterOption={false}
                            placeholder={t('search')}
                            options={patientList}
                            className="mb-0 col-12"
                            onSearch={(data) => {
                                setSearch(data)
                            }}
                            showSearch
                            onSelect={(id, data) => {
                                setPatient(data)
                                filterChanged('search', { patient: id })
                            }}
                        />
                    </div>
                    <div className="col-6 mr-2">
                        <Select
                            name="search"
                            required
                            placeholder={`${t('search')} ${t('category')}`}
                            options={documentsCategoryOptions}
                            className="mb-0 col-12"
                            showSearch
                            onSearch={(data) => {
                                documentsCategoryFilterChange('search', {
                                    name: data,
                                })
                            }}
                            onSelect={(data) =>
                                patientDocumentsFilterChanged('search', {
                                    category: data,
                                })}
                        />
                    </div>
                    </div>
                    <ShadowBox
                        className="overflow-auto"
                        style={{ width: '340px' }}
                    >
                        <AppTable
                            data={data}
                            columns={columns}
                            keyField="_id"
                            size="small"
                            currentPage={total.page}
                            total={total.count}
                            pageChanged={pageChanged}
                            pageSize={filter?.limit}
                            loading={loading}
                        />
                        <AddEditDocumentModal
                            onClose={onClose}
                            open={open}
                            patientId={patient._id}
                            documentType={PatientDocumentsType.PATIENT}
                            data={modalData}
                            files={files}
                            patientBox
                        />
                        <FilePreview
                            url={visible?.url}
                            title={visible?.title}
                            onClose={setVisible}
                            rightAlign
                        />
                    </ShadowBox>
                </HiddenFileUpload>
            </MobileMode>
        </>
    )
}
