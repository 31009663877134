import { theme } from 'utility/theme'
import { CheckCircle, EnvelopeSimple, MapPinLine, Phone, X, XCircle } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Modal, Popconfirm, Space, notification } from 'antd'
import { DateFormat, DateUtility, InsuranceTypeConversion, MangeInsuranceTypes, PatientInsuranceService } from 'utility'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from 'context'
import { DesktopMode, GetInsuranceList, GetInsuranceTypeList, GetPatientInsuranceList, MobileMode } from 'hooks'
import { DangerButton, FormCheckBoxField, FormDateField, FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { Plus } from 'phosphor-react'
import { AppTable, AutoCompleteDropDown, FlexColumn, FlexRow, FlexRowBetween, HeaderTitle, PageHeader } from 'components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const DataBox = ({ children, className }) => {
    return (
        <div className={className}>
            {!children ? <X size={18} color={theme.colors.danger} /> : children}
        </div>
    )
}
const PatientINsuranceSchema = yup.object().shape({
    insurance: yup.string().required('insurance is required'),
    insurancecategory: yup.string().required("insurancecategory is required"),
    additional: yup.string(),
    cadaNumber: yup.string().required("cadaNumber is required"),
    insuranceCardNumber: yup.string().required("insuranceCardNumber is required"),
    insuredNumber: yup.string().required("insuredNumber is required"),
    manageType: yup.string().required("manageType is required"),
    comment: yup.string(),
    valid: yup.boolean(),
})

const AddPatientInsuranceModal = ({ open, data, editMode, onClose, loading, onSubmit, insuranceList, removePatientInsurance }) => {
    const { reset, control, handleSubmit, watch, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(PatientINsuranceSchema),
    })
    const { t } = useTranslation()
    const { data: insuranceTypeList } = GetInsuranceTypeList(t)
    const insurance = watch("insurance")
    const type = watch("type")

    useEffect(() => {
        setValue("insurancecategory", insuranceList.find(ele => ele._id === insurance)?.insurancecategory)
    }, [insurance])

    const insuranceListByType = useMemo(() => {
        return type ? insuranceList.filter(ele => ele.insurancecategory === type) : insuranceList
    }, [type])

    useEffect(() => {
        reset(
            editMode
                ? {
                    ...data,
                    insurance: data.insurance._id,
                    type: data.insurancecategory,
                    startdate: DateUtility.dayJSFormat(data.startdate),
                    enddate: DateUtility.dayJSFormat(data.enddate),
                }
                : {
                    startdate: DateUtility.dayJSFormat(new Date()),
                    enddate: DateUtility.dayJSFormat(DateUtility.addDay(new Date(), 365)),
                },
        )
    }, [open, editMode])
    return (
        <Modal
            title={editMode ? t('edit-insurance') : t('add-insurance')}
            visible={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="col-12 mb-0">
                        <FormSelectionField
                            control={control}
                            name="type"
                            label={t('form-fields.type')}
                            placeholder=""
                            errors={errors?.type || ''}
                            disabled={editMode}
                            options={insuranceTypeList}
                        />
                    </Form.Item>
                    <Form.Item className="col-12 mb-0">
                        <FormSelectionField
                            control={control}
                            name="insurance"
                            label={t('form-fields.insurance')}
                            placeholder=""
                            errors={errors?.insurance || ''}
                            disabled={editMode}
                            options={insuranceListByType}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormDateField
                            control={control}
                            name="startdate"
                            label={t('form-fields.startdate')}
                            placeholder=""
                            showSearch
                            defaultValue=""
                            errors={errors?.startdate || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormDateField
                            control={control}
                            name="enddate"
                            label={t('form-fields.enddate')}
                            placeholder=""
                            showSearch
                            defaultValue=""
                            errors={errors?.enddate || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-12 mb-0">
                        <FormTextFormField
                            control={control}
                            name="additional"
                            label={t('form-fields.additional')}
                            placeholder=""
                            errors={errors?.additional || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormTextFormField
                            control={control}
                            name="insuredNumber"
                            label={t('form-fields.insuredNumber')}
                            placeholder=""
                            errors={errors?.insuredNumber
                                || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormTextFormField
                            control={control}
                            name="cadaNumber"
                            label={t('form-fields.idcard')}
                            placeholder=""
                            errors={errors?.cadaNumber || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormTextFormField
                            control={control}
                            name="insuranceCardNumber"
                            label={t('form-fields.insuranceCardNumber')}
                            placeholder=""
                            errors={errors?.insuranceCardNumber || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormSelectionField
                            control={control}
                            name="manageType"
                            label={t('form-fields.manageType')}
                            placeholder=""
                            errors={errors?.manageType || ''}
                            options={MangeInsuranceTypes}
                        />
                    </Form.Item>
                    <Form.Item className="col-6 mb-0">
                        <FormTextFormField
                            control={control}
                            name="comment"
                            label={t('form-fields.comment')}
                            placeholder=""
                            errors={errors?.comment || ''}
                        />
                    </Form.Item>
                    <div className="col-5 d-flex flex-row">
                        <FormCheckBoxField
                            control={control}
                            name="valid"
                            text={t('Valid')}
                            errors={errors?.valid}
                            defaultValue="true"
                        />
                        <FormCheckBoxField
                            control={control}
                            name="primary"
                            text={t('form-fields.primary')}
                            placeholder=""
                            errors={errors?.primary || ''}
                        />
                    </div>
                </div>
                <Space wrap>
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removePatientInsurance(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton>{t("delete")}</DangerButton>
                    </Popconfirm>
                    <Button key="cancel" onClick={() => onClose()} style={{ marginLeft: "150px" }}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('edit-insurance')
                            : t('add-insurance')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    );
};

export const PatientInsuranceBox = ({ columnsToShow }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { patient, user } = useAuth();
    const [editMode, setEditMode] = useState(false);
    const { optionList: insuranceList,filter } = GetInsuranceList({ all: true })
    const [showManageTypeColumn, setShowManageTypeColumn] = useState(false);
    const {
        data,
        setData,
        refetch,
    } = GetPatientInsuranceList(patient._id, { all: true })
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }
    const onRowDoubleClick = (rowData) => {
        setEditMode(true);
        setShowManageTypeColumn(true);
        onOpen(rowData);
    }

    const onClose = () => {
        setEditMode(false);
        setOpen(false)
        setShowManageTypeColumn(false);
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                formData.patient = patient._id;
                formData.doctor = user._id;
                await PatientInsuranceService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                delete formData.patient
               await PatientInsuranceService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            if (error.statusCode === 400) {
                notification.error({ message: t('notif-message.insurance-category-exits') })
                return
            }
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const removePatientInsurance = async (id) => {
        try {
            await PatientInsuranceService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            onClose()
        }
    }

    const orderChanged = async (items) => {
        try {
            await PatientInsuranceService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }
    const columns = [
        {
            title: t('insurance'),
            dataIndex: 'insurance',
            key: 'insurance',
            width: 100,
            render: (text) => <>{text.insurance}</>,
        },
        {
            title: t('insurancecategory'),
            dataIndex: 'insurancecategory',
            key: 'insurancecategory',
            render: (text) => <>{InsuranceTypeConversion[text]}</>,
            width: 100,
        },
        {
            title: t('form-fields.startdate'),
            dataIndex: 'startdate',
            key: 'startdate',
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
            width: 100,
        },
        {
            title: t('form-fields.enddate'),
            dataIndex: 'enddate',
            key: 'enddate',
            width: 100,
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
        },
        {
            title: t('form-fields.idcard'),
            dataIndex: 'cadaNumber',
            key: 'cadaNumber',
            width: 100,
        },
        {
            title: t('form-fields.manageType'),
            dataIndex: 'manageType',
            key: 'manageType',
            width: 100,
            render: (text) => <>{text}</>,
            hidden: !showManageTypeColumn,
        },
        {
            title: t('form-fields.notes'),
            dataIndex: 'notes',
            key: 'notes',
            width: 100,
        },
        {
            title: t('Valid'),
            dataIndex: 'valid',
            key: 'valid',
            width: 100,
            render: (text) => (
                <>{text ? <CheckCircle size={20} /> : <XCircle size={20} />}</>
            ),
        },
    ]

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('insurance')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'insurance category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.insurance-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.Notes-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-5"
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <AddPatientInsuranceModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                insuranceList={insuranceList}
                editMode={editMode}
                setData={setData}
                removePatientInsurance={removePatientInsurance}
            />
            <div className="mt-sm-2 mt-md-0">
                <AppTable
                    data={data}
                    columns={columns.filter((col) => columnsToShow.includes(col.key))}
                    keyField="_id"
                    size="small"
                    onRowDoubleClick={onRowDoubleClick}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}

export const GeneralBox = ({ data }) => {
    const { t } = useTranslation()

    const GetPhoneNo = ({ phoneNumbers }) => {
        if (phoneNumbers && phoneNumbers.length > 0) {
            return (
                <div>
                    {phoneNumbers.map((phoneNumber, index) => (
                        <div key={index} className="d-flex">
                            {phoneNumber.type && (
                                <>
                                    ({phoneNumber.type})&nbsp;
                                </>
                            )}
                            {phoneNumber.phone && (
                                <a href={`tel:${phoneNumber.phone}`}>{phoneNumber.phone}</a>
                            )}
                        </div>
                    ))}
                </div>
            );
        }
        return null
    }

    const GetAdress = ({ address, extraaddress }) => {
        return (
            <div>
                <Space size={[0, 2]} wrap>
                    {`${address?.addresstype ? `${address.addresstype} : ` : ''
                        } ${address?.address || address?.street || ''} ${address?.postalCode ? ` ${address.postalCode}` : ''
                        } ${address?.city ? ` ${address.city}` : ''} ${address?.country ? `(${address.country})` : ''
                        }`}
                    {extraaddress && extraaddress.length > 0 && (
                        <div>
                            {/* extraaddress display */}
                            {extraaddress.map((extraaddress, index) => (
                                <div key={index}>
                                    {`${extraaddress?.addresstype
                                        ? `${extraaddress.addresstype} : `
                                        : ''
                                        } ${extraaddress?.address ||
                                        extraaddress?.street ||
                                        ''
                                        } ${extraaddress?.postalCode
                                            ? ` ${extraaddress.postalCode}`
                                            : ''
                                        } ${extraaddress?.city
                                            ? ` ${extraaddress.city}`
                                            : ''
                                        } ${extraaddress?.country
                                            ? `(${extraaddress.country})`
                                            : ''
                                        }`}
                                </div>
                            ))}
                        </div>
                    )}
                </Space>
            </div>
        )
    }
    return (
        <div>
            <div>
                <h4 className="col-12 row">
                    <div className="col-4 d-flex">
                        <MapPinLine className="mr-2" size={20} />
                        {t('address')} :
                    </div>
                    <DataBox className="col-6 wrap">
                        <GetAdress
                            address={data.address}
                            extraaddress={data.extraaddress}
                        />
                    </DataBox>
                </h4>
            </div>
            <div>
                <h4 className="col-12 row">
                    <div className="col-4 d-flex">
                        <Phone className="mr-2" size={20} />
                        {t('form-fields.phone')} :
                    </div>
                    <DataBox className="col-6 wrap">
                        <a href={`tel:${data?.phone}`}>
                            {data?.phone}
                            {/* {data?.alternatePhone &&
                                `, ${data?.alternatePhone}`} */}
                            <GetPhoneNo phoneNumbers={data?.alternatePhone} />
                        </a>
                    </DataBox>
                </h4>
            </div>
            <div>
                <h4 className="col-12 row">
                    <div className="col-4 d-flex">
                        <EnvelopeSimple className="mr-2" size={20} />
                        {t('form-fields.email')} :
                    </div>
                    <DataBox className="col-6 wrap" type="email">
                        <a href={`mailto: ${data?.email}`}>{data?.email}</a>
                    </DataBox>
                </h4>
            </div>
        </div>
    )
}

export const MedicalBox = ({ data }) => {
    const { t } = useTranslation()
    return (
        <div>
            <p>
                <h4 className="col-12 row">
                    <div className="col-4 d-flex">
                        {t('form-fields.allergies')} :
                    </div>
                    <DataBox className="col-6 wrap">{data?.allergies}</DataBox>
                </h4>
            </p>
        </div>
    )
}