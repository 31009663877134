'this is the listen of appointment which is rendering below the appointments this take data from the common.js and make a state on the appointment list'

import React, { useState, useMemo, useEffect } from 'react'
import { Alert, DatePicker, List, Popconfirm, Select, Space } from 'antd'
import { DangerButton, PrimaryButton, ShadowBox } from 'elements'
import { NavLink } from 'react-router-dom'
import {
    ColumnSearchType,
    DateFormat,
    DateUtility,
    LocaleFormat,
    Seconds,
} from 'utility'
import { Info, Pencil, Trash, Envelope } from '@phosphor-icons/react'
import {
    AppTable,
    AutoCompleteDropDown,
    ColorBox,
    HeaderTitle,
    PageHeader,
} from 'components'
import { useTranslation } from 'react-i18next'
import Item from 'antd/es/list/Item'
import {
    DesktopMode,
    GetAppointmentCategory,
    GetAppointmentDurationList,
    GetAppointmentList,
    MobileMode,
} from 'hooks'
import styled from 'styled-components'
import { useAuth } from 'context'

const PatientBox = styled(ShadowBox)`
    height: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: max-content;
    ${({ selected, theme }) =>
        selected &&
        `background: ${theme.colors.primary};color: ${theme.colors.white}`};
`

export const AppointmentTableListScreen = ({
    editEvent,
    removeAppointment,
}) => {
    const { patient } = useAuth()
    const [startDate, setStartDate] = useState(
        DateUtility.dayJSFormat(new Date()),
    )
    const [endDate, setEndDate] = useState(
        DateUtility.dayJSFormat(
            DateUtility.addSeconds(new Date(), Seconds.ONE_WEEK),
        ),
    )
    const [patientSelected, setPatientSelected] = useState(null)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { data: appointmentDurationList } = GetAppointmentDurationList(t)
    const [timeDuration, setTimeDuration] = useState(
        appointmentDurationList[1].value,
    )
    const { optionList } = GetAppointmentCategory()
    const {
        data: list,
        filterChanged,
        filter,
        sortChanged,
    } = GetAppointmentList({ startdate: startDate, enddate: endDate })

    const categoryOptionList = useMemo(() => {
        return [
            {
                label: 'All',
                value: '',
            },
            ...optionList,
        ]
    }, [optionList])

    useEffect(() => {
        filterChanged('search', {
            patient: patientSelected ? patient._id : null,
        })
    }, [patientSelected])
    useEffect(() => {
        if (timeDuration > -1) {
            setStartDate(DateUtility.dayJSFormat(new Date()))
            setEndDate(
                DateUtility.dayJSFormat(
                    DateUtility.addSeconds(new Date(), timeDuration),
                ),
            )
        } else {
            setStartDate(null)
            setEndDate(null)
        }
    }, [timeDuration])

    useEffect(() => {
        filterChanged('search', {
            startdate: startDate?.toISOString(),
            enddate: endDate?.toISOString(),
        })
    }, [endDate, startDate, patient])

    const onSelect = () => {
        setPatientSelected(!patientSelected)
    }

    const columns = [
        {
            title: t('form-fields.patient'),
            dataIndex: 'patient',
            key: 'resourcelist',
            sorter: true,
            search: true,
            render: (text) => (
                <p>
                    {text?.firstName || ''} {text?.lastName || ''}
                </p>
            ),
            width: 700,
        },
        {
            title: t('form-fields.reason-for-visit'),
            dataIndex: 'reason_for_visit',
            key: 'reason_for_visit',
            sorter: true,
            search: true,
            width: 700,
            render: (text) => <p>{text || ''}</p>,
        },
        {
            title: t('form-fields.appointment-date'),
            dataIndex: 'appointment_date',
            key: 'appointment_date',
            type: ColumnSearchType.Date,
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
            sorter: true,
            search: true,
            width: 300,
        },
        {
            title: t('form-fields.start-time'),
            dataIndex: 'start_time',
            key: 'start_time',
            type: ColumnSearchType.Date,
            render: (text) =>
                DateUtility.dateToString(text, DateFormat.fulltime),
            sorter: true,
            search: true,
            width: 200,
        },
        {
            title: t('form-fields.end-time'),
            dataIndex: 'end_time',
            key: 'end_time',
            type: ColumnSearchType.Date,
            render: (text) =>
                DateUtility.dateToString(text, DateFormat.fulltime),
            sorter: true,
            search: true,
            width: 200,
        },
        {
            title: t('form-fields.duration'),
            dataIndex: 'duration',
            key: 'duration',
            render: (_, data) =>
                DateUtility.getDistanceInWordBetween(
                    new Date(data.start_time),
                    new Date(data.end_time),
                    LocaleFormat[language],
                ),
            sorter: true,
            search: true,
            width: 200,
        },
        {
            title: t('form-fields.category'),
            dataIndex: 'category',
            key: 'category',
            render: (text) => (
                <p className="d-flex">
                    <ColorBox color={text?.color} />
                    &nbsp;&nbsp;{text?.name}
                </p>
            ),
            sorter: true,
            search: true,
            width: 600,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <NavLink to={`${data._id}`}>
                        <PrimaryButton icon={<Info />} />
                    </NavLink>
                    <div
                        onClick={() => editEvent(data)}
                        to={`edit/${data._id}`}
                    >
                        <PrimaryButton icon={<Pencil />} />
                    </div>
                    <NavLink to={`/${data._id}`}>
                        <PrimaryButton icon={<Envelope />} />
                    </NavLink>
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('appointment')}`,
                        })}
                        onConfirm={() => removeAppointment(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const handleTableChange = (sorter) => {
        if (filter.sortBy !== sorter.field || filter.orderby !== sorter.order) {
            sortChanged({
                sortBy: sorter.field,
                orderby: sorter.order,
            })
        }
    }

    return (
        <>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="">
                            {t('appointments')}
                        </HeaderTitle>
                    }
                    right={
                        <div className="col-10">
                            <div className="row justify-content-end">
                                <div className="col justify-content-end d-flex">
                                    {patient._id && (
                                        <div
                                            onClick={() =>
                                                onSelect(patient._id)}
                                        >
                                            <PatientBox
                                                selected={patientSelected}
                                                className="d-flex align-items-center"
                                            >
                                                {patient.firstName}{' '}
                                                {patient.lastName}
                                                {patient?.birthdate && (
                                                    <span>
                                                        &nbsp;(
                                                        {DateUtility.getDistanceInWord(
                                                            new Date(
                                                                patient?.birthdate,
                                                            ),
                                                            language,
                                                        )}
                                                        )
                                                    </span>
                                                )}
                                                {/* <X size={18} className="ml-2" onClick={onRemove} /> */}
                                            </PatientBox>
                                        </div>
                                    )}
                                </div>
                                <div className="col-2 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setStartDate}
                                        value={startDate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-2 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={endDate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-2 mr-2">
                                    <Select
                                        defaultValue=""
                                        options={categoryOptionList}
                                        onChange={(data) =>
                                            filterChanged('search', {
                                                category: data,
                                                limit: 1000000,
                                            })}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-2 mr-2">
                                    <Select
                                        options={appointmentDurationList}
                                        showArrow
                                        value={timeDuration}
                                        onChange={(value) =>
                                            setTimeDuration(value)}
                                        placeholder={t('Time-duration')}
                                        style={{ width: '100%' }}
                                        // onChange={""}
                                        popupMatchSelectWidth
                                    />
                                </div>
                                <div className="col-3">
                                    <AutoCompleteDropDown
                                        name="search"
                                        required
                                        placeholder={`${t('search')} ${t(
                                            'appointments',
                                        )}`}
                                        options={[]}
                                        className="mb-0"
                                        onSearch={(data) =>
                                            filterChanged('search', {
                                                reason_for_visit: data,
                                            })}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
                <ShadowBox>
                    <AppTable
                        data={list}
                        columns={columns}
                        keyField="_id"
                        filterChanged={(key, value) =>
                            filterChanged('search', { [key]: value })}
                        onChange={handleTableChange}
                        size="small"
                    />
                </ShadowBox>
            </DesktopMode>
            <MobileMode>
                <HeaderTitle className="">{t('appointments')}</HeaderTitle>
                <div className="col-11">
                    <div className="row">
                        <div className="col d-flex">
                            {patient._id && (
                                <div onClick={() => onSelect(patient._id)}>
                                    <PatientBox
                                        selected={patientSelected}
                                        className="d-flex align-items-center"
                                    >
                                        {patient.firstName} {patient.lastName}
                                        {patient?.birthdate && (
                                            <span>
                                                &nbsp;(
                                                {DateUtility.getDistanceInWord(
                                                    new Date(
                                                        patient?.birthdate,
                                                    ),
                                                    language,
                                                )}
                                                )
                                            </span>
                                        )}
                                    </PatientBox>
                                </div>
                            )}
                        </div>
                        <div className="col-12 d-flex py-2">
                            <div className="col-6 mr-2">
                                <DatePicker
                                    format={DateFormat.inputEuDate}
                                    onChange={setStartDate}
                                    value={startDate}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-6 mr-2">
                                <DatePicker
                                    format={DateFormat.inputEuDate}
                                    onChange={setEndDate}
                                    value={endDate}
                                    style={{ width: '100%' }}
                                />
                            </div>
                        </div>
                        <div className="col-12 d-flex">
                            <div className="col-6 mr-2">
                                <Select
                                    defaultValue=""
                                    options={categoryOptionList}
                                    onChange={(data) =>
                                        filterChanged('search', {
                                            category: data,
                                            limit: 1000000,
                                        })}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className="col-6 mr-2">
                                <Select
                                    options={appointmentDurationList}
                                    showArrow
                                    value={timeDuration}
                                    onChange={(value) => setTimeDuration(value)}
                                    placeholder={t('Time-duration')}
                                    style={{ width: '100%' }}
                                    popupMatchSelectWidth
                                />
                            </div>
                        </div>
                        <div className="col-12 py-2">
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'appointments',
                                )}`}
                                options={[]}
                                className="mb-0"
                                onSearch={(data) =>
                                    filterChanged('search', {
                                        reason_for_visit: data,
                                    })}
                            />
                        </div>
                    </div>
                </div>
                <ShadowBox className="overflow-auto m-5 px-5">
                    <AppTable
                        data={list}
                        columns={columns}
                        keyField="_id"
                        filterChanged={(key, value) =>
                            filterChanged('search', { [key]: value })}
                        onChange={handleTableChange}
                        size="small"
                    />
                </ShadowBox>
            </MobileMode>
        </>
    )
}

export const WaitingRoomList = ({ list, loading }) => {
    return (
        <>
            {list.length ? (
                <List>
                    {list?.map((item, i) => {
                        return (
                            <Item key={`${i} + ${item._id}`}>
                                <div>
                                    <span>
                                        {item?.patient?.firstName || ''}
                                    </span>
                                    <span>
                                        &nbsp;&nbsp;
                                        {item?.patient?.lastName || ''}
                                    </span>
                                    <span>
                                        {item?.patient?._id &&
                                            ` - ${DateUtility.dateToString(
                                                item.patient.birthdate,
                                                DateFormat.euDate,
                                            )}`}
                                    </span>
                                    {item?.reason_for_visit && (
                                        <h5 className="my-0">
                                            {`${item?.reason_for_visit}`}{' '}
                                        </h5>
                                    )}
                                </div>
                            </Item>
                        )
                    })}
                </List>
            ) : (
                <div className="my-3">
                    {!loading && (
                        <Alert
                            message="No appointments found"
                            type="info"
                            showIcon
                        />
                    )}
                </div>
            )}
        </>
    )
}
