import React from 'react'
import { Statistic } from 'antd';
import { useAuth } from 'context';
import { GetStatisticDetails } from 'hooks/statistics';
import { Roles } from 'utility';
import { ShadowBox } from 'elements';
import { Calendar, Note, User } from 'phosphor-react';
import { Stethoscope } from '@phosphor-icons/react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { PatientStatisticScreen } from 'page-components/statistics';
import { DesktopMode, MobileMode } from 'hooks';

const formatter = (value) => <CountUp end={value} separator="," />;

export const StatisticScreen = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { data } = GetStatisticDetails(user.role === Roles.admin ? "" : user._id)
  return (
    <>
    <DesktopMode>
    <div className="col-12 mt-4">
      <div className="row">
        <div className="col-3">
          <ShadowBox>
            <Statistic
              prefix={<Stethoscope size={25} className="" />}
              title={t('doctors')}
              value={data.doctor}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <div className="col-3">
          <ShadowBox>
            <Statistic
              prefix={<User size={25} className="" />}
              title={t('patients')}
              value={data.patient}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <div className="col-3">
          <ShadowBox>
            <Statistic
              prefix={<Calendar size={25} className="" />}
              title={t('appointments')}
              value={data.appointment}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <div className="col-3">
          <ShadowBox>
            <Statistic
              prefix={<Note size={25} className="" />}
              title={t('encounters')}
              value={data.encounter}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <PatientStatisticScreen />
      </div>
    </div>
    </DesktopMode>
    <MobileMode>
    <div className="col-12 mt-2">
      <div className="row">
        <div className="col-12">
          <ShadowBox>
            <Statistic
              prefix={<Stethoscope size={25} className="" />}
              title={t('doctors')}
              value={data.doctor}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <div className="col-12 mt-2">
          <ShadowBox>
            <Statistic
              prefix={<User size={25} className="" />}
              title={t('patients')}
              value={data.patient}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <div className="col-12 mt-2">
          <ShadowBox>
            <Statistic
              prefix={<Calendar size={25} className="" />}
              title={t('appointments')}
              value={data.appointment}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <div className="col-12 mt-2">
          <ShadowBox>
            <Statistic
              prefix={<Note size={25} className="" />}
              title={t('encounters')}
              value={data.encounter}
              formatter={formatter}
            />
          </ShadowBox>
        </div>
        <PatientStatisticScreen />
      </div>
    </div>
    </MobileMode>
    </>
  );
};