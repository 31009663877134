import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Button, Collapse, Divider, Form, Modal, Pagination, Popconfirm, Select, Space, notification } from 'antd'
import { AutoCompleteDropDown, HeaderTitle, PageHeader, AppTable } from 'components'
import { DangerButton, FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetInvoiceCodesList, GetInvoicesGroupList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CommonConstant, DateUtility, InvoiceTarmedType, InvoicesGroupService } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { List, Minus, PlusCircle, User } from 'phosphor-react'
import { DndContext } from '@dnd-kit/core';
import { useSortable, arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities'

const DragHandle = ({ id, item, i, editRemoveButton, onUpdate }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });
    const { t } = useTranslation()
    const style = {
        transform: CSS.Transform.toString(
            transform && {
                ...transform,
                scaleY: 1,
            },
        )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
        transition,
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };

    const onQtyUpdate = async (groupid,codeid,status) => {
        let list = [...item.invoicescodes]
        if (status) {
            if (list.find(ele => ele.invoice === codeid)) {
                list = list.map(ele => ({ invoice: ele.invoice, quantity: ele.invoice === codeid ? ele.quantity + 1 : ele.quantity }))
            } else {
                list.push({ invoice: codeid, quantity: 1 })
            }
        } else {
            list = list.map(ele => ({ invoice: ele.invoice, quantity: ele.invoice === codeid ? ele.quantity - 1 : ele.quantity }))
        }
        try {
                await InvoicesGroupService.update(item._id, { invoicescodes: list })
                notification.success({ message: t("notif-message.category-updated") })
                onUpdate(groupid,list)
        } catch {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    return (
        <div ref={setNodeRef} style={style} {...attributes} className="mb-2">
            <Collapse>
                <Collapse.Panel header={item.name} key={`${i} ${item._id}`} extra={editRemoveButton(item, listeners)}>
                    <p className="row">
                        <h4 className="col-1">{t("invoice-codes-fields.service_code")}</h4>
                        <h4 className="col-1">{t("invoice-codes-fields.chapter_code")}</h4>
                        <h4 className="col-4">{t("invoice-codes-fields.service_appellation")}</h4>
                        <h4 className="col-1">{t("invoice-codes-fields.functional_unit_code")}</h4>
                    </p>
                    {item.invoicescodes?.map((ele, i) => (
                        <p className="row" key={ele?.invoice?._id}>
                            {i !== 0 && <Divider />}
                            <div className="col-1">{ele?.invoice?.service_code}</div>
                            <div className="col-1">{ele?.invoice?.chapter_code}</div>
                            <div className="col-4">{ele?.invoice?.service_appellation}</div>
                            <div className="col-1">{ele?.invoice?.functional_unit_code}</div>
                            <div className="col justify-content-end d-flex">
                                <Space size="middle">
                                    <div className="row">
                                        <div className="col-4">{ele?.quantity && <PrimaryButton onClick={() => { onQtyUpdate(item._id,ele.invoice,false) }} icon={<Minus />} />}</div>
                                        <div className="col-4 text-center">{ele?.quantity || "0"}</div>
                                        <div className="col-4"><PrimaryButton onClick={() => { onQtyUpdate(item._id,ele.invoice,true) }} icon={<PlusCircle />} /></div>
                                    </div>
                                </Space>
                            </div>
                        </p>),
                    )}
                </Collapse.Panel>
            </Collapse>
        </div>
    );
};

const InvoiceCodesShema = yup.object().shape({
    tarmed_type: yup.string().required('tarmed_type is required'),
    name: yup.string().required("service_code is required"),
})

const GroupList = ({ list, onEdit, removeInvoiceGroup, loading, onUpdate, orderChanged }) => {
    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        setDataSource([...list])
    }, [list])
    const { t } = useTranslation()
    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setDataSource((prev) => {
                const activeIndex = prev.findIndex((i) => i._id === active.id);
                const overIndex = prev.findIndex((i) => i._id === over?.id);
                let movedItems = arrayMove(prev, activeIndex, overIndex);
                movedItems = movedItems.map((item, index) => ({ ...item, order: index }))
                orderChanged(movedItems)
                return movedItems
            });
        }
    }
    const editRemoveButton = (item, listeners) => (
        <Space size="middle">
            <PrimaryButton icon={<Pencil />} onClick={(e) => { e.stopPropagation(); e.preventDefault(); onEdit(item) }} />
            <Popconfirm
                title={t("delete")}
                description={t("message.delete-message", { name: `${t("form-fields.category")}` })}
                onConfirm={(e) => {
                    e.stopPropagation()
                    e.preventDefault();
                    removeInvoiceGroup(item._id)
                }}
                onCancel={(e) => e.stopPropagation()}
                okButtonProps={{ disabled: false }}
                cancelButtonProps={{ disabled: false }}
            >
                <DangerButton icon={<Trash />} htmlType="button" onClick={(e) => e.stopPropagation()} />
            </Popconfirm>
            <List size={22} {...listeners} />
        </Space>
    );

    return (
        <>
            <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                    items={dataSource.map((i) => i._id)}
                    strategy={verticalListSortingStrategy}
                >
                    {dataSource.map((item, i) => {
                        return (
                            <DragHandle id={item._id} key={item._id} item={item} i={i} editRemoveButton={editRemoveButton} onUpdate={onUpdate} />
                        )
                    })}
                </SortableContext>
            </DndContext>
            <div className="my-3">
                {!loading && !dataSource.length && <Alert message="No snippets found" type="info" showIcon />}
            </div>
        </>

    )
}

const AddInvoiceCodesModal = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const {
        handleSubmit, control, reset, watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(InvoiceCodesShema),
        defaultValues: {
            tarmed_type: "TARMED8",
        },
    })
    const { data: tableData, total, filter, pageChanged, filterChanged } = GetInvoiceCodesList({ tarmed_type: "TARMED8" })
    const { t } = useTranslation()
    const localtarmedType = watch("tarmed_type")
    const [groupList, setGroupList] = useState([])
    useEffect(() => {
        filterChanged("search", {
            tarmed_type: localtarmedType,
        })
    }, [localtarmedType])

    const submit = (formData) => {
        onSubmit({ ...formData, invoicescodes: groupList })
    }

    const amount = useMemo(() => {
        let rate = 0;
        groupList.map(ele => {
            for (let i = 0; i < ele.quantity; i += 1) {
                rate += ele.invoice.rate
            }
            return ""
        })
        return rate
    },[groupList])

    const onToggle = (data, status) => {
        let list = [...groupList]
        if (status) {
            if (list.find(ele => ele.invoice._id === data._id)) {
                list = list.map(ele => ({ invoice: ele.invoice, quantity: ele.invoice._id === data._id ? ele.quantity + 1 : ele.quantity }))
            } else {
                list.push({ invoice: data, quantity: 1 })
            }
        } else {
            list = list.map(ele => ({ invoice: ele.invoice, quantity: ele.invoice._id === data._id ? ele.quantity - 1 : ele.quantity }))
        }
        setGroupList(list)
    }

    const columns = useMemo(() => {
        return [
            {
                title: t("invoice-codes-fields.service_code"),
                dataIndex: 'service_code',
                key: 'service_code',
                width: 100,
            },
            {
                title: t("invoice-codes-fields.service_appellation"),
                dataIndex: 'service_appellation',
                key: 'service_appellation',
                width: 300,
            },
            {
                title: t("invoice-codes-fields.chapter_code"),
                dataIndex: 'chapter_code',
                key: 'chapter_code',
            },
            {
                title: t("invoice-codes-fields.qualitative_dignity_code"),
                dataIndex: 'qualitative_dignity_code',
                key: 'qualitative_dignity_code',
                width: 100,
            },
            {
                title: t("invoice-codes-fields.rate"),
                dataIndex: 'rate',
                key: 'rate',
                width: 100,
            },
            {
                title: t("form-fields.actions"),
                key: 'action',
                render: (_, data) => {
                    return (<Space size="middle">
                        <div className="row">
                            <div className="col-4">{groupList.find(ele => ele.invoice._id === data._id)?.quantity && <PrimaryButton onClick={() => onToggle(data, false)} icon={<Minus />} />}</div>
                            <div className="col-4 text-center">{groupList.find(ele => ele.invoice._id === data._id)?.quantity || "0"}</div>
                            <div className="col-4"><PrimaryButton onClick={() => onToggle(data, true)} icon={<PlusCircle />} /></div>
                        </div>
                    </Space>)
                },
            },
        ]
    }, [groupList]);

    const columnsGroup = useMemo(() => {
        return [
            {
                title: t("invoice-codes-fields.service_code"),
                dataIndex: 'invoice',
                key: 'invoice',
                width: 100,
                render: (text) => <>{text.service_code}</>,
            },
            {
                title: t("invoice-codes-fields.service_appellation"),
                dataIndex: 'invoice',
                key: 'invoice',
                width: 300,
                render: (text) => <>{text.service_appellation}</>,

            },
            {
                title: t("invoice-codes-fields.chapter_code"),
                dataIndex: 'invoice',
                key: 'invoice',
                render: (text) => <>{text.chapter_code}</>,

            },
            {
                title: t("invoice-codes-fields.qualitative_dignity_code"),
                dataIndex: 'invoice',
                key: 'invoice',
                width: 100,
                render: (text) => <>{text.qualitative_dignity_code}</>,

            },
            {
                title: t("invoice-codes-fields.rate"),
                dataIndex: 'invoice',
                key: 'invoice',
                width: 100,
                render: (text) => <>{text.rate}</>,
            },
            {
                title: t("form-fields.actions"),
                key: 'action',
                render: (_, data) => {
                    return (<Space size="middle">
                        <div className="row">
                            <div className="col-4">{data.quantity && <PrimaryButton onClick={() => onToggle(data.invoice, false)} icon={<Minus />} />}</div>
                            <div className="col-4 text-center">{data.quantity || "0"}</div>
                            <div className="col-4"><PrimaryButton onClick={() => onToggle(data.invoice, true)} icon={<PlusCircle />} /></div>
                        </div>
                    </Space>)
                },
            },
        ]
    }, [groupList]);

    useEffect(() => {
        reset(editMode ? {
            ...data,
            startdate: DateUtility.dayJSFormat(data.startdate),
            enddate: DateUtility.dayJSFormat(data.enddate),
            invoices: data.invoices.map(ele => ele?._id || ele),
        } :
            { tarmed_type: InvoiceTarmedType[0].value })
        setGroupList(data?.invoicescodes?.map(ele => ({ ...ele, invoice: ele?.invoice })) || [])
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-invoice-codes")}` : `${t("add-edit.add-invoice-codes")}`}
            open={open}
            footer={null}
            width={1000}
            onCancel={onClose}>
            <Form layout="vertical">
                <div className="row">
                    <div className="col-4">
                        <FormSelectionField
                            control={control}
                            name="tarmed_type"
                            placeholder={t("invoice-codes-fields.tarmed_type")}
                            label={t("invoice-codes-fields.tarmed_type")}
                            errors={errors?.tarmed_type}
                            showSearch
                            options={InvoiceTarmedType}
                        />
                    </div>
                    <div className="col-3">
                        <FormTextFormField
                            control={control}
                            name="name"
                            placeholder={t("invoice-codes-fields.name")}
                            label={t("invoice-codes-fields.name")}
                            errors={errors?.name}
                            showSearch
                            defaultValue=""
                        />
                    </div>
                    <div className="col-5">
                        <Form.Item
                            label={`${t("search")} Documents ${t("form-fields.category")}`}
                        >
                            <AutoCompleteDropDown
                                name={`${t("search")} Documents ${t("form-fields.category")}`}
                                required
                                label={`${t("search")} Documents ${t("form-fields.category")}`}
                                placeholder={`${t("search")} Documents ${t("form-fields.category")}`}
                                options={[]}
                                className="mb-0"
                                onSearch={(data) => filterChanged("search", { search: data })}
                            />
                        </Form.Item>
                    </div>
                    <div className="12">
                        <AppTable
                            data={tableData}
                            columns={columns}
                            keyField="_id"
                            size="small"
                            currentPage={total.page}
                            total={total.count}
                            pageChanged={pageChanged}
                            pageSize={filter?.limit}
                        />
                    </div>
                    <div className="12">
                        <AppTable
                            data={groupList}
                            columns={columnsGroup}
                            keyField="_id"
                            size="small"
                            currentPage={total.page}
                            total={total.count}
                            pageChanged={pageChanged}
                            pageSize={filter?.limit}
                        />
                    </div>
                    <div>
                        Amount : {amount}
                    </div>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(submit)}>{data ? `${t("edit")}` : `${t("create")}`} {t("settings.invoices-group")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

export const InvoicesGroupTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { data, setData, refetch, filterChanged, pageChanged, filter, total, loading } = GetInvoicesGroupList({ tarmed_type: "TARMED8" })
    const [tarmedType, setTarmedType] = useState("TARMED8")
    const onTarmedChange = (data) => {
        setTarmedType(data)
        filterChanged("search", { tarmed_type: data })
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await InvoicesGroupService.add({ ...formData, doctor: User._id })
                notification.success({ message: t("notif-message.category-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await InvoicesGroupService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.category-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeInvoiceGroup = async (id) => {
        try {
            await InvoicesGroupService.remove(id)
            notification.success({ message: t("notif-message.category-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const onUpdate = async (groupid, list) => {
        try {
            setData([...data.map(ele => ({ ...ele, invoicescodes: ele._id === groupid ? list : ele.invoicescodes }))])
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    const orderChanged = async (items) => {
        try {
            await InvoicesGroupService.OrderBy({ items: items.map(item => item._id), skip: filter.skip })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    return (
        <>
        <DesktopMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-6">
                    {t("settings.invoices-group")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onTarmedChange} className="mx-2" defaultValue={tarmedType} popupMatchSelectWidth options={InvoiceTarmedType} />
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} Documents ${t("form-fields.category")}`}
                        options={[]}
                        className="mb-0 ms-5"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton></>}
            />
            <AddInvoiceCodesModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                tarmedType={tarmedType}
            />
            <div className="">
                <GroupList
                    list={data}
                    onEdit={onOpen}
                    loading={loading}
                    removeInvoiceGroup={removeInvoiceGroup}
                    onUpdate={onUpdate}
                    orderChanged={orderChanged}
                />
                <div className="col-12 mt-3 text-end">
                    <Pagination
                        current={total.page}
                        defaultPageSize={CommonConstant.defaultPageSize}
                        total={total.count}
                        onChange={pageChanged}
                        size="small"
                    />
                </div>
            </div>
        </div>
        </DesktopMode>
        <MobileMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-6">
                    {t("settings.invoices-group")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onTarmedChange} className="mx-2" defaultValue={tarmedType} popupMatchSelectWidth options={InvoiceTarmedType} />
                    </>}
            />
            <div className="col-12 d-flex py-1">
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} Documents ${t("form-fields.category")}`}
                        options={[]}
                        className="mb-0 ms-1"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                    </div>
            <AddInvoiceCodesModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                tarmedType={tarmedType}
            />
            <div className="mt-3">
                <GroupList
                    list={data}
                    onEdit={onOpen}
                    loading={loading}
                    removeInvoiceGroup={removeInvoiceGroup}
                    onUpdate={onUpdate}
                    orderChanged={orderChanged}
                />
                <div className="col-12 mt-3 text-end">
                    <Pagination
                        current={total.page}
                        defaultPageSize={CommonConstant.defaultPageSize}
                        total={total.count}
                        onChange={pageChanged}
                        size="small"
                    />
                </div>
            </div>
        </div>
        </MobileMode>
        </>
    )
}
