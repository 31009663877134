import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class DiagnosticType extends CRUDService {
    constructor() {
        super(APIPath.diagnostictype)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.diagnostictype}/order-by`, data)
    }

    hideShow(id,status) {
        return BaseService.put(`${APIPath.diagnostictype}/hide/${id}/${status}`)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.diagnostictype}/bulk`,data)
    }
}

const DiagnosticTypeService = new DiagnosticType()
Object.freeze(DiagnosticTypeService)
export { DiagnosticTypeService }
