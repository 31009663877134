import { useState, useEffect, useMemo } from 'react'
import {
    CommonConstant,
    PatientInsuranceService,
} from 'utility'

export const GetPatientInsuranceList = (id,intialFilter) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const filteredOptionList = useMemo(() => {
        return data?.filter(ele => ele.insurancecategory === filter?.search?.insurancecategory).map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex row justify-content align-items-center">
                        <span className="col-2">{item.insurance.insurance}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data,filter])

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex row justify-content align-items-center">
                        <span className="col-2">{item.insurance.insurance}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...intialFilter,
                ...filter,
                ...filter.search,
                search: filter.search.search ?? null,
            }
            const result = await PatientInsuranceService.byPatient(id,params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error && id) {
            fetchData()
        }
    }, [id])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        optionList,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        filteredOptionList,
        total,
    }
}
