import { useState, useEffect } from 'react'
import {
    ColorCodeService,
    CommonConstant,
} from 'utility'

export const GetEncounterColoCodeList = (intialFilter) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...intialFilter,
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await ColorCodeService.byEncounter(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result?.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const sortChanged = (obj) => {
        const temp = {
            ...filter,
            ...obj,
            skip: 0,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        filter,
        total,
        setData,
        filterChanged,
        pageChanged,
        sortChanged,
        refetch,
    }
}
