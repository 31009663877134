import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import {
    AppTable,
    FlexColumn,
    FlexRowBetween,
    HeaderTitle,
    PageHeader,
} from 'components'
import {
    DangerButton,
    PrimaryButton,
    FormSelectionField,
    FormTextFormField,
} from 'elements'
import { useForm } from 'react-hook-form'
import { MetaDataService } from 'utility'
import { useTranslation } from 'react-i18next'
import {
    GetCustomeEncounterFieldList,
    DesktopMode,
    MobileMode,
    GetFieldsType,
} from 'hooks'
import * as yup from 'yup'
import { useState, useEffect, useMemo } from 'react'
import { Plus, Pencil, Trash } from 'phosphor-react'

const CustomeEncounterSchema = yup.object().shape({
    custom_encounter_title: yup.string().required('title is required').trim(),
    custom_encounter_type: yup.string().required('type is required').trim(),
})

const AddCustomeEncounterModal = ({
    open,
    data,
    editMode,
    onClose,
    loading,
    onSubmit,
}) => {
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CustomeEncounterSchema),
    })
    const { t } = useTranslation()
    const { data: optionList } = GetFieldsType(t)

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={
                editMode
                    ? t('add-edit.edit-custom-encounter-fields')
                    : t('add-edit.add-encounter-fields')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="col-4 mb-0">
                        <FormSelectionField
                            control={control}
                            name="custom_encounter_type"
                            required
                            errors={errors.custom_encounter_type}
                            label={t('form-fields.type')}
                            options={optionList}
                            filterOption={false}
                            defaultValue={optionList[0]}
                            showSearch
                        />
                    </Form.Item>
                    <Form.Item className="col-8 mb-0">
                        <FormTextFormField
                            control={control}
                            name="custom_encounter_title"
                            label={t('form-fields.title')}
                            placeholder="title"
                            errors={errors?.custom_encounter_title || ''}
                        />
                    </Form.Item>
                </div>
                <Space wrap className="">
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('add-edit.edit-custom-encounter-fields')
                            : t('add-edit.add-encounter-fields')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}
export const CustomEncounterTab = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { data, setData } = GetCustomeEncounterFieldList()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (submitedData) => {
        const formData = { ...submitedData }
        try {
            setProcessing(true)
            if (!submitedData?._id) {
                const result = await MetaDataService.addCustomeEncounterFields(
                    formData,
                )
                setData([...data, result])
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await MetaDataService.updateCustomeEncounterFields(
                    modalData._id,
                    formData,
                )
                setData(
                    data.map((ele) => {
                        if (ele._id === submitedData._id) {
                            return { ...ele, ...formData }
                        }
                        return { ...ele }
                    }),
                )
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            onClose()
        }
    }

    const removeCategory = async (id) => {
        try {
            await MetaDataService.removeCustomeEncounterFields(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }
    const columns = useMemo(
        () => [
            {
                title: t('form-fields.title'),
                dataIndex: 'custom_encounter_title',
                key: 'custom_encounter_title',
                width: 390,
            },
            {
                title: t('form-fields.type'),
                dataIndex: 'custom_encounter_type',
                key: 'custom_encounter_type',
                width: 390,
            },
            {
                title: t('form-fields.actions'),
                key: 'action',
                render: (_, data) => (
                    <Space size="middle">
                        <PrimaryButton
                            icon={<Pencil onClick={() => onOpen(data)} />}
                        />
                        <Popconfirm
                            title={t('delete')}
                            description={t('message.delete-message', {
                                name: `${t('form-fields.category')}`,
                            })}
                            onConfirm={() => removeCategory(data._id)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton icon={<Trash />} htmlType="button" />
                        </Popconfirm>
                    </Space>
                ),
            },
        ],
        [data],
    )

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('custom-encounter-fields')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('custom-encounter-fields')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                </FlexColumn>
            </MobileMode>
            <AddCustomeEncounterModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="mt-sm-2 mt-md-0">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                />
            </div>
        </div>
    )
}
