import { Button,Form,Space,notification } from "antd";
import {
    FormSelectionField,PrimaryButton,
} from "elements";
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect,useState } from "react";
import { MetaDataService, MaxTimer } from "utility";
import { HeaderTitle } from "components";
import { useTranslation } from "react-i18next";
import { GetDefaultTimer } from "hooks";

const ConfigsSchema = yup.object().shape({
    timerduration: yup.number().required('*max-timer is required'),
    })

export const AdminConfigsTab = () => {
    const [processing,setProcessing] = useState(false)
    const { t } = useTranslation()
    const { data } = GetDefaultTimer()
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ConfigsSchema),
    })

    useEffect(() => {
        if (data) {
            reset({
                timerduration: data.timerduration,
            })
        }
    },[data])

    const submit = async (formData) => {
        try {
            setProcessing(true)
            await MetaDataService.updateDefaultTimer(formData)
            notification.success({
                message: t("notif-message.doctor-updated"),
            })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        } finally {
            setProcessing(false)
        }
    }

    const cancel = () => {
        reset({
            timerduration: data.timerduration,
        })
    }

    return (
        <div className="container">
            <Form layout="vertical" noValidate>
                <div className="row">
                    <div className="col-12">
                        <HeaderTitle>{t('adminconfigs')}</HeaderTitle>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-4">
                    <FormSelectionField
                            control={control}
                            name="timerduration"
                            required
                            errors={errors?.timerduration}
                            label={t('form-fields.timerduration')}
                            options={MaxTimer}
                            filterOption={false}
                            showSearch
                        />
                    </div>
                    </div>
                <Space className="d-flex justify-content-end">
                    <Button htmlType="button" onClick={cancel}>
                        {t("cancel")}
                    </Button>
                    <PrimaryButton
                        loading={processing}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit(submit)}
                    >{t("submit")}</PrimaryButton>
                </Space>
            </Form>
        </div>
    )
}
