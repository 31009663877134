import { ShadowBox } from 'elements'
import { DesktopMode, GetFrame, MobileMode } from 'hooks'
import { AboutMeTab, HomeNotes } from 'page-components/home'
import React from 'react';

const IFrame = ({ data }) => {
  return (
    <div
      className="col-12"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}

const IFrame2 = ({ data }) => {
  return (
    <div
      className="col-12"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
export const HomeScreen = () => {
  const { data } = GetFrame()

  return (
    <>
    <DesktopMode>
    <div className="mt-4">

      <div className="row">
        <div className="col-6">
          <ShadowBox className="h-full">
            <AboutMeTab />
          </ShadowBox>
        </div>
        <div className="col-6">
          <ShadowBox>
            <HomeNotes />
          </ShadowBox>
        </div>
        <div className="col-6 mt-3">
          <ShadowBox className="col-12">
            <IFrame2 data={data.frame_second} />
          </ShadowBox>
        </div>
        <div className="col-6 mt-3">
          <ShadowBox className="col-12">
            <IFrame data={data.frame_one} />
          </ShadowBox>
        </div>
      </div>
    </div>
    </DesktopMode>
    <MobileMode>
    <div className="mt-4">
<div className="row">
  <div className="col-12">
    <ShadowBox className="">
      <AboutMeTab />
    </ShadowBox>
  </div>
  <div className="col-12 mt-3">
    <ShadowBox>
      <HomeNotes />
    </ShadowBox>
  </div>
  <div className="col-12 mt-3">
    <ShadowBox className="col-12">
      <IFrame2 data={data.frame_second} />
    </ShadowBox>
  </div>
  <div className="col-12 mt-3">
    <ShadowBox className="col-12">
      <IFrame data={data.frame_one} />
    </ShadowBox>
  </div>
</div>
</div>
    </MobileMode>
    </>
  );
};
