import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Checkbox,
    Form,
    Modal,
    Popconfirm,
    Space,
    Switch,
    notification,
} from 'antd'
import {
    DraggableAppTable,
    AutoCompleteDropDown,
    HeaderTitle,
    PageHeader,
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    FlexBox,
} from 'components'
import {
    DangerButton,
    FormColorPicker,
    FormRangeSliderField,
    FormSelectionField,
    FormTextFormField,
    PrimaryButton,
} from 'elements'
import {
    DesktopMode,
    GetAppointmentCategory,
    GetInvoicesGroupList,
    GetInvoiceCodesList,
    MobileMode,
    GetQuestionList,
} from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import {
    AppointmentCategoryService,
    DoctorService,
    TimeRangeOptions,
} from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'context'

const CategoryShema = yup.object().shape({
    name: yup.string().required('*category name is required'),
    color: yup.string().required('color is required'),
    duration: yup
        .number()
        .min(30, 'Minmum 30 min slot is required')
        .required('Duration is required'),
})

const ColorBox = styled.div`
    height: 20px;
    width: 25px;
    border-radius: 5px;
    background-color: ${({ color }) => color};
`

const AddCategoryModal = ({
    open,
    onClose,
    data,
    loading,
    editMode,
    onSubmit,
    codesList,
    groupsList,
    questionList,
    questionFilterChanged,
    groupFilterChanged,
    codeFilterChanged,
}) => {
    const {
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CategoryShema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                      invoicecodes: data?.invoicecodes?.map((ele) => ele._id),
                      invoicegroups: data?.invoicegroups?.map((ele) => ele._id),
                      questions: data?.questions?.map((ele) => ele._id),
                  }
                : { duration: 30 },
        )
    }, [open, editMode])

    const onSearch = (data) => {
        setValue(data)
        groupFilterChanged('search', {
            search: data,
        })
        codeFilterChanged('search', {
            search: data,
        })
    }

    const onQuestionSearch = (data) => {
        questionFilterChanged('search', {
            search: data,
        })
    }

    return (
        <Modal
            title={
                editMode
                    ? t('add-edit.edit-category')
                    : t('add-edit.add-category')
            }
            open={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical">
                <div className="row">
                    <Form.Item className="mb-0 col-10">
                        <FormTextFormField
                            control={control}
                            name="name"
                            label={`${t('form-fields.category')} ${t('name')}`}
                            placeholder="Category Name"
                            errors={errors?.name}
                            defaultValue=""
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 col-2">
                        <FormColorPicker
                            control={control}
                            name="color"
                            label={`${t('form-fields.color')}`}
                            placeholder="color"
                            errors={errors?.color}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 col-12">
                        <FormSelectionField
                            control={control}
                            name="invoicegroups"
                            mode="multiple"
                            required
                            showSearch
                            filterOption={false}
                            // label={`${t("search")} ${t("form-fields.invoice-codes")}`}
                            errors={errors.invoicegroups}
                            placeholder={`${t('search')} ${t(
                                'form-fields.groups-codes',
                            )}`}
                            options={groupsList}
                            className="mb-0"
                            onSearch={onSearch}
                            onSelect={() => {}}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 col-12">
                        <FormSelectionField
                            control={control}
                            name="questions"
                            mode="multiple"
                            required
                            showSearch
                            filterOption={false}
                            // label={`${t("search")} ${t("form-fields.invoice-codes")}`}
                            errors={errors.questions}
                            placeholder={`${t('search')} ${t(
                                'form-fields.questions',
                            )}`}
                            options={questionList}
                            className="mb-0"
                            onSearch={onQuestionSearch}
                            onSelect={() => {}}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 col-12">
                        <FormSelectionField
                            control={control}
                            name="invoicecodes"
                            mode="multiple"
                            required
                            showSearch
                            filterOption={false}
                            // label={`${t("search")} ${t("form-fields.invoice-codes")}`}
                            errors={errors.invoicecodes}
                            placeholder={`${t('search')} ${t(
                                'form-fields.invoice-codes',
                            )}`}
                            options={codesList}
                            className="mb-0"
                            onSearch={onSearch}
                            onSelect={() => {}}
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 col-12">
                        <FormRangeSliderField
                            control={control}
                            name="duration"
                            label={`${t('form-fields.duration')}`}
                            placeholder="duration"
                            errors={errors?.duration}
                            max={120}
                            marks={TimeRangeOptions}
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('add-edit.edit-category')
                            : t('add-edit.add-category')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}

export const AppointmentCategoryTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { user, setUser } = useAuth()
    const { t } = useTranslation()
    const {
        data,
        setData,
        refetch,
        filterChanged,
        total,
        filter,
        pageChanged,
    } = GetAppointmentCategory()
    const { optionList: groupsList, filterChanged: groupFilterChanged } =
        GetInvoicesGroupList()
    const { optionList: questionList, filterChanged: questionFilterChanged } =
        GetQuestionList()
    const { optionList: codesList, filterChanged: codeFilterChanged } =
        GetInvoiceCodesList()

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await AppointmentCategoryService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await AppointmentCategoryService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeCategory = async (id) => {
        try {
            await AppointmentCategoryService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const setAsPrimary = async (item) => {
        try {
            await AppointmentCategoryService.setPrimary(item._id, !item.primary)
            setData(
                data.map((ele) => {
                    return {
                        ...ele,
                        primary: ele._id === item._id ? !item.primary : false,
                    }
                }),
            )
            // notification.success({ message: t("notif-message.set-status-primary") })
            refetch()
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const columns = [
        {
            title: t('form-fields.category'),
            dataIndex: 'name',
            key: 'name',
            width: 700,
        },
        {
            title: t('form-fields.color'),
            dataIndex: 'color',
            key: 'color',
            width: 200,
            render: (_, data) => (
                <Space size="middle">
                    <ColorBox color={data?.color} />
                </Space>
            ),
        },
        {
            title: t('form-fields.primary'),
            dataIndex: 'primary',
            key: 'primary',
            width: 200,
            render: (_, data) => (
                <Space size="middle">
                    <Switch
                        checked={data.primary}
                        onChange={() => setAsPrimary(data)}
                    />
                </Space>
            ),
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    {/* <Popconfirm
                        title={t("set-status-primary")}
                        description={t("message.set-primary")}
                        onConfirm={() => setAsPrimary(data)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                        disabled={data.primary}
                    >
                        <PrimaryButton htmlType="button">{!data.primary ? "Set" : "Remove"} Primary</PrimaryButton>
                    </Popconfirm> */}
                    <PrimaryButton
                        icon={<Pencil onClick={() => onOpen(data)} />}
                    />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await AppointmentCategoryService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    const onCategoryStatusChanged = async (status) => {
        try {
            await DoctorService.update(user._id, {
                category_change_code: status,
            })
            setUser({ ...user, category_change_code: status })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('settings.appointment-category')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'settings.appointment-category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) =>
                                    filterChanged('search', { name: data })}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.appointment-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.appointment-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-5"
                            onSearch={(data) =>
                                filterChanged('search', { name: data })}
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <FlexBox className="justify-content-end my-2">
                <PrimaryButton
                    onClick={() =>
                        onCategoryStatusChanged(!user.category_change_code)}
                >
                    {t('message.change-cateogy-codes')}
                    <Checkbox
                        className="ms-2"
                        checked={user.category_change_code}
                        onClick={() =>
                            onCategoryStatusChanged(!user.category_change_code)}
                    />
                </PrimaryButton>
            </FlexBox>
            <AddCategoryModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                groupsList={groupsList}
                questionList={questionList}
                questionFilterChanged={questionFilterChanged}
                codesList={codesList}
                groupFilterChanged={groupFilterChanged}
                codeFilterChanged={codeFilterChanged}
            />
            <div className="mt-sm-2 mt-md-0">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    // pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
