import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class PatientDrugs extends CRUDService {
    constructor() {
        super(APIPath.patientDrugs)
    }

    byEncounter(id,params) {
        const query = CommonUtility.objectToParams(params)
        const url = `${APIPath.patientDrugs}/${id}?${query}`
        return BaseService.get(url)
    }

    getByPatient(id) {
        return BaseService.get(`${APIPath.patientDrugs}/by-patient/${id}`)
    }
}

const PatientDrugsService = new PatientDrugs()
Object.freeze(PatientDrugsService)
export { PatientDrugsService }
