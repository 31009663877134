import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    Space,
    notification,
} from 'antd'
import {
    FormTextAreaFormField,
    PrimaryButton,
} from 'elements'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { MetaDataService } from 'utility'
import { GetFrame } from 'hooks'

const FrameSchema = yup.object().shape({
    frame_one: yup.string().required('*framlink is required'),
    frame_second: yup.string().required('*framlink is required'),
})

export const FrameSetting = () => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(FrameSchema),
    })
    const { t } = useTranslation()
    const { data } = GetFrame()
    useEffect(() => {
        reset({ frame_one: data.frame_one,frame_second: data.frame_second })
    }, [data])

    const onSubmit = async (formData) => {
        try {
            await MetaDataService.Frame({ frame_one: formData.frame_one,frame_second: formData.frame_second })
            notification.success({
                message: t("notif-message.frame-updated"),
            })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    return (
        <Form layout="vertical">
            <div className="row">
                <Form.Item className="mb-0 col-12">
                    <FormTextAreaFormField
                        control={control}
                        name="frame_one"
                        label={`${t('form-fields.frame_one')}`}
                        placeholder="frame_one"
                        errors={errors?.frame_one}
                        defaultValue=""
                        rows={6}
                    />
                </Form.Item>
            </div>
            <div className="row">
                <Form.Item className="mb-0 col-12">
                    <FormTextAreaFormField
                        control={control}
                        name="frame_second"
                        label={`${t('form-fields.frame_second')}`}
                        placeholder="frame_second"
                        errors={errors?.frame_second}
                        defaultValue=""
                        rows={6}
                    />
                </Form.Item>
            </div>
            <Space wrap>
                <Button key="cancel">
                    {t('cancel')}
                </Button>
                <PrimaryButton
                    key="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    {t('add-edit.save-frames')}
                </PrimaryButton>
            </Space>
        </Form>
    )
}
