import { APIPath } from '../constant'
import { CRUDService } from './crud'

class Drug extends CRUDService {
    constructor() {
        super(APIPath.drug)
    }
}

const DrugService = new Drug()
Object.freeze(DrugService)
export { DrugService }
