// "this code belongs to the patient's data it's rendering in the table with the blue background colour"
import { Copy } from '@phosphor-icons/react'
import { Button, Popover } from 'antd'
import { Images } from 'images'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { CommonUtility, DateUtility, StatusIcons } from 'utility'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import dayjs from 'dayjs'
import { RepeatOnce } from 'phosphor-react'
import { useEffect, useState } from 'react'
import { GetCalendar } from 'hooks'

dayjs.extend(utc)
dayjs.extend(timezone)

const ToolTip = styled.div`
    position: absolute;
    top: 1px;
    right: 1px;
    height: 15px;
`
const EventContentBox = styled.div`
    padding-left: 15px;
    position: relative;
    height: -webkit-fill-available;
    min-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: ${({ height }) => height};
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.5em;
    svg {
        width: 18px;
        height: 1px;
    }
    .ant-tag {
        color: white;
        line-height: 16px;
    }
    .copy-hover {
        display: none;
        position: absolute;
        top: 0;
        right: 0px;
    }
    &:hover {
        .copy-hover {
            display: block;
            opacity: 0.8;
        }
    }
`
export function EventBox({ event, onCopyClick }) {
    const diff = DateUtility.diffTime(event.start, event.end)
    const height = CommonUtility.eventHeightGetter(diff)
    const { t } = useTranslation()
    const icon = StatusIcons?.[event?.resource?.status?.icon] || ''
    const [calendarColor, setCalendarColor] = useState('transparent')
    const [calendarName,setCalendarNames] = useState([]);
    const [additionalCalendarsCount, setAdditionalCalendarsCount] = useState(0);

    const { data: calendars } = GetCalendar()

    useEffect(() => {
        // Check if event.resource.calendars is an array and has items
        if (Array.isArray(event.resource.calendars) && event.resource.calendars.length > 0) {
            const foundCalendars = calendars.filter(calendar =>
                event.resource.calendars.includes(calendar._id),
            );
            // Determine how to display the calendar names
            if (foundCalendars.length > 1) {
                // More than one calendar, show the first name + count of additional calendars
                const firstCalendarName = foundCalendars[0].name;
                const additionalCalendarsCount = foundCalendars.length - 1;
                setCalendarNames(`${firstCalendarName}`);
                setAdditionalCalendarsCount(additionalCalendarsCount);
            } else if (foundCalendars.length === 1) {
                // Only one calendar, show its name
                setCalendarNames(foundCalendars[0].name);
            }
            // Optionally, set the color based on the first found calendar
            if (foundCalendars.length > 0) {
                setCalendarColor(foundCalendars[0].color);
            }
        } else {
            // No calendars found
            setCalendarNames('No Calendar');
        }
    }, [event.resource.calendars, calendars]);

    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    height: 'calc(100% - 20px)',
                    width: 8,
                    background: calendarColor,
                    bottom: 3,
                    left: 1,
                    borderRadius: 4,
                }}
            />
            <EventContentBox height={height}>
                <div>{event.title}</div>
                <ToolTip className="d-flex align-items-center">
                    {!event?.resource?.patient?._id && (
                        <Popover
                            title=""
                            content={
                                <span className="text-capitalize">
                                    {t('no-patient')}
                                </span>
                            }
                        >
                            <img
                                src={Images.noPatient}
                                alt=""
                                className="mx-1 mt-0"
                                width={15}
                                height={15}
                            />
                        </Popover>
                    )}
                    <Popover className="mt-1">
                        {event?.resource?.status?.status && (
                            <Popover
                                title=""
                                content={
                                    <div className="text-capitalize">
                                        <>{event?.resource?.status?.status}</>
                                    </div>
                                }
                            >
                                <div className="">{icon}</div>
                            </Popover>
                        )}
                        {event?.resource?.recurrance && (
                            <RepeatOnce size={35} className="" />
                        )}
                        <Button
                            type="default"
                            className="copy-hover"
                            onClick={(e) =>
                                onCopyClick && onCopyClick(e, event)}
                            icon={<Copy size={35} color="grey" />}
                        />
                    </Popover>
                </ToolTip>
            </EventContentBox>
         <div style={{
    position: 'absolute',
    bottom: 1,
    right: 4,
    display: 'flex',
    gap: '4px',
}}>
    <div
        style={{
            fontSize: 12,
            background: calendarColor,
            padding: 4,
            borderRadius: 4,
        }}
    >
        {calendarName}
    </div>
    {additionalCalendarsCount > 0 && (
        <div
            style={{
                fontSize: 12,
                background: calendarColor,
                padding: 4,
                borderRadius: 4,
            }}
        >
            +{additionalCalendarsCount}
        </div>
    )}
</div>

        </>
    )
}

export const eventStyleGetter = (data, events) => {
    const overlapCount = CommonUtility.checkoverlap(
        events?.filter((x) => x.resource._id !== data.resource._id),
        data.start,
        data.end,
    )
    const color = data?.resource?.category?.color || '#3174ad'
    return {
        className: 'event-style',
        style: {
            backgroundColor: color,
            opacity: overlapCount > 0 ? 0.5 : 1,
        },
    }
}

const isWeekend = (date) => {
    const day = date.getDay()
    return day === 0 || day === 6 // Sunday is 0, Saturday is 6
}

export const dayPropGetter = (date, weekShow) => {
    if (isWeekend(date) && !weekShow) {
        return {
            className: 'weekend-day', // Apply a custom CSS class
            style: {
                display: 'none', // Hide the day cell
            },
        }
    }
    return {}
}

const workingDaysArray = {
    MOF: [1, 2, 3, 4, 5],
    MOS: [1, 2, 3, 4, 5, 6],
}
export const slotPropGetter = (date, start, end, workingdays) => {
    return {
        className: 'slotDefault',
        ...(DateUtility.diffTime(new Date(date), new Date(start)) > 0 && {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black',
            },
        }),
        ...(DateUtility.diffTime(new Date(date), new Date(end)) < 0 && {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black',
            },
        }),
        ...(!workingDaysArray[workingdays]?.includes(
            new Date(date).getDay(),
        ) && {
            style: {
                backgroundColor: '#f2f2f2',
                color: 'black',
            },
        }),
    }
}
