import { useState, useEffect, useMemo } from 'react'
import { CommonConstant, DoctorService } from 'utility'

export const GetDoctorsList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        search: '',
    })

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex align-items-center">
                        <div className="mx-3">{item.firstName} {item.lastName}</div>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await DoctorService.get({ ...filter })
                setData(result.data)
                setTotal({ ...total, count: result.count })
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (data) => {
        setFilter({
            ...filter,
            ...data,
        })
    }

    const fetchMoreData = (skipCount) => {
        setFilter({
            ...filter,
            skip: (skipCount - 1) * CommonConstant.defaultPageSize,
        })
    }

    return {
        data,
        error,
        loading,
        setData,
        filterChanged,
        total,
        fetchMoreData,
        optionList,
    }
}

export const GetDoctorDetail = (id) => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                let result
                if (id) {
                    result = await DoctorService.getById(id)
                }
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        error,
        loading,
        setData,
    }
}
