import { useState, useEffect, useMemo } from 'react'
import {
    CommonConstant,
    DateUtility,
    DrugService,
    PatientDrugsService,
} from 'utility'

export const GetDrugsList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>{item.name}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await DrugService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    const refetchAll = () => {
        const temp = {
            ...filter,
            limit: 10000000,
        }
        setFilter({ ...temp })
    }

    return {
        data,
        error,
        loading,
        setData,
        optionList,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        refetchAll,
        total,
    }
}

export const GetAllDrugsList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: 1000000000000000000000000000,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>{item.name}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await DrugService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    const refetchAll = () => {
        const temp = {
            ...filter,
            limit: 10000000,
        }
        setFilter({ ...temp })
    }

    return {
        data,
        error,
        loading,
        setData,
        optionList,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        refetchAll,
        total,
    }
}

export const GetDrugsListOfPatient = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {
            startdate: -1,
        },
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await PatientDrugsService.getByPatient(id)
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const filteredData = useMemo(() => {
        return data?.filter(item => (DateUtility.isSame(item.startdate,new Date(filter.search.startdate))) || filter.search.startdate === -1)
    },[filter,data])

    return {
        data,
        filteredData,
        error,
        setData,
        loading,
        filterChanged,
    }
}