import { Typography } from 'antd'
import styled from 'styled-components';

const { Title } = Typography;

const AppTitle = styled(Title)`
  margin-top : 0.5rem;
`

export const HeaderTitle = ({ level = 5,children,...rest }) => {
  return (
    <AppTitle level={level} {...rest}>{children}</AppTitle>
  )
}