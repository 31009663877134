import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class StateRate extends CRUDService {
    constructor() {
        super(APIPath.StateRate)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.StateRate}/bulk`,data)
    }
}

const StateRateService = new StateRate()
Object.freeze(StateRateService)
export { StateRateService }
