import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class QuestionAnswers extends CRUDService {
    constructor() {
        super(APIPath.questionAnswers)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.questionAnswers}/order-by`, data)
    }

    byAppointment(id) {
        return BaseService.get(`${APIPath.questionAnswers}/appointment/${id}`)
    }

    byPatient(id) {
        return BaseService.get(`${APIPath.questionAnswers}/patient/${id}`)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.questionAnswers}/bulk`, data)
    }
}

const QuestionAnswersService = new QuestionAnswers()
Object.freeze(QuestionAnswersService)
export { QuestionAnswersService }
