import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Calendar extends CRUDService {
    constructor() {
        super(APIPath.calendar)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.calendar}/order-by`, data)
    }
}

const CalendarService = new Calendar()
Object.freeze(CalendarService)
export { CalendarService }
