import {
    addDays,
    subDays,
    isValid,
    formatDistanceToNowStrict,
    differenceInDays,
    fromUnixTime,
    getUnixTime,
    addMinutes,
    isPast,
    isWithinInterval,
    isSameDay,
    isToday,
    startOfDay,
    differenceInSeconds, formatDistanceStrict, addSeconds, startOfWeek, endOfWeek,
} from 'date-fns'
import format from 'date-fns/format'
import dayjs from 'dayjs'
import { enUS, fr } from 'date-fns/locale';
import { RecurrenceEndType } from './constant';
import { t } from 'i18next'

export const DateFormat = {
    date: 'MM/dd/yyyy',
    dateTime: 'MM/dd/yyyy hh:mm a',
    dateMonthTime: 'dd MMM yyyy, hh:mm a',
    monthDate: 'MMM yyyy',
    time: 'hh:mm',
    fulltime: "HH:mm",
    timepicker: 'hh:mm:ss',
    euDate: 'dd.MM.yyyy',
    euDateTime: 'dd.MM.yyyy hh:mm a',
    euDateFullTime: 'dd.MM.yyyy - HH:mm',
    weekDate: 'EEEE, MMM d',
    weekDateYear: 'EEEE, MMM d yyyy',
    inputEuDate: "DD.MM.YYYY",
}

export const Launguge = {
    fr: 'fr',
    eu: 'en',
    en: 'en',
}

export const LocaleFormat = {
    eu: { locale: enUS },
    "en-US": { locale: enUS },
    en: { locale: enUS },
    fr: { locale: fr },
}

export class DateUtility {
    static dateToString(
        date,
        formatStr = DateFormat.dateTime,
        locale = LocaleFormat.eu,
    ) {
        if (!date) return ''
        const newDate = new Date(date)
        if (isValid(newDate)) {
            return format(newDate, formatStr, locale)
        }
        return ''
    }

    static formatToUnixString(date, formatStr = DateFormat.date) {
        if (!date) return ''
        if (isValid(date)) {
            return format(addMinutes(date, date.getTimezoneOffset()), formatStr)
        }
        return ''
    }

    static formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    static toDate(unixTimestamp) {
        return new Date(unixTimestamp * 1000)
    }

    static msToDate(ms) {
        return fromUnixTime(ms)
    }

    static isToday(date) {
        return isToday(date)
    }

    static addDay(date, day) {
        return addDays(date, day)
    }

    static addSeconds(date, seconds) {
        return addSeconds(date, seconds)
    }

    static subDays(date, day) {
        return subDays(date, day)
    }

    static addMinnutesInTime(date, minute) {
        return addMinutes(new Date(date), minute)
    }

    static diff(date1, date2) {
        return differenceInDays(date2, date1)
    }

    static getSeconds(date) {
        return date.getHours() * 60;
    }

    static diffTime(date1, date2) {
        const fDate = new Date(
            2000,
            1,
            1,
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds(),
        )
        const sDate = new Date(
            2000,
            1,
            1,
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds(),
        )
        return differenceInSeconds(sDate, fDate)
    }

    static getDistanceInWord(date, launguage = 'eu') {
        return formatDistanceToNowStrict(date, {
            locale: LocaleFormat[launguage].locale,
        })
    }

    static getDistanceInWordWithMinutes(date) {
        const targetDate = new Date(date)
        const currentDate = new Date()
        const timeDifferenceMillis = targetDate - currentDate
        const hoursDifference = Math.floor(
            timeDifferenceMillis / (60 * 60 * 1000),
        )
        const minutesDifference = Math.floor(
            (timeDifferenceMillis % (60 * 60 * 1000)) / (60 * 1000),
        )
        let formattedDistance = ''
        if (hoursDifference >= 24) {
            const daysDifference = Math.floor(hoursDifference / 24)
            formattedDistance = `${daysDifference} ${t("calendar-properties.day")}`
        } else {
            formattedDistance = `${hoursDifference > 0 ? `${hoursDifference} ${t("calendar-properties.hour")}` : ""}  ${minutesDifference} ${t("calendar-properties.minute")}`
        }
        return formattedDistance
    }

    static getDistanceInWordBetween(date1, date2, locale = LocaleFormat.eu) {
        return formatDistanceStrict(date1, date2, locale)
    }

    static getUnixTimeStamp() {
        return getUnixTime(new Date())
    }

    static stringToDate(dateString) {
        const year = parseInt(dateString.substring(0, 4), 10);
        const month = parseInt(dateString.substring(4, 6), 10) - 1; // Months are zero-indexed
        const day = parseInt(dateString.substring(6, 8), 10);
        // Create a new Date object
        return new Date(year, month, day).toISOString();
    }

    static getStartWeek(date) {
        return startOfWeek(date)
    }

    static getEndWeek(date) {
        return endOfWeek(date)
    }

    static convertDaysToSeconds = (days = 1) => {
        const secondsInDay = 86400
        return days * secondsInDay
    }

    static isPastTime = (date) => {
        return isPast(date)
    }

    static isSameOrBefore = (r, l) => {
        return !isPast(l) || isSameDay(r, l)
    }

    static isSame = (r, l) => {
        return isSameDay(startOfDay(new Date(r)), startOfDay(new Date(l)))
    }

    static isBetween = (date, date1, date2) => {
        return isWithinInterval(date, {
            state: date1,
            end: date2,
        })
    }

    static dayJSFormat(date) {
        return dayjs(date)
    }

    static generateDailyDates(start, end, recurrences) {
        const startDate = dayjs(start)
        const dates = []

        if (end && recurrences) {
            return dates
        }

        if (end) {
            const endDate = dayjs(end)
            let currentDate = startDate
            while (currentDate.isBefore(endDate)) {
                dates.push(currentDate.format('YYYY-MM-DD HH:mm:ss'))
                currentDate = currentDate.add(1, 'day')
            }
        } else if (recurrences) {
            let currentDate = startDate
            for (let i = 0; i < recurrences; i += 1) {
                dates.push(currentDate.format('YYYY-MM-DD HH:mm:ss'))
                currentDate = currentDate.add(1, 'day')
            }
        }

        return dates
    }

    static generateWeeklyEvents(
        startDateStr,
        endOnDate,
        recurrenceEndType,
        endAfterNRecurrences,
        weekdays,
        recursEveryNWeek,
    ) {
        const startDate = dayjs(startDateStr)
        const events = []
        let startTime = startDate

        const isRecurrenceEndTypeEndAfterN =
            recurrenceEndType === RecurrenceEndType.endAfterN

        const isWeekdayIncluded = (date) =>
            weekdays.includes(date.format('dddd'))

        while (
            (isRecurrenceEndTypeEndAfterN &&
                events.length < endAfterNRecurrences) ||
            (!isRecurrenceEndTypeEndAfterN && startTime.isBefore(endOnDate))
        ) {
            if (isWeekdayIncluded(startTime)) {
                events.push(startTime)
            }
            startTime = startTime.add(1, 'day')
            if (startTime.format('dddd') === 'Sunday') {
                startTime = startTime.add(recursEveryNWeek - 1, 'weeks')
            }
        }
        return events
    }

    static generateMonthlyEvents(
        startDateStr,
        endOnDate,
        recurrenceEndType,
        endAfterNRecurrences,
        recursEveryNMonths,
    ) {
        const startDate = dayjs(startDateStr)
        const events = []
        let startTime = startDate

        const isRecurrenceEndTypeEndAfterN =
            recurrenceEndType === RecurrenceEndType.endAfterN

        while (
            (isRecurrenceEndTypeEndAfterN &&
                events.length < endAfterNRecurrences) ||
            (!isRecurrenceEndTypeEndAfterN && startTime.isBefore(endOnDate))
        ) {
            const lastDayOfMonth = startTime.endOf('month').date()
            for (let day = 1; day <= lastDayOfMonth; day += 1) {
                const currentDate = startTime.set('date', day)
                if (
                    (isRecurrenceEndTypeEndAfterN ||
                        currentDate.isBefore(endOnDate) ||
                        currentDate.isSame(endOnDate, 'day')) &&
                    currentDate.date() === startDate.date()
                ) {
                    events.push(currentDate)
                }
            }
            startTime = startTime
                .add(recursEveryNMonths, 'month')
                .startOf('month')
        }
        return events
    }

    static generateYearlyEvents(
        startDateStr,
        endOnDate,
        recurrenceEndType,
        endAfterNRecurrences,
        recursEveryNYears,
    ) {
        const startDate = dayjs(startDateStr)
        const events = []
        let startTime = startDate

        const isRecurrenceEndTypeEndAfterN =
            recurrenceEndType === RecurrenceEndType.endAfterN

        while (
            (isRecurrenceEndTypeEndAfterN &&
                events.length < endAfterNRecurrences) ||
            (!isRecurrenceEndTypeEndAfterN && startTime.isBefore(endOnDate))
        ) {
            for (let month = 0; month < 12; month += 1) {
                for (let day = 1; day <= 31; day += 1) {
                    const currentDate = startTime
                        .set('month', month)
                        .set('date', day)
                    if (
                        (isRecurrenceEndTypeEndAfterN ||
                            currentDate.isBefore(endOnDate) ||
                            currentDate.isSame(endOnDate, 'day')) &&
                        currentDate.date() === startDate.date() &&
                        currentDate.month() === startDate.month()
                    ) {
                        events.push(currentDate)
                    }
                }
            }
            startTime = startTime.add(recursEveryNYears, 'year')
        }
        return events
    }

    static calculateTimeDifferences(dateStrings) {
        let seconds = 0;
        for (let i = 0; i < dateStrings.length; i += 2) {
            const startDate = new Date(dateStrings[i]);
            const endDate = i + 1 < dateStrings.length ? new Date(dateStrings[i + 1]) : new Date();
            const timeDifferenceInSeconds = DateUtility.diffTime(startDate, endDate);
            seconds += timeDifferenceInSeconds;
        }
        return seconds;
    }
}
