import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Space, notification } from 'antd'
import { AutoCompleteDropDown, HeaderTitle, PageHeader, AppTable } from 'components'
import { DangerButton, FormColorPicker, FormSelectionField, PrimaryButton } from 'elements'
import { DesktopMode, GetEncounterColoCodeList, GetEncounterOptions, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { AppointmentCategoryService, ColorCodeService } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'context'

const CategoryShema = yup.object().shape({
    encounter_type: yup.string().required('*encounter type is required'),
    color: yup.string().required("color is required"),
})

const ColorBox = styled.div`
    height: 20px;
    width: 25px;
    border-radius:5px;
    background-color: ${({ color }) => color};

`

const AddCategoryModal = ({ open, onClose, data, loading, editMode, onSubmit, options }) => {
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CategoryShema),
    })
    const { t } = useTranslation()
    useEffect(() => {
        reset(editMode ? data : {})
    }, [open, editMode])

    return (
        <Modal
            title={editMode ? t("add-edit.edit-encounter-color") : t("add-edit.add-encounter-color")}
            open={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical">
                <div className="row">
                    <Form.Item className="col-10">
                        <FormSelectionField
                            control={control}
                            name="encounter_type"
                            label={`${t("form-fields.encounter-type")}`}
                            placeholder="Category encounter_type"
                            errors={errors?.encounter_type}
                            defaultValue=""
                            options={options}
                        />
                    </Form.Item>
                    <Form.Item className="col-2">
                        <FormColorPicker
                            control={control}
                            name="color"
                            label={`${t("form-fields.color")}`}
                            placeholder="color"
                            errors={errors?.color}
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}>{data ? t("add-edit.edit-encounter-color") : t("add-edit.add-encounter-color")}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

const EncounterColorTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { user } = useAuth()
    const { data, setData, refetch, filterChanged, total, filter, pageChanged } = GetEncounterColoCodeList()
    const { data: EncounterTypeOptions } = GetEncounterOptions(t)
    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        const submitdata = {
            ...formData,
            encounter: true,
            doctor: user._id,
        }
        try {
            setProcessing(true)
            if (!submitdata?._id) {
                await ColorCodeService.add(submitdata)
                notification.success({ message: t("notif-message.category-added") })
            } else {
                delete submitdata._id
                delete submitdata.doctor
                await ColorCodeService.update(modalData._id, submitdata)
                notification.success({ message: t("notif-message.category-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeCategory = async (id) => {
        try {
            await ColorCodeService.remove(id)
            notification.success({ message: t("notif-message.category-removed") })
            setData(data.filter(item => item._id !== id))
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const columns = [
        {
            title: t("form-fields.encounter-type"),
            dataIndex: 'encounter_type',
            key: 'encounter_type',
            width: 700,
            render: (text) => (
                <Space size="middle">
                    {EncounterTypeOptions.find(ele => ele.value === text).label}
                </Space>
            ),
        },
        {
            title: t("form-fields.color"),
            dataIndex: 'color',
            key: 'color',
            width: 200,
            render: (_, data) => (
                <Space size="middle">
                    <ColorBox color={data?.color} />
                </Space>
            ),
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    {/* <Popconfirm
                        title={t("set-status-primary")}
                        description={t("message.set-primary")}
                        onConfirm={() => setAsPrimary(data)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                        disabled={data.primary}
                    >
                        <PrimaryButton htmlType="button">{!data.primary ? "Set" : "Remove"} Primary</PrimaryButton>
                    </Popconfirm> */}
                    <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.category")}` })}
                        onConfirm={() => removeCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >

                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const orderChanged = async (items) => {
        try {
            await AppointmentCategoryService.OrderBy({ items: items.map(item => item._id), skip: filter.skip })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    return (
        <>
        <DesktopMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-6">
                    {t("settings.encounter-color")}
                </HeaderTitle>}
                right={<>
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("settings.encounter-type")}`}
                        options={[]}
                        className="mb-0 ms-5"
                        onSearch={(data) => filterChanged("search", { name: data })}
                    /><PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton></>}
            />
            <AddCategoryModal open={open} data={modalData} options={EncounterTypeOptions} onClose={onClose} onSubmit={onSubmit} loading={processing} editMode={!!modalData?._id} />
            <div className="">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    // pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </DesktopMode>
        <MobileMode>
        <div>
            <HeaderTitle className="col-12">
                    {t("settings.encounter-color")}
                </HeaderTitle>
                <div className="col-12 d-flex">
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("settings.encounter-type")}`}
                        options={[]}
                        className="mb-0 ms-1"
                        onSearch={(data) => filterChanged("search", { name: data })}
                    /><PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                    </div>
            <AddCategoryModal open={open} data={modalData} options={EncounterTypeOptions} onClose={onClose} onSubmit={onSubmit} loading={processing} editMode={!!modalData?._id} />
            <div className="mt-4">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    // pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </MobileMode>
        </>
    )
}
export default EncounterColorTab