import { List } from 'antd';
import Item from 'antd/es/list/Item';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DateFormat, DateUtility } from 'utility';
import { HeaderTitle } from './Text';
import { Images } from 'images';
import styled from 'styled-components';

const ContainerBox = styled.div`
border:  ${({ theme }) => `1px solid ${theme.colors.box}`};
boxShadow: 0 2px 15px rgba(57;57;72;0.05);
borderRadius: 4px;
padding: 8px;
margin: 8px;
background-color:${({ theme }) => theme.colors.box};
`

const ListItem = styled.div`
user-select: none;
padding-inline: 8px;
margin: 8px;
background-color: ${({ theme,isDragging }) => isDragging ? theme.colors.box : theme.colors.white };
border-radius: 8px;
box-shadow: ${({ isDragging }) => isDragging ? '0 2px 15px  rgba(57,57,72,0.05)' : 'none'};
cursor: grab;
`

export const Draglist = ({ list,onStatusChanged,onSelect }) => {
  const [containers, setContainers] = useState([]);
  useEffect(() => {
    setContainers(list)
  }, [list])
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;
    const sourceContainerId = source.droppableId;
    const destinationContainerId = destination.droppableId;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    if (sourceContainerId === destinationContainerId) {
      const containerIndex = containers.findIndex((container) => container.id === sourceContainerId);
      const container = containers[containerIndex];

      const newItems = Array.from(container.items);
      const [removedItem] = newItems.splice(sourceIndex, 1);
      newItems.splice(destinationIndex, 0, removedItem);

      const newContainers = Array.from(containers);
      newContainers[containerIndex] = { ...container, items: newItems };

      setContainers(newContainers);
    } else {
      const sourceContainerIndex = containers.findIndex((container) => container.id === sourceContainerId);
      const destinationContainerIndex = containers.findIndex((container) => container.id === destinationContainerId);

      const sourceContainer = containers[sourceContainerIndex];
      const destinationContainer = containers[destinationContainerIndex];

      const sourceItems = Array.from(sourceContainer.items);
      const destinationItems = Array.from(destinationContainer.items);

      const [removedItem] = sourceItems.splice(sourceIndex, 1);
      destinationItems.splice(destinationIndex, 0, removedItem);

      const newContainers = Array.from(containers);
      newContainers[sourceContainerIndex] = { ...sourceContainer, items: sourceItems };
      newContainers[destinationContainerIndex] = { ...destinationContainer, items: destinationItems };
      onStatusChanged(removedItem._id, newContainers[destinationContainerIndex].id)
      setContainers(newContainers);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{ display: 'flex', flexDirection: "column" }}>
        {containers.map((container) => (
          <Droppable key={container.id} droppableId={container.id}>
            {(provided) => (
              <ContainerBox ref={provided.innerRef}>
                <HeaderTitle className="mx-2">{container.data.status}</HeaderTitle>
                <List>
                  {container.items.map((item, index) => (
                    <Draggable key={item._id} draggableId={item._id} index={index}>
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                            style={{
                            ...provided.draggableProps.style,
                          }}
                        >
                          <Item>
                            <div onClick={() => onSelect(item)}>
                              {item?.patient?.firstName && <span>{item?.patient?.firstName || ''}</span>}
                              {item?.patient?.lastName && <span>&nbsp;&nbsp;{item?.patient?.lastName || ''}</span>}
                              <span>{item?.patient?._id ? ` - ${DateUtility.dateToString(item?.patient?.birthdate, DateFormat.euDate)}` : <div className="mx-0 mb-1"><img src={Images.noPatientBlack} alt="" className="mx-1" width={15} height={15} />No Patient</div>}</span>
                              {item?.reason_for_visit && <h5 className="my-0">{`${item?.reason_for_visit}`} </h5>}
                            </div>
                          </Item>
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                </List>
                {provided.placeholder}
              </ContainerBox>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};