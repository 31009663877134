import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class Patient extends CRUDService {
    constructor() {
        super(APIPath.patients)
    }

    invoicesByPatient(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.patients}/patients-invoices?${query}`
        return BaseService.get(url)
    }
}

const PatientService = new Patient()
Object.freeze(PatientService)
export { PatientService }
