import { Tabs } from 'antd'
import { ShadowBox } from 'elements'
import { InvoiceByEncounter, InvoiceByPatient, PendingInvoices, RemindarInvoices, SentInvoices } from 'page-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

export function InvoicesScreen() {
    const { t } = useTranslation()
    const items = [
        {
            label: <h3 className="my-0">{t('By Patient')}</h3>,
            key: 1,
            children: <InvoiceByPatient />,
        },
        {
            label: <h3 className="my-0">{t('By Encounter')}</h3>,
            key: 2,
            children: <InvoiceByEncounter />,
        },
        {
            label: <h3 className="my-0">{t('Pending Invoices')}</h3>,
            key: 3,
            children: <PendingInvoices />,
        },
        {
            label: <h3 className="my-0">{t('Sent Invoices')}</h3>,
            key: 4,
            children: <SentInvoices />,
        },
        {
            label: <h3 className="my-0">{t('Invoices Remindars')}</h3>,
            key: 5,
            children: <RemindarInvoices />,
        },
    ]
    return (
        <ShadowBox className="container mt-3">
            <Tabs items={items} />
        </ShadowBox>
    )
}
