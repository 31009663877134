import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Select, Space, notification } from 'antd'
import { AutoCompleteDropDown, HeaderTitle, PageHeader, AppTable } from 'components'
import { DangerButton, FormDateField, FormSelectionField, FormTextAreaFormField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetInvoiceCodesList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DateUtility, InvoiceCodesService, InvoiceTarmedType } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const InvoiceCodesShema = yup.object().shape({
    tarmed_type: yup.string().required('tarmed_type is required'),
    service_code: yup.string().required("service_code is required"),
    service_description: yup.string().required("service_description is required"),
    service_appellation: yup.string().required("service_appellation is required"),
    chapter_code: yup.string().required("chapter_code is required"),
    qualitative_dignity_code: yup.string().required("qualitative_dignity_code is required"),
    qualitative_dignity_description: yup.string().required("qualitative_dignity_description is required"),
    qualitative_intrinsic_value_appellation: yup.string().required("qualitative_intrinsic_value_appellation is required"),
    functional_unit_code: yup.string().required("functional_unit_code is required"),
    functional_unit_description: yup.string().required("functional_unit_description is required"),
    functional_unit_appellation: yup.string().required("functional_unit_appellation is required"),
    spartan_recognition_type: yup.string().required("functional_unit_recognition_required is required"),
    functional_unit_recognition_type: yup.string().required("functional_unit_recognition_type is required"),
    startdate: yup.string().required("startdate is required"),
    enddate: yup.string().required("enddate is required"),
    rate: yup.string().required("rate is required"),
})

const AddInvoiceCodesModal = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(InvoiceCodesShema),
    })
    const { t } = useTranslation()
    useEffect(() => {
        reset(editMode ? { ...data,startdate: DateUtility.dayJSFormat(data.startdate),enddate: DateUtility.dayJSFormat(data.enddate) } : {})
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-invoice-codes")}` : `${t("add-edit.add-invoice-codes")}`}
            open={open}
            footer={null}
            width={900}
            onCancel={onClose}>
            <Form layout="vertical">
                <div className="row">
                    <div className="col-4">
                    <FormSelectionField
                    control={control}
                    name="tarmed_type"
                    placeholder={t("invoice-codes-fields.tarmed_type")}
                    label={t("invoice-codes-fields.tarmed_type")}
                    errors={errors?.tarmed_type}
                    showSearch
                    options={InvoiceTarmedType}
                    defaultValue=""
                />
                </div>
                <div className="col-3">
                <FormDateField
                    control={control}
                    name="startdate"
                    placeholder={t("invoice-codes-fields.startdate")}
                    label={t("invoice-codes-fields.startdate")}
                    errors={errors?.startdate}
                    showSearch
                    defaultValue={DateUtility.dayJSFormat(new Date())}
                /></div><div className="col-3">
                <FormDateField
                    control={control}
                    name="enddate"
                    placeholder={t("invoice-codes-fields.enddate")}
                    label={t("invoice-codes-fields.enddate")}
                    errors={errors?.enddate}
                    showSearch
                    defaultValue=""
                /></div><div className="col-2">
                 <FormTextFormField
                   control={control}
                    name="rate"
                    placeholder={t("invoice-codes-fields.rate")}
                    label={t("invoice-codes-fields.rate")}
                    errors={errors?.rate}
                    showSearch
                    defaultValue=""
                /></div>
                <h4>{t("service")}</h4>
                <div className="col-6">
                <FormTextFormField
                    control={control}
                    name="service_code"
                    placeholder={t("invoice-codes-fields.service_code")}
                    label={t("invoice-codes-fields.service_code")}
                    errors={errors?.service_code}
                    showSearch
                    defaultValue=""
                /></div>
                <div className="col-6">
                <FormTextFormField
                    control={control}
                    name="service_description"
                    placeholder={t("invoice-codes-fields.service_description")}
                    label={t("invoice-codes-fields.service_description")}
                    errors={errors?.service_description}
                    showSearch
                    defaultValue=""
                /></div><div className="col-6">
                <FormTextFormField
                    control={control}
                    name="service_appellation"
                    placeholder={t("invoice-codes-fields.service_appellation")}
                    label={t("invoice-codes-fields.service_appellation")}
                    errors={errors?.service_appellation}
                    showSearch
                    defaultValue=""
                /></div><div className="col-6">
                <FormTextFormField
                    control={control}
                    name="chapter_code"
                    placeholder={t("invoice-codes-fields.chapter_code")}
                    label={t("invoice-codes-fields.chapter_code")}
                    errors={errors?.chapter_code}
                    showSearch
                    defaultValue=""
                /></div>
                <h4>{t("qualitative")}</h4>
                <div className="col-6">
                <FormTextFormField
                    control={control}
                    name="qualitative_dignity_code"
                    placeholder={t("invoice-codes-fields.qualitative_dignity_code")}
                    label={t("invoice-codes-fields.qualitative_dignity_code")}
                    errors={errors?.qualitative_dignity_code}
                    showSearch
                    defaultValue=""
                /></div><div className="col-6">
                <FormTextFormField
                    control={control}
                    name="qualitative_dignity_description"
                    placeholder={t("invoice-codes-fields.qualitative_dignity_description")}
                    label={t("invoice-codes-fields.qualitative_dignity_description")}
                    errors={errors?.qualitative_dignity_description}
                    showSearch
                    defaultValue=""
                /></div><div className="col-6">
                <FormTextFormField
                    control={control}
                    name="qualitative_intrinsic_value_appellation"
                    placeholder={t("invoice-codes-fields.qualitative_intrinsic_value_appellation")}
                    label={t("invoice-codes-fields.qualitative_intrinsic_value_appellation")}
                    errors={errors?.qualitative_intrinsic_value_appellation}
                    showSearch
                    defaultValue=""
                /></div>
                <h4>{t("functional unit")}</h4>
                <div className="col-6">
                <FormTextFormField
                    control={control}
                    name="functional_unit_code"
                    placeholder={t("invoice-codes-fields.functional_unit_code")}
                    label={t("invoice-codes-fields.functional_unit_code")}
                    errors={errors?.functional_unit_code}
                    showSearch
                    defaultValue=""
                /></div>
                <div className="col-6">
                <FormTextFormField
                    control={control}
                    name="functional_unit_appellation"
                    placeholder={t("invoice-codes-fields.functional_unit_appellation")}
                    label={t("invoice-codes-fields.functional_unit_appellation")}
                    errors={errors?.functional_unit_appellation}
                    showSearch
                    defaultValue=""
                /></div>
                <div className="col-6">
                <FormTextFormField
                    control={control}
                    name="functional_unit_description"
                    placeholder={t("invoice-codes-fields.functional_unit_description")}
                    label={t("invoice-codes-fields.functional_unit_description")}
                    errors={errors?.functional_unit_description}
                    showSearch
                    defaultValue=""
                /></div><div className="col-12">
                <FormTextAreaFormField
                    control={control}
                    name="spartan_recognition_type"
                    placeholder={t("invoice-codes-fields.spartan_recognition_type")}
                    label={t("invoice-codes-fields.spartan_recognition_type")}
                    errors={errors?.spartan_recognition_type}
                    showSearch
                    defaultValue=""
                    rows={6}
                /></div>
                <div className="col-12">
                <FormTextAreaFormField
                    control={control}
                    name="functional_unit_recognition_type"
                    placeholder={t("invoice-codes-fields.functional_unit_recognition_type")}
                    label={t("invoice-codes-fields.functional_unit_recognition_type")}
                    errors={errors?.functional_unit_recognition_type}
                    showSearch
                    defaultValue=""
                    rows={6}
                /></div>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? `${t("edit")}` : `${t("create")}`} {t("settings.invoice-codes")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

export const InvoiceCodesTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { data, setData, refetch, filterChanged, total, filter, pageChanged } = GetInvoiceCodesList({ tarmed_type: "TARMED8" })
    const [tarmedType,setTarmedType] = useState("TARMED8")
    const onTarmedChange = (data) => {
        setTarmedType(data)
        filterChanged("search", { tarmed_type: data })
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await InvoiceCodesService.add(formData)
                notification.success({ message: t("notif-message.category-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await InvoiceCodesService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.category-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeInvoiceCodes = async (id) => {
        try {
            await InvoiceCodesService.remove(id)
            notification.success({ message: t("notif-message.category-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const columns = [
        {
            title: t("invoice-codes-fields.service_code"),
            dataIndex: 'service_code',
            key: 'service_code',
            width: 200,
        },
        {
            title: t("invoice-codes-fields.service_appellation"),
            dataIndex: 'service_appellation',
            key: 'service_appellation',
            width: 1000,

        },
        {
            title: t("invoice-codes-fields.chapter_code"),
            dataIndex: 'chapter_code',
            key: 'chapter_code',
        },
        {
            title: t("invoice-codes-fields.qualitative_dignity_code"),
            dataIndex: 'qualitative_dignity_code',
            key: 'qualitative_dignity_code',
            width: 200,
        },
        {
            title: t("invoice-codes-fields.rate"),
            dataIndex: 'rate',
            key: 'rate',
            width: 700,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.category")}` })}
                        onConfirm={() => removeInvoiceCodes(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >

                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const orderChanged = async (items) => {
        try {
            await InvoiceCodesService.OrderBy({ items: items.map(item => item._id), skip: filter.skip })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    return (
        <>
        <DesktopMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-6">
                    {t("settings.invoice-codes")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onTarmedChange} className="mx-2" defaultValue={tarmedType} popupMatchSelectWidth options={InvoiceTarmedType} />
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} Documents ${t("form-fields.category")}`}
                        options={[]}
                        className="mb-0 ms-5"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton></>}
            />
            <AddInvoiceCodesModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="12">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </DesktopMode>
        <MobileMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-6">
                    {t("settings.invoice-codes")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onTarmedChange} className="mx-2" defaultValue={tarmedType} popupMatchSelectWidth options={InvoiceTarmedType} />
                    </>}
            />
            <div className="col-12 d-flex py-1">
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} Documents ${t("form-fields.category")}`}
                        options={[]}
                        className="mb-0 ms-1"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                    </div>
            <AddInvoiceCodesModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="overflow-auto py-1">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </MobileMode>
        </>
    )
}