import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Notes extends CRUDService {
    constructor() {
        super(APIPath.notes)
    }

    get(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.notes}?${query}`
        return BaseService.get(url)
    }

    getAdmin() {
        const url = `${APIPath.notes}/admin`
        return BaseService.get(url)
    }
}

const NotesService = new Notes()
Object.freeze(NotesService)
export { NotesService }