import {
    AuthProvider,
    PatientHistoryProvider,
    ProtectRoute,
    RoleGuard,
} from 'context'
import { GlobalStyles } from 'global'
import {
    BrowserRouter as Router,
    Navigate,
    Outlet,
    Route,
    Routes,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import {
    SettingsScreen,
    AddEditPatientScreen,
    PatientListScreen,
    AddEditDoctorScreen,
    DoctorListScreen,
    PatientDetailsScreen,
    LoginScreen,
    AppointmentListScreen,
    AppointmentDetailsScreen,
    EncounterScreen,
    HomeScreen,
    StatisticScreen,
    InvoicesScreen,
    DocumentScreen,
    LaboratoryScreen,
} from 'screens'
import { Layout } from 'layout'
import { theme } from 'utility/theme'
import { Roles } from 'utility'
import React, { Suspense } from 'react'
import PDFEdior from 'screens/editor'

function App() {
    return (
        <Suspense fallback={null}>
            <ThemeProvider theme={theme}>
                <Router>
                    <AuthProvider>
                        <PatientHistoryProvider>
                            <GlobalStyles />
                            <Layout>
                                <Routes>
                                    <Route
                                        path="editor"
                                        element={
                                            <ProtectRoute>
                                                <PDFEdior />
                                            </ProtectRoute>
                                        }
                                    />
                                    <Route
                                        path="login"
                                        element={<LoginScreen />}
                                    />
                                    <Route
                                        index
                                        element={<Navigate to="/app" replace />}
                                    />
                                    <Route
                                        path="app"
                                        element={
                                            <ProtectRoute>
                                                <Outlet />
                                            </ProtectRoute>
                                        }
                                    >
                                        <Route
                                            path="statistic"
                                            element={<StatisticScreen />}
                                        />
                                        <Route
                                            path="home"
                                            element={<HomeScreen />}
                                        />
                                        <Route
                                            index
                                            element={
                                                <Navigate
                                                    to="/app/home"
                                                    replace
                                                />
                                            }
                                        />
                                        <Route
                                            path="encounter"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<EncounterScreen />}
                                            />
                                        </Route>
                                        <Route
                                            path="appointments"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={
                                                    <AppointmentListScreen />
                                                }
                                            />
                                            <Route
                                                path=":id"
                                                element={
                                                    <AppointmentDetailsScreen />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="billing"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<InvoicesScreen />}
                                            />
                                        </Route>
                                        <Route
                                            path="document"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<DocumentScreen />}
                                            />
                                        </Route>
                                        <Route
                                            path="laboratory"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<LaboratoryScreen />}
                                            />
                                        </Route>
                                        <Route
                                            path="patient"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<PatientListScreen />}
                                            />
                                            <Route
                                                path=":id"
                                                element={
                                                    <PatientDetailsScreen />
                                                }
                                            />
                                            <Route
                                                path="add"
                                                element={
                                                    <AddEditPatientScreen />
                                                }
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={
                                                    <AddEditPatientScreen />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="patient"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.doctor]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<PatientListScreen />}
                                            />
                                            <Route
                                                path=":id"
                                                element={
                                                    <PatientDetailsScreen />
                                                }
                                            />
                                            <Route
                                                path="add"
                                                element={
                                                    <AddEditPatientScreen />
                                                }
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={
                                                    <AddEditPatientScreen />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="doctor"
                                            element={
                                                <RoleGuard
                                                    roles={[Roles.admin]}
                                                >
                                                    <Outlet />
                                                </RoleGuard>
                                            }
                                        >
                                            <Route
                                                index
                                                element={<DoctorListScreen />}
                                            />
                                            <Route
                                                path="add"
                                                element={
                                                    <AddEditDoctorScreen />
                                                }
                                            />
                                            <Route
                                                path="edit/:id"
                                                element={
                                                    <AddEditDoctorScreen />
                                                }
                                            />
                                        </Route>
                                        <Route
                                            path="settings"
                                            element={
                                                <RoleGuard
                                                    roles={[
                                                        Roles.doctor,
                                                        Roles.admin,
                                                    ]}
                                                >
                                                    <SettingsScreen />
                                                </RoleGuard>
                                            }
                                        />
                                    </Route>
                                </Routes>
                            </Layout>
                        </PatientHistoryProvider>
                    </AuthProvider>
                </Router>
            </ThemeProvider>
        </Suspense>
    )
}
export default App
