import { DateFormat, DateUtility, FileUploadService, PatientDocumentService } from 'utility'
import { useEffect, useMemo, useState } from 'react'
import { Button, Form, Modal, Space, notification } from 'antd'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
import { DocumentScreen } from './list'
import { FormSelectionField, FormTextAreaFormField, FormTextFormField, PrimaryButton } from 'elements'
import { GetDoctorsList, GetDocumentStatusList, GetDocumentsCategory, GetPatientList } from 'hooks'
import { GetEncounterByPatientWithSearch } from 'hooks/encounter'
import { FileUpload } from 'components'

const DocumentShema = yup.object().shape({
    category: yup.string().required(),
    status: yup.string().required(),
    description: yup.string(),
    content_type: yup.string(),
    name: yup.string().required(),
    patient: yup.string().required(),
})

export const AddEditDocumentModal = ({
    open,
    onClose,
    patientId,
    documentType,
    data,
    files,
}) => {
    const [filesArray, setFilesArray] = useState([])
    const [processing,setProcessing] = useState(false)

    const { t } = useTranslation()
    const { user } = useAuth()
    const { optionList: categoryList } = GetDocumentsCategory()
    const { data: encounters, filterChanged } = GetEncounterByPatientWithSearch(patientId)
    const { optionList: patientList, filterChanged: patientFilterChanged } = GetPatientList({ all: true })
    const { optionList: documentStatusList } = GetDocumentStatusList()
    const { optionList: doctorList } = GetDoctorsList()

    const {
        reset,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(DocumentShema),
    })

    const listOfEncounter = useMemo(() => {
        return encounters.map(item => ({
            value: item._id,
            label: `${item.name} - ${DateUtility.dateToString(new Date(item.date || item.createdAt), DateFormat.euDate)}`,
        }))
    }, [encounters])

    useEffect(() => {
        if (data?._id) {
            reset({
                name: data.name,
                status: data.status?._id,
                description: data.description,
                content_type: data.content_type,
                type: data.type,
                patient: data?.patient?._id,
                category: data?.category?._id,
                validationAccess: data?.validationAccess,
                _id: data?._id,
            })
            setFilesArray([{ url: data.url, name: data.name, link: true }])
        } else {
            setFilesArray([])
            reset({})
        }
    }, [open, data])

    useEffect(() => {
        setFilesArray(files)
     },[files])

    const onNewFile = async ({ file }) => {
        setFilesArray([...filesArray, file])
    };

    const onRemoveModel = async (file) => {
        setFilesArray(filesArray.filter((x) => x._id !== file._id));
    };

    const onSubmit = async (formData) => {
        if (filesArray.length === 0) {
            notification.error({
                message: t('notif-message.upload-atleast-one'),
            })
            return
        }
        try {
            setProcessing(true)
            const promises = filesArray.map(async (item) => {
                if (item.link) {
                    const result = await PatientDocumentService.update(
                        formData._id,
                        {
                            ...formData,
                            url: item.url,
                            _id: undefined,
                        },
                    )
                    return {
                        ...result,
                    }
                }
                const { signedUrl, keyOrUrl } =
                    await FileUploadService.signedUrl({
                        ext: `.${item.name.split('.').pop()}`,
                        contentType: item.type,
                        isPublic: true,
                    })
                await FileUploadService.media(signedUrl, item, item.type)
                delete formData._id
                const result = await PatientDocumentService.add({
                    ...formData,
                    patient: patientId || formData.patient,
                    doctor: user._id,
                    type: documentType,
                    url: keyOrUrl,
                })

                return result
            })
            const result = await Promise.all(promises)
            notification.success({
                message: t('notif-message.document-submit'),
            })
            onClose(result)
        } catch {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            reset({ name: '' })
        }
    }

    return (
        <Modal
            title={`${t('add-edit.add-documents')}`}
            open={open}
            onCancel={() => {
                reset({})
                onClose()
            }}
            onSubmit={onSubmit}
            footer={false}>
            <Form layout="vertical" className="row">
            <FormSelectionField
                control={control}
                name="patient"
                errors={errors?.patient}
                label={t("form-fields.patient")}
                options={patientList}
                filterOption={false}
                showSearch
                onSearch={(data) => patientFilterChanged("search", data)}
                placeholder={`${t("search")} ${t("form-fields.patient")}`}
            />
            <Form.Item className="col-6 mb-0">
            <FormSelectionField
                control={control}
                name="category"
                label={t("form-fields.category")}
                placeholder={`${t("search")} ${t("form-fields.category")}`}
                options={categoryList}
                errors={errors?.category}
            />
            </Form.Item>
            <Form.Item className="col-6 mb-0">
            <FormSelectionField
                control={control}
                name="status"
                label={t("form-fields.status")}
                placeholder=""
                errors={errors?.status}
                options={documentStatusList}
            />
                        </Form.Item>
                        <FormSelectionField
                control={control}
                mode="multiple"
                name="validationAccess"
                label={t("form-fields.validationAccess")}
                placeholder={`${t("search")} ${t("form-fields.validationAccess")}`}
                options={doctorList}
                onSelect={() => {}}
                errors={errors?.validationAccess}
            />
            <FormSelectionField
                control={control}
                name="content_type"
                errors={errors?.encounter}
                label={t("form-fields.content-type")}
                options={listOfEncounter}
                filterOption={false}
                showSearch
                onSearch={(data) => filterChanged("search", data)}
                placeholder={`${t("search")} ${t("form-fields.content-type")}`}
            />
            <FormTextFormField
                control={control}
                name="name"
                label={t("form-fields.name")}
                placeholder="Enter Name"
                errors={errors?.name}
            />
            <FormTextAreaFormField
                control={control}
                name="description"
                label="Description"
                placeholder="Description"
                errors={errors?.description}
                rows={5}
            />
            <FileUpload
                onRemoveModel={onRemoveModel}
                onNewFile={onNewFile}
                fileList={filesArray}
                multiple={data._id}
            />
            <div className="d-flex justify-content-end">
                <Space align="end" className="mt-3">
                    <Button
                        key="cancel"
                        onClick={() => onClose()}
                    >
                        {t("cancel")}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={processing}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {t("add-edit.add-documents")}
                    </PrimaryButton>
                </Space>
            </div>
        </Form>
    </Modal>
    )
}

export const SelectDocumentsPopup = ({
    open,
    onClose,
    patientId,
    onSelect,
    selactedRows,
    documentType,
}) => {
    const { t } = useTranslation()
    return (
        <Modal
            title={`${t('add-edit.add-documents')}`}
            open={open}
            onCancel={onClose}
            footer={false}
        >
            <DocumentScreen
                patientId={patientId}
                actions={false}
                add={false}
                selectable
                onSelect={onSelect}
                selactedRows={selactedRows}
                documentType={documentType}
            />
        </Modal>
    )
}
