import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    Space,
    notification,
    Popconfirm,
    Modal,
    Select,
} from 'antd'
import {
    FormCheckBoxField,
    FormColorPicker,
    FormDateField,
    FormTextAreaFormField,
    PrimaryButton,
    DangerButton,
    FormSelectionField,
} from 'elements'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    ColumnSearchType,
    DateFormat,
    DateUtility,
    DiagnosticService,
    DoctorService,
} from 'utility'
import { AppTable } from 'components'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAuth } from 'context'
import { CheckCircle, Plus, XCircle } from 'phosphor-react'
import dayjs from 'dayjs'
import { GetDiagnosticTypeList, useDebounce } from 'hooks'
import { BlockHeader } from './header'

const DiagnosticSchema = yup.object().shape({
    description: yup.string().required(),
    startdate: yup.string().required('*Last Name is required'),
    enddate: yup.string().nullable(),
    active: yup.boolean(),
    color: yup.string(),
})
export const DiagnosticTable = ({
    list,
    editDiagnosatic,
    diagnosticTypeList,
}) => {
    const { t } = useTranslation()
    const rowClassName = (data) => {
        if (!data.active) {
            return 'grey-row'
        }
        return ''
    }
    const onRowDoubleClick = (data) => {
        editDiagnosatic(data)
    }
    const columns = [
        {
            title: t('form-fields.description'),
            dataIndex: 'description',
            key: 'description',
            // width: paneSize < 600 ? 300 : null,
        },
        {
            title: t('form-fields.diagnostictype'),
            dataIndex: 'diagnostictype',
            key: 'diagnostictype',
            width: 200,
            render: (text) => (
                <>{text?.name}</>
            ),
        },
        {
            title: t('form-fields.startdate'),
            dataIndex: 'startdate',
            key: 'startdate',
            type: ColumnSearchType.Date,
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.enddate'),
            dataIndex: 'enddate',
            key: '',
            type: ColumnSearchType.Date,
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate) || '-'}</>
            ),
        },
        {
            title: t('form-fields.active'),
            dataIndex: 'active',
            key: 'active',
            render: (text) => (
                <>{text ? <CheckCircle size={20} /> : <XCircle size={20} />}</>
            ),
        },
    ]

    return (
        <>
            <>
                <AppTable
                    data={list}
                    options={diagnosticTypeList}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    rowClassName={rowClassName}
                    onRowDoubleClick={onRowDoubleClick}
                />
            </>
        </>
    )
}
export const DiagnosticTab = ({
    list,
    setData,
    refetch,
    appointment,
    patient,
    paneSize,
    filterChanged,
}) => {
    const [processing, setProcessing] = useState(false)
    const { user, setUser } = useAuth()
    const [open, setOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const { t } = useTranslation()
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(DiagnosticSchema),
    })
    const { optionList: diagnosticTypeList ,filterChanged: diagnosticfilterChanged } = GetDiagnosticTypeList()
    const [search, setSearch] = useState("")
    const typeSearch = useDebounce(search,1000)
    useEffect(() => {
        diagnosticfilterChanged('search', {
            name: typeSearch,
        })
    },[typeSearch])
    const color = watch('color')
    const rowId = watch('_id')
    const onSubmit = async (data) => {
        if (!patient?._id) {
            notification.error({
                message: t('notif-message.patient-not-found'),
            })
            return
        }
        try {
            setProcessing(true)
            const reqData = {
                ...data,
                patient: patient?._id,
                doctor: user._id,
                appointment: appointment?._id,
            }
            if (data._id) {
                delete reqData._id
                await DiagnosticService.update(data._id, reqData)
                notification.success({
                    message: t('notif-message.diagnostic-updated'),
                })
            } else {
                await DiagnosticService.add(reqData)
                notification.success({
                    message: t('notif-message.diagnostic-added'),
                })
            }
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
            refetch()
            reset({
                color: user.diagnostic_color || '#000000',
                diagnostictype: '',
            })
            setOpen(false)
        }
    }

    const removeDiagnostic = async (id) => {
        try {
            await DiagnosticService.remove(id)
            notification.success({
                message: t('notif-message.diagnostic-removed'),
            })
            setData(list.filter((ele) => ele._id !== id))
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setOpen(false)
            refetch()
            setEditMode(true)
        }
    }

    const onSelectSign = (sign) => {
        setOpen(true)
        reset({
            diagnostictype: sign,
        })
    }

    const editDiagnosatic = (data) => {
        reset({
            ...data,
            startdate: dayjs(data.startdate),
            enddate: data.enddate ? dayjs(data.enddate) : null,
            diagnostictype: data?.diagnostictype?._id,
        })
        setEditMode(true)
        setOpen(true)
    }
    useEffect(() => {
        reset({
            color: user?.diagnostic_color || false,
        })
    }, [])

    const setDefaultColor = async (remove) => {
        // if (!color) {
        //     notification.warning({
        //         message: t('notif-message.select-color'),
        //     })
        //     return
        // }
        try {
            await DoctorService.update(user._id, {
                diagnostic_color: remove ? '' : color,
            })
            notification.success({
                message: t('notif-message.doctor-updated'),
            })
            setUser({
                ...user,
                diagnostic_color: remove ? '' : color,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }
    const onClose = () => {
        setOpen(false)
        reset({ diagnostictype: undefined })
    }
    return (
        <div className="">
            <div>
                <BlockHeader
                    title={t('form-fields.diagnostic')}
                    searchElement={
                        <Select
                            popupMatchSelectWidth
                            className="col-12"
                            options={diagnosticTypeList}
                            placeholder={t("Select-diagnostic")}
                            onSelect={(id, data) => onSelectSign(id, data)}
                            showSearch
                            notFoundContent={
                                <PrimaryButton
                                    onClick={() => setOpen(true)}
                                    className="d-flex align-items-center"
                                    icon={<Plus />}
                                >
                                    Add Type
                                </PrimaryButton>
                            }
                            filterOption={false}
                            onSearch={(data) => {
                                setSearch(data)
                            }}
                        />
                    }
                >
                    <Select
                        defaultValue=""
                        popupClassName="filter-options"
                        style={{ width: 200 }}
                        options={[
                            { value: '', label: 'All' },
                            ...diagnosticTypeList,
                        ]}
                        onChange={(val) =>
                            filterChanged('search', { type: val })}
                    />
                </BlockHeader>
                {/* <div className="d-flex justify-content-between align-items-center mt-0 mb-3">
                    <h3 className="mt-0 align-items-center mb-0">
                        {t('diagnostic')}
                    </h3>
                    <PrimaryButton onClick={() => setOpen(true)}>
                        {t('add-edit.add-diagnostic')}
                    </PrimaryButton>
                </div> */}
                <DiagnosticTable
                    list={list}
                    // sortChanged={sortChanged}
                    editDiagnosatic={editDiagnosatic}
                    removeDiagnostic={removeDiagnostic}
                    paneSize={paneSize}
                />
            </div>
            <Modal
                title={
                    editMode
                        ? t('add-edit.edit-diagnostic')
                        : t('add-edit.add-diagnostic')
                }
                open={open}
                confirmLoading
                footer={null}
                width={600}
                onClose={onClose}
                onCancel={onClose}
            >
                <Form layout="vertical">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="startdate"
                                    label={t('form-fields.startdate')}
                                    placeholder=""
                                    errors={errors?.startdate}
                                    defaultValue={DateUtility.dayJSFormat(
                                        new Date(),
                                    )}
                                />{' '}
                            </div>
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="enddate"
                                    label={t('form-fields.enddate')}
                                    placeholder=""
                                    errors={errors?.enddate}
                                    allowClear
                                    defaultValue={null}
                                />{' '}
                            </div>
                            <div className="col-12">
                                <FormTextAreaFormField
                                    control={control}
                                    name="description"
                                    label={t('form-fields.description')}
                                    placeholder=""
                                    errors={errors?.description || ''}
                                    defaultValue=""
                                />
                            </div>
                            <div className="col-12">
                                <FormSelectionField
                                    control={control}
                                    name="diagnostictype"
                                    label={t('form-fields.diagnostictype')}
                                    placeholder="diagnostictype"
                                    errors={errors?.diagnostictype || ''}
                                    options={diagnosticTypeList}
                                />
                            </div>
                            <div className="col-5">
                                <FormCheckBoxField
                                    control={control}
                                    name="active"
                                    text={t('form-fields.active')}
                                    errors={errors?.active}
                                    defaultValue="true"
                                />
                            </div>
                            <div className="col-7">
                                <div className=" justify-content-end d-flex flex-row">
                                    <div className="mt-1">
                                        Color &nbsp;&nbsp;
                                    </div>
                                    <FormColorPicker
                                        control={control}
                                        name="color"
                                        placeholder="color"
                                        errors={errors?.color}
                                    />
                                    {color === user.diagnostic_color ? (
                                        <PrimaryButton
                                            className="ms-2"
                                            onClick={() =>
                                                setDefaultColor(true)}
                                        >
                                            Remove as default
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton
                                            className="ms-2"
                                            onClick={() => setDefaultColor()}
                                        >
                                            Set as default
                                        </PrimaryButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Popconfirm
                            title={t('delete')}
                            description={t('message.delete-message', {
                                name: `${t('diagnostic')}`,
                            })}
                            onConfirm={() => removeDiagnostic(rowId)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton> {t('delete')} </DangerButton>
                        </Popconfirm>
                        <div />
                        <Space wrap>
                            <Button key="cancel" onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                key="submit"
                                loading={processing}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('submit')}
                            </PrimaryButton>
                        </Space>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
