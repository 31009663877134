import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class UserVitalSign extends CRUDService {
    constructor() {
        super(APIPath.uservitalsigns)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.uservitalsigns}/order-by`, data)
    }

    byAppointment(id, params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(
            `${APIPath.uservitalsigns}/appointment/${id}?${data}`,
        )
    }

    byPatient(id, params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(
            `${APIPath.uservitalsigns}/patient/${id}?${data}`,
        )
    }
}

const UserVitalSignsService = new UserVitalSign()
Object.freeze(UserVitalSignsService)
export { UserVitalSignsService }
