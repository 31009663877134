import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Form, Button, Space, notification } from 'antd'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { HeaderTitle } from 'components'
import { FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { CountryList, DateUtility, DoctorService, StateList } from 'utility'
import { useAuth } from 'context'

const ProfileSchema = yup.object().shape({
    bank: yup.object().shape({
        companyName: yup.string().required('* Company Name is required'),
        title: yup.string().required('* Title is required'),
        firstName: yup.string().required('*First name is required'),
        lastName: yup.string().required('*Last name is required'),
        technicalServicePointTariff: yup
            .number()
            .transform((value, originalValue) =>
                originalValue.trim() === '' ? null : parseFloat(originalValue),
            ), // Allow number or float
        address: yup.object().shape({
            address: yup.string().required('*Address is required'),
            city: yup.string().required('*City is required'),
            postalCode: yup.string().required('*PostalCode is required'),
            country: yup.string().required('*Country is required'),
            state: yup.string().required('*State is required'),
        }),
        iban: yup.string().required('*iban is required'),
        qriban: yup.string().required('*qriban is required'),
    }),
})

const BankConatiner = styled.div`
    min-height: 70vh;
`
export const BankTab = () => {
    const { t } = useTranslation()

    const [processing, setProcessing] = useState(false)
    const { user } = useAuth()

    const {
        control,
        handleSubmit,
        reset,
        setValue,

        formState: { errors },
    } = useForm({
        resolver: yupResolver(ProfileSchema),
    })

    const [technicalServicePointTariff, setTechnicalServicePointTariff] =
        useState('')

    useEffect(() => {
        if (user._id) {
            reset({
                ...user,
                starttime: DateUtility.dayJSFormat(user.starttime),
                endtime: DateUtility.dayJSFormat(user.endtime),
            })
            setTechnicalServicePointTariff(
                user.bank?.technicalServicePointTariff || '',
            )
        }
    }, [user])

    const handleStateChange = (selectedState) => {
        const selectedStateInfo = StateList.find(
            (state) => state.value === selectedState,
        )
        const newTariff = selectedStateInfo ? selectedStateInfo.tariff : '0'
        setTechnicalServicePointTariff(newTariff)
        setValue('bank.technicalServicePointTariff', newTariff)
    }

    const submit = async (formData) => {
        try {
            setProcessing(true)
            delete formData._id
            delete formData.password
            delete formData.access_token
            formData.bank.technicalServicePointTariff =
                technicalServicePointTariff

            await DoctorService.update(user._id, formData)
            notification.success({
                message: t('notif-message.doctor-updated'),
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
        }
    }

    const cancel = () => {
        reset({
            ...user,
            starttime: DateUtility.dayJSFormat(user?.starttime),
            endtime: DateUtility.dayJSFormat(user?.endtime),
        })
    }

    return (
        <>
            <BankConatiner>
                <Form layout="vertical" noValidate>
                    <div className="row">
                        <div className="col-12">
                            <HeaderTitle>
                                {t('General Information')}
                            </HeaderTitle>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <FormTextFormField
                                control={control}
                                name="bank.companyName"
                                label={t('form-fields.bank.companyName')}
                                placeholder="Company Name"
                                errors={errors?.bank?.companyName || ''}
                                required
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <FormTextFormField
                                control={control}
                                name="bank.title"
                                label={t('form-fields.bank.title')}
                                placeholder="Title"
                                errors={errors?.bank?.title || ''}
                                required
                            />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                            <FormTextFormField
                                control={control}
                                name="bank.firstName"
                                label={t('form-fields.bank.firstName')}
                                placeholder="First Name"
                                errors={errors?.bank?.firstName || ''}
                                required
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="bank.lastName"
                                label={t('form-fields.bank.lastName')}
                                placeholder="Last Name"
                                errors={errors?.bank?.lastName || ''}
                                required
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FormTextFormField
                                type="number"
                                control={control}
                                name="bank.technicalServicePointTariff"
                                label="Technical Service Point Tariff"
                                placeholder="Technical Service Point Tariff"
                                value={technicalServicePointTariff}
                                onChange={(val) => {
                                    setTechnicalServicePointTariff(val)
                                }}
                                errors={
                                    errors?.bank?.technicalServicePointTariff ||
                                    ''
                                }
                            />
                        </div>
                    </div>
                    <h3>{t('address')}</h3>
                    <div className="row">
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="bank.address.address"
                                label={t('form-fields.bank.address.address')}
                                placeholder="Address"
                                errors={errors?.bank?.address?.address}
                                required
                            />
                        </div>
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="bank.address.postalCode"
                                label={t('form-fields.bank.address.postalCode')}
                                placeholder="Postal Code"
                                errors={errors?.bank?.address?.postalCode}
                                required
                            />
                        </div>
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="bank.address.city"
                                label={t('form-fields.bank.address.city')}
                                placeholder="City"
                                errors={errors?.bank?.address?.city}
                                required
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FormSelectionField
                                control={control}
                                name="bank.address.country"
                                label={t('form-fields.bank.address.country')}
                                placeholder="Country"
                                errors={errors?.bank?.address?.country}
                                options={CountryList}
                                showSearch
                                required
                            />
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <FormSelectionField
                                    control={control}
                                    name="bank.address.state"
                                    label="State"
                                    placeholder="State"
                                    errors={errors?.bank?.address?.state}
                                    options={StateList}
                                    showSearch
                                    required
                                    onChange={(selectedState) =>
                                        handleStateChange(selectedState)} // Add this line
                                />
                            </div>
                        </div>
                    </div>
                    <h3>{t('Bank Details')}</h3>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <FormTextFormField
                                control={control}
                                name="bank.iban"
                                label={t('form-fields.bank.iban')}
                                placeholder="Iban"
                                errors={errors?.bank?.iban || ''}
                                required
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="bank.qriban"
                                label={t('form-fields.bank.qriban')}
                                placeholder="QRIBAN"
                                errors={errors?.bank?.qriban || ''}
                                required
                            />
                        </div>
                    </div>
                    <Space className="d-flex justify-content-end">
                        <Button htmlType="button" onClick={cancel}>
                            {t('cancel')}
                        </Button>
                        <PrimaryButton
                            loading={processing}
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit(submit)}
                        >
                            {t('submit')}
                        </PrimaryButton>
                    </Space>
                </Form>
            </BankConatiner>
        </>
    )
}
