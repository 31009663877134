import { Spin } from "antd";
import styled from "styled-components";

const Spiner = styled(Spin)`
    width: 100%;
    height: 100%;
    span {
            top:50%;
    }
`
export const LoaderBar = ({ loading }) => (
    <Spiner size="large" spinning={loading} />
)
