import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class MedicalHistoryType extends CRUDService {
    constructor() {
        super(APIPath.medicalHistoryType)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.medicalHistoryType}/order-by`, data)
    }

    hideShow(id,status) {
        return BaseService.put(`${APIPath.medicalHistoryType}/hide/${id}/${status}`)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.medicalHistoryType}/bulk`,data)
    }
}

const MedicalHistoryTypeService = new MedicalHistoryType()
Object.freeze(MedicalHistoryTypeService)
export { MedicalHistoryTypeService }
