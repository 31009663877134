import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class AppointmentCategory extends CRUDService {
    constructor() {
        super(APIPath.appointmentCategory)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.appointmentCategory}/order-by`, data)
    }

    setPrimary(id, status) {
        return BaseService.put(
            `${APIPath.appointmentCategory}/primary/${id}/${status}`,
        )
    }

    codes(id) {
        return BaseService.get(`${APIPath.appointmentCategory}/${id}/codes`)
    }
}

const AppointmentCategoryService = new AppointmentCategory()
Object.freeze(AppointmentCategoryService)
export { AppointmentCategoryService }
