import React, { useMemo, useState } from 'react'
import { GetAppointmentList, GetAppointmentStatus } from 'hooks'
import styled from 'styled-components'
import {
    AppointmentService,
    CommonUtility,
    DateFormat,
    DateUtility,
    LocaleFormat,
} from 'utility'
import { useTranslation } from 'react-i18next'
import { Draglist } from 'components'
import { notification } from 'antd'
import { useNavigate } from 'react-router-dom'

const Button = styled.button`
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
`

const WaitingHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    .date-current {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
    }
`

const ButtonWrapper = styled.div`
    display: inline-block;
    white-space: nowrap;
    .today {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .pre {
        border-radius: 0;
        margin-left: -1px;
    }
    .next {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
    }
`

const WatingRoomHeader = ({ date, setDate }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <WaitingHeader>
            <ButtonWrapper>
                <Button className="today" onClick={() => setDate(new Date())}>
                    {t('calendar-properties.today')}
                </Button>
                <Button
                    className="pre"
                    onClick={() => setDate(DateUtility.subDays(date, 1))}
                >
                    {t('calendar-properties.previous')}
                </Button>
                <Button
                    className="next"
                    onClick={() => setDate(DateUtility.addDay(date, 1))}
                >
                    {t('calendar-properties.next')}
                </Button>
            </ButtonWrapper>
            <div className="date-current">
                {DateUtility.dateToString(
                    date,
                    DateFormat.weekDate,
                    LocaleFormat[language],
                )}
            </div>
        </WaitingHeader>
    )
}

export const WaitingRoom = () => {
    const { data } = GetAppointmentList()
    const { waitingRoomStatus } = GetAppointmentStatus()
    const [date, setDate] = useState(new Date())
    const { t } = useTranslation
    const navigate = useNavigate()
    const waitingList = useMemo(() => {
        return data.filter(
            (item) =>
                waitingRoomStatus?.some(
                    (data) => data._id === item?.status?._id,
                ) &&
                DateUtility.isSame(item.appointment_date, date.toISOString()),
        )
    }, [data, date, waitingRoomStatus])

    const statusList = useMemo(() => {
        let newStatusList = {}
        if (waitingList.length && waitingRoomStatus.length) {
            newStatusList = CommonUtility.groupedTwoList(
                waitingList,
                waitingRoomStatus,
                '_id',
            )
        }
        return Object.values(newStatusList)?.sort(
            (a, b) => a.data.order - b.data.order,
        )
    }, [waitingRoomStatus, waitingList])

    const onStatusChanged = async (appointmentid, statusid) => {
        try {
            await AppointmentService.update(appointmentid, { status: statusid })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    const onSelect = (appointment) => {
        navigate(`/app/appointments/${appointment._id}`)
    }

    return (
        <div>
            <WatingRoomHeader setDate={setDate} date={date} />
            <Draglist
                list={statusList}
                onStatusChanged={onStatusChanged}
                onSelect={onSelect}
            />
        </div>
    )
}
