import { APIPath } from '../constant'
import { AuthService } from './auth'
import { BaseService } from './base'
import { CRUDService } from './crud'

class HL7 extends CRUDService {
    constructor() {
        super(APIPath.parse)
    }

    uploadHL7(file) {
        const headers = {
            'Content-Type': 'multipart/form-data',
          }
          const token = AuthService.getToken()
          headers.Authorization = `Bearer ${token}`
        return BaseService.upload(`${process.env.REACT_APP_API_PATH}${APIPath.parse}/hl7/upload`, file, headers)
    }

    uploadXML(file) {
        const headers = {
            'Content-Type': 'multipart/form-data',
          }
          const token = AuthService.getToken()
          headers.Authorization = `Bearer ${token}`
        return BaseService.upload(`${process.env.REACT_APP_API_PATH}${APIPath.parse}/xml/upload`, file, headers)
    }
}

const HL7Service = new HL7()
Object.freeze(HL7Service)
export { HL7Service }
