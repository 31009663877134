import { ShadowBox } from "elements";
import React, { useState,useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export const MyResponsiveGrid = ({ items,onViewChange }) => {
  const [layout,setLayout] = useState([])
  useEffect(() => {
    setLayout(items)
  },[items])
  const onDragEnd = (result) => {
    if (!result.destination) return;
      const updatedItems = [...items];
      const [movedItem] = updatedItems.splice(result.source.index, 1);
      updatedItems.splice(result.destination.index, 0, movedItem);
      const newLayout = updatedItems.map((item,index) => ({
        ...item,
        id: index.toString(),
        w: item.w,
      }));
      setLayout(newLayout)
      onViewChange(newLayout.map(ele => ele.key))
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="row">
        <div className="col-12">
          <Droppable droppableId="1" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="drag-drop-container"
              >
                {layout.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={`drag-item col-${item.w} ${items.find((it) => it.id === item.id) && snapshot.isDragging ? "dragging" : ""
                          }`}
                      >
                        <ShadowBox style={{ height: "100%" }} className="">{items.find((it) => it.id === item.id)?.compo}</ShadowBox>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};
