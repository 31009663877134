import { useTranslation } from 'react-i18next'
import { EncounterEditor } from './add-edit'
import { AppTable, FlexRow } from 'components'
import {
    Button,
    Alert,
    Checkbox,
    Popconfirm,
    Space,
    Tag,
    notification,
} from 'antd'
import { DangerButton, FormSelectionField, PrimaryButton } from 'elements'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    GetDoctorDetail,
    GetInvoiceCodesList,
    GetInvoicesByEncounter,
    GetInvoicesGroupList,
} from 'hooks'
import {
    EncounterInvoicesService,
    EncounterTypeList,
    InvoiceCodesService,
    InvoiceStatusType,
    StateList,
} from 'utility'
import { Trash, Minus, Plus } from 'phosphor-react'
import { useEffect, useMemo } from 'react'

const InvoicesSchema = yup.object().shape({
    category: yup.string().required('*Category is required'),
})

export const ContentsTab = ({
    data,
    save,
    remove,
    quickEntryList,
    patient,
    drugOptions,
    drugData,
    onDrugAdd,
    dropDocument,
    readMode,
    diagnosticOptions,
    patientDrugData,
    lastmedication,
    setPatientDrugData,
    patientDrugDataFilterChanged,
}) => {
    return (
        <>
            <EncounterEditor
                key={data._id}
                item={data}
                onSave={save}
                onDelete={remove}
                quickEntryList={quickEntryList}
                patient={patient}
                drugOptions={drugOptions}
                onDrugAdd={onDrugAdd}
                drugData={drugData}
                dropDocument={dropDocument}
                diagnosticOptions={diagnosticOptions}
                patientDrugData={patientDrugData}
                setPatientDrugData={setPatientDrugData}
                patientDrugDataFilterChanged={patientDrugDataFilterChanged}
                lastmedication={lastmedication}
                readMode={readMode}
            />
        </>
    )
}

export const InvoicesTab = ({
    patient,
    encounter,
    updateInvoiceDraft,
    updateInvoiceStatus,
    readMode,
    changeConsulation,
}) => {
    const { t } = useTranslation()
    const {
        control,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(InvoicesSchema),
    })
    const { optionList: groupsList, filterChanged: groupFilterChanged } =
        GetInvoicesGroupList()
    const { optionList: codesList, filterChanged: codeFilterChanged } =
        GetInvoiceCodesList()

    const { data: doctor } = GetDoctorDetail(patient.doctor)
    const {
        data: encounterInvoicesList,
        setData,
        refetch,
    } = GetInvoicesByEncounter(encounter._id)
    const list = useMemo(() => {
        return [
            { label: 'Groups', options: groupsList },
            { label: 'Codes', options: codesList },
        ]
    }, [codesList, groupsList])

    useEffect(() => {
        setValue('consulation', encounter.consulation || patient.consulation)
        setValue('insurance', encounter.insurance)
    }, [patient, encounter])

    const changeConsulationLocal = async (type) => {
        changeConsulation(encounter, type, true)
        console.log(type)

        const new_invoice_codes = []
        const service_codes = [
            ...new Set(
                encounterInvoicesList.map(
                    (item) => item.invoicecode.service_code,
                ),
            ),
        ]
        await Promise.all(
            service_codes.map(async (code) => {
                const { data } = await InvoiceCodesService.get({
                    service_code: code,
                    tarmed_type: type === 'UVG' ? 'TARMED8' : 'TARMED9',
                })
                new_invoice_codes.push(data[0])
                return data
            }),
        )

        console.log(new_invoice_codes)

        await Promise.all(
            encounterInvoicesList.map(async (item) => {
                await EncounterInvoicesService.remove(item._id)
                // eslint-disable-next-line no-return-await
                return await EncounterInvoicesService.add({
                    invoicecode: new_invoice_codes.find(
                        (newItem) =>
                            newItem.service_code ===
                            item.invoicecode.service_code,
                    )?._id,
                    patient: patient?._id,
                    cost: 1,
                    encounter: encounter?._id,
                })
            }),
        ).then(refetch)
    }

    const onSearch = (data) => {
        setValue(data)
        groupFilterChanged('search', {
            search: data,
        })
        codeFilterChanged('search', {
            search: data,
        })
    }

    const removeInvoiceCodes = async (id, silent = false) => {
        try {
            await EncounterInvoicesService.remove(id)
            if (!silent) {
                notification.success({
                    message: t('notif-message.code-removed'),
                })
            }
            setData(encounterInvoicesList.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const columns = [
        {
            title: t('invoice-codes-fields.service_code'),
            dataIndex: 'invoicecode',
            key: 'invoicecode',
            width: 150,
            render: (text) => <div>{text.service_code}</div>,
        },
        {
            title: t('invoice-codes-fields.service_appellation'),
            dataIndex: 'invoicecode',
            key: 'invoicecode',
            render: (text) => <div>{text.service_appellation}</div>,
        },
        {
            title: t('invoice-codes-fields.chapter_code'),
            dataIndex: 'invoicecode',
            key: 'invoicecode',
            render: (text) => <div>{text.chapter_code}</div>,
        },
        {
            title: t('invoice-codes-fields.qualitative_dignity_code'),
            dataIndex: 'invoicecode',
            key: 'invoicecode',
            width: 200,
            render: (text) => <div>{text.qualitative_dignity_code}</div>,
        },
        {
            title: 'PT',
            dataIndex: 'invoicecode',
            key: 'invoicecode',
            width: 100,
            render: (text) => <div>{text.PT}</div>,
        },
        {
            title: 'PM',
            dataIndex: 'invoicecode',
            key: 'invoicecode',
            width: 100,
            render: (text) => <div>{text.PM}</div>,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100,
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        type="primary"
                        disabled={readMode}
                        icon={<Minus />}
                        htmlType="button"
                        onClick={() => removeInvoiceCodes(record._id)}
                        style={{ marginRight: '8px' }}
                        size="small"
                    />
                    {text}

                    <Button
                        type="primary"
                        disabled={readMode}
                        icon={<Plus />}
                        htmlType="button"
                        onClick={() => addInvoices(record.invoicecode._id)}
                        style={{ marginLeft: '8px' }}
                        size="small"
                    />
                </div>
            ),
        },

        {
            title: t('invoice-codes-fields.cost'),
            dataIndex: 'cost',
            key: 'cost',
            width: 200,
            render: (text) => <div>{text}</div>,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('code')}`,
                        })}
                        onConfirm={() => removeInvoiceCodes(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton
                            disabled={readMode}
                            icon={<Trash />}
                            htmlType="button"
                        />
                    </Popconfirm>
                </Space>
            ),
        },
    ]
    const addInvoices = async (data, silent = false) => {
        const invoiceList = [...codesList, ...groupsList].find(
            (ele) => ele._id === data,
        )
        if (encounterInvoicesList.length === 0) {
            updateInvoiceStatus(encounter)
        }
        if (invoiceList) {
            if (invoiceList?.invoicescodes?.length) {
                await EncounterInvoicesService.add({
                    patient: patient?._id || null,
                    encounter: encounter?._id,
                    invoicescodes: invoiceList?.invoicescodes,
                })
            } else {
                await EncounterInvoicesService.add({
                    invoicecode: invoiceList?._id,
                    patient: patient?._id,
                    cost: invoiceList.rate,
                    encounter: encounter?._id,
                })
            }
        }
        if (!silent) {
            notification.success({ message: t('notif-message.code-added') })
        }
        refetch()
    }

    const removeAll = async (encounterInvoicesList, silent = false) => {
        try {
            encounterInvoicesList.map(async (item) => {
                await EncounterInvoicesService.remove(item._id)
            })
            if (!silent) {
                notification.success({
                    message: t('notif-message.code-removed'),
                })
            }
            setData([])
            updateInvoiceStatus(encounter, InvoiceStatusType.WithoutCodes)
        } catch (error) {
            if (!silent) {
                notification.error({
                    message: t('notif-message.default-error'),
                })
            }
        }
    }

    return (
        <>
            <div>
                <FlexRow>
                    <div className="col">
                        <FormSelectionField
                            control={control}
                            name="group"
                            required
                            showSearch
                            disabled={readMode}
                            filterOption={false}
                            // label={`${t("search")} ${t("form-fields.invoice-codes")}`}
                            errors={errors.group}
                            placeholder={`${t('search')} ${t(
                                'form-fields.invoice-codes',
                            )}`}
                            options={list}
                            className="mb-0"
                            onSearch={onSearch}
                            onSelect={addInvoices}
                        />
                    </div>
                    <div className="col-2 mx-1 row">
                        <div className="col flex-column">
                            <FormSelectionField
                                control={control}
                                name="consulation"
                                required
                                showSearch
                                disabled={readMode}
                                filterOption={false}
                                errors={errors.group}
                                // options={InsuranceTypeList}
                                options={EncounterTypeList}
                                onSelect={(data) =>
                                    changeConsulationLocal(data)}
                                className="mb-0 "
                            />
                        </div>
                    </div>
                    <PrimaryButton
                        disabled={readMode}
                        className="mx-1 my-0 text-end d-flex align-items-center"
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            updateInvoiceDraft(
                                encounter,
                                !encounter.invoice_missing,
                            )
                        }}
                    >
                        {t('ignore-billing')}
                        <Checkbox
                            disabled={readMode}
                            className="ms-2"
                            checked={encounter.invoice_missing}
                            onChange={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                updateInvoiceDraft(encounter, e.target.checked)
                            }}
                        />
                    </PrimaryButton>
                    {/* <PrimaryButton className="col ml-2" onClick={addInvoices}>
                    {t("group")}
                </PrimaryButton> */}
                </FlexRow>
                <h3 className="my-0">
                    {t('insurance')}:{' '}
                    <Tag className="">
                        {encounter?.insurance?.insurance || ''}
                    </Tag>
                </h3>
                {!doctor?.bank?.address?.state && (
                    <Alert
                        message="Invoice pricing could not be calculated. No state found in bank address. Please visit Settings > Bank Details."
                        type="error"
                    />
                )}

                <div className="my-3">
                    <div className="text-end">
                        {!!encounterInvoicesList.length && (
                            <Popconfirm
                                title={t('delete')}
                                description={t('message.delete-message', {
                                    name: `${t('form-fields.category')}`,
                                })}
                                onConfirm={() =>
                                    removeAll(encounterInvoicesList)}
                                okButtonProps={{ disabled: false }}
                                cancelButtonProps={{ disabled: false }}
                            >
                                <DangerButton
                                    htmlType="button"
                                    className="mb-3"
                                >
                                    Delete All
                                </DangerButton>
                            </Popconfirm>
                        )}
                    </div>
                    <AppTable
                        data={[
                            ...new Set(
                                encounterInvoicesList.map(
                                    (listItem) =>
                                        listItem.invoicecode.service_code,
                                ),
                            ),
                        ].map((invoiceCode) => {
                            const codeInvoices = encounterInvoicesList.filter(
                                (listItem) =>
                                    listItem.invoicecode.service_code ===
                                    invoiceCode,
                            )

                            if (!doctor?.bank?.address?.state) {
                                notification.error(
                                    { message: 'No state selected in bank details. Please check settings page for bank details.' },
                                )
                            }
                            if (!doctor?.bank?.technicalServicePointTariff) {
                                notification.error(
                                    { message: 'No Technical Service Value selected in bank details. Please check settings page for bank details.' },
                                )
                            }

                            const MPV = doctor?.bank?.address?.state
                                ? Number(
                                      StateList.find(
                                          (state) =>
                                              state.value ===
                                              doctor.bank.address.state,
                                      ).tariff || 1,
                                  )
                                : 0

                            const TPV =
                                doctor?.bank?.technicalServicePointTariff || 0

                            const invoice = codeInvoices[0]

                            invoice.quantity = codeInvoices.length
                            invoice.cost = (
                                invoice.quantity *
                                (invoice.invoicecode.PM * MPV +
                                    invoice.invoicecode.PT * TPV)
                            ).toPrecision(4)

                            return invoice
                        })}
                        columns={columns}
                    />
                    {!encounterInvoicesList.length && (
                        <Alert
                            message="Invoices codes of appointments add when created a encounter"
                            type="info"
                            showIcon
                        />
                    )}
                </div>
                <Button>+</Button>
            </div>
        </>
    )
}
