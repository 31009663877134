import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class MedicalPatientHistoryType extends CRUDService {
    constructor() {
        super(APIPath.medicalPatientHistory)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.medicalPatientHistory}/order-by`, data)
    }

    getByPatient(id,params) {
        const query = CommonUtility.objectToParams(params || {})
        return BaseService.get(`${APIPath.medicalPatientHistory}/by-patient/${id}?${query}`)
    }
}

const MedicalPatientHistoryService = new MedicalPatientHistoryType()
Object.freeze(MedicalPatientHistoryService)
export { MedicalPatientHistoryService }
