import { Button,Form,Space,notification } from "antd";
import {
    FormSelectionField,FormTextFormField,PrimaryButton,
} from "elements";
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect,useState } from "react";
import { useAuth } from "context";
import {
    DateUtility,DoctorService,GenderOptions,
} from "utility";
import { HeaderTitle } from "components";
import { useTranslation } from "react-i18next";

const ProfileSchema = yup.object().shape({
    firstName: yup.string().required('*First name is required'),
    lastName: yup.string().required('*Last name is required'),
    phone: yup.string().required('*Phone is required'),
    alternatePhone: yup.string(),
    gender: yup.string().required('*Gender is required'),
    degree: yup.string().required('*Degree is required'),
    specialist: yup.string().required('*Specialist  is required'),
    workingdays: yup.string().required('*Workingdays is required'),
    content: yup.string().required('*content is required'),
    starttime: yup.string().required('*Start time is required'),
    endtime: yup.string().required('*End time is required'),
    experience: yup.string().required('*Experience is required'),
    glnno: yup.string().required('*GLN no. is required'),
    address: yup.object().shape({
        address: yup.string().required('*Address is required'),
        city: yup.string().required('*City is required'),
        postalCode: yup.string().required('*PostalCode is required'),
        country: yup.string().required('*Country is required'),
    }),
})

export function DEPTab() {
    const [processing,setProcessing] = useState(false)
    const { user } = useAuth()
    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ProfileSchema),
    })

    useEffect(() => {
        if (user._id) {
            reset({
                ...user,
                starttime: DateUtility.dayJSFormat(user.starttime),
                endtime: DateUtility.dayJSFormat(user.endtime),
            })
        }
    },[user])

    const submit = async (formData) => {
        try {
            setProcessing(true)
            delete formData._id
            delete formData.password
            delete formData.access_token
            await DoctorService.update(user._id,formData)
            notification.success({
                message: t("notif-message.doctor-updated"),
            })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        } finally {
            setProcessing(false)
        }
    }

    const cancel = () => {
        reset({
            ...user,
            starttime: DateUtility.dayJSFormat(user?.starttime),
            endtime: DateUtility.dayJSFormat(user?.endtime),
        })
    }

    return (
        <div className="container">
            <Form layout="vertical" noValidate>
                <div className="row">
                    <div className="col-12">
                        <HeaderTitle>{t("general-info")}</HeaderTitle>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                        <FormTextFormField
                            control={control}
                            name="firstName"
                            label={t("form-fields.firstName")}
                            placeholder="First Name"
                            errors={errors?.firstName || ''}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <FormTextFormField
                            control={control}
                            name="lastName"
                            label={t("form-fields.lastName")}
                            placeholder="Last Name"
                            errors={errors?.lastName || ''}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormSelectionField
                            control={control}
                            name="gender"
                            label={t("form-fields.gender")}
                            placeholder="Gender"
                            errors={errors?.gender}
                            options={GenderOptions}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormTextFormField
                            control={control}
                            name="phone"
                            label={t("form-fields.phone")}
                            placeholder="Phone"
                            errors={errors?.phone}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormTextFormField
                            control={control}
                            name="alternatePhone"
                            label={t("form-fields.alternatePhone")}
                            placeholder="Alternate Phone"
                            errors={errors?.alternatePhone}
                        />
                    </div>
                </div>
                <Space className="d-flex justify-content-end">
                    <Button htmlType="button" onClick={cancel}>
                        {t("cancel")}
                    </Button>
                    <PrimaryButton
                        loading={processing}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit(submit)}
                    >
                    {t("submit")}
                    </PrimaryButton>
                </Space>
            </Form>
        </div>
    )
}
