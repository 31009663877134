import { useEffect, useState, useMemo } from 'react'
import { GetEncounterColoCodeList } from './color-code'
import {
    EncounterType,
    TimeLineColor,
    CommonConstant,
    AboutUsService,
    MetaDataService,
    VitalSignsTypesService,
    CustomFieldsType,
    Roles,
    Seconds,
} from 'utility'
import { useAuth } from 'context'

export const MetadataModule = {
    ABOUTUS: 'about-us',
}

export const GetEncounterOptions = (t) => {
    const data = useMemo(() => {
        return [
            {
                label: t('settings.consultation'),
                value: EncounterType.CONSULTATION,
                key: EncounterType.CONSULTATION,
            },
            {
                label: t('settings.medication'),
                value: EncounterType.MEDICATION,
                key: EncounterType.MEDICATION,
            },
            {
                label: t('settings.laboratory'),
                value: EncounterType.LABORATORY,
                key: EncounterType.LABORATORY,
            },
            {
                label: t('settings.radiology'),
                value: EncounterType.RADIOLOGY,
                key: EncounterType.RADIOLOGY,
            },
            {
                label: 'Document',
                value: EncounterType.DOCUMENT,
                key: EncounterType.DOCUMENT,
            },
            {
                label: t('settings.stain'),
                value: EncounterType.STAIN,
                key: EncounterType.STAIN,
            },
            {
                label: 'E-mail',
                value: EncounterType.EMAIL,
                key: EncounterType.EMAIL,
            },
            {
                label: 'Custom',
                value: EncounterType.CUSTOM,
                key: EncounterType.CUSTOM,
            },
        ]
    }, [t])
    return {
        data,
    }
}

export const GetAppointmentDurationList = (t) => {
    const data = useMemo(() => {
        return [
            { label: `1 ${t('all')}`, value: -1 },
            { label: `1 ${t('calendar-properties.week')}`, value: Seconds.ONE_WEEK },
            { label: `2 ${t('calendar-properties.week')}`, value: Seconds.TWO_WEEK },
            { label: `1 ${t('calendar-properties.month')}`, value: Seconds.ONE_MONTH },
            { label: `2 ${t('calendar-properties.month')}`, value: Seconds.TWO_MONTH },
            { label: `3 ${t('calendar-properties.month')}`, value: Seconds.THREE_MONTH },
            { label: `6 ${t('calendar-properties.month')}`, value: Seconds.SIX_MONTH },
            { label: `1 ${t('calendar-properties.year')}`, value: Seconds.ONE_YEAR },
            { label: `2 ${t('calendar-properties.year')}`, value: Seconds.TWO_YEAR },
          ]
    }, [t])
    return {
        data,
    }
}

export const GetEncounterSortOptionsList = (t) => {
    const data = useMemo(() => {
        return [
            {
                label: t('sort.old-to-new'),
                value: 'date-asc',
            },
            {
                label: t('sort.new-to-old'),
                value: 'date-desc',
            },
        ]
    }, [t])
    return {
        data,
    }
}

export const GetExpiryList = (t) => {
    const data = useMemo(() => [
        { label: t('expiry.1-month'), value: 2592000 },
        { label: t('expiry.3-months'), value: 7776000 },
        { label: t('expiry.6-months'), value: 15552000 },
        { label: t('expiry.1-year'), value: 31536000 },
    ], [t]);
    return {
        data,
    }
};

export const GetInsuranceTypeList = (t) => {
    const data = useMemo(() => [
        { label: t('insurance-type.KVG-Disease'), value: 'KVG' },
        { label: t('insurance-type.UVG-Accident'), value: 'UVG' },
        { label: t('insurance-type.IVG-Invalidity'), value: 'IVG' },
    ], [t]);
    return {
        data,
    }
};

export const GetEncounterTypeList = (t) => {
    const data = useMemo(() => [
        { label: t('illness'), value: 'KVG' },
        { label: t('accident'), value: 'UVG' },
        { label: t('invalidity'), value: 'IVG' },
    ], [t]);
    return {
        data,
    }
};

export const GetFieldsType = (t) => {
    const data = useMemo(() => {
        return [
            {
                label: t('Text Area'),
                value: CustomFieldsType.TEXTAREA,
                key: CustomFieldsType.TEXTAREA,
            },
        ]
    }, [t])
    return {
        data,
    }
}

export const GetEncounterColors = () => {
    const { data: list } = GetEncounterColoCodeList()
    const [data, setData] = useState({})
    useEffect(() => {
        let colors = { ...TimeLineColor }
        if (list.length) {
            list.map((ele) => {
                colors = { ...colors, [ele.encounter_type]: ele.color }
                return ''
            })
        }
        setData(colors)
    }, [list])
    return {
        data,
    }
}

export const GetFrame = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await MetaDataService.getFrame()
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return {
        data,
        error,
        loading,
        setData,
    }
}

export const GetCustomeEncounterFieldList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>{item?.custom_encounter_title}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await MetaDataService.getCustomeEncounterFields()
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return {
        data,
        optionList,
        error,
        loading,
        setData,
    }
}

export const GetAboutUs = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await AboutUsService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    const fetchAll = () => {
        setFilter({ ...filter, limit: 100000000000 })
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        total,
        fetchAll,
    }
}

export const GetDefaultTimer = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await MetaDataService.getDefaultTimer()
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
    }
}

export const GetVitalSignTypeList = (intialFilter) => {
    const { user } = useAuth()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const optionList = useMemo(() => {
        return data.filter(ele => !ele.hideusers.includes(user._id)).map((item) => {
            return {
                value: item?._id,
                label: (
                    <div>
                        <span>{item.title}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                doctor: user.role === Roles.doctor ? user._id : "",
                ...intialFilter,
                ...filter,
                ...filter.search,
                search: filter.search.search ?? null,
            }
            const result = await VitalSignsTypesService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    const fetchAll = () => {
        setFilter({ ...filter, limit: 100000000000 })
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        total,
        fetchAll,
        optionList,
    }
}
export const CCBCCOptions = [
    {
        label: 'CC',
        value: 'cc',
        key: 'cc',
    },
    {
        label: 'BCC',
        value: 'bcc',
        key: 'bcc',
    },
]
