import dayjs from 'dayjs'
import { AcceptFileType } from './constant'

const queryString = require('query-string')

export class CommonUtility {

    static currencyFormat(value, currency) {
        if (Number.isNaN(value || 0)) {
            return value
        }

        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency || 'USD',
        }).format(value || 0)
    }

    static isNotEmpty(item) {
        return (
            item !== undefined &&
            item !== null &&
            item !== '' &&
            item.length !== 0
        )
    }

    static truncateString(text, ellipsisString) {
        return (text || '').length > ellipsisString
            ? `${text.substring(0, ellipsisString)}...`
            : text
    }

    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }

    static objectToParams(obj) {
        const str = queryString.stringify(obj)
        return str
    }

    static toTitleCase(phrase) {
        return phrase
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
    }

    static timeoutPromise(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms))
    }

    static roundNumber(num, decimals = 6) {
        const t = 10 ** decimals
        let result = Math.round((num + Number.EPSILON) * t) / t
        if (num < 0) {
            result *= -1
        }
        return result
    }

    static generateRandomColor = () => {
        const maxVal = 0xFFFFFF; // 16777215
        let randomNumber = Math.random() * maxVal;
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        const randColor = randomNumber.padStart(6, 0);
        return `#${randColor.toUpperCase()}`
    }

    static isFileImageVideo = (file) => {
        return (
            file.type.indexOf('image') > -1 || file.type.indexOf('video') > -1
        )
    }

    static isFileImage = (file) => {
        return file.type.indexOf('image') > -1
    }

    static isFileVideo = (file) => {
        return file?.type?.indexOf('video') > -1
    }

    static isURLImageVideo = (url) => {
        const types = AcceptFileType.imageVideo['image/*']
        return types.some((x) => url.includes(x))
    }

    static isFile = (file) => {
        return file?.type?.indexOf('application/pdf') > -1
    }

    static isURLFile = (url) => {
        const types = AcceptFileType.application['application/*']
        return types.some((x) => url?.includes(x))
    }

    static isURLVideo = (url) => {
        const types = AcceptFileType.video['video/*']
        return types.some((x) => url?.includes(x))
    }

    static isURLImage = (url) => {
        const types = AcceptFileType.image['image/*']
        return types.some((x) => url?.includes(x))
    }

    static eventHeightGetter = (duration) => {
        return (duration / 1800)
    }

    static downloadTxt = (data, name = "file") => {
        const blob = new Blob([data], { type: "text/plain" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.txt`;
        a.click();
        URL.revokeObjectURL(url);
    }

    static checkoverlap = (events, startTime, endTime) => {
        const addedStart = startTime.getTime()
        const addedEnd = endTime.getTime()

        return events.some((x) => {
            const eventStart = new Date(x.start_time || x.start).getTime()
            const eventEnd = new Date(x.end_time || x.end).getTime()

            if (addedStart > eventStart && addedStart < eventEnd) {
                console.log('start-time in between any of the events')
                return true
            }

            if (addedEnd > eventStart && addedEnd < eventEnd) {
                console.log('end-time in between any of the events')
                return true
            }

            if (addedStart <= eventStart && addedEnd >= eventEnd) {
                console.log(
                    'any of the events in between/on the start-time and end-time',
                )
                return true
            }
            return false
        })
    }

    static getInitials(firstName, lastName,format = "") {
        if (typeof firstName === 'string' && typeof lastName === 'string' &&
            firstName.trim() !== '' && lastName.trim() !== '') {
          const firstInitial = firstName.charAt(0).toUpperCase();
          const lastInitial = lastName.charAt(0).toUpperCase();
          return firstInitial + format + lastInitial;
        }
        return ""
      }

    static groupedTwoList = (list1, list2, field) => {
        const groupedLists = list1?.reduce((acc, item) => {
            const id = item.status[field];
            if (!acc[id]) {
                acc[id] = { items: [], id: null, data: {} };
            }
            acc[id].items.push(item);
            return acc;
        }, {});

        list2?.forEach((item) => {
            const id = item[field];
            if (groupedLists[id]) {
                groupedLists[id].data = item;
                groupedLists[id].id = item._id;
            } else {
                groupedLists[id] = { items: [], id: item._id, data: item };
            }
        });

        return groupedLists;
    };

    static generateTimeSlots = (start, end, interval) => {
        const timeSlots = [];
        const startTime = dayjs(new Date()).set('hour', dayjs(start).hour()).set('minute', dayjs(start).minute());
        const endTime = dayjs(new Date()).set('hour', dayjs(end).hour()).set('minute', dayjs(end).minute());
        let currentTime = startTime;
        while (currentTime.isSame(startTime) || currentTime.isBefore(endTime)) {
            timeSlots.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }
        return timeSlots;
    };
}
