import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class InvoiceCodes extends CRUDService {
    constructor() {
        super(APIPath.invoiceCodes)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.invoiceCodes}/bulk`,data)
    }
}

const InvoiceCodesService = new InvoiceCodes()
Object.freeze(InvoiceCodesService)
export { InvoiceCodesService }
