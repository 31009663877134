import { BaseService } from './base'
import { APIPath } from '../constant'
import { CRUDService } from './crud'

class Appointment extends CRUDService {
    constructor() {
        super(APIPath.appointment)
    }

    getbyPatient(id) {
        return BaseService.get(`${APIPath.appointment}/patient/${id}`)
    }

    getNextAppointments() {
        return BaseService.get(`${APIPath.appointment}/next-appointments`)
    }

    addWithRecurrance(data) {
        return BaseService.post(`${APIPath.appointment}/recurrance`, data)
    }

    removeRecurrance(id) {
        return BaseService.remove(`${APIPath.appointment}/recurrance/${id}`)
    }
}

const AppointmentService = new Appointment()
Object.freeze(AppointmentService)
export { AppointmentService }
