import { APIPath } from '../constant'
import { CRUDService } from './crud'

class Doctor extends CRUDService {
    constructor() {
        super(APIPath.doctors)
    }
}

const DoctorService = new Doctor()
Object.freeze(DoctorService)
export { DoctorService }
