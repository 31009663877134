import { X } from "@phosphor-icons/react";
import styled,{ css } from "styled-components";

export const FlexBox = styled.div`
  display:flex;
`

export const FlexRow = styled(FlexBox)`
  flex-direction:row;
`
export const FlexColumn = styled(FlexBox)`
    flex-direction: column;
`
export const FlexRowBetween = styled(FlexRow)`
  justify-content:space-between;
`
export const Line = styled.div`
    width: 100%;
    height: 2px;
    background: #e7eaf9;
    border: solid 0.5px #e7eaf9;
`
export const FlexRowWrap = styled(FlexRow)`
    flex-wrap:wrap;
`
export const ColorBox = styled.div`
    height: 20px;
    width: 25px;
    border-radius:5px;
    background-color: ${({ color }) => color};
`

export const DangerText = styled.div`
    color: red;
    font-size: 12px;
    font-weight: 700;
`

export const ImageContainer = styled.img`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`

export const CustomScrollY = css`
  ::-webkit-scrollbar {
    width: 0.2rem !important;
  }
`

export const XIcon = styled(X)`
  cursor: pointer;
`