import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import {
    AppTable,
    AutoCompleteDropDown,
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    HeaderTitle,
    PageHeader,
} from 'components'
import {
    DangerButton,
    PrimaryButton,
    FormTextFormField,
    FormColorPicker,
} from 'elements'
import { Pencil, Trash } from '@phosphor-icons/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DesktopMode, MobileMode, GetCalendar } from 'hooks'
import * as yup from 'yup'
import { useState, useEffect } from 'react'
import { Plus } from 'phosphor-react'
import { useAuth } from 'context'
import { CalendarService } from 'utility'
import styled from 'styled-components'

const CalendarSchema = yup.object().shape({
    name: yup.string().required('* Calendar name is required'),
    color: yup.string().required('Color is required'),
})

const ColorBox = styled.div`
    height: 20px;
    width: 25px;
    border-radius: 5px;
    background-color: ${({ color }) => color};
`

const AddCalendarModal = ({
    open,
    data,
    editMode,
    onClose,
    loading,
    onSubmit,
}) => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(CalendarSchema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={
                editMode
                    ? t('form-fields.editcalendar')
                    : t('form-fields.addcalendar')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="mb-0 col-10">
                        <FormTextFormField
                            control={control}
                            name="name"
                            label={`${t('form-fields.name')} ${t('name')}`}
                            placeholder="Calendar Name"
                            errors={errors?.name}
                            defaultValue=""
                        />
                    </Form.Item>
                    <Form.Item className="mb-0 col-2">
                        <FormColorPicker
                            control={control}
                            name="color"
                            label={`${t('form-fields.color')}`}
                            placeholder="color"
                            errors={errors?.color}
                        />
                    </Form.Item>
                    {errors.color && (
                        <div className="ant-form-item-explain">
                            {errors.color.message}
                        </div>
                    )}
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('form-fields.editcalendar')
                            : t('form-fields.addcalendar')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}

export const CalendarTab = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const [calendarFormDataList, setCalendarFormDataList] = useState([])
    const { patient } = useAuth()
    const {
        data,
        filterChanged,
        setData,
        refetch,
        total = { page: 1 },
        filter,
        pageChanged,
    } = GetCalendar()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                formData.patient = patient._id
                await CalendarService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await CalendarService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
            setCalendarFormDataList([...calendarFormDataList, formData])
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }
    // const removeFormData = (index) => {
    //     const updatedFormDataList = [...calendarFormDataList]
    //     updatedFormDataList.splice(index, 1)
    //     setCalendarFormDataList(updatedFormDataList)
    // }

    // const addFormData = () => {
    //     setCalendarFormDataList([
    //         ...calendarFormDataList,
    //         { name: '', color: '' },
    //     ])
    // }

    const removeCategory = async (id) => {
        try {
            await CalendarService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const columns = [
        {
            title: t('form-fields.name'),
            dataIndex: 'name',
            key: 'name',
            width: 700,
        },
        {
            title: t('form-fields.color'),
            dataIndex: 'color',
            key: 'color',
            width: 200,
            render: (_, data) => (
                <Space size="middle">
                    <ColorBox color={data?.color} />
                </Space>
            ),
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton
                        icon={<Pencil onClick={() => onOpen(data)} />}
                    />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await CalendarService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('calendar')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'calendar-category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) => {
                                    filterChanged('search', { title: data })
                                }}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.Calendar-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.calendar-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-1"
                            onSearch={(data) => {
                                filterChanged('search', { name: data })
                            }}
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <AddCalendarModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="mt-sm-2 mt-md-0 mt-3">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
