import { ColorBox } from 'components'
import { useState, useEffect, useMemo } from 'react'
import {
    AppointmentCategoryService,
    AppointmentService,
    CommonConstant,
    CommonUtility,
} from 'utility'

export const GetAppointmentList = (intialFilter) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...intialFilter,
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await AppointmentService.get(params)
            setData(result)
            setTotal({
                ...total,
                count: result?.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
            fetchData()
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const sortChanged = (obj) => {
        const temp = {
            ...filter,
            ...obj,
            skip: 0,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        filter,
        total,
        setData,
        filterChanged,
        pageChanged,
        sortChanged,
        refetch,
    }
}

export const GetAppointmentDeatils = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await AppointmentService.getById(id)
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
        setData,
    }
}

export const GetAppointmentListByPatient = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await AppointmentService.getbyPatient(id)
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return {
        data,
        error,
        loading,
        setData,
    }
}

export const GetAppointmentCategory = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <ColorBox color={item.color} className="mr-2" />
                        <span>{item.name}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await AppointmentCategoryService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    const fetchAll = () => {
        setFilter({ ...filter, limit: 100000000000 })
    }

    return {
        data,
        error,
        loading,
        setData,
        optionList,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        total,
        fetchAll,
    }
}

export const GetAppointmentCategoryCodesList = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const list = useMemo(() => {
        return [{
            label: "Groups",
            options: data?.invoicegroups?.map((item) => {
                return {
                    value: item?._id,
                label: (
                    <div className="d-flex row justify-content align-items-center">
                        <div className="col-2">{item.name}</div>
                        <div className="col-10">{item.invoices.map(item => (<span>{item.service_code}&nbsp;&nbsp;&nbsp;</span>))}</div>
                    </div>
                ),
                ...item,
                }
            }) || [],
        }, {
            label: "Codes",
            options: data?.invoicecodes?.map((item) => {
                return {
                    value: item?._id,
                    label: (
                        <div className="d-flex row justify-content align-items-center">
                            <span className="col-2">{item.service_code}</span>
                            <span className="col-10">{CommonUtility.truncateString(item.service_appellation, 1000)}</span>
                        </div>
                    ),
                    ...item,
                }
            }) || [],
        }]
    }, [data])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await AppointmentCategoryService.codes(id)
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading && id) {
            fetchData()
        }
    }, [id])

    return {
        list,
        data,
        error,
        loading,
        setData,
    }
}

export const GetNextAppointmentsList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await AppointmentService.getNextAppointments()
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
    }
}