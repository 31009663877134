import { useMemo } from 'react'
import {
    LogsType,
} from 'utility'

export const GetLogsTypeOptions = (t) => {
    const data = useMemo(() => {
        return [
            {
                label: t('All'),
                value: "",
                key: "",
            },
            {
                label: t('logs-type.create-appointment'),
                value: LogsType.CREATE_APPOINTMENT,
                key: LogsType.CREATE_APPOINTMENT,
            },
            {
                label: t('logs-type.update-appointment'),
                value: LogsType.MODIFY_APPOINTMENT,
                key: LogsType.MODIFY_APPOINTMENT,
            },{
                label: t('logs-type.delete-appointment'),
                value: LogsType.DELETE_APPOINTMENT,
                key: LogsType.DELETE_APPOINTMENT,
            },{
                label: t('logs-type.create-encounter'),
                value: LogsType.CREATE_ENCOUNTER,
                key: LogsType.CREATE_ENCOUNTER,
            },{
                label: t('logs-type.update-encounter'),
                value: LogsType.MODIFY_ENCOUNTER,
                key: LogsType.MODIFY_ENCOUNTER,
            },{
                label: t('logs-type.delete-encounter'),
                value: LogsType.DELETE_ENCOUNTER,
                key: LogsType.DELETE_ENCOUNTER,
            },{
                label: t('logs-type.create-patient'),
                value: LogsType.CREATE_PATIENT,
                key: LogsType.CREATE_PATIENT,
            },{
                label: t('logs-type.update-patient'),
                value: LogsType.MODIFY_PATIENT,
                key: LogsType.MODIFY_PATIENT,
            },{
                label: t('logs-type.delete-patient'),
                value: LogsType.DELETE_PATIENT,
                key: LogsType.DELETE_PATIENT,
            },{
                label: t('logs-type.create-document'),
                value: LogsType.CREATE_DOCUMENT,
                key: LogsType.CREATE_DOCUMENT,
            },{
                label: t('logs-type.update-document'),
                value: LogsType.MODIFY_DOCUMENT,
                key: LogsType.MODIFY_DOCUMENT,
            },{
                label: t('logs-type.delete-document'),
                value: LogsType.DELETE_DOCUMENT,
                key: LogsType.DELETE_DOCUMENT,
            }]
    }, [t])
    return {
        data,
    }
}
