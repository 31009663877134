import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Button, Collapse, Form, Modal, Pagination, Popconfirm, Space, notification } from 'antd'
import { AutoCompleteDropDown, HeaderTitle, PageHeader } from 'components'
import { DangerButton, FormTextAreaFormField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetSnippetsList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SnippetsService, CommonConstant } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const DocumentCategoryShema = yup.object().shape({
    key: yup.string().trim().required('*key is required'),
    value: yup.string().trim().required("*value is required"),
})

const AddSnippetsModal = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const { t } = useTranslation()
    const {
        handleSubmit, control, reset, watch, setValue, setError,
        formState: { errors, isSubmitted },
    } = useForm({
        resolver: yupResolver(DocumentCategoryShema),
    })

    const key = watch("key")

    useEffect(() => {
        reset(editMode ? data : {})
    }, [open, editMode])

    useEffect(() => {
        if (key === "") {
            setValue("key", ";")
        }
    }, [key])

    useEffect(() => {
        if (key === ";" && isSubmitted) {
            setError('key', { type: 'required', message: '*key is required.' });
        }
    }, [isSubmitted])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-quick-entry")}` : `${t("add-edit.add-quick-entry")}`}
            open={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical">
                <FormTextFormField
                    control={control}
                    name="key"
                    label={t("form-fields.key")}
                    placeholder="Key"
                    errors={errors?.key}
                    defaultValue=";"
                />
                <FormTextAreaFormField
                    control={control}
                    name="value"
                    fieldName="snippets"
                    label={t("form-fields.snippets")}
                    placeholder="write snippet description here"
                    errors={errors?.value}
                    rows={15}

                />
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? t("add-edit.edit-quick-entry") : t("add-edit.create-quick-entry")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

const SnippetsList = ({ list, onEdit, onRemove, loading }) => {
    const { t } = useTranslation()
    const editRemoveButton = (item) => (
        <Space size="middle">
            <PrimaryButton icon={<Pencil />} onClick={(e) => { e.stopPropagation(); onEdit(item) }} />
            <Popconfirm
                title={t("delete")}
                description={t("message.delete-message",{ name: `${t("form-fields.category")}` })}
                onConfirm={(e) => {
                    e.stopPropagation()
                    onRemove(item._id)
                }}
                onCancel={(e) => e.stopPropagation()}
                okButtonProps={{ disabled: false }}
                cancelButtonProps={{ disabled: false }}
            >
                <DangerButton icon={<Trash />} htmlType="button" onClick={(e) => e.stopPropagation()} />
            </Popconfirm>
        </Space>
    );

    return (
        <>
            {list.length ?
            <Collapse>
                {list?.map((item, i) => {
                    return (
                        <Collapse.Panel header={item.key} key={`${i} + ${item.key}`} extra={editRemoveButton(item)}>
                            {item.value}
                        </Collapse.Panel>
                    )
                })}
            </Collapse> :
            <div className="my-3">
                { !loading && <Alert message="No snippets found" type="info" showIcon />}
            </div>}
        </>

    )
}

export const SnippetsTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()

    const { data, setData, refetch, total, pageChanged, filterChanged, loading } = GetSnippetsList()

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await SnippetsService.add(formData)
                notification.success({ message: t("notif-message.quick-entry-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await SnippetsService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.quick-entry-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeDocumentCategory = async (id) => {
        try {
            await SnippetsService.remove(id)
            notification.success({ message: t("notif-message.quick-entry-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    // const columns = [
    //     {
    //         title: 'key',
    //         dataIndex: 'key',
    //         key: 'key',
    //     },
    //     {
    //         title: 'Snippets',
    //         dataIndex: 'value',
    //         key: 'value',
    //         render: (_, data) => (
    //             <Space size="middle">
    //                 {data.value}
    //             </Space>
    //         ),
    //     },
    //     {
    //         title: 'Actions',
    //         key: 'action',
    //         render: (_, data) => (
    //             <Space size="middle">
    //                 <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
    //                 <Popconfirm
    //                     title={t("Delete")}
    //                     description="Are you sure, you want to delete this category?"
    //                     onConfirm={() => removeDocumentCategory(data._id)}
    //                     okButtonProps={{ disabled: false }}
    //                     cancelButtonProps={{ disabled: false }}
    //                 >

    //                     <DangerButton icon={<Trash />} htmlType="button" />
    //                 </Popconfirm>
    //             </Space>
    //         ),
    //     },
    // ];

    return (
        <>
        <DesktopMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-6">
                    {t("quick-entry-list")}
                </HeaderTitle>}
                right={<>
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("settings.quick-entry")}`}
                        options={[]}
                        className="mb-0 ms-5"
                        onSearch={(data) => filterChanged("search", { key: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                </>}
            />
            <AddSnippetsModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <SnippetsList
                list={data}
                onEdit={onOpen}
                onRemove={removeDocumentCategory}
                loading={loading}
            />
            <div className="col-12 mt-3 text-end">
                <Pagination
                    current={total.page}
                    defaultPageSize={CommonConstant.defaultPageSize}
                    total={total.count}
                    onChange={pageChanged}
                    size="small"
                />
            </div>
            {/* <div className="">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                />
            </div> */}
        </div>
        </DesktopMode>
        <MobileMode>
        <div>
            <HeaderTitle className="">
                    {t("quick-entry-list")}
                </HeaderTitle>
                </div>
                <div className="col-12 d-flex py-2">
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("settings.quick-entry")}`}
                        options={[]}
                        className="mb-0 ms-1"
                        onSearch={(data) => filterChanged("search", { key: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                    </div>
            <AddSnippetsModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <SnippetsList
                list={data}
                onEdit={onOpen}
                onRemove={removeDocumentCategory}
                loading={loading}
            />
            <div className="col-12 mt-3 text-end">
                <Pagination
                    current={total.page}
                    defaultPageSize={CommonConstant.defaultPageSize}
                    total={total.count}
                    onChange={pageChanged}
                    size="small"
                />
        </div>
        </MobileMode>
        </>
    )
}
