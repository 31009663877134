import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import { AutoCompleteDropDown, DraggableAppTable, FlexColumn, FlexRow, FlexRowBetween, HeaderTitle, PageHeader } from 'components'
import { DangerButton, PrimaryButton, FormSelectionField, FormTextFormField } from 'elements'
import { Pencil, Trash } from '@phosphor-icons/react'
import { useForm } from 'react-hook-form'
import { AboutUsIconList, AboutUsIcons, AboutUsService } from 'utility'
import { useTranslation } from 'react-i18next'
import { GetAboutUs,DesktopMode, MobileMode } from 'hooks'
import * as yup from 'yup'
import { useState,useEffect } from 'react'
import { Plus } from 'phosphor-react'

const AboutUsSchema = yup.object().shape({
    icon: yup.string().required("icon is required").trim(),
    title: yup.string().trim(),
    content: yup.string().required("content is required").trim(),
})

const AddAboutUsModal = ({ open, data, editMode, onClose, loading,onSubmit }) => {
    const { reset,control,handleSubmit,formState: { errors } } = useForm({
        resolver: yupResolver(AboutUsSchema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={editMode ? t('form-fields.editneedus') : t('form-fields.addneedus')}
            visible={open}
            footer={null}
            onCancel={onClose}>
             <Form layout="vertical" noValidate>
             <div className="row">
                    <Form.Item className="col-3 mb-0">
                        <FormSelectionField
                            control={control}
                            name="icon"
                            required
                            errors={errors.icon}
                            label={t('form-fields.icon')}
                            options={AboutUsIconList}
                            filterOption={false}
                            showSearch
                        />
                    </Form.Item>
                    <Form.Item className="col-4 mb-0">
                        <FormTextFormField
                            control={control}
                            name="title"
                            label={t('form-fields.title')}
                            placeholder="title"
                            errors={errors?.title || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-4 mb-0">
                        <FormTextFormField
                            control={control}
                            name="content"
                            label={t('form-fields.contents')}
                            placeholder={t('contents')}
                            errors={errors?.content || ''}
                        />
                    </Form.Item>
                    </div>
                    <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('form-fields.editneedus')
                            : t('form-fields.addneedus')}
                    </PrimaryButton>
                </Space>
            </Form>
            </Modal>
    );
};
export const NeedUsTab = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const {
        data,
        setData,
        filterChanged,
        refetch,
        total = { page: 1 },
        filter,
        pageChanged,
    } = GetAboutUs()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await AboutUsService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await AboutUsService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const removeCategory = async (id) => {
        try {
            await AboutUsService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }
    const columns = [
        {
            title: t('form-fields.icon'),
            dataIndex: 'icon',
            key: 'icon',
            width: 390,
            render: (_, data) => (<>{AboutUsIcons?.[data?.icon] || ""}</>),
        },
        // {
        //     title: t('Title'),
        //     dataIndex: 'title',
        //     key: 'title',
        //     width: 300,
        // },
        {
            title: t('form-fields.contents'),
            dataIndex: 'content',
            key: 'content',
            width: 390,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton
                        icon={<Pencil onClick={() => onOpen(data)} />}
                    />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await AboutUsService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('need-us')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'about-us-category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) => filterChanged('search', { title: data })}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.About-us-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.about-us-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-1"
                            onSearch={(data) => filterChanged('search', { name: data })}
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <AddAboutUsModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="mt-sm-2 mt-md-0 mt-3">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
