import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class InvoicesGroup extends CRUDService {
    constructor() {
        super(APIPath.invoicesGroup)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.invoicesGroup}/bulk`,data)
    }

    toggle(groupid,invoiceid,status) {
        return BaseService.put(`${APIPath.invoicesGroup}/${groupid}/${invoiceid}/${status}`)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.invoicesGroup}/order-by`,data)
    }
}

const InvoicesGroupService = new InvoicesGroup()
Object.freeze(InvoicesGroupService)
export { InvoicesGroupService }
