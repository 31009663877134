import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class AppointmentStatus extends CRUDService {
    constructor() {
        super(APIPath.appointmentStatus)
    }

    setPrimary(id) {
        return BaseService.put(`${APIPath.appointmentStatus}/primary/${id}`)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.appointmentStatus}/order-by`,data)
    }

}

const AppointmentStatusService = new AppointmentStatus()
Object.freeze(AppointmentStatusService)
export { AppointmentStatusService }
