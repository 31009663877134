import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Space, notification } from 'antd'
import { DraggableAppTable, AutoCompleteDropDown, HeaderTitle, PageHeader, FlexRowBetween } from 'components'
import { DangerButton, FormDateField, FormNumberEventField, FormRangeSliderField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetDrugsList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DateUtility, DayRangeOptions, DrugService } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const DrugsSchema = yup.object().shape({
    name: yup.string().required('*name is required'),
    price: yup.number().nullable(),
})

const AddEditDrugs = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(DrugsSchema),
        defaultValues: {
            defaultValues: {
                night: 0,
                noon: 0,
                morning: 0,
                evening: 0,
            },
        },
    })
    const { t } = useTranslation()
    useEffect(() => {
        let resetData = { ...data }
        if (data) {
            resetData = {
                ...resetData,
            }
            if (data.startdate) {
                resetData.startdate = DateUtility.dayJSFormat(data.startdate)
            }
            if (data.enddate) {
                resetData.enddate = DateUtility.dayJSFormat(data.enddate)
            }
        }
        reset(editMode ? resetData : {})
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-drug")}` : `${t("add-edit.add-drug")}`}
            open={open}
            footer={null}
            width={600}
            onCancel={onClose}>
            <Form layout="vertical">
                <div className="row">
                    <FormTextFormField
                        control={control}
                        name="name"
                        label={`${t("form-fields.name")}`}
                        placeholder="Drug Name"
                        errors={errors?.name}
                        defaultValue=""
                    />
                    <div className="row">
                        <h4>{t("default-dosage")}</h4>
                        <div className="col-3">
                            <FormTextFormField
                                control={control}
                                name="dosage"
                                label={`${t("form-fields.dosage")}`}
                                placeholder="dosage"
                                type="number"
                                errors={errors?.dosage}
                                defaultValue={0}
                            />
                        </div>
                        <div className="col-3">
                            <FormTextFormField
                                control={control}
                                name="unit"
                                label={`${t("form-fields.unit")}`}
                                placeholder="unit"
                                errors={errors?.unit}
                            />
                        </div>
                        <div className="col-6">
                            <FormRangeSliderField
                                control={control}
                                name="duration"
                                label={`${t("form-fields.duration")}`}
                                placeholder={`${t("form-fields.duration")}`}
                                errors={errors?.duration}
                                marks={DayRangeOptions}
                                step
                                max={60}
                            /></div>
                        <div className="col-3">
                            <FormNumberEventField
                                control={control}
                                name="morning"
                                label={`${t("form-fields.morning")}`}
                                placeholder="morning"
                                errors={errors?.morning}
                                defaultValue={0}
                            /></div>
                        <div className="col-3">
                            <FormNumberEventField
                                control={control}
                                name="noon"
                                label={`${t("form-fields.noon")}`}
                                placeholder="noon"
                                errors={errors?.noon}
                                defaultValue={0}
                            /></div>                    <div className="col-3">
                            <FormNumberEventField
                                control={control}
                                name="evening"
                                label={`${t("form-fields.evening")}`}
                                placeholder="evening"
                                errors={errors?.evening}
                                defaultValue={0}

                            /></div><div className="col-3">
                            <FormNumberEventField
                                control={control}
                                name="night"
                                label={`${t("form-fields.night")}`}
                                placeholder="night"
                                errors={errors?.night}
                                defaultValue={0}
                            /></div>

                    </div>
                    <h4>{t("validity")}</h4>
                    <div className="col-6">
                        <FormDateField
                            control={control}
                            name="startdate"
                            label={`${t("form-fields.startdate")}`}
                            placeholder={`${t("form-fields.startdate")}`}
                            errors={errors?.startdate}
                            defaultValue={DateUtility.dayJSFormat(new Date())}
                        /></div> <div className="col-6">
                        <FormDateField
                            control={control}
                            name="enddate"
                            label={`${t("form-fields.enddate")}`}
                            placeholder={`${t("form-fields.enddate")}`}
                            errors={errors?.enddate}
                        /></div>
                    <h4>{t("others")}</h4>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="method"
                            label={`${t("form-fields.method")}`}
                            placeholder={`${t("form-fields.method")}`}
                            errors={errors?.method}
                            defaultValue=""
                        /></div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="active_substance"
                            label={`${t("form-fields.active-substance")}`}
                            placeholder={`${t("form-fields.active-substance")}`}
                            errors={errors?.active_substance}
                            defaultValue=""
                        /></div> <div className="col-6"><FormTextFormField
                            control={control}
                            name="interactions"
                            label={`${t("form-fields.interactions")}`}
                            placeholder={`${t("form-fields.interactions")}`}
                            errors={errors?.interactions}
                            defaultValue=""
                        /></div> <div className="col-6"><FormTextFormField
                            control={control}
                            name="price"
                            label={`${t("form-fields.price")}`}
                            placeholder={`${t("form-fields.price")}`}
                            type="number"
                            errors={errors?.price}
                            defaultValue={0}
                        /></div><div className="col-6"><FormTextFormField
                            control={control}
                            name="nbeo"
                            label={`${t("form-fields.nbeo")}`}
                            placeholder={`${t("form-fields.nbeo")}`}
                            errors={errors?.nbeo}
                            defaultValue=""
                        /></div><div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="comments"
                            label={`${t("form-fields.comments")}`}
                            placeholder={`${t("form-fields.comments")}`}
                            errors={errors?.comments}
                            defaultValue=""
                        /></div>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? `${t("edit")}` : `${t("create")}`} {t("form-fields.drug")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

export const DrugsTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { data, setData, refetch, filterChanged, total, filter, pageChanged } = GetDrugsList()

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await DrugService.add(formData)
                notification.success({ message: t("notif-message.drug-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await DrugService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.drug-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeDocumentCategory = async (id) => {
        try {
            await DrugService.remove(id)
            notification.success({ message: t("notif-message.drug-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const columns = [
        {
            title: t("form-fields.name"),
            dataIndex: 'name',
            key: 'name',
            width: 700,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.drug")}` })}
                        onConfirm={() => removeDocumentCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >

                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const orderChanged = async () => {
        // try {
        //     await DrugService.OrderBy({ items: items.map(item => item._id),skip: filter.skip })
        // } catch {
        //     notification.error({
        //         message: t("notif-message.default-error"),
        //     })
        // }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={<HeaderTitle className="col-6">
                        {t("settings.drug-list")}
                    </HeaderTitle>}
                    right={<>
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t("search")} Documents ${t("form-fields.drug")}`}
                            options={[]}
                            className="mb-0 ms-5"
                            onSearch={(data) => filterChanged("search", { name: data })}
                        />
                        <PrimaryButton
                            onClick={() => onOpen()}
                            className=" mx-3 d-flex align-items-center">
                            {t("add")}&nbsp;
                            <Plus />
                        </PrimaryButton></>}
                />
            </DesktopMode>
            <MobileMode>
                <FlexRowBetween>
                    <HeaderTitle className="">
                        {t("settings.drug-list")}
                    </HeaderTitle>
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className="d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                </FlexRowBetween>
                <AutoCompleteDropDown
                    name="search"
                    required
                    placeholder={`${t("search")} Documents ${t("form-fields.drug")}`}
                    options={[]}
                    className="mb-0 ms-1"
                    onSearch={(data) => filterChanged("search", { name: data })}
                />
            </MobileMode>
            <AddEditDrugs
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
