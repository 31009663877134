import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import { AutoCompleteDropDown, DraggableAppTable, FlexColumn, FlexRow, FlexRowBetween, HeaderTitle, PageHeader } from 'components'
import { DangerButton, PrimaryButton, FormTextAreaFormField } from 'elements'
import { Pencil, Trash } from '@phosphor-icons/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DesktopMode, MobileMode, GetQuestionList, GetAppointmentCategory } from 'hooks'
import * as yup from 'yup'
import { useState, useEffect } from 'react'
import { Check, Eye, EyeSlash, Plus } from 'phosphor-react'
import { Roles, QuestionsService } from 'utility'
import { useAuth } from 'context'

const QuestionsSchema = yup.object().shape({
    question: yup.string().required('question is required').trim(),
})

export const AddQuestionsModal = ({ open, data, editMode, onClose, loading, onSubmit }) => {
    const { reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(QuestionsSchema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                    ...data,
                    category: data.category._id,
                }
                : {},
        )
    }, [open, editMode])

    return (
        <Modal
            title={editMode ? t('add-edit.edit-questions') : t('add-edit.add-questions')}
            visible={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="col-12 mb-0">
                        <FormTextAreaFormField
                            control={control}
                            name="question"
                            label={t('form-fields.question')}
                            placeholder="question"
                            errors={errors?.question || ''}
                            rows={5}
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('add-edit.edit-questions')
                            : t('add-edit.add-questions')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    );
};
export const QuestionsConfigTab = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const { user } = useAuth()
    const [processing, setProcessing] = useState(false)
    const { optionList } = GetAppointmentCategory()
    const {
        data,
        setData,
        filterChanged,
        refetch,
        total = { page: 1 },
        filter,
        pageChanged,
    } = GetQuestionList()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await QuestionsService.add(formData)
                notification.success({
                    message: t('notif-message.question-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await QuestionsService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.question-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const removeQuestion = async (id) => {
        try {
            await QuestionsService.remove(id)
            notification.success({
                message: t('notif-message.question-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const validateQuestion = async (id) => {
        try {
            await QuestionsService.update(id, { admin: true })
            notification.success({
                message: t('notif-message.type-validated'),
            })
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const toggleVisibilty = async (id, ele) => {
        try {
            await QuestionsService.hideShow(id, !ele?.hideusers?.includes(user._id))
            notification.success({
                message: t('notif-message.type-updated'),
            })
            refetch()
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const getActions = (data) => {
        return (
            <Space size="middle">
                {((user.role === Roles.doctor && !data.admin) || user.role === Roles.admin) && <><PrimaryButton
                    icon={<Pencil onClick={() => onOpen(data)} />}
                />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeQuestion(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </>}
                {!data.admin && user.role === Roles.admin && <PrimaryButton
                    onClick={() => validateQuestion(data._id)}
                    icon={<Check />}
                >{t("validate")}</PrimaryButton>}
                {user.role === Roles.doctor && <PrimaryButton
                    onClick={() => toggleVisibilty(data._id, data)}
                    icon={!data?.hideusers?.includes(user._id) ? <Eye size={20} /> : <EyeSlash size={20} />}
                />}
            </Space>
        )
    }
    const columns = [
        {
            title: t('form-fields.question'),
            dataIndex: 'question',
            key: 'question',
            width: 600,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            width: 200,
            render: (_, data) => getActions(data),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await QuestionsService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('questions')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'questions',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) => filterChanged('search', { type: data })}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.question')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'questions',
                            )}`}
                            options={[]}
                            className="mb-0 ms-1"
                            onSearch={(data) => filterChanged('search', { name: data })}
                        />
                    </FlexRow>
                </FlexColumn>
                <AddQuestionsModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                optionList={optionList}
            />
            <div className="mt-sm-2 mt-md-0 overflow-auto mt-3">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
            </MobileMode>
            <AddQuestionsModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                optionList={optionList}
            />
            <div className="mt-sm-2 mt-md-0">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
