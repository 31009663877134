import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class Contact extends CRUDService {
    constructor() {
        super(APIPath.contacts)
    }

    ByDoctorId(id, params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(`${APIPath.contacts}/doctor/${id}?${data}`)
    }
}

const ContactService = new Contact()
Object.freeze(ContactService)
export { ContactService }
