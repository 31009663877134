import { useMediaQuery } from 'react-responsive'

export const DesktopMode = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 769 })
  return isDesktop ? children : null
}

export const MobileMode = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 })
  return isMobile ? children : null
}

export const isMobileMode = () => {
  return useMediaQuery({ maxWidth: 768 })
}

export const isDesktopMode = () => {
  return useMediaQuery({ minWidth: 768 })
}
