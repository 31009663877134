import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class AboutUs extends CRUDService {
    constructor() {
        super(APIPath.aboutUs)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.aboutUs}/order-by`, data)
    }
}

const AboutUsService = new AboutUs()
Object.freeze(AboutUsService)
export { AboutUsService }
