import { Checkbox, Collapse } from 'antd'
import React from 'react'
import { DateRangePicker } from 'react-date-range';
import { startOfWeek, endOfWeek, isSameDay,differenceInCalendarWeeks } from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import styled from 'styled-components'

// const ColorBox = styled.div`
//     height: 12px;
//     width: 12px;
//     border-radius: 5px;
//     background-color: ${({ color }) => color};
// `

function CalendarDropdown({
    formDataList,
    selectedCalendars,
    setSelectedCalendars,
    onSelectRange,
    externalRangeUpdate,
    setExternalRangeUpdate,
}) {

    const [state, setState] = React.useState([
        {
            startDate: startOfWeek(new Date(), { weekStartsOn: 1 }), // Week starts on Monday
            endDate: endOfWeek(new Date(), { weekStartsOn: 1 }), // Week ends on Sunday
            key: 'selection',
        },
    ]);

    React.useEffect(() => {
        setState([{
            startDate: externalRangeUpdate.startDate,
            endDate: externalRangeUpdate.endDate,
            key: 'selection',
        }]);

    }, [externalRangeUpdate]);

    const handleSelect = (ranges) => {
        const { selection } = ranges;
        let adjustedStartDate;
        let adjustedEndDate;

        // Check if the selection is a single day
        if (isSameDay(selection.startDate, selection.endDate)) {
            adjustedStartDate = selection.startDate;
            adjustedEndDate = selection.endDate;
        } else {
            // Adjust to the full week, but check if the range spans more than one week
            const startWeek = startOfWeek(selection.startDate, { weekStartsOn: 1 });
            const endWeek = endOfWeek(selection.endDate, { weekStartsOn: 1 });

            if (differenceInCalendarWeeks(endWeek, startWeek, { weekStartsOn: 1 }) >= 1) {
                // If the selection spans more than one week, restrict to the starting week
                adjustedStartDate = startWeek;
                adjustedEndDate = endOfWeek(startWeek, { weekStartsOn: 1 });
            } else {
                // For a range within the same week
                adjustedStartDate = startWeek;
                adjustedEndDate = endWeek;
            }
        }

        // Update the local component state to reflect the new selection
        setState([{ ...state[0], startDate: adjustedStartDate, endDate: adjustedEndDate }]);

        // Invoke the onSelectRange callback provided by the parent component
        onSelectRange(adjustedStartDate, adjustedEndDate);

        // Update the external state via a callback to the parent component
        setExternalRangeUpdate({ startDate: adjustedStartDate, endDate: adjustedEndDate });
    };

    return (
        <div className="dropdown-container" style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>

        <DateRangePicker
                onChange={handleSelect}
                showSelectionPreview
                moveRangeOnFirstSelection={false}
                months={1} // Reduce to 1 to fit better in a small space
                ranges={state}
                direction="vertical" // Change to vertical to fit better in a narrow space
                className="date-picker"
                style={{ width: '100%' }}
/>
            <Collapse
                defaultActiveKey="1"
                items={[
                    {
                        key: '1',
                        label: 'Calendars',
                        children: (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                {formDataList.length === 0 && (
                                    <p>
                                        You currently don't have any calendars
                                    </p>
                                )}
                                {formDataList.map((formData, index) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            background: `${formData.color}60`,
                                            borderRadius: 8,
                                            paddingLeft: 6,
                                            height: 30,
                                        }}
                                        key={index}
                                    >
                                        <Checkbox
                                            checked={selectedCalendars.includes(
                                                formData._id,
                                            )}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedCalendars([
                                                        ...selectedCalendars.filter(
                                                            (calendar) =>
                                                                formData._id !==
                                                                calendar,
                                                        ),
                                                        formData._id,
                                                    ])
                                                } else {
                                                    setSelectedCalendars([
                                                        ...selectedCalendars.filter(
                                                            (calendar) =>
                                                                formData._id !==
                                                                calendar,
                                                        ),
                                                    ])
                                                }
                                            }}
                                        >
                                            {formData.name}
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        ),
                    },
                ]}
            />
        </div>
    )
}

export default CalendarDropdown
