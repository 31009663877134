import React, { useEffect, useState } from 'react';
import { Form, notification, Select, Popconfirm, Space, Modal, Button } from 'antd';
import { DangerButton, FormTextAreaFormField, PrimaryButton } from 'elements';
import { AppTable, PageHeader } from 'components';
import { CommonUtility, QuestionsService } from 'utility';
import { Plus } from 'phosphor-react';
import { AddQuestionsModal } from 'page-components/settings';
import { useTranslation } from 'react-i18next';
import { QuestionAnswersService } from 'utility/services/question-answer';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

const QASchema = yup.object().shape({
  answer: yup.string().required(),
})
export const QATable = ({ list, editQA }) => {
  const { t } = useTranslation()
  const onRowDoubleClick = (data) => {
    editQA(data)
  }
  const columns = [
    {
      title: t('Question'), // You can replace 'Question' with the appropriate translation
      dataIndex: 'question',
      key: 'question',
      render: (text, record) => record.question.question,
    },
    {
      title: t('Answer'), // You can replace 'Answer' with the appropriate translation
      dataIndex: 'answer',
      key: 'answer',
      render: (text) => <>{CommonUtility.truncateString(text, 25)}</>,
    },
  ];

  return (
    <>
      <>
        <AppTable
          data={list}
          columns={columns}
          keyField="_id"
          size="small"
          onRowDoubleClick={onRowDoubleClick}
        />
      </>
    </>
  )
}

export const QuestionsAnswerTab = ({
  questionOptions = [],
  appointment,
  patient,
  questionFilterChanged,
  questionAnswersList,
  encounterPage,
  setQuestionAnswersList,
}) => {
  const [form] = Form.useForm();
  const [questions, setQuestions] = useState([])
  const [open, setOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const { t } = useTranslation()
  const {
    handleSubmit, control, reset,watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(QASchema),
  })
  const rowId = watch('_id')
  useEffect(() => {
    setQuestions(questionAnswersList)
  }, [questionAnswersList])

  const onSubmit = async (data) => {
    if (data.localid) {
      setQuestionAnswersList(questions.map(ele => ({ ...ele, answer: data.localid === ele.localid ? data.answer : ele.answer })))
      onClose()
      return
    }
    try {
      await QuestionAnswersService.update(data._id, {
        answer: data.answer,
      })
      setQuestions(questions.map(ele => ({ ...ele, answer: ele._id === data._id ? data.answer : ele.answer })))
      notification.success({ message: t("notif-message.answer-saved") });
    } catch (error) {
      notification.error({ message: t("notif-message.default-error") });
    } finally {
      onClose()
    }
  };

  const onSelect = async (data) => {
    const question = questionOptions.find(ele => ele._id === data)
    if (questions.some(ele => ele.question._id === data)) {
      notification.warning({ message: 'Allreday selected' });
      return
    }
    if (!appointment?._id && !encounterPage) {
      setQuestionAnswersList([...questions, { localid: (new Date()).toUTCString(), question, answer: "" }])
      return
    }
    if (question) {
      const result = await QuestionAnswersService.add({ question: question?._id, answer: "", appointment: appointment?._id, patient: patient?._id })
      const list = [...questions, {
        ...result,
      }]
      notification.success({ message: t("notif-message.question-added") });
      setQuestions(list)
    }
  }

  const onQuestionRemove = async (id) => {
    if (!appointment?._id) {
      setQuestionAnswersList(questions.filter((ele => ele.localid !== id)))
      return
    }
    try {
      await QuestionAnswersService.remove(id)
      setQuestions([...questions.filter(ele => ele._id !== id)])
      notification.success({ message: t("notif-message.question-removed") });
    } catch {
      notification.error({ message: t("notif-message.default-error") });
    } finally {
      setOpen(false)
      setEditOpen(false)
    }
    form.setFieldsValue({ [id]: "" })
  }

  const onQuestionAdd = async (formdata) => {
    try {
      const result = await QuestionsService.add(formdata)
      const qaResult = await QuestionAnswersService.add({ question: result._id, answer: "", appointment: appointment._id, patient: patient._id })
      setQuestions([...questions, { ...qaResult }])
      notification.success({ message: t("notif-message.question-added") });
    } catch {
      notification.error({ message: t("notif-message.default-error") });
    } finally {
      setOpen(false)
    }
  }

  const editQA = (data) => {
    reset({ ...data })
    setEditOpen(true)
  }
  const onClose = () => {
    setEditOpen(false)
  }
  return (
    <div>
      <PageHeader
        left={<h3 className="my-0 col-6">{t("question-answer")}</h3>}
        right={
          <div className="col-6 d-flex justify-content-end">
            <Select
              className="col-12 me-2"
              options={questionOptions}
              notFoundContent={<PrimaryButton onClick={() => setOpen(true)} className="d-flex align-items-center" icon={<Plus />}>Add Question</PrimaryButton>}
              onSelect={onSelect}
              showSearch
              onSearch={(data) => { questionFilterChanged("search", { type: data }) }}
            />
          </div>
        }
      />
      <QATable
        list={questions}
        // sortChanged={sortChanged}
        editQA={editQA}
        onQuestionRemove={onQuestionRemove}
      />
      <AddQuestionsModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={onQuestionAdd}
        categoryid={appointment?.category}
      />
      <div>
        <Modal
          title={t("submit-answer")}
          open={editOpen}
          confirmLoading
          footer={null}
          width={600}
          onCancel={() => onClose()}>
          <Form layout="vertical">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="col-12">
                    <FormTextAreaFormField
                      control={control}
                      name="answer"
                      label={t("form-fields.answer")}
                      placeholder=""
                      errors={errors?.answer || ''}
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between">
            <Popconfirm
            title={t("delete")}
            description={t("message.delete-message", { name: `${t("diagnostic")}` })}
            onConfirm={() => onQuestionRemove(rowId)}
            okButtonProps={{ disabled: false }}
            cancelButtonProps={{ disabled: false }}
          >
            <DangerButton> {t('delete')} </DangerButton>
          </Popconfirm>
              <div />
              <Space wrap>
                <Button key="cancel" onClick={onClose}>{t("cancel")}</Button>
                <PrimaryButton key="submit" onClick={handleSubmit(onSubmit)}>{t("submit")}</PrimaryButton>
              </Space>
            </div>
          </Form>
        </Modal>
      </div>
    </div>
  );
};
