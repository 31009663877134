import { useState, useEffect } from 'react'
import { PatientHistoryService } from 'utility'

export const GetPatientHistoryList = (isAuthenticated) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [refresh, setRefresh] = useState(0)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await PatientHistoryService.get()
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (isAuthenticated) {
            fetchData()
        }
    }, [refresh, isAuthenticated])

    const refreshData = () => {
        setRefresh(Math.random())
    }

    return {
        data,
        error,
        loading,
        setData,
        refreshData,
    }
}
