import { Menu } from 'antd'
import { ShadowBox } from 'elements'
import {
    Calendar,
    User,
    Textbox,
    FolderUser,
    Bank,
    Signature,
    CalendarBlank,
    Users,
} from '@phosphor-icons/react'
import { useQueryString } from 'hooks/query'
import { useNavigate } from 'react-router-dom'
import { HeaderTitle, PageHeader } from 'components'
import {
    AppointmentCategoryTab,
    DocumentCategoryTab,
    ProfileTab,
    SnippetsTab,
    BankTab,
    DEPTab,
    AppointStatusTab,
    StateRateTab,
    InvoiceCodesTab,
    InvoicesGroupTab,
    NeedUsTab,
    TypesTab,
    VitalSignsTypes,
    FrameSetting,
    CustomEncounterTab,
    AdminConfigsTab,
    AddressTypeTab,
    UserLogsList,
    AllergiesTab,
    QuestionsConfigTab,
    VaccinationTab,
    InsuranceTab,
    MedicalHistoryTypesTab,
    CalendarTab,
    DocumentStatusTab,
} from 'page-components/settings'
import { useTranslation } from 'react-i18next'
import { DrugsTab } from 'page-components/settings/drugs'
import EncounterColorTab from 'page-components/settings/encounter-color'
import { DesktopMode, MobileMode } from 'hooks'
import styled from 'styled-components'
import {
    AddressBook,
    Book,
    Books,
    Coins,
    File,
    Info,
    PaintBrushBroad,
    Pill,
    Question,
    SpinnerGap,
    Timer,
} from 'phosphor-react'
import { Roles } from 'utility'
import { useAuth } from 'context'
import { useMemo } from 'react'
import { ContactsTab } from 'page-components/settings/contacts'

const MenuContainer = styled.div`
    .mobile-menu {
        .ant-menu-horizontal {
            overflow-wrap: anywhere;
            display: flex;
            overflow-x: scroll;
            overflow-y: hidden;
            ::-webkit-scrollbar {
                display: none;
            }
            display: flex;
            .ant-menu-overflow-item {
                pointer-events: none;
                position: relative !important;
                opacity: 1 !important;
                height: auto !important;
                pointer-events: initial !important;
                display: flex;
                align-items: center;
            }
        }
    }
`

const SettingScreens = {
    Profile: 'profile',
    AppointCategory: 'appointment-category',
    DocumentsCategory: 'documents-category',
    Snippets: 'snippets',
    Bank: 'bank-settings',
    DEP: 'dep-settings',
    AppointmentStatus: 'appointment-status',
    DocumentStatus: 'document-status',
    DrugsList: 'drugs-list',
    StateRate: 'state-rate',
    InvoiceCodes: 'invoice-codes',
    InvoicesGroup: 'invoices-group',
    EncounterColor: 'encounter-color',
    NeedUs: 'need-us',
    Types: 'types',
    VitalSignsTypes: 'vital-signs-types',
    FrameConfig: 'frame-config',
    CustomEncounterTab: 'custom-encounter-fields',
    AdminConfigs: 'admin-configs',
    AddressType: 'address-type',
    Logs: 'logs',
    Allergies: 'allergies',
    Questions: 'questions',
    Demo7: 'demo-7',
    Vaccination: 'Vaccination',
    Insurance: 'insurance',
    Calendar: 'calendar',
    MedicalHistoryType: 'medical-history-types',
    Contacts: 'contacts',
}

function getItem(label, key, icon, role) {
    return {
        label,
        key,
        icon,
        role,
    }
}

export function SettingsScreen() {
    const query = useQueryString()
    const redirectTab = query.get('tab')
    const { user } = useAuth()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const items = [
        getItem(
            t('settings.profile'),
            SettingScreens.Profile,
            <User size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.appointment-category'),
            SettingScreens.AppointCategory,
            <Calendar size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.appointment-status'),
            SettingScreens.AppointmentStatus,
            <SpinnerGap size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.document-status'),
            SettingScreens.DocumentStatus,
            <SpinnerGap size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.documents-category'),
            SettingScreens.DocumentsCategory,
            <File size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.bank-details'),
            SettingScreens.Bank,
            <Bank size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.dep-setup'),
            SettingScreens.DEP,
            <FolderUser size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.quick-entry'),
            SettingScreens.Snippets,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.drug-list'),
            SettingScreens.DrugsList,
            <Pill size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.state-rate'),
            SettingScreens.StateRate,
            <Coins size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.invoice-codes'),
            SettingScreens.InvoiceCodes,
            <Book size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.invoices-group'),
            SettingScreens.InvoicesGroup,
            <Books size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.encounter-color'),
            SettingScreens.EncounterColor,
            <PaintBrushBroad size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(t('need-us'), SettingScreens.NeedUs, <Info size={22} />, [
            Roles.admin,
            Roles.doctor,
        ]),
        getItem(
            t('settings.types'),
            SettingScreens.Types,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.medical-history-types'),
            SettingScreens.MedicalHistoryType,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.vital-signs-types'),
            SettingScreens.VitalSignsTypes,
            <Signature size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.custom-encounter-fields'),
            SettingScreens.CustomEncounterTab,
            <Signature size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.admin-configs'),
            SettingScreens.AdminConfigs,
            <Timer size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.address-type'),
            SettingScreens.AddressType,
            <AddressBook size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.frame-config'),
            SettingScreens.FrameConfig,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(t('settings.logs'), SettingScreens.Logs, <Book size={22} />, [
            Roles.admin,
            Roles.doctor,
        ]),
        getItem(
            t('settings.allergies'),
            SettingScreens.Allergies,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.questions'),
            SettingScreens.Questions,
            <Question size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.vaccination'),
            SettingScreens.Vaccination,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.insurance'),
            SettingScreens.Insurance,
            <Textbox size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.calendar'),
            SettingScreens.Calendar,
            <CalendarBlank size={22} />,
            [Roles.admin, Roles.doctor],
        ),
        getItem(
            t('settings.contacts'),
            SettingScreens.Contacts,
            <Users size={22} />,
            [Roles.admin, Roles.doctor],
        ),
    ]

    const roleBaseMenu = useMemo(
        () => items.filter((item) => item.role.includes(user?.role)),
        [user],
    )

    const onClick = (e) => {
        navigate(`/app/settings?tab=${e.key}`)
    }

    return (
        <MenuContainer className="container">
            <PageHeader
                left={<HeaderTitle>{t('settings.settings')}</HeaderTitle>}
            />
            <div className="row">
                <DesktopMode>
                    <div className="col-3">
                        <Menu
                            onClick={onClick}
                            defaultSelectedKeys={[redirectTab]}
                            selectedKeys={[redirectTab]}
                            mode="inline"
                            items={roleBaseMenu}
                        />
                    </div>
                </DesktopMode>
                <MobileMode>
                    <div className="col-12 px-0 mobile-menu">
                        <Menu
                            onClick={onClick}
                            defaultSelectedKeys={[redirectTab]}
                            items={roleBaseMenu}
                            mode="horizontal"
                            overflowedIndicator={false}
                        />
                    </div>
                </MobileMode>
                <DesktopMode>
                    <div className="col-9">
                        <ShadowBox>
                            {SettingScreens.Profile === redirectTab && (
                                <ProfileTab />
                            )}
                            {SettingScreens.AppointCategory === redirectTab && (
                                <AppointmentCategoryTab />
                            )}
                            {SettingScreens.DocumentsCategory ===
                                redirectTab && <DocumentCategoryTab />}
                            {SettingScreens.AppointmentStatus ===
                                redirectTab && <AppointStatusTab />}
                            {SettingScreens.DocumentStatus === redirectTab && (
                                <DocumentStatusTab />
                            )}
                            {SettingScreens.Snippets === redirectTab && (
                                <SnippetsTab />
                            )}
                            {SettingScreens.DrugsList === redirectTab && (
                                <DrugsTab />
                            )}
                            {SettingScreens.Bank === redirectTab && <BankTab />}
                            {SettingScreens.DEP === redirectTab && <DEPTab />}
                            {SettingScreens.EncounterColor === redirectTab && (
                                <EncounterColorTab />
                            )}
                            {SettingScreens.StateRate === redirectTab && (
                                <StateRateTab />
                            )}
                            {SettingScreens.InvoiceCodes === redirectTab && (
                                <InvoiceCodesTab />
                            )}
                            {SettingScreens.InvoicesGroup === redirectTab && (
                                <InvoicesGroupTab />
                            )}
                            {SettingScreens.NeedUs === redirectTab && (
                                <NeedUsTab />
                            )}
                            {SettingScreens.Types === redirectTab && (
                                <TypesTab />
                            )}
                            {SettingScreens.VitalSignsTypes === redirectTab && (
                                <VitalSignsTypes />
                            )}
                            {SettingScreens.FrameConfig === redirectTab && (
                                <FrameSetting />
                            )}
                            {SettingScreens.CustomEncounterTab ===
                                redirectTab && <CustomEncounterTab />}
                            {SettingScreens.AdminConfigs === redirectTab && (
                                <AdminConfigsTab />
                            )}
                            {SettingScreens.AddressType === redirectTab && (
                                <AddressTypeTab />
                            )}
                            {SettingScreens.Logs === redirectTab && (
                                <UserLogsList />
                            )}
                            {SettingScreens.Allergies === redirectTab && (
                                <AllergiesTab />
                            )}
                            {SettingScreens.Questions === redirectTab && (
                                <QuestionsConfigTab />
                            )}
                            {SettingScreens.Vaccination === redirectTab && (
                                <VaccinationTab />
                            )}
                            {SettingScreens.Insurance === redirectTab && (
                                <InsuranceTab />
                            )}
                            {SettingScreens.MedicalHistoryType ===
                                redirectTab && <MedicalHistoryTypesTab />}
                            {SettingScreens.DEP === redirectTab && <DEPTab />}
                            {SettingScreens.Calendar === redirectTab && (
                                <CalendarTab />
                            )}
                            {SettingScreens.Contacts === redirectTab && (
                                <ContactsTab />
                            )}
                        </ShadowBox>
                    </div>
                </DesktopMode>
                <MobileMode>
                    <div className="col-12 px-0 mt-3">
                        <ShadowBox>
                            {SettingScreens.Profile === redirectTab && (
                                <ProfileTab />
                            )}
                            {SettingScreens.AppointCategory === redirectTab && (
                                <AppointmentCategoryTab />
                            )}
                            {SettingScreens.DocumentsCategory ===
                                redirectTab && <DocumentCategoryTab />}
                            {SettingScreens.AppointmentStatus ===
                                redirectTab && <AppointStatusTab />}
                            {SettingScreens.DocumentStatus === redirectTab && (
                                <DocumentStatusTab />
                            )}
                            {SettingScreens.Snippets === redirectTab && (
                                <SnippetsTab />
                            )}
                            {SettingScreens.DrugsList === redirectTab && (
                                <DrugsTab />
                            )}
                            {SettingScreens.Bank === redirectTab && <BankTab />}
                            {SettingScreens.DEP === redirectTab && <DEPTab />}
                            {SettingScreens.EncounterColor === redirectTab && (
                                <EncounterColorTab />
                            )}
                            {SettingScreens.StateRate === redirectTab && (
                                <StateRateTab />
                            )}
                            {SettingScreens.InvoiceCodes === redirectTab && (
                                <InvoiceCodesTab />
                            )}
                            {SettingScreens.InvoicesGroup === redirectTab && (
                                <InvoicesGroupTab />
                            )}
                            {SettingScreens.NeedUs === redirectTab && (
                                <NeedUsTab />
                            )}
                            {SettingScreens.Types === redirectTab && (
                                <TypesTab />
                            )}
                            {SettingScreens.VitalSignsTypes === redirectTab && (
                                <VitalSignsTypes />
                            )}
                            {SettingScreens.FrameConfig === redirectTab && (
                                <FrameSetting />
                            )}
                            {SettingScreens.CustomEncounterTab ===
                                redirectTab && <CustomEncounterTab />}
                            {SettingScreens.AdminConfigs === redirectTab && (
                                <AdminConfigsTab />
                            )}
                            {SettingScreens.AddressType === redirectTab && (
                                <AddressTypeTab />
                            )}
                            {SettingScreens.Logs === redirectTab && (
                                <UserLogsList />
                            )}
                            {SettingScreens.Allergies === redirectTab && (
                                <AllergiesTab />
                            )}
                            {SettingScreens.Questions === redirectTab && (
                                <QuestionsConfigTab />
                            )}
                            {SettingScreens.Vaccination === redirectTab && (
                                <VaccinationTab />
                            )}
                            {SettingScreens.Insurance === redirectTab && (
                                <InsuranceTab />
                            )}
                            {SettingScreens.MedicalHistoryType ===
                                redirectTab && <MedicalHistoryTypesTab />}
                            {SettingScreens.DEP === redirectTab && <DEPTab />}
                            {SettingScreens.Calendar === redirectTab && (
                                <CalendarTab />
                            )}
                            {SettingScreens.Contacts === redirectTab && (
                                <ContactsTab />
                            )}
                        </ShadowBox>
                    </div>
                </MobileMode>
            </div>
        </MenuContainer>
    )
}
