import { useState, useEffect, useMemo } from 'react'
import { EncounterService, EncounterSortOptions } from 'utility'

export const GetEncounterByAppointment = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        search: '',
        sort: EncounterSortOptions[1].value,
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            let result
            if (id) {
                result = await EncounterService.byAppointment(id)
            }
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id && !loading) {
            fetchData()
        }
    }, [id])

    const refetch = () => {
        fetchData()
    }

    const filteredData = useMemo(() => {
        let result = [...data]
        if (filter.search) {
            result = result?.filter(
                (x) =>
                    (!x?.local && (x?.name?.toLowerCase()?.includes(filter.search.toLowerCase()) ||
                    x?.encounter_type
                        ?.toLowerCase()
                        .includes(filter.search.toLowerCase()) ||
                        x?.content_type
                        ?.toLowerCase()
                        .includes(filter.search.toLowerCase()) ||
                        (Object.keys(x?.metadata)?.some((y) =>
                        x?.metadata?.[y].toString()
                            .toLowerCase()
                            .includes(filter.search.toLowerCase()) ||
                            Object.values(x?.metadata?.[y]).some((a) =>
                            a?.toString().toLowerCase().includes(filter.search.toLowerCase())),
                    )))))
        }
        if (filter.sort) {
            result = result.sort((a, b) => {
                if (filter.sort === 'date-asc') {
                    if (a.bookmark && !b.bookmark) {
                        return -1;
                    }
                    if (!a.bookmark && b.bookmark) {
                        return 1;
                    }
                    return new Date(a.date) - new Date(b.date);
                }
                if (a.bookmark && !b.bookmark) {
                    return -1;
                }
                if (!a.bookmark && b.bookmark) {
                    return 1;
                }
                return new Date(b.date) - new Date(a.date);
            });
        }
        return result
    }, [data, filter])

    const filterChanged = (key, value) => {
            setFilter({
                ...filter,
                [key]: value,
            })
    }

    return {
        data,
        filteredData,
        error,
        loading,
        setData,
        filter,
        refetch,
        filterChanged,
    }
}

export const GetEncounterByPatient = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        search: '',
        sort: EncounterSortOptions[1].value,
    })
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                let result
                if (id) {
                    result = await EncounterService.byPatient(id,filter)
                }
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    const filteredData = useMemo(() => {
        let result = [...data]
        if (filter.search) {
            result = result.filter(
                (x) => {
                    return (!x?.local && (x?.name?.toLowerCase()?.includes(filter.search.toLowerCase()) ||
                    x?.encounter_type
                        ?.toLowerCase()
                        .includes(filter.search.toLowerCase()) ||
                        x?.content_type
                        ?.toLowerCase()
                        .includes(filter.search.toLowerCase()) ||
                        (Object.keys(x?.metadata)?.some((y) =>
                        x?.metadata?.[y].toString()
                            .toLowerCase()
                            .includes(filter.search.toLowerCase()) ||
                            Object.values(x?.metadata?.[y]).some((a) =>
                            a?.toString().toLowerCase().includes(filter.search.toLowerCase())),
                    ))))
                },
            )
        }
        if (filter.sort) {
            result = result.sort((a, b) => {
                if (filter.sort === 'date-asc') {
                    if (a.bookmark && !b.bookmark) {
                        return -1;
                    }
                    if (!a.bookmark && b.bookmark) {
                        return 1;
                    }
                    return new Date(a.date) - new Date(b.date);
                }
                if (a.bookmark && !b.bookmark) {
                    return -1;
                }
                if (!a.bookmark && b.bookmark) {
                    return 1;
                }
                return new Date(b.date) - new Date(a.date);
            });
        }
        return result
    }, [data, filter])

    const filterChanged = (key, value) => {
        setFilter({
            ...filter,
            [key]: value,
        })
    }

    return {
        data,
        filteredData,
        error,
        loading,
        setData,
        filter,
        filterChanged,
    }
}

export const GetEncounterByPatientWithSearch = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({
        search: '',
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                let result
                if (id) {
                    result = await EncounterService.byPatient(id, filter)
                }
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    const filterChanged = (key, value) => {
        setFilter({
            ...filter,
            [key]: value,
        })
    }

    return {
        data,
        error,
        loading,
        setData,
        filter,
        filterChanged,
    }
}
