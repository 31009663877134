import { Select, DatePicker, Checkbox, Space, Popconfirm, Popover } from "antd";
import { AppTable, PageHeader } from "components";
import { DangerButton, PrimaryButton } from "elements";
import { DesktopMode, GetLogsList, GetLogsTypeOptions, MobileMode } from "hooks"
import { Info, Trash } from "phosphor-react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonUtility, DateFormat, DateUtility, LogsType, LogsTypeStrings } from "utility";

const logText = (t, type, user, item, documemt) => {
    const byuser = documemt ? `${user?.firstName} ${user?.lastName}` : `<a href="/app/patient/${user?._id}">${user?.firstName} ${user?.lastName}</a>`
    const patientName = documemt ? `${item?.patient?.firstName} ${item?.patient?.lastName}` : `<a href="/app/patient/${item?.patient?._id}">${item?.patient?.firstName} ${item?.patient?.lastName}</a>`;
    const appointmentName = documemt ? `${item?.appointment?.reason_for_visit}` : `<a href="/app/appointments/${item?.appointment?._id}">${item?.appointment?.reason_for_visit}</a>`;
    const encounterName = `${item?.encounter?.encounter_type} ${item?.encounter?.name}`;
    let text = ""
    switch (type) {
        case LogsType.CREATE_APPOINTMENT: text = `${t(`logs.appointment-created`, { appointmentName, byuser })}`;
            break;
        case LogsType.MODIFY_APPOINTMENT: text = t(`logs.appointment-updated`, { appointmentName, byuser });
            break;
        case LogsType.DELETE_APPOINTMENT: text = t(`logs.appointment-removed`, { appointmentName, byuser });
            break;
        case LogsType.CREATE_PATIENT: text = t(`logs.patient-created`, { patientName, byuser });
            break;
        case LogsType.MODIFY_PATIENT: text = t(`logs.patient-updated`, { patientName, byuser });
            break;
        case LogsType.DELETE_PATIENT: text = t(`logs.patient-removed`, { patientName, byuser });
            break;
        case LogsType.CREATE_ENCOUNTER: text = t(`logs.encounter-removed`, { encounterName, byuser });
            break;
        case LogsType.MODIFY_ENCOUNTER: text = t(`logs.encounter-removed`, { encounterName, byuser });
            break;
        case LogsType.DELETE_ENCOUNTER: text = t(`logs.encounter-removed`, { encounterName, byuser });
            break;
        case LogsType.CREATE_DOCUMENT: text = t(`logs.document-removed`, { patientName, byuser });
            break;
        case LogsType.MODIFY_DOCUMENT: text = t(`logs.document-removed`, { patientName, byuser });
            break;
        case LogsType.DELETE_DOCUMENT: text = t(`logs.document-removed`, { patientName, byuser });
            break;
        default:
            text = ""
            break;
    }
    return text
}

const LogsText = ({ type, user, item }) => {
    const { t } = useTranslation("logs")
    return <div dangerouslySetInnerHTML={{
        __html: logText(t, type, user, item),
    }} />
}

const LogsInfo = ({ item }) => {
    return <div>
        <div className="row mb-1"><h4 className="col-4 my-0">Type: </h4><div className="col my-0">{LogsTypeStrings[item.type.split("_")[0]]} - {LogsTypeStrings[item.type.split("_")[1]]}</div></div>
        <div className="row mb-1"><h4 className="col-4 my-0">User: </h4><div className="col my-0">{item.doctor?._id}<div className="my-0">{item.doctor?.firstName} {item.doctor?.lastName}</div></div></div>
        {item.appointment?._id && <div className="row mb-1"><h4 className="col-4 my-0">Appointment: </h4><div className="col my-0">{item.appointment?._id}<div className="my-0">{item.appointment?.reason_for_visit}</div></div></div>}
        {item.encounter?._id && <div className="row mb-1"><h4 className="col-4 my-0">Encounter: </h4><div className="col my-0">{item.encounter._id}<div className="my-0">{item?.encounter?.encounter_type} {item?.encounter?.name}</div></div></div>}
        {item.patient?._id && <div className="row mb-1">
            <h4 className="col-4 my-0">Patient: </h4>
            <div className="col my-0">{item.patient._id}
            <div className="my-0">{item?.patient?.firstName} {item?.patient?.lastName} - {item.patient?.birthdate && <>&nbsp;-{DateUtility.dateToString(new Date(item.patient?.birthdate), DateFormat.euDate)}</>}
            {item.patient?.birthdate && <>&nbsp;({DateUtility.getDistanceInWord(new Date(item.patient?.birthdate)).replace(/years?/, 'Y')})</>}</div></div></div>}
    </div>
}
export const UserLogsList = () => {
    const { data, total, pageChanged, filter, filterChanged } = GetLogsList()
    const { t } = useTranslation("logs")
    const { data: typeOptions } = GetLogsTypeOptions(t)
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const onRowClick = (data) => {
        handleCheckboxChange(data._id)
    }

    const handleDownloadClick = () => {
        const textContent = selectedItems.map((item) => {
            const data = logText(t, item.type, item.doctor, item, true)
            return `Type: ${item.type}\n
            Date: ${DateUtility.dateToString(item.createdAt, DateFormat.euDateFullTime)}\n
            User: ${item.doctor.firstName} ${item.doctor.lastName}\n
            Content:${data}\n
            `;
        }).join("\n\n");
        CommonUtility.downloadTxt(textContent, "logs")
    };

    const columns = useMemo(() => [
        {
            title: "",
            dataIndex: "_id",
            key: "_id",
            render: (text) => <Checkbox
                checked={selectedItems.includes(text)}
                onChange={() => handleCheckboxChange(text)}
            />,

        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text) => DateUtility.dateToString(new Date(text), DateFormat.euDateFullTime),
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text) => LogsTypeStrings[text.split("_")[0]],
        },
        {
            title: "Content",
            dataIndex: "content",
            key: "content",
            render: (_, record) => (
                <LogsText type={record.type} user={record.doctor} item={record} />
            ),
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, ele) => (
                <Space size="middle">
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => { }}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                    <Popover destroyTooltipOnHide overlayClassName="log-info" content={<LogsInfo item={ele} />} title="">
                        <PrimaryButton icon={<Info />} htmlType="button" />
                    </Popover>
                    <></>
                </Space>
            ),
        },
    ], [selectedItems, data]);

    return (
        <>
       <DesktopMode>
    <div className="container mt-2">
        <PageHeader
            right={<div
                className="d-flex col mb-3 justify-content-end">
                <Select defaultValue="" options={typeOptions} label={t("Users")} className="col-3 ml-2" onChange={(data) => filterChanged("search", { type: data })} />
                <DatePicker label={t("Start Date")} format={DateFormat.inputEuDate} className="col-2 ml-2" onChange={(data) => { filterChanged("search", { startdate: data || undefined }) }} />
                <DatePicker label={t("End Date")} format={DateFormat.inputEuDate} className="col-2 ml-2" onChange={(data) => { filterChanged("search", { enddate: data || undefined }) }} />
                <PrimaryButton
                    onClick={() => handleDownloadClick(selectedItems)}
                    disabled={selectedItems.length === 0}
                    type="primary"
                    className="ml-2"
                >
                    Download Selected
                </PrimaryButton>
            </div>}
            left={<h2 className="my-0">{t("Logs")}</h2>} />
        <AppTable
            data={data}
            columns={columns}
            rowKey="_id"
            pageChanged={pageChanged}
            currentPage={total.page}
            total={total.count}
            onRowClick={onRowClick}
            pageSize={filter.limit}
        />
    </div>
    </DesktopMode>
    <MobileMode>
    <div className="container mt-2">
        <PageHeader
            right={<div
                className="col-6 py-1">
                <Select defaultValue="" options={typeOptions} label={t("Users")} className="col-12 ml-2" onChange={(data) => filterChanged("search", { type: data })} />
            </div>}
            left={<h2 className="my-0">{t("Logs")}</h2>} />
            <div className="col-12 d-flex py-1">
            <DatePicker label={t("Start Date")} format={DateFormat.inputEuDate} className="col-6 mr-2" onChange={(data) => { filterChanged("search", { startdate: data || undefined }) }} />
                <DatePicker label={t("End Date")} format={DateFormat.inputEuDate} className="col-6 mr-2" onChange={(data) => { filterChanged("search", { enddate: data || undefined }) }} />
                </div>
                <div className="d-flex col-12 justify-content-end mt-2">
                <PrimaryButton
                    onClick={() => handleDownloadClick(selectedItems)}
                    disabled={selectedItems.length === 0}
                    type="primary"
                    className=""
                >
                    Download Selected
                </PrimaryButton>
                </div>
                <div className="mt-3 overflow-auto">
        <AppTable
            data={data}
            columns={columns}
            rowKey="_id"
            pageChanged={pageChanged}
            currentPage={total.page}
            total={total.count}
            onRowClick={onRowClick}
            pageSize={filter.limit}
        />
    </div>
    </div>
    </MobileMode>
    </>
    )
}