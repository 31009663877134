const EditableValue = ({ edit, children }) => {
    const style = {
        background: edit ? 'white' : 'rgba(255,255,0,0.3)',
    }
    return (
        <span
            contentEditable
            sandbox="allow-same-origin"
            suppressContentEditableWarning
            style={style}
        >
            {children}
        </span>
    )
}

export default EditableValue
