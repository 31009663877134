import { useEffect ,useState } from "react"
import { CommonConstant, PatientLaboratoryService } from "utility"

export const GetPatientLaboratory = (id,type) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
        type,
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await PatientLaboratoryService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter,type])

    const filterChanged = (key, value) => {
        console.log("🚀 ~ file: patient-laboratory.js ~ line 47 ~ filterChanged ~ key, value", key, value)
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
            fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filterChanged,
        filter,
        total,
        pageChanged,
    }
}
