const basePath = process.env.REACT_APP_API_PATH
const swissAdressBasePath = process.env.REACT_APP_SWISS_ADDRESS_API_ENDPOINT
export const APIPath = {
    server: basePath,
    swissAdressServer: swissAdressBasePath,
    // auth
    login: `auth/login`,
    register: 'auth/register',
    me: 'auth/me',
    // add-get-patient
    patients: 'patients',
    patientHistory: 'patient-history',

    appointment: 'appointment',
    nextAppontments: 'next-appointments',
    encounter: 'encounter',
    encounterByAppointment: 'encounter/appointment',
    encounterByPatient: 'encounter/patient',
    encounterLock: 'encounter/lock',
    encounterBookmark: 'encounter/bookmark',
    appointmentCategory: 'appointment-category',
    appointmentStatus: 'appointment-status',
    documentStatus: 'document-status',
    documentCategory: 'documents-category',
    diagnostic: 'diagnostic',

    getSignedURL: 'aws/requestUploadUrl',
    patientDocuments: 'patient-documents',
    patientDocumentsByPatient: 'patient-documents/by-patient',
    drug: 'drugs',
    snippets: 'snippets',
    patientDrugs: 'patient-drugs',
    StateRate: 'state-rate',
    invoiceCodes: 'invoice-codes',
    invoicesGroup: 'invoice-group',
    encounterInvoices: 'encounter-invoices',
    notes: 'notes',
    statistics: 'statistics',
    aboutUs: 'about-us',
    diagnostictype: 'diagnostic-type',
    medicalHistoryType: 'medical-history-type',
    medicalPatientHistory: 'medical-patient-history',
    vitalSignsTypes: 'vital-signs-types',
    uservitalsigns: 'user-vital-signs',
    userallergies: 'user-allergies',
    uservaccination: 'user-vaccination',
    colorCode: 'color-code',
    encounterFields: 'encounter-fields',
    defaultTimerDuration: 'default-timer-duration',
    metadata_address_type: 'meta-data/address-type',
    logs: 'logs',
    patientnotes: 'patient-notes',
    doctors: 'doctors',
    metadata: 'meta-data',
    timer: 'timer',
    allergies: 'allergies-types',
    questions: 'questions',
    questionAnswers: 'question-answers',
    vaccination: 'vaccination-types',
    insurance: 'insurance',
    patientInsurance: 'patient-insurance',
    calendar: 'calendar',
    patientlaboratory: 'patient-laboratory',
    contacts: 'contacts',

    HL7: 'hl7',
    parse: 'parse',
    // swiss adress apis
    zips: 'zips',
    streets: 'streets',
    houses: 'houses',
}
