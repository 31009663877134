import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import fr from 'date-fns/locale/fr'
import { ShadowBox } from 'elements'
import { GetAppointmentList } from 'hooks'
import {
    eventStyleGetter,
    EventBox,
    slotPropGetter,
    dayPropGetter,
} from 'page-components/appointments'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
import { DatePicker, Tag } from 'antd'
import { DateFormat, DateUtility } from 'utility'

const CalendarContainer = styled(ShadowBox)`
    max-height: 70vh;
    overflow-y: auto;
    position: relative;
    .rbc-event {
        border: 0 !important;
    }
    .section-transition {
        overflow: hidden;
        transition: height 0.3s ease-out;
        height: auto;
    }
    .event-style {
        margin-bottom: 2px;
        &:hover {
            opacity: 0.9 !important;
            z-index: 9 !important;
        }
    }
`
const locales = { fr }

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

export function CalendarMenuContent() {
    const navigate = useNavigate()
    const params = useParams()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { data } = GetAppointmentList()
    const [culture, setCulture] = useState(language)
    const { user } = useAuth()
    const [currentDate, setCurrentDate] = useState(new Date())

    const currentEvent = useMemo(() => {
        if (params.id) {
            return data.find((x) => x._id === params.id)
        }
        return {}
    }, [params, data])
    console.log('currentEvent', currentEvent)

    const messages = {
        week: t('calendar-properties.week'),
        work_week: t('calendar-properties.work_week'),
        day: t('calendar-properties.day'),
        month: t('calendar-properties.month'),
        previous: t('calendar-properties.previous'),
        next: t('calendar-properties.next'),
        today: t('calendar-properties.today'),
        agenda: t('calendar-properties.agenda'),
        showMore: (total) => `+${total} plus`,
    }

    const events = useMemo(() =>
        data.map((item) => ({
            title: `${item.patient?.firstName || ''} ${
                item.patient?.lastName || ''
            } ${
                (item?.patient?.firstName || item?.patient?.lastName) &&
                item?.reason_for_visit
                    ? ' - '
                    : ''
            }${item?.reason_for_visit}`,
            start: new Date(item.start_time),
            end: new Date(item.end_time),
            resource: item,
        })),
    )

    const appointmentStatus = useMemo(() => {
        const todayEvents = events.filter((ele) =>
            DateUtility.isToday(new Date(ele.resource.appointment_date)),
        )
        let status = {
            events: todayEvents.length,
            completed: 0,
            remained: todayEvents.length,
            percantage: 0,
        }
        if (todayEvents.length) {
            todayEvents.map((ele) => {
                if (ele?.resource?.status?.completed) {
                    status = {
                        ...status,
                        completed: status.completed + 1,
                        remained: status.remained - 1,
                    }
                }
                return ''
            })
        }
        status = {
            ...status,
            percantage: status.completed / (status.events || 1),
        }
        return status
    }, [events])

    useEffect(() => {
        setCulture(language)
    }, [language])

    // useEffect(() => {
    //     if (encounterData?.length === 0 && !loading) {
    //         createNewEncounter("consultation")
    //     }
    // }, [encounterData, loading])

    const { defaultDate, views, formats } = useMemo(
        () => ({
            defaultDate: new Date(),
            views: [Views.DAY],
            formats: {
                dateFormat: 'dd',
                dayFormat: (date, culture, localizer) =>
                    localizer.format(date, 'E d/MM', culture),
                dayRangeHeaderFormat: ({ start, end }, culture) =>
                    `${localizer.format(
                        start,
                        'd',
                        culture,
                    )}-${localizer.format(
                        end,
                        'd',
                        culture,
                    )} ${localizer.format(
                        start,
                        'MMM',
                        culture,
                    )} ${localizer.format(start, 'YYY', culture)}`,
                timeGutterFormat: (date, culture) =>
                    localizer.format(date, 'HH:mm', culture),
                eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
                    `${localizer.format(
                        start,
                        'HH:mm',
                        culture,
                    )} - ${localizer.format(end, 'HH:mm', culture)}`,
            },
        }),
        [],
    )

    const handleSelectEvent = (data) => {
        navigate(`/app/appointments/${data.resource._id}`)
    }
    const { components } = useMemo(
        () => ({
            components: {
                event: (props) => <EventBox {...props} />,
            },
        }),
        [data],
    )

    const slotStyleGetter = (date) => {
        return slotPropGetter(
            date,
            user.starttime,
            user.endtime,
            user.workingdays,
        )
    }

    const handleNavigate = useCallback((newDate) => {
        setCurrentDate(newDate)
    }, [])

    const CalendarTab = useMemo(() => {
        return (
            <CalendarContainer>
                <div className="mb-3 col-12">
                    <DatePicker
                        onChange={(value) => setCurrentDate(value.toDate())}
                        placeholder={t('select date')}
                        value={DateUtility.dayJSFormat(currentDate)}
                        format={DateFormat.inputEuDate}
                        className="col-6"
                    />
                    <span className="col-6 ml-2">
                        <Tag className="p-1 f-16">
                            {appointmentStatus.completed}/
                            {appointmentStatus.events} -{' '}
                            {appointmentStatus.percantage * 100}% completed
                        </Tag>
                    </span>
                </div>
                <Calendar
                    localizer={localizer}
                    defaultDate={defaultDate}
                    date={currentDate}
                    onNavigate={handleNavigate}
                    defaultView={Views.DAY}
                    views={views}
                    events={events}
                    onSelectEvent={handleSelectEvent}
                    eventPropGetter={(e) => eventStyleGetter(e, events)}
                    dayPropGetter={(e) => dayPropGetter(e, true)}
                    components={components}
                    // scrollToTime={scrollToTime}
                    min={new Date(user.starttime)}
                    max={new Date(user.endtime)}
                    timeslots={1}
                    culture={culture}
                    selectable
                    messages={messages}
                    slotPropGetter={slotStyleGetter}
                    formats={formats}
                />
            </CalendarContainer>
        )
    }, [events, localizer])

    return (
        <div>
            <div className="row" style={{ height: '' }}>
                {CalendarTab}
            </div>
        </div>
    )
}
