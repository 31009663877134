import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class Vaccination extends CRUDService {
    constructor() {
        super(APIPath.vaccination)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.vaccination}/order-by`, data)
    }

    getByPatient(id,params) {
        const query = CommonUtility.objectToParams(params || {})
        return BaseService.get(`${APIPath.vaccination}/by-patient/${id}?${query}`)
    }
}

const VaccinationService = new Vaccination()
Object.freeze(VaccinationService)
export { VaccinationService }
