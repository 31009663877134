import React,{ useEffect } from 'react'
import {
    DangerButton,
    FormSelectionField,
    PrimaryButton,
    FormTextAreaFormField,
} from 'elements'
import { Form, Space, Popconfirm, Alert, Checkbox,Button,Modal } from 'antd'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppTable, FlexRow } from 'components'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next';
import { Trash } from 'phosphor-react'

const AddVitalSignsSchemaType = yup.object().shape({
    firstName: yup.string(),
})

const InvoicesSchema = yup.object().shape({
    category: yup.string().required('*Category is required'),
})

const ReasonSchema = yup.object().shape({
    reason_for_visit: yup.string().trim(),
})

export const AppontmentInvoicesTab = ({ onCodesSearch, removeAll, selectedCodes, codesList, addInvoices, removeInvoiceCodes }) => {
    const { t } = useTranslation()
    const {
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(InvoicesSchema),
    })

    const columns = [
        {
            title: t("invoice-codes-fields.service_code"),
            dataIndex: 'service_code',
            key: 'service_code',
            width: 150,
            render: (text) => <div>{text}</div>,
        },
        // {
        //     title: t("invoice-codes-fields.service_appellation"),
        //     dataIndex: 'service_appellation',
        //     key: 'service_appellation',
        //     render: (text) => <div>{text}</div>,
        // },
        {
            title: t("invoice-codes-fields.chapter_code"),
            dataIndex: 'chapter_code',
            key: 'chapter_code',
            render: (text) => <div>{text}</div>,
        },
        {
            title: t("invoice-codes-fields.qualitative_dignity_code"),
            dataIndex: 'qualitative_dignity_code',
            key: 'qualitative_dignity_code',
            render: (text) => <div>{text}</div>,
        },
        // {
        //     title: t("invoice-codes-fields.cost"),
        //     dataIndex: 'cost',
        //     key: 'cost',
        //     width: 200,
        //     render: (text) => <div>{text}</div>,
        // },
        {
            title: t("invoice-codes-fields.rate"),
            dataIndex: 'rate',
            key: 'rate',
            width: 100,
            render: (text) => <div>{text}</div>,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("code")}` })}
                        onConfirm={() => removeInvoiceCodes(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (<>
        <div>
            <FlexRow>
                <div className="col-12">
                    <FormSelectionField
                        control={control}
                        name="group"
                        required
                        showSearch
                        filterOption={false}
                        // label={`${t("search")} ${t("form-fields.invoice-codes")}`}
                        errors={errors.group}
                        placeholder={`${t("search")} ${t("form-fields.invoice-codes")}`}
                        options={codesList}
                        className="mb-0"
                        onSearch={onCodesSearch}
                        onSelect={addInvoices}
                    />
                </div>
            </FlexRow>
            <div className="my-3">
                <div className="text-end">
                    {!!selectedCodes.length && <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.category")}` })}
                        onConfirm={() => removeAll()}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton htmlType="button" className="mb-3">Delete All</DangerButton>
                    </Popconfirm>}
                </div>
                <AppTable
                    data={selectedCodes}
                    columns={columns}
                />
                {!selectedCodes.length && <Alert message="Invoices codes of appointments add when created a encounter" type="info" showIcon />}
            </div>
        </div>
    </>)
}

export const VitalSignTypeTabs = ({ list, filterChanged }) => {
    const {
        handleSubmit, control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AddVitalSignsSchemaType),
    })
    const submit = (formData) => {
    console.log("formData", formData)
    }
    return (
        <div className="col-12">
            <FlexRow className="col-12">
        <Form.Item className="col-10">
            <FormSelectionField
                control={control}
                name="vitalsign"
                required
                errors={errors.vitalsign}
                filterOption={false}
                placeholder={`${t("search")} ${t("form-fields.vital-signs-types")}`}
                className="mb-0"
                options={list}
                onSearch={(data) => { filterChanged("search",{ title: data }) }}
                mode="multiple"
                onSelect={() => {}}
            />
            </Form.Item>
            <PrimaryButton className="col-2  ml-2" onClick={handleSubmit(submit)}>
                {t("group")}
            </PrimaryButton>
        </FlexRow>
            <div className="my-3">
                <Alert message="Vital signe of apppintment add when created a encounter" type="info" showIcon />
            </div>
        </div>
    )
}

export function EditReasonPopup({ open, modalData, onClose }) {
    const { t } = useTranslation()
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ReasonSchema),
    })

    useEffect(() => {
        if (modalData) {
            reset({ ...modalData })
        }
    }, [modalData])

    return (
        <Modal
            title={t("add-edit.edit-reason")}
            open={open}
            confirmLoading
            footer={null}
            width={600}
            onCancel={() => onClose()}>
            <Form layout="vertical">
                <FormTextAreaFormField
                    control={control}
                    name="reason_for_visit"
                    label={t("form-fields.reason-for-visit")}
                    placeholder="Reason For Visit"
                    errors={errors?.reason_for_visit}
                    defaultValue=""
                    rows={5}
                    height={5}
                />
                <div className="d-flex justify-content-between">
                    <Space wrap>
                        <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                        <PrimaryButton key="submit" onClick={handleSubmit(onClose)}>{t("add-edit.edit-reason")}</PrimaryButton>
                    </Space>
                </div>
            </Form>
        </Modal>
    )
}

export const DeleteAppointmentModal = ({ onSelectAll, recurranceDelete }) => {
    return (<>
        <p className="my-0 pointer" onClick={() => onSelectAll()}>
            <Checkbox checked={recurranceDelete} className="me-2 pointer" onChange={() => onSelectAll()} />
            {t("message.remove-all-recurrance")}
        </p>
        <p className="my-0">{t("message.delete-message", { name: `${t("form-fields.encounter")}` })}</p>
    </>)
}