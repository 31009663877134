import { useEffect, useMemo, useState } from 'react'
import { AddressMetaDataService } from 'utility/services/address-type'

export const GetAddressTypeList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item.address_type_title,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>{item.address_type_title}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])
    // const optionList = useMemo(() => {
    //     return data.map((item) => ({
    //         value: item.address_type_title,
    //         label: item.address_type_title, // Display the address_type_title
    //     }));
    // }, [data]);

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await AddressMetaDataService.getAddressType()
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    },[])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        optionList,
    }
}