import {
    Button,
    Checkbox,
    Form,
    Input,
    Modal,
    Popconfirm,
    Select,
    Space,
    notification,
} from 'antd'
import { useAuth } from 'context'
import {
    PrimaryButton,
    FormTextFormField,
    FormCheckBoxField,
    FormDateField,
    FormSelectionWithAdd,
    DangerButton,
    FormRangeSliderField,
    FormNumberEventField,
} from 'elements'
import { Trash, Pencil } from 'phosphor-react'
import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
    ColumnSearchType,
    DateFormat,
    DateUtility,
    DayRangeOptions,
    DrugFilterOptions,
    PatientDrugsService,
} from 'utility'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppTable, FlexBox } from 'components'
import { Prescription } from './prescription'
import styled from 'styled-components'
// import { savePDF } from '@progress/kendo-react-pdf';

const PrescriptionBox = styled.div`
    position: absolute;
    z-index: -9;
    top: 0;
`

const DeleteEncounterDrugs = ({
    list,
    selectedList,
    onDrugSelect,
    lastmedication,
}) => {
    const { t } = useTranslation()
    return (
        <div>
            {t('message.delete-message', { name: `${t('encounter')}` })}
            {lastmedication && (
                <p onClick={() => onDrugSelect('all')}>
                    <Checkbox
                        checked={selectedList.includes('all')}
                        disabled={false}
                        className="me-2"
                    />
                    All Drugs
                </p>
            )}
            {list.map((ele) => (
                <p onClick={() => onDrugSelect(ele._id)}>
                    <Checkbox
                        checked={selectedList.includes(ele._id)}
                        disabled={false}
                        className="me-2"
                    />
                    {ele.drug.name}
                </p>
            ))}
        </div>
    )
}

const SelectEncounterDrugs = ({ list, selectedList, onDrugSelect }) => {
    const { t } = useTranslation()
    const [search, setSearch] = useState('')
    const searchList = useMemo(() => {
        return list.filter((ele) =>
            ele.drug.name
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()),
        )
    }, [search, list])
    return (
        <div style={{ height: '300px', width: '400px', overflow: 'auto' }}>
            {t('message.delete-message', { name: `${t('encounter')}` })}
            <Input.Search
                className="col-11"
                value={search}
                onChange={(data) => setSearch(data.target.value)}
            />
            {searchList.map((ele) => (
                <p className="pointer" onClick={() => onDrugSelect(ele)}>
                    <Checkbox
                        checked={selectedList.find(
                            (item) => item._id === ele._id,
                        )}
                        disabled={false}
                        className="me-2"
                    />
                    {ele.drug.name}
                </p>
            ))}
        </div>
    )
}

const PatientUsedDrugList = ({ list, onEdit, removeDrugs, disabled }) => {
    const { t } = useTranslation()
    const columns = [
        {
            title: t('form-fields.drug'),
            dataIndex: 'drug',
            key: 'drug',
            render: (text) => <p>{text?.name}</p>,
        },
        {
            title: t('form-fields.dosage'),
            dataIndex: 'dosage',
            key: 'dosage',
            render: (text) => <p>{text}</p>,
        },
        {
            title: t('form-fields.unit'),
            dataIndex: 'unit',
            key: 'unit',
            render: (text) => <p>{text}</p>,
        },
        {
            title: t('form-fields.startdate'),
            dataIndex: 'startdate',
            key: 'startdate',
            type: ColumnSearchType.Date,
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
        },
        {
            title: t('form-fields.enddate'),
            dataIndex: 'enddate',
            key: 'enddate',
            type: ColumnSearchType.Date,
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
        },
        {
            title: t('form-fields.duration'),
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: <>
                {t('short-name.morning')} - {t('short-name.noon')} - {t('short-name.evening')} - {t('short-name.night')}</>,
            dataIndex: 'morning',
            key: 'morning',
            width: 200,
            render: (_, data) => <p className="row">
                <div className="col-1">{data.morning}</div>
                <div className="col-1">-</div>
                <div className="col-1">{data.noon}</div>
                <div className="col-1">-</div>
                <div className="col-1">{data.evening}</div>
                <div className="col-1">-</div>
                <div className="col-1">{data.night}</div>
            </p>,

        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <div className="d-flex" size="middle">
                    <div className="me-3" onClick={() => onEdit(data)}>
                        <PrimaryButton icon={<Pencil />} />
                    </div>
                    <Popconfirm
                        title={t('delete')}
                        description={() => {
                            return (
                                <p>
                                    {t('message.delete-message', {
                                        name: `${t('diagnostic')}`,
                                    })}
                                </p>
                            )
                        }}
                        onConfirm={() => removeDrugs(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} />
                    </Popconfirm>
                </div>
            ),
        },
    ]
    const onRowDoubleClick = (data) => {
        onEdit(data)
    }
    const expandedRowRender = (data) => {
        const columns = [
            {
                title: t('form-fields.method'),
                dataIndex: 'method',
                key: 'method',
                render: (text) => <p>{text}</p>,
            },
            {
                title: t('form-fields.byday'),
                dataIndex: 'byday',
                key: 'byday',
                render: (text) => <p>{text}</p>,
            },
            {
                title: t('form-fields.price'),
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: t('form-fields.active-substance'),
                dataIndex: 'active_substance',
                key: 'active_substance',
            },
            {
                title: t('form-fields.comments'),
                dataIndex: 'comments',
                key: 'comments',
                render: (text) => <p>{text}</p>,
            },
            {
                title: t('form-fields.interactions'),
                dataIndex: 'interactions',
                key: 'interactions',
            },
        ];
        return <AppTable
            data={[data]}
            columns={columns}
            keyField="_id"
            size="small"
            onRowDoubleClick={onRowDoubleClick}
            disabled={disabled}
        />;
    };
    return (
        <>
            <AppTable
                data={list}
                columns={columns}
                keyField="_id"
                size="small"
                onRowDoubleClick={onRowDoubleClick}
                disabled={disabled}
                expandedRowRender={expandedRowRender}
            />
        </>
    )
}

const AddDrugSchema = yup.object().shape({
    drug: yup.string().required('*drug is required'),
    startdate: yup.string().required('*startdate is required').nullable(),
    enddate: yup.string().nullable(),
    renewal: yup.boolean(),
    reserve: yup.boolean(),
    unknown_enddate: yup.boolean(),
    active_substance: yup.string().trim().nullable(),
    interactions: yup.string().nullable(),
    duration: yup.string().trim().nullable(),
    morning: yup.number().nullable(),
    noon: yup.number().nullable(),
    evening: yup.number().nullable(),
    night: yup.number().nullable(),
    price: yup.number().nullable(),
    nbeo: yup.string().trim().nullable(),
    comments: yup.string().trim().nullable(),
})

export const MedicationAddEdit = ({
    patient,
    disabled,
    item,
    // setReadable,
    readable,
    onDelete,
    drugOptions,
    onDrugAdd,
    drugData,
    patientDrugData,
    setPatientDrugData,
    patientDrugDataFilterChanged,
    lastmedication, readMode,
}) => {
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AddDrugSchema),
        defaultValues: {
            night: 0,
            noon: 0,
            morning: 0,
            evening: 0,
            startdate: DateUtility.dayJSFormat(new Date()),
            duration: 0,
        },
    })
    const { t } = useTranslation()
    const { user } = useAuth()
    const [editMode, setEditMode] = useState(false)
    const [selecteDrug, setSelectDrug] = useState([])
    const [open, setOpen] = useState(false)
    const [prescreptionDrug, setPrescreptionDrug] = useState([])
    const startdate = watch('startdate')
    const duration = watch('duration')
    const drug = watch('drug')
    const unknownenddate = watch('unknown_enddate')
    const componentRef = useRef()

    const onDrugSelect = (id) => {
        if (selecteDrug.includes(id)) {
            setSelectDrug(selecteDrug.filter((ele) => ele !== id))
        } else {
            setSelectDrug([...selecteDrug, id])
        }
    }

    const onPrescreptionDrugSelect = (data) => {
        if (prescreptionDrug.find((ele) => ele._id === data._id)) {
            setPrescreptionDrug(
                prescreptionDrug.filter((ele) => ele._id !== data._id),
            )
        } else {
            setPrescreptionDrug([...prescreptionDrug, data])
        }
    }

    useEffect(() => {
        if (Number(duration)) {
            setValue(
                'enddate',
                DateUtility.dayJSFormat(
                    DateUtility.addDay(new Date(startdate), duration),
                ),
            )
        }
    }, [duration])

    useEffect(() => {
        if (unknownenddate) {
            setValue('enddate', null)
        }
    }, [unknownenddate])

    useEffect(() => {
        const newdrugData = drugData.find((ele) => ele._id === drug)
        if (!editMode && drug && drugData) {
            reset({
                ...newdrugData,
                startdate: DateUtility.dayJSFormat(new Date()),
                enddate: null,
                drug: newdrugData._id,
            })
        }
    }, [drug])

    const onEdit = (data) => {
        reset({
            ...data,
            drug: data.drug._id,
            startdate: DateUtility.dayJSFormat(data.startdate),
            enddate: data.enddate
                ? DateUtility.dayJSFormat(data.enddate)
                : null,
        })
        setOpen(true)
        setEditMode(true)
    }

    const onClose = () => {
        setOpen(false)
    }

    const removeDrugs = async (id) => {
        setPatientDrugData(patientDrugData.filter((ele) => ele._id !== id))
        await PatientDrugsService.remove(id)
    }

    const onDrugAddToPatient = async (formData) => {
        if (!patient?._id) {
            notification.error({
                message: t('notif-message.patient-not-found'),
            })
            return
        }
        const reqData = {
            ...formData,
            patient: patient?._id,
            encounter: item._id,
            doctor: user._id,
            drug: formData.drug,
            _id: undefined,
        }
        let tableList = [...patientDrugData]
        if (formData.drug && formData._id && editMode) {
            try {
                await PatientDrugsService.update(formData._id, reqData)
                tableList = tableList.map((ele) => {
                    if (ele._id === formData._id) {
                        return {
                            ...ele,
                            ...formData,
                            drug: drugData.find(
                                (item) => item._id === formData.drug,
                            ),
                        }
                    }
                    return ele
                })
                notification.success({
                    message: t('notif-message.drug-updated'),
                })
            } catch {
                notification.error({
                    message: t('notif-message.default-error'),
                })
            }
        } else {
            try {
                const newData = await PatientDrugsService.add(reqData)
                tableList.push({
                    ...newData,
                    patient: patient?._id,
                    doctor: user._id,
                    encounter: item._id,
                    drug: drugData.find((item) => item._id === formData.drug),
                })
                notification.success({
                    message: t('notif-message.drug-added'),
                })
            } catch {
                notification.error({
                    message: t('notif-message.default-error'),
                })
            }
        }
        setPatientDrugData(tableList)
        reset({ startdate: DateUtility.dayJSFormat(new Date()) })
        setEditMode(false)
        onClose()
    }

    const encounterDrugList = useMemo(() => {
        return patientDrugData.filter((ele) => ele.encounter === item._id)
    }, [patientDrugData, item])

    const pdf = () => {
        // savePDF(componentRef.current, {
        //     fileName: 'form.pdf',
        //     paperSize: "A4",
        //     margin: "0.5cm",
        //     scale: 0.6,
        // });
    }

    return (
        <>
            <Form.Item className="mb-0" name="metadata">
                {/* <Form layout="vertical" className="row"> */}
                <div className="12">
                    <Form.Item>
                        <FlexBox className="justify-content-end mb-3">
                            <Select
                                options={DrugFilterOptions}
                                disabled={readMode}
                                defaultValue={DrugFilterOptions[0]}
                                onChange={(value) =>
                                    patientDrugDataFilterChanged('search', {
                                        startdate: value,
                                    })}
                                style={{ width: 160 }}
                            />
                            <PrimaryButton
                                className="ml-2"
                                disabled={readMode}
                                onClick={() => setOpen(true)}
                            >
                                {t('add-edit.add-drug')}
                            </PrimaryButton>
                        </FlexBox>
                        <PatientUsedDrugList
                            list={patientDrugData}
                            onEdit={onEdit}
                            removeDrugs={removeDrugs}
                            disabled={disabled}
                        />
                    </Form.Item>
                </div>
                <Modal
                    title={
                        editMode
                            ? t('add-edit.edit-drug')
                            : t('add-edit.add-drug')
                    }
                    open={open}
                    footer={null}
                    confirmLoading
                    width={600}
                    disabled={readMode}
                    onCancel={() => onClose()}
                >
                    <Form layout="vertical" className="row">
                        <div className="row">
                            {/* <div className="col-4"> */}
                            <div className="row">
                                <div className="col-12">
                                    <FormSelectionWithAdd
                                        control={control}
                                        name="drug"
                                        searchfield="name"
                                        disabled={disabled}
                                        errors={errors?.drug}
                                        label={t('form-fields.drug')}
                                        options={drugOptions}
                                        showSearch
                                        onAdd={onDrugAdd}
                                        placeholder={`${t('search')} ${t(
                                            'form-fields.drug',
                                        )}`}
                                    />
                                </div>
                                <div className="col-4">
                                    <FormTextFormField
                                        control={control}
                                        name="dosage"
                                        disabled={readMode}
                                        label={`${t('form-fields.dosage')}`}
                                        placeholder="dosage"
                                        type="number"
                                        errors={errors?.dosage}
                                    />
                                </div>
                                <div className="col-4">
                                    <FormTextFormField
                                        control={control}
                                        name="unit"
                                        label={`${t('form-fields.unit')}`}
                                        placeholder="unit"
                                        errors={errors?.unit}
                                    />
                                </div>
                                <div className="col-4">
                                    <FormTextFormField
                                        control={control}
                                        name="byday"
                                        label={`${t('form-fields.byday')}`}
                                        placeholder="byday"
                                        type="number"
                                        errors={errors?.byday}
                                        defaultValue={0}
                                    />
                                </div>
                                <div className="col">
                                    <FormNumberEventField
                                        control={control}
                                        name="morning"
                                        label={`${t(
                                            'form-fields.morning',
                                        )}`}
                                        placeholder="morning"
                                        errors={errors?.morning}
                                        defaultValue=""
                                    />
                                </div>
                                <div className="col">
                                    <FormNumberEventField
                                        control={control}
                                        name="noon"
                                        label={`${t('form-fields.noon')}`}
                                        placeholder="noon"
                                        errors={errors?.noon}
                                        defaultValue=""
                                    />
                                </div>
                                <div className="col">
                                    <FormNumberEventField
                                        control={control}
                                        name="evening"
                                        label={`${t(
                                            'form-fields.evening',
                                        )}`}
                                        placeholder="evening"
                                        errors={errors?.evening}
                                        defaultValue=""
                                    />
                                </div>
                                <div className="col">
                                    <FormNumberEventField
                                        control={control}
                                        name="night"
                                        label={`${t('form-fields.night')}`}
                                        placeholder="night"
                                        errors={errors?.night}
                                        defaultValue=""
                                    />
                                </div>
                            </div>
                            {/* </div> */}
                            {/* <div className="row"> */}
                            <div className="col-4">
                                <FormTextFormField
                                    control={control}
                                    name="method"
                                    label={`${t('form-fields.method')}`}
                                    placeholder="method"
                                    errors={errors?.method}
                                />
                            </div>
                            <div className="col-4">
                                <FormDateField
                                    control={control}
                                    name="startdate"
                                    label={t('form-fields.startdate')}
                                    placeholder={t('form-fields.startdate')}
                                    errors={errors?.startdate}
                                    defaultValue={DateUtility.dayJSFormat(new Date())}
                                />
                            </div>
                            <div className="col-4">
                                <FormDateField
                                    control={control}
                                    name="enddate"
                                    label={t('form-fields.enddate')}
                                    placeholder={t('form-fields.enddate')}
                                    allowClear
                                    errors={errors?.enddate}
                                    disabled={unknownenddate}
                                    defaultValue={null}
                                />
                            </div>
                            <div className="col-12">
                                <FormRangeSliderField
                                    control={control}
                                    name="duration"
                                    label={`${t('form-fields.duration')}`}
                                    placeholder="duration"
                                    errors={errors?.duration}
                                    marks={DayRangeOptions}
                                    defaultValue={0}
                                    disabled={disabled}
                                    step
                                    max={60}
                                />
                            </div>
                            <div className="col-4">
                                <FormCheckBoxField
                                    control={control}
                                    name="unknown_enddate"
                                    text={t('form-fields.unknown-end-date')}
                                    errors={errors?.unknown_enddate}
                                />
                            </div>
                            <div className="col-4">
                                <FormCheckBoxField
                                    control={control}
                                    name="reserve"
                                    text={t('form-fields.reserve')}
                                    errors={errors?.reserve}
                                />
                            </div>
                            <div className="col-4">
                                <FormCheckBoxField
                                    control={control}
                                    name="renewal"
                                    text={t('form-fields.renewal')}
                                    errors={errors?.renewal}
                                />
                            </div>
                            {/* <div className="col-3">
                            <FormTextFormField
                                control={control}
                                name="active_substance"
                                label={t("form-fields.active-substance")}
                                placeholder="Last Name"
                                errors={errors?.active_substance || ''}
                                required
                            />
                        </div> */}
                            <div className="col-6">
                                <FormTextFormField
                                    control={control}
                                    name="interactions"
                                    label={t('form-fields.interactions')}
                                    placeholder={t(
                                        'form-fields.interactions',
                                    )}
                                    disabled
                                    errors={errors?.interactions || ''}
                                    required
                                />
                            </div>
                            {/* <div className="col-3">
                            <FormTextFormField
                                control={control}
                                name="price"
                                label={t("form-fields.price")}
                                placeholder="Last Name"
                                errors={errors?.price || ''}
                                required
                                defaultValue={0}
                                type="number"
                            />
                        </div> */}
                            {/* <div className="col-3">
                            <FormTextFormField
                                control={control}
                                name="nbeo"
                                label={t("form-fields.nbeo")}
                                placeholder="Last Name"
                                errors={errors?.nbeo || ''}
                                required
                            />
                        </div> */}
                            <div className="col-6">
                                <FormTextFormField
                                    control={control}
                                    name="comments"
                                    label={t('form-fields.comments')}
                                    placeholder={t('form-fields.comments')}
                                    errors={errors?.comments || ''}
                                    required
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div />
                            <Space wrap>
                                <Button key="cancel" onClick={onClose}>
                                    {t('cancel')}
                                </Button>
                                <PrimaryButton
                                    key="submit"
                                    className=""
                                    onClick={handleSubmit(
                                        onDrugAddToPatient,
                                    )}
                                >
                                    {t('submit')}
                                </PrimaryButton>
                            </Space>
                        </div>
                    </Form>
                </Modal>
                {/* </Form> */}
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <Popconfirm
                            title={t('delete')}
                            description={
                                <DeleteEncounterDrugs
                                    lastmedication={lastmedication}
                                    list={encounterDrugList}
                                    selectedList={selecteDrug}
                                    onDrugSelect={onDrugSelect}
                                />
                            }
                            onConfirm={() => onDelete(item._id, selecteDrug)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton disabled={readMode} htmlType="button">
                                {t('delete')}
                            </DangerButton>
                        </Popconfirm>
                        {/* {!item.local && (
                            <PrimaryButton
                                disabled={!readable || readMode}
                                className="ms-2"
                                onClick={() => setReadable(!readable)}
                            >
                                {readable ? 'Edit' : 'Mark as Readable'}
                            </PrimaryButton>
                        )} */}
                    </div>
                    <span>
                        <>
                            <Popconfirm
                                title={t('add-edit.create-prescription')}
                                description={
                                    <SelectEncounterDrugs
                                        lastmedication={lastmedication}
                                        list={patientDrugData}
                                        selectedList={prescreptionDrug}
                                        onDrugSelect={onPrescreptionDrugSelect}
                                    />
                                }
                                onConfirm={() => pdf()}
                                disabled={readMode}
                                okButtonProps={{ disabled: false }}
                                cancelButtonProps={{ disabled: false }}
                            >
                                <PrimaryButton className="me-2">
                                    {t('add-edit.create-prescription')}
                                </PrimaryButton>
                            </Popconfirm>
                        </>
                        <PrimaryButton
                            htmlType="submit"
                            disabled={readable || readMode}>
                            {t('add-edit.save-changes')}
                        </PrimaryButton>
                    </span>
                </div>
            </Form.Item>
            <PrescriptionBox>
                <div ref={componentRef}>
                    {patient && (
                        <div ref={componentRef} id="divIdToPrint">
                            <Prescription
                                patient={patient}
                                list={prescreptionDrug}
                            />
                        </div>
                    )}
                </div>
            </PrescriptionBox>
        </>
    )
}
