import React from 'react'
import { ShadowBox } from 'elements'
import {
    Tooltip,
    Legend,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Bar,
    Line, LineChart, ResponsiveContainer,
} from 'recharts'
import { GetAgeByGenderRatio, GetAppointmentBYEncounterRatio, GetEncounterByAppointmentByAgeRatio, GetEncounterByAppointmentRatio } from 'hooks/statistics'
import { PageHeader } from 'components'
import { Divider } from 'antd'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { DesktopMode, MobileMode } from 'hooks'

export const EncounterByAppointmentChart = () => {
    const { t } = useTranslation()
    const { barData } = GetEncounterByAppointmentRatio(t)
    return (
        <>
        <DesktopMode>
    <ShadowBox className="mt-3">
        <PageHeader left={<h3 className="my-0">{t("encounter-by-appointment")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <BarChart width={870} height={400} data={barData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis dataKey="ratio" />
            <Tooltip />
            <Legend />
            <Bar dataKey="ratio" name={t("encounter/appointment")} fill="#82ca9d" />
        </BarChart>
        </ResponsiveContainer>
    </ShadowBox>
    </DesktopMode>
    <MobileMode>
    <ShadowBox className="mt-3 overflow-auto">
        <PageHeader left={<h3 className="my-0">{t("encounter-by-appointment")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <BarChart width={870} height={400} data={barData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis dataKey="ratio" />
            <Tooltip />
            <Legend />
            <Bar dataKey="ratio" name={t("encounter/appointment")} fill="#82ca9d" />
        </BarChart>
        </ResponsiveContainer>
    </ShadowBox>
    </MobileMode>
    </>
    )
}

export const AppointmentByEncounterChart = () => {
    const { t } = useTranslation()
    const { barData } = GetAppointmentBYEncounterRatio(t)
    return (
        <>
        <DesktopMode>
    <ShadowBox className="mt-3">
        <PageHeader left={<h3 className="my-0">{t("appointment-by-encounter")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <BarChart width={870} height={400} data={barData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis dataKey="ratio" />
            <Tooltip />
            <Legend />
            <Bar dataKey="ratio" name={t("appointment/encounter")} fill="#82ca9d" />
        </BarChart>
        </ResponsiveContainer>
    </ShadowBox>
    </DesktopMode>
    <MobileMode>
    <ShadowBox className="mt-3 overflow-auto">
        <PageHeader left={<h3 className="my-0">{t("appointment-by-encounter")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <BarChart width={870} height={400} data={barData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis dataKey="ratio" />
            <Tooltip />
            <Legend />
            <Bar dataKey="ratio" name={t("appointment/encounter")} fill="#82ca9d" />
        </BarChart>
        </ResponsiveContainer>
        </ShadowBox>
    </MobileMode>
    </>
    )
}

export const PatientAgeByGenderChart = () => {
    const { data } = GetAgeByGenderRatio()
    return (
        <>
        <DesktopMode>
    <ShadowBox className="mt-3">
        <PageHeader left={<h3 className="my-0">{t("gender-by-age")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="ageRange" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" name={t("female")} dataKey="femaleRatio" stroke="#8884d8" />
            <Line type="monotone" name={t("male")} dataKey="maleRatio" stroke="#82ca9d" />
            <Line type="monotone" name={t("other")} dataKey="otherRatio" stroke="#324afd" />
        </LineChart>
        </ResponsiveContainer>
    </ShadowBox>
    </DesktopMode>
    <MobileMode>
    <ShadowBox className="mt-3 overflow-auto">
        <PageHeader left={<h3 className="my-0">{t("gender-by-age")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="ageRange" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" name={t("female")} dataKey="femaleRatio" stroke="#8884d8" />
            <Line type="monotone" name={t("male")} dataKey="maleRatio" stroke="#82ca9d" />
            <Line type="monotone" name={t("other")} dataKey="otherRatio" stroke="#324afd" />
        </LineChart>
        </ResponsiveContainer>
    </ShadowBox>
    </MobileMode>
    </>
    )
}

export const EncounterByAppointmentByAgeChart = () => {
    const { data } = GetEncounterByAppointmentByAgeRatio()
    return (
        <>
        <DesktopMode>
    <ShadowBox className="mt-3">
        <PageHeader left={<h3 className="my-0">{t("encounter/appointment-by-age")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="range" />
            <YAxis dataKey="ratio" />
            <Tooltip />
            <Legend />
            <Line type="monotone" name={t("ratio")} dataKey="ratio" stroke="#000000" />
            <Line type="monotone" name={t("female")} dataKey="femaleRatio" stroke="#8884d8" />
            <Line type="monotone" name={t("male")} dataKey="maleRatio" stroke="#82ca9d" />
            <Line type="monotone" name={t("other")} dataKey="otherRatio" stroke="#324afd" />
        </LineChart>
        </ResponsiveContainer>
    </ShadowBox>
    </DesktopMode>
    <MobileMode>
    <ShadowBox className="mt-3 overflow-auto">
        <PageHeader left={<h3 className="my-0">{t("encounter/appointment-by-age")}</h3>} />
        <Divider />
        <ResponsiveContainer width="100%" height={400}>
        <LineChart width={730} height={250} data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="range" />
            <YAxis dataKey="ratio" />
            <Tooltip />
            <Legend />
            <Line type="monotone" name={t("ratio")} dataKey="ratio" stroke="#000000" />
            <Line type="monotone" name={t("female")} dataKey="femaleRatio" stroke="#8884d8" />
            <Line type="monotone" name={t("male")} dataKey="maleRatio" stroke="#82ca9d" />
            <Line type="monotone" name={t("other")} dataKey="otherRatio" stroke="#324afd" />
        </LineChart>
        </ResponsiveContainer>
    </ShadowBox>
    </MobileMode>
    </>
    )
}