import { Encounters } from 'page-components/encounters'
import styled from 'styled-components'
import { MedicalHistoryTab } from './user-medical-history'
import { DiagnosticTab } from './user-diagnostic'
import { VitalSignTab } from './user-vital-sign'
import {
    AppointmentDetailsPageSettings,
    CommonUtility,
    DateFormat,
    DateUtility,
} from 'utility'
import { Tooltip } from 'antd'
import {
    FlexRowBetween,
    LoaderBar,
    MyResponsiveGrid,
    TimeTracker,
} from 'components'
import { Pencil } from '@phosphor-icons/react'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { Eye, EyeSlash } from 'phosphor-react'
import { useAuth } from 'context'
import { QuestionsAnswerTab } from './user-question-answer'
import { UserAllergiesTab } from './user-allergies'
import { UserVaccinationTab } from './user-vaccination'

const DetailsHeader = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 100%;
    h3 {
        // color: #1677ff;
        // text-shadow: 0 0 0.25px currentcolor;
    }
    .seprator {
        left: ${({ menu }) => (menu ? '0' : '10')}px;
        width: ${({ menu }) => (menu ? '58' : '115')}px;
        bottom: 0;
        position: absolute;
        background: #1677ff;
        height: 2px;
    }
`

const Container = styled.div`
    .timeline {
        .ant-timeline {
            width: 120px;
        }
        .ant-timeline-item-content {
            text-align: start;
        }
        .ant-timeline-item {
            height: 56px !important;
            width: 170px;
        }
    }
`

export const EncounterCard = ({
    appointment,
    quickEntryList,
    dropDocument,
    diagnosticOptions,
    colors,
    loading,
    readMode = false,
    patient,
    encounterPage,
}) => (
    <Container className="px-3">
        {/* <HiddenFileUpload onNewFile={onNewFile}> */}
        <Encounters
            patient={patient}
            quickEntryList={quickEntryList}
            dropDocument={dropDocument}
            diagnosticOptions={diagnosticOptions}
            colors={colors}
            appointment={appointment}
            appointmentLoading={loading}
            readMode={readMode}
            encounterPage={encounterPage}
        />
        {/* </HiddenFileUpload> */}
    </Container>
)

export const TimerCard = ({
    onTimerPageChange,
    totalTimerData,
    timerLoading,
    pastTimerList,
    todayTimer,
    appointment,
    updateTimer,
    patient,
    maxTimer,
}) => (
    <TimeTracker
        list={pastTimerList}
        todayTimer={todayTimer}
        appointment={appointment}
        updateTimer={updateTimer}
        loading={timerLoading}
        patient={appointment?.patient || patient}
        total={totalTimerData}
        onPageChange={onTimerPageChange}
        maxTimer={maxTimer}
    />
)

export const MedicalHistoryCard = ({
    patient,
    appointment,
    medicalPatientHistoryList,
    setMedicalPatientHistoryData,
    medicalPatientHistoryRefetch,
    medicalHistoryTypeList,
    medicalHistoryTypeListFilterChanged,
    refetchMedicalHistoryType,
}) => (
    <MedicalHistoryTab
        list={medicalPatientHistoryList}
        typeList={medicalHistoryTypeList}
        setData={setMedicalPatientHistoryData}
        refetch={medicalPatientHistoryRefetch}
        appointment={appointment}
        refetchMedicalHistoryType={refetchMedicalHistoryType}
        patient={appointment?.patient || patient}
        medicalHistoryTypeListFilterChanged={
            medicalHistoryTypeListFilterChanged
        }
    />
)

export const VitalSignCard = ({
    patient,
    appointment,
    vitalsigntypeOptions,
    localVitalSign,
    setLocalVitalSign,
    vitalSignfilterChanged,
    refetcVitalSignType,
}) => (
    <>
        <VitalSignTab
            optionList={vitalsigntypeOptions}
            appointment={appointment}
            patient={appointment?.patient || patient}
            setLocalVitalSign={setLocalVitalSign}
            localVitalSign={localVitalSign}
            vitalSignfilterChanged={vitalSignfilterChanged}
            refetcVitalSignType={refetcVitalSignType}
        />
    </>
)
export const AllergiesCard = ({
    patient,
    appointment,
    allergiesOptions,
    allergiesfilterChanged,
    refetchAllergiesType,
    encounterPage,
}) => (
    <>
        <UserAllergiesTab
            allergiesOptions={allergiesOptions}
            appointment={appointment}
            patient={appointment?.patient || patient}
            allergiesfilterChanged={allergiesfilterChanged}
            refetchAllergiesType={refetchAllergiesType}
            encounterPage={encounterPage}
        />
    </>
)

export const VaccinationCard = ({
    patient,
    appointment,
    vaccinationOptions,
    vaccinationfilterChanged,
    refetchVaccinationType,
    encounterPage,
}) => (
    <>
        <UserVaccinationTab
            vaccinationOptions={vaccinationOptions}
            appointment={appointment}
            patient={appointment?.patient || patient}
            vaccinationfilterChanged={vaccinationfilterChanged}
            refetchVaccinationType={refetchVaccinationType}
            encounterPage={encounterPage}
        />
    </>
)

export const QuestionsCard = ({
    patient,
    appointment,
    questionFilterChanged,
    setQuestionAnswersList,
    questionAnswersList,
    questionOptions,
}) => (
    <>
        <QuestionsAnswerTab
            appointment={appointment}
            patient={appointment?.patient || patient}
            questionFilterChanged={questionFilterChanged}
            questionAnswersList={questionAnswersList || []}
            setQuestionAnswersList={setQuestionAnswersList}
            questionOptions={questionOptions}
        />
    </>
)

export const DignosticCard = ({
    diagnosticList,
    dignosticTypeList,
    setDignosticData,
    diagnosticRefetch,
    appointment,
    patient,
}) => (
    <>
        <DiagnosticTab
            list={diagnosticList}
            optionList={dignosticTypeList}
            setData={setDignosticData}
            refetch={diagnosticRefetch}
            appointment={appointment}
            patient={appointment?.patient || patient}
        />
    </>
)

export const QuestionAnswerCard = ({
    appointment,
    patient,
    questionOptions,
    questionFilterChanged,
    categoryOptionList,
    questionAnswersList,
    encounterPage,
}) => (
    <>
        <QuestionsAnswerTab
            appointment={appointment}
            patient={appointment?.patient || patient}
            questionOptions={questionOptions}
            questionFilterChanged={questionFilterChanged}
            categoryOptionList={categoryOptionList}
            questionAnswersList={questionAnswersList}
            encounterPage={encounterPage}
        />
    </>
)

export const AonymPatient = ({
    anonym,
    appointment,
    language,
    patient,
    anonymString,
}) => (
    <div>
        {anonym ? (
            <div>{anonymString}</div>
        ) : (
            <div>
                <div>
                    {patient?.firstName} {patient?.lastName}
                </div>
                {DateUtility.dateToString(
                    patient?.birthdate,
                    DateFormat.euDate,
                )}
                {appointment?.patient?.birthdate && (
                    <span className="text-muted">
                        &nbsp;(
                        {DateUtility.getDistanceInWord(
                            new Date(patient?.birthdate),
                            language,
                        )}
                        )
                    </span>
                )}
            </div>
        )}
    </div>
)

export const PatientHeaderCard = ({
    loading,
    appointment,
    setOpenReasonModal,
    language,
    patient,
    encounterPage,
}) => {
    const { setPatient } = useAuth()
    const { t } = useTranslation()
    const [anonym, setaAnonym] = useState(false)
    const anonymString =
        CommonUtility.getInitials(patient?.firstName, patient?.lastName, '.') +
        DateUtility.dateToString(patient?.birthdate, DateFormat.euDate)
    useEffect(() => {
        setPatient({ ...patient, anonym, anonymName: anonymString })
    }, [anonym])
    return (
        <>
            <DetailsHeader className="mb-2">
                <FlexRowBetween className="col-12 my-0">
                    <h3 className="my-0">{t('details')} </h3>
                    {anonym ? (
                        <Eye size={20} onClick={() => setaAnonym(!anonym)} />
                    ) : (
                        <EyeSlash
                            size={20}
                            onClick={() => setaAnonym(!anonym)}
                        />
                    )}
                </FlexRowBetween>
            </DetailsHeader>
            <>
                {loading && <LoaderBar />}
                <div className="row">
                    <div className="col-4">
                        <b>{t('patient-info')}</b>
                        <AonymPatient
                            patient={appointment?.patient || patient}
                            anonymString={anonymString}
                            language={language}
                            anonym={anonym}
                            encounterPage={encounterPage}
                        />
                    </div>
                    {!encounterPage && (
                        <>
                            <div className="col-4">
                                <b>{t('form-fields.appointment-date')}</b>
                                <div>
                                    {DateUtility.dateToString(
                                        appointment?.appointment_date,
                                        DateFormat.euDate,
                                    )}
                                </div>
                                <div>
                                    {DateUtility.dateToString(
                                        appointment?.start_time,
                                        DateFormat.fulltime,
                                    )}
                                    -
                                    {DateUtility.dateToString(
                                        appointment?.end_time,
                                        DateFormat.fulltime,
                                    )}
                                </div>
                            </div>
                            <div className="col-4">
                                <b>{t('form-fields.reason-for-visit')}</b>
                                <div className="d-flex">
                                    {appointment?.reason_for_visit}
                                    <Tooltip
                                        placement="bottomLeft"
                                        title={t('add-edit.edit-reason')}
                                    >
                                        <Pencil
                                            className="mx-2"
                                            size={15}
                                            onClick={() => {
                                                setOpenReasonModal(appointment)
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>
        </>
    )
}

export const AppointmentDetailsCards = ({
    loading,
    setMedicalPatientHistoryData,
    medicalPatientHistoryRefetch,
    medicalPatientHistoryList,
    colors,
    diagnosticOptions = [],
    dropDocument,
    quickEntryList = [],
    appointment,
    diagnosticList = [],
    dignosticTypeList = [],
    setDignosticData,
    language,
    vitalsigntypeOptions = [],
    diagnosticRefetch,
    setOpenReasonModal,
    timerLoading,
    totalTimerData,
    onTimerPageChange,
    patient,
    updateTimer,
    todayTimer,
    refetchAllergiesType,
    pastTimerList = [],
    encounterPage,
    refetchVaccinationType,
    maxTimer,
    vitalSignfilterChanged,
    refetcVitalSignType,
    vaccinationOptions = [],
    vaccinationfilterChanged,
    userquestionList = [],
    questionOptions = [],
    questionFilterChanged,
    categoryOptionList = [],
    questionAnswersList = [],
    allergiesfilterChanged,
    allergiesOptions = [],
    medicalHistoryFilterChanged,
    refetchMedicalHistoryType,
    medicalHistoryTypeListFilterChanged,
    medicalHistoryTypeList = [],
    onViewChange,
    selectedScreens = [],
    localVitalSign,
    setLocalVitalSign,
}) => {
    const componentsItems = useMemo(
        () => [
            {
                id: '0',
                w: 6,
                key: AppointmentDetailsPageSettings.Patient,
                compo: (
                    <PatientHeaderCard
                        setOpenReasonModal={setOpenReasonModal}
                        appointment={appointment}
                        loading={loading}
                        language={language}
                        encounterPage={encounterPage}
                        patient={patient}
                    />
                ),
            },
            {
                id: '1',
                w: 6,
                key: AppointmentDetailsPageSettings.Timer,
                compo: (
                    <TimerCard
                        patient={patient}
                        updateTimer={updateTimer}
                        appointment={appointment}
                        todayTimer={todayTimer}
                        pastTimerList={pastTimerList}
                        timerLoading={timerLoading}
                        totalTimerData={totalTimerData}
                        onTimerPageChange={onTimerPageChange}
                        maxTimer={maxTimer}
                    />
                ),
            },
            {
                id: '2',
                w: 6,
                key: AppointmentDetailsPageSettings.VitalSign,
                compo: (
                    <VitalSignCard
                        patient={patient}
                        appointment={appointment}
                        vitalsigntypeOptions={vitalsigntypeOptions}
                        vitalSignfilterChanged={vitalSignfilterChanged}
                        refetcVitalSignType={refetcVitalSignType}
                        localVitalSign={localVitalSign}
                        setLocalVitalSign={setLocalVitalSign}
                    />
                ),
            },
            {
                id: '3',
                w: 6,
                key: AppointmentDetailsPageSettings.Diagnostic,
                compo: (
                    <DignosticCard
                        patient={patient}
                        appointment={appointment}
                        diagnosticRefetch={diagnosticRefetch}
                        diagnosticList={diagnosticList}
                        dignosticTypeList={dignosticTypeList}
                        setDignosticData={setDignosticData}
                    />
                ),
            },
            {
                id: '4',
                w: 6,
                key: AppointmentDetailsPageSettings.MedicalHistory,
                compo: (
                    <MedicalHistoryCard
                        setMedicalPatientHistoryData={
                            setMedicalPatientHistoryData
                        }
                        medicalPatientHistoryRefetch={
                            medicalPatientHistoryRefetch
                        }
                        medicalPatientHistoryList={medicalPatientHistoryList}
                        appointment={appointment}
                        patient={patient}
                        medicalHistoryFilterChanged={
                            medicalHistoryFilterChanged
                        }
                        medicalHistoryTypeList={medicalHistoryTypeList}
                        medicalHistoryTypeListFilterChanged={
                            medicalHistoryTypeListFilterChanged
                        }
                        refetchMedicalHistoryType={refetchMedicalHistoryType}
                    />
                ),
            },
            {
                id: '5',
                w: 6,
                key: AppointmentDetailsPageSettings.QuestionAnswer,
                compo: (
                    <QuestionAnswerCard
                        appointment={appointment}
                        patient={patient}
                        questionOptions={questionOptions}
                        questionFilterChanged={questionFilterChanged}
                        categoryOptionList={categoryOptionList}
                        questionAnswersList={questionAnswersList}
                        encounterPage={encounterPage}
                    />
                ),
            },
            {
                id: '6',
                key: AppointmentDetailsPageSettings.Allergies,
                w: 6,
                compo: (
                    <AllergiesCard
                        patient={patient}
                        appointment={appointment}
                        allergiesOptions={allergiesOptions}
                        allergiesfilterChanged={allergiesfilterChanged}
                        refetchAllergiesType={refetchAllergiesType}
                        encounterPage={encounterPage}
                    />
                ),
            },
            {
                id: '7',
                w: 6,
                key: AppointmentDetailsPageSettings.Vaccination,
                compo: (
                    <VaccinationCard
                        patient={patient}
                        appointment={appointment}
                        vaccinationOptions={vaccinationOptions}
                        vaccinationfilterChanged={vaccinationfilterChanged}
                        refetcVitalSignType={refetcVitalSignType}
                        refetchVaccinationType={refetchVaccinationType}
                        encounterPage={encounterPage}
                    />
                ),
            },
            {
                id: '8',
                w: 12,
                key: AppointmentDetailsPageSettings.Encounter,
                compo: (
                    <EncounterCard
                        loading={loading}
                        colors={colors}
                        diagnosticOptions={diagnosticOptions}
                        dropDocument={dropDocument}
                        quickEntryList={quickEntryList}
                        appointment={appointment}
                        patient={appointment?.patient || patient}
                        encounterPage={encounterPage}
                    />
                ),
            },
        ],
        [
            loading,
            patient,
            colors,
            diagnosticOptions,
            dropDocument,
            quickEntryList,
            appointment,
            timerLoading,
            totalTimerData,
            diagnosticList,
            pastTimerList,
            todayTimer,
            medicalHistoryTypeList,
            medicalPatientHistoryList,
            dignosticTypeList,
            maxTimer,
            vitalsigntypeOptions,
            userquestionList,
            questionOptions,
            questionAnswersList,
            allergiesOptions,
            vaccinationOptions,
            selectedScreens,
        ],
    )
    const screens = useMemo(() => {
        return selectedScreens?.length
            ? selectedScreens?.map((ele, index) => ({
                  ...componentsItems.find((val) => val.key === ele),
                  id: index.toString(),
              }))
            : componentsItems
    }, [selectedScreens, componentsItems])
    return (
        <MyResponsiveGrid
            items={screens}
            selectedScreens={selectedScreens}
            onViewChange={onViewChange}
        />
    )
}
