import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    Space,
    notification,
    Popconfirm,
    Modal,
    Select,
    Input,
} from 'antd'
import {
    PrimaryButton,
    DangerButton,
    FormSelectionField,
    FormDateField,
} from 'elements'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    DateFormat,
    DateUtility,
    UserAllergiesService,
    AllergiesService,
    CommonUtility,
} from 'utility'
import { Trash } from '@phosphor-icons/react'
import { AppTable, FlexRowBetween } from 'components'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { GetUserAllergiesByAppointment, GetUserAllergiesByPatient } from 'hooks'
import { Plus } from 'phosphor-react'
import { AddAllergiesModal } from 'page-components/settings'
import { useAuth } from 'context'
import { BlockHeader } from './header'

const UserAllergiesSchema = yup.object().shape({
    allergiestype: yup.string().required(),
    startdate: yup.string().required(),
    enddate: yup.string().nullable(),
    descriptions: yup.array().min(1),
})
export const UserAllergiesTable = ({
    data,
    editallergies,
    // removeAllergies,
    pageChanged,
    total,
    filter,
}) => {
    const { t } = useTranslation()
    const rowClassName = (data) => {
        if (!data.active) {
            return 'grey-row'
        }
        return ''
    }
    const handleRowDoubleClick = (data) => {
        editallergies(data)
    }
    const columns = [
        {
            title: t('form-fields.allergiestype'),
            dataIndex: 'allergiestype',
            key: 'allergiestype',
            render: (text) => text?.type,
        },
        {
            title: t('form-fields.startdate'),
            dataIndex: 'startdate',
            key: 'startdate',
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.enddate'),
            dataIndex: 'enddate',
            key: 'enddate',
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.descriptions'),
            dataIndex: 'descriptions',
            key: 'descriptions',
            width: 300,
            render: (text) => (
                <span title={text.map((ele) => ele.description).toString()}>
                    {CommonUtility.truncateString(
                        text.map((ele) => ele.description).toString(),
                        20,
                    )}
                </span>
            ),
        },
    ]

    return (
        <>
            <>
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    rowClassName={rowClassName}
                    // onRowDoubleClick={onRowDoubleClick}
                    onRowDoubleClick={handleRowDoubleClick}
                    pageChanged={pageChanged}
                    currentPage={total.page}
                    total={total.count}
                    pageSize={filter.limit}
                />
            </>
        </>
    )
}
export const UserAllergiesTab = ({
    appointment,
    allergiesOptions,
    allergiesfilterChanged,
    refetchAllergiesType,
    encounterPage,
    patientPage,
}) => {
    const { patient } = useAuth()
    const [processing, setProcessing] = useState(false)
    const [open, setOpen] = useState(false)
    const [openUserAllergiesModal, setUserAllergiesModal] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [selectedDescriptions, setSelectedDescriptions] = useState([])
    const { t } = useTranslation()
    const {
        data: appointmentAllergies,
        setData: setappointmentAllergies,
        pageChanged: appointmentAllergiesPageChanged,
        total: appointmentAllergiesTotal,
        filter: appointmentAllergiesFilter,
        filterChanged: appointmentAllergiesFilterChangd,
        refetch: appointmentAllergiesRefetch,
    } = GetUserAllergiesByAppointment(appointment?._id)
    const {
        data: patientAllergies,
        setData: setPatientAllergies,
        pageChanged: patientAllergiesPageChanged,
        total: patientAllergiesTotal,
        filter: patientAllergiesFilter,
        filterChanged: patientAllergiesFilterChangd,
        refetch: patientAllergiesRefetch,
    } = GetUserAllergiesByPatient(patient?._id)
    const { data, setData, pageChanged, total, filter, filterChanged,refetch } =
        useMemo(() => {
            let data = appointmentAllergies
            let setData = setappointmentAllergies
            let pageChanged = appointmentAllergiesPageChanged
            let total = appointmentAllergiesTotal
            let filter = appointmentAllergiesFilter
            let filterChanged = appointmentAllergiesFilterChangd
            let refetch = appointmentAllergiesRefetch
            if (encounterPage || patientPage) {
                data = patientAllergies
                setData = setPatientAllergies
                pageChanged = patientAllergiesPageChanged
                total = patientAllergiesTotal
                filter = patientAllergiesFilter
                filterChanged = patientAllergiesFilterChangd
                refetch = patientAllergiesRefetch
            }
            return { data, setData, pageChanged, total, filter, filterChanged,refetch }
        }, [
            encounterPage,
            patientPage,
            appointmentAllergies,
            patientAllergies,
            patientAllergiesTotal,
            appointmentAllergiesTotal,
        ])
    const {
        handleSubmit,
        watch,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserAllergiesSchema),
    })
    const allergiestype = watch('allergiestype')
    const rowId = watch('_id')
    const onSubmit = async (formdata) => {
        try {
            setProcessing(true)
            const reqData = {
                ...formdata,
                patient: appointment?.patient?._id || patient?._id,
                appointment: appointment?._id,
                descriptions: selectedDescriptions,
            }
            if (formdata?._id) {
                delete reqData._id
                delete reqData?.appointment
                delete reqData?.patient
                const updated = await UserAllergiesService.update(
                    formdata._id,
                    reqData,
                )
                notification.success({
                    message: t('notif-message.allergies-updated'),
                })
                setData(
                    data.map((ele) => {
                        if (ele._id === formdata._id) {
                            return { ...ele, ...updated }
                        }
                        return { ...ele }
                    }),
                )
            } else {
                const result = await UserAllergiesService.add(reqData)
                notification.success({
                    message: t('notif-message.allergies-added'),
                })
                setData([...data, result])
            }
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
            reset({ descriptions: [], allergiestype: '' })
            setOpen(false)
        }
    }
    const removeAllergies = async (id) => {
        try {
            await UserAllergiesService.remove(id)
            notification.success({
                message: t('notif-message.allergies-removed'),
            })
            setData(data.filter((ele) => ele._id !== id));
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setOpen(false)
            setEditMode(false)
            refetch()
        }
    }
    const editallergies = (data) => {
        reset({
            _id: data._id,
            value: data.value,
            startdate: DateUtility.dayJSFormat(data.startdate),
            enddate: DateUtility.dayJSFormat(data.enddate),
            allergiestype: data.allergiestype._id,
            localid: data.localid || undefined,
        })
        setSelectedDescriptions(data.descriptions)
        setEditMode(true)
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
        setSelectedDescriptions([])
    }

    const descriptionSuggestionsOptions = useMemo(() => {
        return (
            allergiesOptions
                ?.find((ele) => ele._id === allergiestype)
                ?.descriptions?.map((ele, i) => ({
                    label: ele.description,
                    value: ele.description + new Date().toUTCString() + i,
                })) || []
        )
    }, [allergiestype])

    const onSelectSign = (sign) => {
        setOpen(true)
        reset({
            allergiestype: sign,
        })
    }

    const onAllergiesSubmit = async (formData) => {
        try {
            setProcessing(true)
            await AllergiesService.add(formData)
            notification.success({
                message: t('notif-message.category-added'),
            })
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetchAllergiesType()
            setUserAllergiesModal(false)
        }
    }
    const onSelectDesc = (id) => {
        if (!selectedDescriptions.some((ele) => ele.id === id)) {
            setSelectedDescriptions([
                ...selectedDescriptions,
                {
                    id,
                    description: descriptionSuggestionsOptions.find(
                        (ele) => ele.value === id,
                    )?.label,
                },
            ])
        }
    }
    const onDescChange = (id, data) => {
        setSelectedDescriptions(
            selectedDescriptions.map((ele) => ({
                id: ele.id,
                description: ele.id === id ? data : ele.description,
            })),
        )
    }
    const onDeselectDesc = (id) => {
        const updatedDescriptions = selectedDescriptions.filter(
            (desc) => desc.id !== id,
        )
        setSelectedDescriptions(updatedDescriptions)
    }
    return (
        <div className="">
            <div>
            <BlockHeader
                    title={t('form-fields.allergies')}
                    searchElement={<Select
                        popupMatchSelectWidth
                        className="col-12"
                        options={allergiesOptions}
                        placeholder={t("select-allergies")}
                        onSelect={(id, data) => onSelectSign(id, data)}
                        showSearch
                        notFoundContent={<PrimaryButton onClick={() => setUserAllergiesModal(true)} className="d-flex align-items-center" icon={<Plus />}>Add Type</PrimaryButton>}
                        filterOption={false}
                        onSearch={(data) => { allergiesfilterChanged("search", { type: data }) }}
                    />}
                >
                    <Select
                        defaultValue=""
                        popupClassName="filter-options"
                        style={{ width: 200 }}
                        options={[
                            { value: '', label: 'All' },
                            ...allergiesOptions,
                        ]}
                        onChange={(val) =>
                            filterChanged('search', { type: val })}
                    />
                </BlockHeader>
                <UserAllergiesTable
                    data={data}
                    options={allergiesOptions}
                    editallergies={editallergies}
                    removeAllergies={removeAllergies}
                    pageChanged={pageChanged}
                    total={total}
                    filter={filter}
                />
            </div>
            <Modal
                title={
                    editMode ? t('allergies') : t('form-fields.add-allergies')
                }
                open={open}
                confirmLoading
                footer={null}
                width={600}
                onCancel={() => onClose()}
            >
                <Form layout="vertical">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <FormSelectionField
                                    control={control}
                                    name="allergiestype"
                                    label={t('form-fields.allergiestype')}
                                    placeholder=""
                                    errors={errors?.allergiestype}
                                    defaultValue=""
                                    options={allergiesOptions}
                                />
                            </div>
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="startdate"
                                    label={t('form-fields.startdate')}
                                    placeholder=""
                                    errors={errors?.startdate}
                                    defaultValue={DateUtility.dayJSFormat(
                                        new Date(),
                                    )}
                                />
                            </div>
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="enddate"
                                    label={t('form-fields.enddate')}
                                    placeholder=""
                                    errors={errors?.enddate}
                                    allowClear
                                    defaultValue={null}
                                />
                            </div>
                            <div className="col-12">
                                <Select
                                    name="descriptions"
                                    label={t('form-fields.descriptions')}
                                    placeholder=""
                                    className="col-12"
                                    errors={errors?.descriptions}
                                    options={descriptionSuggestionsOptions}
                                    filterOption={false}
                                    onSelect={onSelectDesc}
                                />
                            </div>
                            <div className="mt-2">
                                {selectedDescriptions.map((ele) => {
                                    return (
                                        <FlexRowBetween className="mb-2 align-items-center">
                                            <Input
                                                value={ele.description}
                                                onChange={(e) =>
                                                    onDescChange(
                                                        ele.id,
                                                        e.target.value,
                                                    )}
                                            />
                                            <DangerButton
                                                className="ms-2"
                                                onClick={() =>
                                                    onDeselectDesc(ele.id)}
                                                icon={<Trash size={20} />}
                                            />
                                        </FlexRowBetween>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Popconfirm
                            title={t('delete')}
                            description={t('message.delete-message', {
                                name: `${t('Allergies')}`,
                            })}
                            onConfirm={() => removeAllergies(rowId)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton>{t("delete")}</DangerButton>
                        </Popconfirm>
                        <Space wrap>
                            <Button key="cancel" onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                key="submit"
                                loading={processing}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('submit')}
                            </PrimaryButton>
                        </Space>
                    </div>
                </Form>
            </Modal>
            <AddAllergiesModal
                open={openUserAllergiesModal}
                onClose={() => setUserAllergiesModal(false)}
                onSubmit={onAllergiesSubmit}
                loading={processing}
            />
        </div>
    )
}
