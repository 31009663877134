import { CommonUtility } from '../common'
import { BaseService } from './base'
import { APIPath } from '../constant'
import { CRUDService } from './crud'

class Encounter extends CRUDService {
    constructor() {
        super(APIPath.encounter)
    }

    byAppointment(id) {
        return BaseService.get(`${APIPath.encounterByAppointment}/${id}`)
    }

    byPatient(id, reqParams) {
        const query = CommonUtility.objectToParams(reqParams || {})
        return BaseService.get(`${APIPath.encounterByPatient}/${id}?${query}`)
    }

    lock(id,flag) {
        return BaseService.post(`${APIPath.encounterLock}/${id}/${flag}`)
    }

    bookmark(id,flag) {
        return BaseService.post(`${APIPath.encounterBookmark}/${id}/${flag}`)
    }
}

const EncounterService = new Encounter()
Object.freeze(EncounterService)
export { EncounterService }
