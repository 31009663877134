import { Form, Input } from 'antd'
import { GetCustomeEncounterFieldList } from 'hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomFieldsType } from 'utility'

const { TextArea } = Input

const RenderTextField = ({ id, optionList, onChange,readMode }) => {
    const type = optionList?.find((ele) => ele.value === id)
    switch (type?.custom_encounter_type) {
        case CustomFieldsType.TEXTAREA:
            return (
                <Form.Item
                    name={['metadata', `${type.custom_encounter_title}`]}
                    label={type.custom_encounter_title}
                >
                    <TextArea
                        name={`${type.custom_encounter_title}`}
                        onKeyDown={onChange}
                        rows={5}
                        disabled={readMode}
                    />
                </Form.Item>
            )

        default:
            return (
                <Form.Item
                    // name={type.custom_encounter_title}
                    name={["metadata", `${type.custom_encounter_title.toString()}`]}
                    label={type.custom_encounter_title}
                >
                    <TextArea
                        name={type.custom_encounter_title.toString()}
                        onKeyDown={onChange}
                        rows={5}
                        disabled={readMode}
                    />
                </Form.Item>
            )
    }
}

export const CustomeEncounter = ({ onChange,readMode }) => {
    const { optionList } = GetCustomeEncounterFieldList()
    const { t } = useTranslation()
    return (
        <div>
            <h4>{t('details')}</h4>
            <div className="row">
                {!!optionList?.length && optionList?.map((ele) => {
                    return (
                        <div className="col-4">
                            <RenderTextField readMode={readMode} id={ele.value} optionList={optionList} onChange={onChange} />
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
