import { Empty, Select, Space, Dropdown, Tabs, Collapse, Popconfirm, notification, Tag, Input, Checkbox, Form } from "antd";
import { EncounterTimeLine, FlexRow, HeaderTitle, HiddenFileUpload, LoaderBar, PageHeader } from "components";
import { DangerButton, PrimaryButton, ShadowBox } from "elements";
import { DateFormat, DateUtility, DrugService, EncounterService, EncounterType, InvoiceStatusType, LocaleFormat, PatientDrugsService } from "utility";
import { useTranslation } from "react-i18next";
import { ContentsTab, InvoicesTab } from "./tabs";
import { GetEncounterOptions, useDebounce, GetSnippetsList, GetDrugsListOfPatient, GetAllDrugsList, GetPatientInsuranceList, GetEncounterSortOptionsList } from "hooks";
import { useState, useEffect, useMemo } from "react";
import { Bookmark, Check, Eye, LockKey, LockKeyOpen, Trash } from "phosphor-react";
import { useAuth, usePatientHistory } from "context";
import styled from "styled-components";
import { GetEncounterByAppointment, GetEncounterByPatient } from "hooks/encounter";
import { useParams } from 'react-router-dom'

const CollapseList = styled.div`
.ant-tag {
  font-size: 14px;
}
.highlight {
  border-left : 4px solid black;
}
`

const { Button: DropButton } = Dropdown

const EncouterCollapseList = ({ data, appointment, openView, highlightedItem, itemsMenu, user, toggleLock, updateNormalDraft, readMode, viewToggle, remove, toogleBookmark, saveImportant }) => {
  const { t, i18n: { language } } = useTranslation();
  const GetHeader = ({ item }) => {
    const renderNormalDraftButtons = () => {
      return (
        <div onClick={() => viewToggle(item)}>
          <span>{t("The patient has an appointment for the day is it normal or not")}</span>
          <PrimaryButton disabled={readMode} className="mx-3" onClick={(e) => { e.stopPropagation(); updateNormalDraft(item, true) }}>
            {t("normal")}
          </PrimaryButton>
          <PrimaryButton disabled={readMode} onClick={(e) => { e.stopPropagation(); updateNormalDraft(item, false) }}>
            {t("abnormal")}
          </PrimaryButton>
        </div>
      );
    };

    const renderDefaultHeader = () => {
      const reason = (appointment && appointment?.reason_for_visit) ? "-" : "";
      const reasonString = appointment && appointment?.reason_for_visit ? appointment?.reason_for_visit : "";
      return (
        <div onClick={() => viewToggle(item)}>
          {`${item.encounter_type} ${reason} ${reasonString} on 
          ${DateUtility.dateToString(item?.date, DateFormat.euDate)} at 
          ${DateUtility.dateToString(item.date, DateFormat.fulltime)} - 
          ${DateUtility.getDistanceInWordBetween(new Date(item.date), new Date(), LocaleFormat[language])} - ${item?.name || ""}`}
        </div>
      );
    };

    if (item.normal && !item.normal_draft) {
      return <div onClick={() => viewToggle(item)}>
        {t("encounter-title", {
          appointmentDate: DateUtility.dateToString(item.date, DateFormat.euDate),
          name: `${user?.firstName || ""}  ${user?.lastName || ""}`,
          encounterDate: DateUtility.dateToString(item.normaldate, DateFormat.euDate) || "XXXX.XX.XX",
          encounterTime: DateUtility.dateToString(item.normaldate, DateFormat.fulltime) || "XX.XX",
        })},
      </div>;
    }

    if (item?.normal_draft) {
      return <div>{renderNormalDraftButtons()}</div>;
    }
    return renderDefaultHeader();
  };
  const Getbuttons = ({ item }) => {
    return (
      <div className="col d-flex align-items-center">
        <div className="me-2">{user?.firstName} {user?.lastName}</div>
        <Tag>{t(item.invoice_status)}</Tag>
        {false && <PrimaryButton
          icon={<Eye size={18} />}
          className="ms-2"
          disabled={readMode}
          onClick={(e) => { e.stopPropagation(); viewToggle(item) }}
        />}
        <div>{!!item.important && <Check size={18} />}</div>
        <PrimaryButton
          icon={<>{item.open ? <LockKeyOpen size={18} /> : <LockKey size={18} />}</>}
          className="ms-2"
          danger={!item.open}
          disabled={readMode}
          onClick={(e) => { e.stopPropagation(); toggleLock(item) }}
        />
        <PrimaryButton
          icon={<Bookmark size={18} weight={!item.bookmark ? "light" : "fill"} />}
          className="ms-2"
          disabled={readMode}
          onClick={(e) => { e.stopPropagation(); toogleBookmark(item) }}
        />
        <Popconfirm
          title={t("delete")}
          description={t("message.delete-message", { name: `${t("encounter")}` })}
          onConfirm={(e) => {
            e.stopPropagation()
            remove(item._id)
          }}
          okButtonProps={{ disabled: false }}
          cancelButtonProps={{ disabled: false }}
          onCancel={(e) => e.stopPropagation()}
          onPopupClick={(e) => e.stopPropagation()}
        >
          <DangerButton disabled={readMode} className="ms-2" icon={<Trash size={18} />} htmlType="button" />
        </Popconfirm>
      </div>)
  }
  return (
    <CollapseList className="col">
      {!!data.length && <Collapse defaultActiveKey={openView} activeKey={openView}>{
        data?.map((item) =>
          <Collapse.Panel
            id={item._id}
            key={item._id}
            className={`${highlightedItem === item._id && "highlight"} text-start`}
            style={{ paddingLeft: highlightedItem !== item._id && '4px' }}
            header={<GetHeader item={item} />}
            extra={<Getbuttons item={item} />}
          >
            <div
              className="mb-3"
              key={item._id}
            >
              <Tabs
                items={itemsMenu(item, data)}
                tabBarExtraContent={<><Form.Item
                  name="important"
                  onChange={() => saveImportant(item)}
                  label={t("form-fields.important")}
                >
                  <Checkbox
                    checked={item.important}
                    placeholder={t("form-fields.important")}
                  />
                </Form.Item></>} />
            </div>
          </Collapse.Panel>)
      }
      </Collapse>}
    </CollapseList>
  )
}

export const Encounters = ({
  patient,
  appointment,
  diagnosticOptions,
  encounterPage = false,
  colors,
  readMode,
}) => {
  const [highlightedItem, setHighlightedItem] = useState(null);
  const [search, setsearch] = useState("")
  const [openView, setOpenView] = useState([]);
  const finalSearch = useDebounce(search)
  const { refreshData } = usePatientHistory()
  const [open, setOpen] = useState(false);
  const [dropDocument, setDropDocument] = useState([]);
  const { t } = useTranslation();
  const { user } = useAuth()
  const params = useParams()
  const { data: items } = GetEncounterOptions(t);
  const { data: quickEntryList } = GetSnippetsList()
  const { data: encounterSortOptions } = GetEncounterSortOptionsList(t)
  const { optionList: drugOptions, setData: setDrugOptionData, data: drugData } = GetAllDrugsList()
  const { optionList: insuranceList,
  } = GetPatientInsuranceList(patient?._id, { all: true })
  const { filteredData: patientDrugData,
    setData: setPatientDrugData,
    filterChanged: patientDrugDataFilterChanged,
  } = GetDrugsListOfPatient(appointment?.patient?._id || patient?._id)
  const {
    data: allEncounterDataAppointment,
    filteredData: encounterDataAppointment,
    loading: loadingAppointment,
    setData: setEncounterDataAppointment,
    filter: filterAppointment,
    filterChanged: filterChangedAppointment,
  } = GetEncounterByAppointment(appointment?._id || params.id)
  const {
    data: allEncounterDataPatient,
    filteredData: encounterDatapatient,
    loading: encounterLoadingPatient,
    setData: setEncounterDatapatient,
    filter: filterPatient,
    filterChanged: filterChangedPatient,
  } = GetEncounterByPatient(patient?._id)

  const { allEncounterData, loading, filter, filterChanged, setEncounterData, encounterData } = useMemo(() => {
    let allEncounterData;
    let loading;
    let filter;
    let encounterData;
    let filterChanged;
    let setEncounterData
    if (encounterPage) {
      allEncounterData = allEncounterDataPatient
      encounterData = encounterDatapatient
      loading = encounterLoadingPatient
      filter = filterPatient
      filterChanged = filterChangedPatient
      setEncounterData = setEncounterDatapatient
    } else {
      allEncounterData = allEncounterDataAppointment
      encounterData = encounterDataAppointment
      loading = loadingAppointment
      filter = filterAppointment
      filterChanged = filterChangedAppointment
      setEncounterData = setEncounterDataAppointment
    }
    return { allEncounterData, loading, filter, encounterData, filterChanged, setEncounterData }
  }, [
    encounterPage,
    allEncounterDataAppointment,
    allEncounterDataPatient,
    encounterDataAppointment,
    encounterDatapatient,
    loadingAppointment,
    encounterLoadingPatient,
  ])

  useEffect(() => {
    const item = encounterData?.filter(ele => ele.open).map(ele => ele._id)
    if (item) {
      setOpenView([...item])
    }
  }, [allEncounterData])

  useEffect(() => {
    if (
      allEncounterData?.length === 0 &&
      !loading &&
      appointment?._id && !readMode
    ) {
      // createNewEncounter()
    }
  }, [allEncounterData, loading, appointment, readMode])

  useEffect(() => {
    filterChanged("search", finalSearch.toString())
  }, [finalSearch])

  const scrollToDivByKey = id => {
    const element = document.getElementById(id);
    if (element) {
      // element.scrollIntoView({ be.havior: 'smooth' });
      setHighlightedItem(id);
      setTimeout(() => setHighlightedItem(null), 2500);
    }
  }

  const onMenuClick = (e) => {
    createNewEncounter(e?.key);
    if (e?.key) {
      setOpen(true)
    }
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  const viewToggle = (item) => {
    if (openView.includes(item._id)) {
      setOpenView(openView.filter(ele => ele !== item._id))
    } else {
      setOpenView([...openView, item._id])
    }
  }

  const onDrugAdd = async (id) => {
    if (!drugData.some(ele => ele._id === id)) {
      try {
        const result = await DrugService.add({ name: id })
        setDrugOptionData([...drugData, { ...result }])
        notification.success({
          message: (t("notif-message.drug-added")),
        })
      } catch (error) {
        notification.error({
          message: (t("notif-message.default-error")),
        })
      }
    }
  }

  const removeEncounter = async (id, drugs) => {
    try {
      const temp = allEncounterData.find(x => x._id === id);
      if (temp) {
        if (temp?.local) {
          setEncounterData(allEncounterData.filter(x => x._id !== id))
        } else {
          await EncounterService.remove(id);
          setEncounterData(allEncounterData.filter(x => x._id !== id))
          notification.success({
            message: t("notif-message.encounter-removed"),
          })
        }
      }
      if (drugs?.length) {
        const list = drugs?.[0] === "all" ? patientDrugData.map(ele => ele._id) : drugs
        try {
          list.forEach(async (element) => {
            await PatientDrugsService.remove(element)
          });
          notification.success({
            message: t("notif-message.drug-removed"),
          })
          setPatientDrugData(patientDrugData.filter(item => !list.includes(item._id)))
        } catch {
          notification.success({
            message: t("notif-message.default-error"),
          })
        }
      }
    } catch {
      notification.error({
        message: t("notif-message.default-error"),
      })
    }

  }

  const saveEncounter = async (item, formData, preSelectedEncounter) => {
    try {
      const reqData = {
        ...formData,
        plan: formData.metadata?.plan || formData.metadata?.recommendation,
      }
      let filesList = []
      if (reqData.metadata?.files?.length) {
        filesList = [...reqData.metadata.files]
      }
      let result;
      if (item.local) {
        reqData.appointment = appointment?._id;
        reqData.patient = appointment?.patient?._id || patient?._id;
        reqData.chief_complaint = appointment?.reason_for_visit;
        reqData.content_type = user?.content;
        if (reqData.metadata?.files?.length) {
          reqData.metadata.files = [...reqData.metadata.files.map(item => item._id)]
        }
        result = await EncounterService.add(reqData)
      } else {
        reqData.chief_complaint = item.chief_complaint;
        reqData.normal = false;
        reqData.normal_draft = false;
        if (reqData.metadata?.files?.length) {
          reqData.metadata.files = [...reqData.metadata.files.map(item => item._id)]
        }
        if (!item.normal_draft && item.normal) {
          reqData.normal = true;
          reqData.normal_draft = false;
          reqData.encounter_type = item.encounter_type
        }
        result = await EncounterService.update(item._id, reqData)
      }
      if (reqData?.metadata?.files?.length) {
        reqData.metadata.files = filesList
      }
      const temp = allEncounterData.map(encounter => {
        if (encounter._id === item._id) {
          if (item.local) {
            return result
          }
          return {
            ...encounter,
            ...reqData,
            open: true,
          }
        }
        return encounter
      })
      if (preSelectedEncounter) {
        temp.push({ ...result, newlocal: true, open: true })
      }
      setEncounterData(temp)
      refreshData()
      notification.success({
        message: t("notif-message.encounter-saved"),
      })
    } catch {
      notification.error({
        message: t("notif-message.default-error"),
      })
    }
  }

  const createNewEncounter = (encounter) => {
    filterChanged("search", "")
    // if (encounter) {
    saveEncounter({
      local: true,
      date: DateUtility.dayJSFormat(),
      name: `Encounter ${encounterData.length + 1}`,
      encounter_type: encounter || EncounterType.CONSULTATION,
      insurance: insuranceList?.find(ele => ele.insurancecategory === patient.consulation)?.insurance,
    }, {
      insurance: insuranceList?.find(ele => ele.insurancecategory === patient.consulation)?.insurance?._id,
      consulation: patient?.consulation,
      encounter_type: encounter || EncounterType.CONSULTATION,
      doctor: user._id,
      date: DateUtility.dayJSFormat(),
      normal: false,
    }, encounter || EncounterType.CONSULTATION)
  }

  const changeConsulation = async (encounter, type, silent = false) => {
    try {
      await EncounterService.update(encounter._id, { consulation: type, encounter_type: encounter.encounter_type })
      await EncounterService.update(encounter._id, {
        insurance: insuranceList?.find(ele => ele.insurancecategory === type)?.insurance?._id,
        encounter_type: encounter.encounter_type,
      })
      setEncounterData(allEncounterData.map(ele => ({
        ...ele,
        consulation: encounter._id === ele._id ? type : ele.consulation,
        insurance: encounter._id === ele._id ? insuranceList?.find(ele => ele.insurancecategory === type).insurance : ele.insurance,
      })))
    } catch {
      if (!silent) notification.error({ message: t("notif-message.default-error") })
    }
  }

  const itemsMenu = (item, data) => {
    const lastmedication = data.filter(ele => ele.encounter_type === EncounterType.MEDICATION)
    return [
      {
        label: <h3 className="my-0">{t("contents")}</h3>,
        key: 1,
        children: <ContentsTab
          data={item}
          save={saveEncounter}
          remove={removeEncounter}
          quickEntryList={quickEntryList}
          patient={patient}
          drugOptions={drugOptions}
          readMode={readMode}
          onDrugAdd={onDrugAdd}
          drugData={drugData}
          dropDocument={dropDocument}
          diagnosticOptions={diagnosticOptions}
          patientDrugData={patientDrugData}
          setPatientDrugData={setPatientDrugData}
          patientDrugDataFilterChanged={patientDrugDataFilterChanged}
          lastmedication={lastmedication.length === 1}
        />,
      },
      {
        label: <h3 className="my-0">{t("invoices")}</h3>,
        key: 2,
        children: <InvoicesTab
          data={item}
          readMode={readMode}
          patient={patient}
          encounter={item}
          updateInvoiceDraft={updateInvoiceDraft}
          updateInvoiceStatus={updateInvoiceStatus}
          changeConsulation={changeConsulation}
          insuranceList={insuranceList}
        />,
      },
    ]
  }

  const toggleLock = async (item) => {
    try {
      await EncounterService.lock(item._id, !item.open)
      setEncounterData(allEncounterData.map(ele => ({ ...ele, open: ele._id === item._id ? !item.open : ele.open })))
      if (openView.includes(item._id)) {
        setOpenView(openView.filter(ele => ele !== item._id))
      } else {
        setOpenView([...openView, item._id])
      }
    } catch (error) {
      notification.error({
        message: (t("notif-message.default-error")),
      })
    }
  }

  const toogleBookmark = async (item) => {
    try {
      await EncounterService.bookmark(item._id, !item.bookmark)
      setEncounterData(allEncounterData.map(ele => ({ ...ele, bookmark: ele._id === item._id ? !ele.bookmark : ele.bookmark })))
      notification.success({
        message: (t("notif-message.encounter-saved")),
      })
    } catch {
      notification.error({
        message: (t("notif-message.default-error")),
      })
    }
  }

  const saveImportant = async (item) => {
    try {
      await EncounterService.update(item._id, {
        encounter_type: item.encounter_type,
        important: !item.important,
      })
      setEncounterData(allEncounterData.map(ele => ({
        ...ele,
        important: !item.important,
      })))
      notification.success({
        message: t("notif-message.encounter-saved"),
      })
    } catch {
      notification.error({
        message: t("notif-message.default.error"),
      })
    }
  }

  const updateNormalDraft = async (item, status) => {
    try {
      await EncounterService.update(item._id, {
        encounter_type: item.encounter_type,
        normal_draft: false,
        normal: status,
        normaldate: new Date(),
      })
      setEncounterData(allEncounterData.map(ele => ({
        ...ele,
        normal: ele._id === item._id ? status : ele.normal,
        normaldate: ele._id === item._id ? new Date() : ele.normaldate,
        normal_draft: ele._id === item._id ? false : ele.normal_draft,
      })))
      notification.success({
        message: t("notif-message.encounter-saved"),
      })
    } catch {
      notification.error({
        message: t("notif-message.default.error"),
      })
    }
  }

  const updateInvoiceDraft = async (item, status) => {
    try {
      await EncounterService.update(item._id, { encounter_type: item.encounter_type, invoice_missing_draft: false, invoice_missing: status })
      setEncounterData(allEncounterData.map(ele => ({
        ...ele,
        invoice_missing: ele._id === item._id ? !item.invoice_missing : ele.invoice_missing,
        open: true,
      })))
      notification.success({
        message: t("notif-message.encounter-saved"),
      })
    } catch {
      notification.error({
        message: t("notif-message.default.error"),
      })
    }
  }

  const updateInvoiceStatus = async (item, status) => {
    try {
      setEncounterData(allEncounterData.map(ele => ({
        ...ele,
        invoice_status: item._id === ele._id ? (status || InvoiceStatusType.NoBilling) : ele.invoice_status,
      })))
      notification.success({
        message: t("notif-message.encounter-saved"),
      })
    } catch {
      notification.error({
        message: t("notif-message.default.error"),
      })
    }
  }

  const onNewFile = (file) => {
    setDropDocument(file)
    createNewEncounter(EncounterType.DOCUMENT)
  }

  const encounterTypeOptions = [{ label: "All", value: "" }, ...items]
  return (
    <HiddenFileUpload onNewFile={onNewFile}>
      <div className="d-flex col-12">
        <div className="timeline text-start col-2 mt-2">
          <HeaderTitle>{t("timeline")}</HeaderTitle>
          <div className="mt-4">
            <EncounterTimeLine patient={patient} data={encounterData} colors={colors} scrollToDiv={scrollToDivByKey} />
          </div>
        </div>
        <div className="col">
          {true && <PageHeader
            left={<HeaderTitle>
              {t("contents")} & {t("invoices")}
            </HeaderTitle>}
            right={<Space>
              <Input
                placeholder={t("search")}
                onChange={(e) => setsearch(e.target.value)}
                value={search}
                allowClear
              />
              <Select
                options={encounterTypeOptions}
                value={encounterTypeOptions.find(item => item.value === filter.search) || encounterTypeOptions[0]}
                defaultValue={encounterTypeOptions[0]}
                onChange={(value) => filterChanged("search", value)}
                style={{ width: 160 }}
              />
              <Select
                options={encounterSortOptions}
                showArrow
                value={filter.sort}
                disabled={encounterPage && !patient._id}
                onChange={(value) => filterChanged("sort", value)}
                placeholder={t("sort-by")}
                style={{ width: 160 }}
                popupMatchSelectWidth
              />
              <div>
                <DropButton
                  onOpenChange={handleOpenChange}
                  open={open}
                  disabled={(encounterPage && !patient._id) || readMode}
                  menu={{
                    items,
                    onClick: onMenuClick,
                  }}
                >
                  <span onClick={(e) => onMenuClick(e)}>{t("form-fields.encounter-type")}</span>
                </DropButton>
              </div>
            </Space>}
          />}
          {loading && <LoaderBar />}
          <>
            {!loading && encounterData.length === 0 ?
              <ShadowBox className="col">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
                  <PrimaryButton disabled={(encounterPage && !patient._id) || readMode} onClick={() => createNewEncounter()}>{t("add-edit.create-consultation")}</PrimaryButton>
                </Empty>
              </ShadowBox> :
              <FlexRow className="col">
                <EncouterCollapseList
                  data={encounterData}
                  openView={openView}
                  appointment={appointment}
                  highlightedItem={highlightedItem}
                  readMode={readMode}
                  itemsMenu={itemsMenu}
                  user={user}
                  toggleLock={toggleLock}
                  updateNormalDraft={updateNormalDraft}
                  remove={removeEncounter}
                  toogleBookmark={toogleBookmark}
                  viewToggle={viewToggle}
                  saveImportant={saveImportant}
                />
              </FlexRow>}
          </>
        </div>
      </div>
    </HiddenFileUpload>
  )
}
