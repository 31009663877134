import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Statistics extends CRUDService {
    constructor() {
        super(APIPath.statistics)
    }

    mostAppointmetns(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.statistics}/most-appointments?${query}`
        return BaseService.get(url)
    }

    stateWisePatients(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.statistics}/state-patient?${query}`
        return BaseService.get(url)
    }

    patientWithMostMissedAppointments(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.statistics}/missed-appointments?${query}`
        return BaseService.get(url)
    }

    appointmentByEncounterRatio(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.statistics}/appointment-ratio-encounter?${query}`
        return BaseService.get(url)
    }

    encounterByAppointmentRatio(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.statistics}/encounter-ratio-appointment?${query}`
        return BaseService.get(url)
    }

    ageByGender() {

        const url = `${APIPath.statistics}/age-ratio-gender`
        return BaseService.get(url)
    }

    encounterByAppointmentByAge() {
        const url = `${APIPath.statistics}/encounter-appointment-ratio-age`
        return BaseService.get(url)
    }
}
const StatisticsService = new Statistics()
Object.freeze(StatisticsService)
export { StatisticsService }