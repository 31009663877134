import React, { useRef, useState } from 'react';
import { DesktopMode, GetEncounterOptions, GetInsuranceTypeList, GetInvoicesByPatient, MobileMode } from 'hooks';
import { Popconfirm, Select, Space, Table } from 'antd';
import { DangerButton, PrimaryButton } from 'elements';
import { File, Pencil, Trash } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { DateFormat, DateUtility } from 'utility';
import { EncounterPdf } from './encounter-pdf';
// import { savePDF } from '@progress/kendo-react-pdf';
import styled from 'styled-components'

const PDFBox = styled.div`
    position: absolute;
    z-index: -9;
    top: 0;
`

const Filters = ({ filterChanged }) => {
  const { t } = useTranslation()
  const { data: insuranceTypeList } = GetInsuranceTypeList(t)
  const { data } = GetEncounterOptions(t)
  const encounteroptions = [{ label: "All", value: "" }, ...data]
  const consulationotions = [{ label: "All", value: "" }, ...insuranceTypeList]
  return (
    <>
    <DesktopMode>
  <div className="col-12 mb-2 justify-content-end d-flex">
    <Select placeholder={t("form-fields.encounter-type")} onChange={(data) => filterChanged("search", { encounter_type: data })} className="col-2 me-1" options={encounteroptions} />
    <Select placeholder={t("form-fields.consulation-type")} onChange={(data) => filterChanged("search", { consulation: data })} className="col-2 mx-1" options={consulationotions} />
  </div>
  </DesktopMode>
  <MobileMode>
  <div className="col-12 mb-2 d-flex">
    <Select placeholder={t("form-fields.encounter-type")} onChange={(data) => filterChanged("search", { encounter_type: data })} className="col-6 me-1" options={encounteroptions} />
    <Select placeholder={t("form-fields.consulation-type")} onChange={(data) => filterChanged("search", { consulation: data })} className="col-6 mx-1" options={consulationotions} />
  </div>
  </MobileMode>
  </>
  )
}

export const InvoiceByPatient = () => {
  const { data, filterChanged } = GetInvoicesByPatient();
  const { t } = useTranslation();
  const [pdfData, setPdfData] = useState({})
  const componentRef = useRef()
  const pdf = () => {
    // savePDF(componentRef.current, {
    //   fileName: 'form.pdf',
    //   paperSize: "A4",
    //   margin: "0.5cm",
    //   scale: 0.6,
    // });
  }

  const patientColumns = [
    {
      title: t('form-fields.patient'),
      dataIndex: 'patient',
      key: 'patient',
      render: (patient) => (
        <span>
          {`${patient.firstName} ${patient.lastName}`}
        </span>
      ),
    },
    {
      title: t('form-fields.price'),
      dataIndex: 'encounters',
      key: 'encounters',
      render: (encounters) => {
        if (!Array.isArray(encounters) || encounters.length === 0) {
          return <span>0</span>;
        }
        let totalCost = 0;
        encounters.forEach((encounter) => {
          const encounterCost = encounter.data.reduce((acc, sencounter) => acc + sencounter.cost, 0);
          totalCost += encounterCost;
        });
        return (
          <span>
            {totalCost}
          </span>
        );
      },
    },
    // {
    //   title: t('form-fields.date'),
    //   dataIndex: 'encounters',
    //   key: 'encounters',
    //   render: (encounters) => (
    //     <span>
    //       {encounters.map((encounter) => encounter.createdAt).join(', ')}
    //     </span>
    //   ),
    // },
    {
      title: t("form-fields.actions"),
      key: 'action',
      render: () => (
        <Space size="middle">
          <div onClick={() => { }}>
            <PrimaryButton icon={<Pencil />} />
          </div>
          <Popconfirm
            title={t("delete")}
            description={t("message.delete-message", { name: `${t("invoice")}` })}
            onConfirm={() => { }}
            okButtonProps={{ disabled: false }}
            cancelButtonProps={{ disabled: false }}
          >
            <DangerButton icon={<Trash />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const invoiceCodeColumns = [
    {
      title: t('form-fields.invoicecode'),
      dataIndex: 'invoicecode',
      key: 'invoicecode',
    },
    {
      title: t('form-fields.cost'),
      dataIndex: 'cost',
      key: 'cost',
    },
    {
      title: t('form-fields.date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => DateUtility.dateToString(new Date(text), DateFormat.euDateFullTime),

    },
  ];

  const encounterColumns = [
    {
      title: t('form-fields.chief_complaint'),
      dataIndex: 'encounter',
      key: 'encounter',
      render: (text) => (
        <span>
          {text?.chief_complaint}
        </span>),
    },
    {
      title: t('form-fields.price'),
      dataIndex: 'data',
      key: 'data',
      render: (encounters) => (
        <span>
          {encounters.reduce((totalCost, encounter) => totalCost + encounter.cost, 0)}
        </span>
      ),
    },
    {
      title: t('form-fields.content-type'),
      dataIndex: 'encounter',
      key: 'encounter',
      render: (text) => (
        <span>
          {text?.content_type}
        </span>),
    },
    {
      title: t('form-fields.encounter-type'),
      dataIndex: 'encounter',
      key: 'encounter',
      render: (text) => (
        <span>
          {text?.encounter_type}
        </span>),
    },
    {
      title: t('form-fields.actions'),
      dataIndex: 'actions',
      key: 'actions',
      render: (_, data) => (
        <span>
          <Popconfirm
            title={t('add-edit.create-prescription')}
            onConfirm={() => pdf()}
            okButtonProps={{ disabled: false }}
            cancelButtonProps={{ disabled: false }}
          >
            <PrimaryButton onClick={() => setPdfData({ ...pdfData, encounter: data })} icon={<File size={20} />} />
          </Popconfirm>
        </span>),
    },
  ];

  return (
    <>
   <DesktopMode>
    <div>
      <Filters filterChanged={filterChanged} />
      <Table
        dataSource={data}
        columns={patientColumns}
        rowKey={(record) => record.patient._id}
        expandable={{
          onExpand: (_,data) => setPdfData({ patient: data.patient }),
          expandedRowRender: (record) => (
            <Table
              dataSource={record.encounters}
              columns={encounterColumns}
              expandable={{
                expandedRowRender: (invoicerecord) => (
                  <Table
                    dataSource={invoicerecord.data}
                    columns={invoiceCodeColumns}
                  />
                ),
              }}
            />
          ),
        }}
      />
      <PDFBox>
        <div ref={componentRef}>
          <div ref={componentRef} id="divIdToPrint">
            <EncounterPdf data={pdfData} />
          </div>
        </div>
      </PDFBox>
    </div>
    </DesktopMode>
    <MobileMode>
    <div className="overflow-auto">
      <Filters filterChanged={filterChanged} />
      <Table
        dataSource={data}
        columns={patientColumns}
        rowKey={(record) => record.patient._id}
        expandable={{
          onExpand: (_,data) => setPdfData({ patient: data.patient }),
          expandedRowRender: (record) => (
            <Table
              dataSource={record.encounters}
              columns={encounterColumns}
              expandable={{
                expandedRowRender: (invoicerecord) => (
                  <Table
                    dataSource={invoicerecord.data}
                    columns={invoiceCodeColumns}
                  />
                ),
              }}
            />
          ),
        }}
      />
      <PDFBox>
        <div ref={componentRef}>
          <div ref={componentRef} id="divIdToPrint">
            <EncounterPdf data={pdfData} />
          </div>
        </div>
      </PDFBox>
    </div>
    </MobileMode>
    </>
  );
};