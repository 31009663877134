import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Logs extends CRUDService {
    constructor() {
        super(APIPath.logs)
    }

    get(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.logs}?${query}`
        return BaseService.get(url)
    }
}

const LogsService = new Logs()
Object.freeze(LogsService)
export { LogsService }