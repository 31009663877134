import { Plus } from 'phosphor-react';
import { Button, Divider, Input, Select, Space } from 'antd';
import { useRef, useState, useEffect } from 'react';

let index = 0;
export const AddTagSelect = ({ options,onAdd,keyname,setList, selectedIds }) => {
  const [items, setItems] = useState([]);
  const [name, setName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setItems(options);
  }, [options]);

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, { key: name || `New item ${index += 1}`, value: name }]);
    setName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
    onAdd(name)
  };

  const onChange = (data) => {
    setList(data)
  }

  return (
    <Select
      mode="multiple"
      name={keyname}
      options={items}
      value={selectedIds}
      onChange={onChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
            />
            <Button type="text" icon={<Plus />} onClick={addItem}>
              Add item
            </Button>
          </Space>
        </>
      )}
    />
  );
};
