import { LoaderBar } from 'components'
import React, {
    createContext,
    useState,
    useContext,
    useMemo,
    useEffect,
} from 'react'
import { Navigate } from 'react-router-dom'
import { AuthService } from 'utility'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [patient, setPatient] = useState({})

    const loadToken = async () => {
        const authenticated = AuthService.isAuthenticated()
        setIsAuthenticated(authenticated)

        if (authenticated) {
            const user = AuthService.getUser()
            setUser({ ...user })
            const profile = await AuthService.me()
            setUser({ ...user, ...profile })
        }
        setLoading(false)
    }

    useEffect(() => {
        // eslint-disable-next-line no-extra-semi
        // ;(async () => {
        //     await client.init({
        //         flow: 'implicit',
        //         onLoad: 'check-sso',
        //     })
        //     window.keycloak = client

        //     if (client.tokenParsed?.email) {
        //         const loginResponse = await AuthService.login({
        //             username: client.tokenParsed?.email || '',
        //             keycloak_token: client.token,
        //         })

        //         login(loginResponse)
        //         setTimeout(() => {
        //             if (loginResponse.role === Roles.doctor) {
        //                 navigate('/app')
        //             } else {
        //                 navigate('/app/doctor')
        //             }
        //         }, 0)
        //     }
        // })()
        loadToken()
    }, [])

    const logout = () => {
        AuthService.logout()
        setIsAuthenticated(false)
    }

    const login = (data) => {
        AuthService.storeToken(data.access_token)
        AuthService.storeUser(data)
        loadToken()
    }

    const contextData = useMemo(
        () => ({
            loading,
            user,
            isAuthenticated,
            logout,
            login,
            setPatient,
            patient,
            setUser,
        }),
        [
            loading,
            user,
            isAuthenticated,
            logout,
            login,
            setPatient,
            patient,
            setUser,
        ],
    )

    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectRoute = ({ redirectPath = '/login', children }) => {
    const { isAuthenticated, loading } = useAuth()
    if (loading) {
        return <LoaderBar />
    }

    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace />
    }

    return <>{children}</>
}

export const RoleGuard = ({ redirectPath = '/', roles, children }) => {
    const { user, loading } = useAuth()
    if (loading) {
        return <LoaderBar />
    }

    if (!(roles || []).includes(user?.role)) {
        return <Navigate to={redirectPath} replace />
    }

    return <>{children}</>
}
