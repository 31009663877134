import React, { useMemo, useState } from 'react'
import { AppTable, PageHeader } from 'components'
import { ShadowBox } from 'elements'
import { useTranslation } from 'react-i18next'
import { ColumnSearchType, DateFormat, DateUtility } from 'utility'
import { useAuth } from 'context'
import {
    GetMostAppointmetns,
    GetMostMissedAppointmentsPatientList,
    GetMostStatesPatientList,
} from 'hooks/statistics'
import {
    AppointmentByEncounterChart,
    EncounterByAppointmentChart,
    PatientAgeByGenderChart,
    EncounterByAppointmentByAgeChart,
} from './addchart'
import { Button, DatePicker } from 'antd'
import { FileCsv, FileXls } from '@phosphor-icons/react'
import { DesktopMode, MobileMode } from 'hooks'

export const PatientStatisticScreen = ({}) => {
    const {
        data: mostAppointmetnsList,
        total: appointmentTotal,
        pageChanged: appointmentPageChanged,
        filter: appointmentFilter,
    } = GetMostAppointmetns()
    const {
        data: statePatientList,
        total: stateTotal,
        pageChanged: statePageChanged,
        filter: stateFilter,
    } = GetMostStatesPatientList()
    const {
        data: mostMissedappointments,
        total: missesAppointmentsTotal,
        pageChanged: missesAppointmentsPageChanged,
        filter: missesAppointmentsFilter,
    } = GetMostMissedAppointmentsPatientList()

    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { patient } = useAuth()
    const [startdate, setStartDate] = useState(
        DateUtility.dayJSFormat(new Date()),
    )
    const [enddate, setEndDate] = useState(DateUtility.dayJSFormat(new Date()))
    const handleStartDateChange = (date) => {
      setStartDate(date);
  };
   const handleStartDate = (date) => {
    setStartDate(date)
   }
    const columns = useMemo(
        () => [
            {
                title: t('form-fields.patientNumber'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) => `${text.patientNumber}`,
            },
            {
                title: t('form-fields.fullname'),
                key: 'patient',
                dataIndex: 'patient',
                render: (text) => `${text.firstName} ${text.lastName}`,
            },
            {
                title: t('form-fields.age'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) =>
                    DateUtility.getDistanceInWord(
                        new Date(text.birthdate),
                        language,
                    ),
                type: ColumnSearchType.Number,
            },
            {
                title: t('form-fields.phone'),
                dataIndex: 'patient',
                key: 'patient',
                type: ColumnSearchType.Tel,
                render: (text) => <>{text.phone}</>,
            },
            {
                title: t('form-fields.email'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) => (
                    <a href={`mailto: ${text.email}`}>{text.email}</a>
                ),
            },
            {
                title: t('appointments'),
                dataIndex: 'count',
                key: 'count',
            },
        ],
        [patient, t],
    )

    const columnsStates = useMemo(
        () => [
            {
                title: t('form-fields.city'),
                dataIndex: 'city',
                key: 'city',
            },
            {
                title: t('patients'),
                dataIndex: 'count',
                key: 'count',
            },
        ],
        [patient, t],
    )

    const columnsMissed = useMemo(
        () => [
            {
                title: t('form-fields.patientNumber'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) =>
                    text?.patientNumber && `${text?.patientNumber}`,
            },
            {
                title: t('form-fields.fullname'),
                key: 'patient',
                dataIndex: 'patient',
                render: (text) =>
                    text?.firstName && `${text?.firstName} ${text?.lastName}`,
            },
            {
                title: t('form-fields.age'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) =>
                    text?.birthdate &&
                    DateUtility.getDistanceInWord(
                        new Date(text?.birthdate),
                        language,
                    ),
                type: ColumnSearchType?.Number,
            },
            {
                title: t('form-fields.phone'),
                dataIndex: 'patient',
                key: 'patient',
                type: ColumnSearchType.Tel,
                render: (text) => <>{text?.phone}</>,
            },
            {
                title: t('form-fields.email'),
                dataIndex: 'patient',
                key: 'patient',
                render: (text) =>
                    text && <a href={`mailto: ${text.email}`}>{text?.email}</a>,
            },
            {
                title: t('appointments'),
                dataIndex: 'count',
                key: 'count',
            },
        ],
        [patient, t],
    )

    return (
        <>
        <DesktopMode>
        <div className="container-fluid">
            <div className="row">
                <div className="col-6">
                    <ShadowBox className="mt-3">
                        <PageHeader
                              left={
                                <h3 className="d-flex my-0 mb-2">
                                    {t('most-appointments')}
                                </h3>
                            }
                            right={
                                <div className="col-6 d-flex align-items-center justify-content-between">
                                    <div>
                                    <Button type="primary" className="">
                                    <FileXls size={25} />
                                    </Button>
                                </div>
                                <div>
                                    <Button type="primary" className="">
                                      <FileCsv size={25} />
                                    </Button>
                                </div>
                                <div className="col-4">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={handleStartDateChange}
                                        value={startdate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-4">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={enddate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        }
                    />
                        <AppTable
                            data={mostAppointmetnsList}
                            columns={columns}
                            keyField="_id"
                            currentPage={appointmentTotal.page}
                            total={appointmentTotal.count}
                            pageChanged={appointmentPageChanged}
                            pageSize={appointmentFilter?.limit || 10}
                            size="small"
                        />
                    </ShadowBox>
                </div>
                <div className="col-6">
                    <ShadowBox className="mt-3">
                        <PageHeader
                              left={
                                <h3 className="d-flex my-0 mb-2">
                                      {t('missed-appointments')}
                                </h3>
                            }
                            right={
                                <div className="col-6 d-flex align-items-center justify-content-between">
                                <div>
                                <Button type="primary" className="">
                                <FileXls size={25} />
                                </Button>
                            </div>
                            <div>
                                <Button type="primary" className="">
                                <FileCsv size={25} />
                                </Button>
                            </div>
                                <div className="col-4">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={handleStartDate}
                                        value={startdate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-4">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={enddate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            }
                        />
                        <AppTable
                            data={mostMissedappointments}
                            columns={columnsMissed}
                            size="small"
                            currentPage={missesAppointmentsTotal.page}
                            total={missesAppointmentsTotal.count}
                            pageChanged={missesAppointmentsPageChanged}
                            pageSize={missesAppointmentsFilter?.limit || 10}
                        />
                    </ShadowBox>
                </div>
                <div className="col-6">
                    <ShadowBox className="mt-3">
                         <PageHeader
                              left={
                                <h3 className="d-flex my-0 mb-2">
                                    {t('most-patient-by-city')}
                                </h3>
                            }
                            right={
                                <div className="col-6 d-flex align-items-center justify-content-between">
                                    <div>
                                    <Button type="primary" className="">
                                    <FileXls size={25} />
                                    </Button>
                                </div>
                                <div>
                                    <Button type="primary" className="">
                                    <FileCsv size={25} />
                                    </Button>
                                </div>
                                <div className="col-4">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={handleStartDateChange}
                                        value={startdate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-4">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={enddate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                            }
                        />
                        <AppTable
                            data={statePatientList}
                            columns={columnsStates}
                            keyField="city"
                            currentPage={stateTotal.page}
                            total={stateTotal.count}
                            pageChanged={statePageChanged}
                            pageSize={stateFilter?.limit || 10}
                            size="small"
                        />
                    </ShadowBox>
                </div>
                <div className="col-6">
                    <EncounterByAppointmentChart />
                </div>
                <div className="col-6">
                    <AppointmentByEncounterChart />
                </div>
                <div className="col-6">
                    <PatientAgeByGenderChart />
                </div>
                <div className="col-6">
                    <EncounterByAppointmentByAgeChart />
                </div>
            </div>
        </div>
        </DesktopMode>
        <MobileMode>
        <div className="">
            <div className="row">
                <div className="col-12 overflow-auto">
                    <ShadowBox className="mt-3 overflow-auto">
                        <PageHeader
                              left={
                                <h3 className="d-flex my-0 mb-2 col-7">
                                    {t('most-appointments')}
                                </h3>
                            }
                            right={
                                <div className="col-5 d-flex">
                                    <div className="col-6">
                                    <Button type="primary" className="">
                                    <FileXls size={25} />
                                    </Button>
                                </div>
                                <div className="col-3">
                                    <Button type="primary" className="">
                                      <FileCsv size={25} />
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                    <div className="col-12 d-flex">
                     <div className="col-6 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={handleStartDateChange}
                                        value={startdate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-6 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={enddate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                </div>
                        <AppTable
                            data={mostAppointmetnsList}
                            columns={columns}
                            keyField="_id"
                            currentPage={appointmentTotal.page}
                            total={appointmentTotal.count}
                            pageChanged={appointmentPageChanged}
                            pageSize={appointmentFilter?.limit || 10}
                            size="small"
                        />
                    </ShadowBox>
                </div>
                <div className="col-12 overflow-auto">
                    <ShadowBox className="mt-3 overflow-auto">
                        <PageHeader
                              left={
                                <h3 className="d-flex my-0 mb-2 col-7">
                                      {t('missed-appointments')}
                                </h3>
                            }
                            right={
                                <div className="col-5 d-flex">
                                    <div className="col-6">
                                <Button type="primary" className="">
                                <FileXls size={25} />
                                </Button>
                            </div>
                            <div className="col-3">
                                <Button type="primary" className="">
                                <FileCsv size={25} />
                                </Button>
                            </div>
                            </div>
                            }
                        />
                        <div className="col-12 d-flex">
                     <div className="col-6 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={handleStartDateChange}
                                        value={startdate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-6 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={enddate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                </div>
                        <AppTable
                            data={mostMissedappointments}
                            columns={columnsMissed}
                            size="small"
                            currentPage={missesAppointmentsTotal.page}
                            total={missesAppointmentsTotal.count}
                            pageChanged={missesAppointmentsPageChanged}
                            pageSize={missesAppointmentsFilter?.limit || 10}
                        />
                    </ShadowBox>
                </div>
                <div className="col-12 overflow-auto">
                    <ShadowBox className="mt-3 overflow-auto">
                         <PageHeader
                              left={
                                <h3 className="d-flex my-0 mb-2 col-7">
                                    {t('most-patient-by-city')}
                                </h3>
                            }
                            right={
                                <div className="col-5 d-flex">
                                    <div className="col-6">
                                    <Button type="primary" className="">
                                    <FileXls size={25} />
                                    </Button>
                                </div>
                                <div className="col-3">
                                    <Button type="primary" className="">
                                    <FileCsv size={25} />
                                    </Button>
                                </div>
                            </div>
                            }
                        />
                         <div className="col-12 d-flex">
                     <div className="col-6 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={handleStartDateChange}
                                        value={startdate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                <div className="col-6 mr-2">
                                    <DatePicker
                                        format={DateFormat.inputEuDate}
                                        onChange={setEndDate}
                                        value={enddate}
                                        style={{ width: '100%' }}
                                    />
                                </div>
                                </div>
                        <AppTable
                            data={statePatientList}
                            columns={columnsStates}
                            keyField="city"
                            currentPage={stateTotal.page}
                            total={stateTotal.count}
                            pageChanged={statePageChanged}
                            pageSize={stateFilter?.limit || 10}
                            size="small"
                        />
                    </ShadowBox>
                </div>
                <div className="col-12 overflow-auto">
                    <EncounterByAppointmentChart />
                </div>
                <div className="col-12 overflow-auto">
                    <AppointmentByEncounterChart />
                </div>
                <div className="col-12 overflow-auto">
                    <PatientAgeByGenderChart />
                </div>
                <div className="col-12 overflow-auto">
                    <EncounterByAppointmentByAgeChart />
                </div>
            </div>
        </div>
        </MobileMode>
        </>
    )
}
