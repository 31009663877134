import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class AddressType extends CRUDService {
    constructor() {
        super(APIPath.metadata_address_type)
    }

    getAddressType() {
        return BaseService.get(`${APIPath.metadata_address_type}`)
    }
}

const AddressMetaDataService = new AddressType()
Object.freeze(AddressMetaDataService)
export { AddressMetaDataService }
