/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import {
    AppTable,
    AutoCompleteDropDown,
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    HeaderTitle,
    PageHeader,
} from 'components'
import { DangerButton, PrimaryButton, FormTextFormField } from 'elements'
import { Pencil, Trash } from '@phosphor-icons/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DesktopMode, MobileMode } from 'hooks'
import * as yup from 'yup'
import { useState, useEffect } from 'react'
import { Plus } from 'phosphor-react'
import { useAuth } from 'context'
import { ContactService } from 'utility/services/contacts'
import { useContacts } from 'hooks/contacts'

const ContactSchema = yup.object().shape({
    firstName: yup.string().required('first name is required').trim(),
    lastName: yup.string().required('last name is required').trim(),
    title: yup.string().required('title is required').trim(),
    address: yup.object().shape({
        address: yup.string().required('Address is required'),
        city: yup.string().required('City is required'),
        postalCode: yup.string().required('PostalCode is required'),
        country: yup.string().required('Country is required'),
    }),
})

const AddContactModal = ({
    open,
    data,
    editMode,
    onClose,
    loading,
    onSubmit,
}) => {
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ContactSchema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={
                editMode
                    ? t('form-fields.editcontact')
                    : t('form-fields.addcontact')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="col-12 mb-0">
                        <FormTextFormField
                            control={control}
                            name="firstName"
                            required
                            errors={errors.firstName}
                            label={t('form-fields.firstName')}
                        />
                        {/* </Form.Item>
                    <Form.Item className="col-12 mb-0"> */}
                        <FormTextFormField
                            control={control}
                            name="lastName"
                            required
                            errors={errors.lastName}
                            label={t('form-fields.lastName')}
                        />
                    </Form.Item>
                    <FormTextFormField
                        control={control}
                        name="title"
                        required
                        errors={errors.title}
                        label="Title"
                    />
                    <Form.Item className="col-12 mb-0">
                        <FormTextFormField
                            control={control}
                            name="address.address"
                            required
                            errors={errors.address}
                            label={t('form-fields.address.address')}
                        />
                        <FormTextFormField
                            control={control}
                            name="address.city"
                            required
                            errors={errors.city}
                            label={t('form-fields.address.city')}
                        />
                    </Form.Item>
                    <Form.Item className="col-12 mb-0">
                        <FormTextFormField
                            control={control}
                            name="address.postalCode"
                            required
                            errors={errors.postalCode}
                            label={t('form-fields.address.postalCode')}
                        />
                        {/* </Form.Item>
                    <Form.Item className="col-12 mb-0"> */}
                        <FormTextFormField
                            control={control}
                            name="address.country"
                            required
                            errors={errors.country}
                            label={t('form-fields.address.country')}
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('form-fields.editcontact')
                            : t('form-fields.addcontact')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}

export const ContactsTab = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { patient, user } = useAuth()
    const {
        data,
        setData,
        filterChanged,
        refetch,
        total = { page: 1 },
        filter,
        pageChanged,
    } = useContacts()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await ContactService.add({
                    ...formData,
                    doctor: user._id,
                })
                notification.success({
                    message: 'Contact Added',
                })
            } else {
                delete formData._id
                delete formData.doctor
                await ContactService.update(modalData._id, {
                    ...formData,
                })
                notification.success({
                    message: 'Contact Updated',
                    // message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const removeCategory = async (id) => {
        try {
            await ContactService.remove(id)
            notification.success({
                message: 'Contact Removed',
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }
    const columns = [
        {
            title: t('form-fields.firstName'),
            dataIndex: 'firstName',
            key: 'firstName',
            width: 350,
        },
        {
            title: t('form-fields.lastName'),
            dataIndex: 'lastName',
            key: 'lastName',
            width: 350,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton
                        icon={<Pencil onClick={() => onOpen(data)} />}
                    />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {/* {t('calendar')} */}
                            Contacts
                        </HeaderTitle>
                    }
                    right={
                        <>
                            {/* <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'calendar-category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) => {
                                    filterChanged('search', { title: data })
                                }}
                            /> */}
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.Calendar-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.calendar-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-1"
                            onSearch={(data) => {
                                filterChanged('search', { name: data })
                            }}
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <AddContactModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="mt-sm-2 mt-md-0 mt-3">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                />
            </div>
        </div>
    )
}
