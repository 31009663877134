import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class PatientHistory extends CRUDService {
    constructor() {
        super(APIPath.patientHistory)
    }

    save(data) {
        return BaseService.post(`${APIPath.patientHistory}/save`,data)
    }
}

const PatientHistoryService = new PatientHistory()
Object.freeze(PatientHistoryService)
export { PatientHistoryService }
