export const Images = {
    logo: require('./logo.png'),
    google: require('./google.png'),
    fb: require('./fb.png'),
    apple: require('./apple.png'),
    desk: require('./login-banner.png'),
    en: require('./english.png'),
    "en-US": require('./english.png'),
    fr: require('./france.png'),
    noPatient: require('./remove-user.png'),
    noPatientBlack: require('./remove-user-black.png'),
    new: require("./new.svg")?.default,
}
