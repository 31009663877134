import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class PatientInsurance extends CRUDService {
    constructor() {
        super(APIPath.patientInsurance)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.patientInsurance}/bulk`,data)
    }

    byPatient(id, data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.patientInsurance}/patient/${id}?${query}`
        return BaseService.get(url)
    }

    setPrimary(patient,id) {
        return BaseService.put(`${APIPath.patientInsurance}/primary/${patient}/${id}`)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.patientInsurance}/order-by`,data)
    }
}

const PatientInsuranceService = new PatientInsurance()
Object.freeze(PatientInsuranceService)
export { PatientInsuranceService }
