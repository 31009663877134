import { Select, notification } from 'antd'
import { PrimaryButton } from 'elements'
import { useContacts } from 'hooks/contacts'
import jsPDF from 'jspdf'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Page1, Page2 } from 'templates/patient-report'
import { useAuth } from 'context/auth'
import {
    DocumentCategoryService,
    DocumentStatusService,
    FileUploadService,
    PatientDocumentService,
} from 'utility'
import { GetDocumentStatusList, GetDocumentsCategory } from 'hooks'
import html2canvas from 'html2canvas'

export default function PDFEdior() {
    const [searchParams] = useSearchParams()

    const [exportMode, setExportMode] = useState(false)

    const [selectedContact, setSelectedContact] = useState({})

    const { data: contacts } = useContacts()

    const {
        data: documentCategory,
        setData: setDocumentCategory,
        loading: documentCategoryLoading,
    } = GetDocumentsCategory()
    const {
        data: documentStatus,
        setData: setDocumentStatus,
        loading: documentStatusLoading,
    } = GetDocumentStatusList()

    const { user } = useAuth()

    const navigate = useNavigate()

    const generatePDF = async () => {
        const pages = document.querySelectorAll('#editor_export_candidate')

        // eslint-disable-next-line new-cap
        let doc = new jsPDF({
            format: [842 / 1.5, 595 / 1.5],
            // format: 'a4',
            unit: 'px',
            // compress: true,
        })

        doc.setFont('Inter-Regular', 'normal')

        await Promise.all(
            new Array(pages.length).fill(0).map(async (_, index) => {
                const canvas = await html2canvas(
                    pages.item(pages.length - 1 - index),
                )
                // const html = await doc.html(
                //     pages.item(pages.length - 1 - index),
                // )

                doc = doc.addImage(
                    canvas.toDataURL('png/image', 1),
                    0,
                    0,
                    595 / 1.5,
                    842 / 1.5,
                    '',
                    'SLOW',
                )

                doc = doc.insertPage(1)
                return canvas
            }),
        )

        doc.deletePage(1)
        // doc.deletePage(doc.getNumberOfPages())

        const blob = doc.output('blob')
        const file = new File([blob], 'patient-report.pdf')
        const { signedUrl, keyOrUrl } = await FileUploadService.signedUrl({
            ext: `.pdf`,
            contentType: 'application/pdf',
            isPublic: true,
        })
        await FileUploadService.media(signedUrl, file, 'application/pdf')

        console.log({
            status: documentStatus.filter(
                (status) => status.status === 'Pending',
            )[0]?._id,
            category: documentCategory.filter(
                (category) => category.name === 'Encounter',
            )[0]?._id,
        })

        await PatientDocumentService.add({
            status: documentStatus.filter(
                (status) => status.status === 'Pending',
            )[0]._id,
            category: documentCategory.filter(
                (category) => category.name === 'Encounter',
            )[0]._id,
            description: `encounter consultation: ${searchParams.get(
                'e_name',
            )}`,
            content_type: 'application/pdf',
            name: `Patient Report: ${searchParams.get('e_name')}`,
            patient: searchParams.get('p_id'),
            doctor: user._id,
            url: keyOrUrl,
            type: 'patient',
            mode: 'OUTGOING',
        })

        notification.success({
            message: 'Successfully created document.',
        })
        window.open(keyOrUrl)

        // doc.save('patient-report')
    }

    useEffect(() => {
        if (exportMode === true) {
            generatePDF().then(() => setExportMode(false))
        }
    }, [exportMode])

    useEffect(() => {
        if (!documentStatusLoading && !documentCategoryLoading) {
            // eslint-disable-next-line no-extra-semi
            ;(async () => {
                if (
                    !documentCategory.length ||
                    !documentCategory.filter(
                        (category) => category.name === 'Encounter',
                    ).length
                ) {
                    try {
                        setDocumentCategory([
                            ...documentCategory,
                            await DocumentCategoryService.add({
                                name: 'Encounter',
                                color: '#00000',
                            }),
                        ])
                        notification.success({
                            message: 'Created document category.',
                        })
                        console.log(documentCategory)
                    } catch (err) {
                        console.error(err)
                        notification.error({
                            message: 'Could not create document category.',
                        })
                    }
                }
                if (
                    !documentStatus.length ||
                    !documentStatus.filter(
                        (status) => status.status === 'Pending',
                    ).length
                ) {
                    try {
                        setDocumentStatus([
                            ...documentStatus,
                            await DocumentStatusService.add({
                                status: 'Pending',
                                icon: 'note',
                            }),
                        ])
                        notification.success({
                            message: 'Created document status.',
                        })
                        console.log(documentStatus)
                    } catch (err) {
                        console.error(err)
                        notification.error({
                            message: 'Could not create document status.',
                        })
                    }
                }
            })()
        }
    }, [documentStatusLoading, documentCategoryLoading])

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 20,
                    marginBottom: 20,
                    marginLeft: 20,
                    marginRight: 20,
                }}
            >
                <PrimaryButton onClick={() => navigate(-1)}>
                    {'<'} Encounters
                </PrimaryButton>
                <h3
                    style={{
                        position: 'absolute',
                        top: 75,
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                >
                    Patient Report
                </h3>
                <div style={{ display: 'flex', gap: 5 }}>
                    <Select
                        label="Select Contact"
                        defaultValue="default"
                        onChange={(e) => {
                            setSelectedContact(
                                contacts.filter(
                                    (contact) => contact._id === e,
                                )[0],
                            )
                        }}
                    >
                        <Select.Option hidden disabled value="default">
                            -- Select Contact --
                        </Select.Option>
                        {contacts.map((contact, i) => (
                            <Select.Option value={contact._id} key={i}>
                                {contact.firstName} {contact.lastName}
                            </Select.Option>
                        ))}
                    </Select>
                    <PrimaryButton
                        onClick={() => setExportMode(true)}
                        disabled={exportMode}
                    >
                        Download
                    </PrimaryButton>
                </div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 16,
                    width: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        background: 'white',
                        width: 'min-content',
                        height: 'min-content',
                        margin: 'auto',
                    }}
                    id="editor_export_candidate"
                >
                    <Page1
                        p_gender={searchParams.get('p_gender')}
                        p_fullname={searchParams.get('p_fullname')}
                        p_address={searchParams.get('p_address')}
                        p_zipcode={searchParams.get('p_zipcode')}
                        p_city={searchParams.get('p_city')}
                        p_phone={searchParams.get('p_phone')}
                        d_fullname={searchParams.get('d_fullname')}
                        d_speciality={searchParams.get('d_speciality')}
                        p_birthdate={searchParams.get('p_birthdate')}
                        e_date={searchParams.get('e_date')}
                        e_name={searchParams.get('e_name')}
                        d_address={searchParams.get('d_address')}
                        d_city={searchParams.get('d_city')}
                        d_zip={searchParams.get('d_zip')}
                        d_phone={searchParams.get('d_phone')}
                        e_subjective={searchParams.get('e_subjective')}
                        p_history={searchParams.get('p_history')}
                        p_allergy={searchParams.get('p_allergy')}
                        p_diagnostic={searchParams.get('p_diagnostic')}
                        d_fax={user.fax || ''}
                        c_address={
                            selectedContact.address
                                ? JSON.stringify(selectedContact)
                                : ''
                        }
                        d_logo={
                            user.logo
                                ? `${process.env.REACT_APP_PROXY_URL}${user.logo}`
                                : ''
                        }
                        exportMode={exportMode}
                    />
                </div>
                <div
                    style={{
                        background: 'white',
                        width: 'min-content',
                        height: 'min-content',
                        margin: 'auto',
                    }}
                    id="editor_export_candidate"
                >
                    <Page2
                        d_fax={user.fax || ''}
                        p_gender={searchParams.get('p_gender')}
                        p_fullname={searchParams.get('p_fullname')}
                        d_fullname={searchParams.get('d_fullname')}
                        d_speciality={searchParams.get('d_speciality')}
                        p_birthdate={searchParams.get('p_birthdate')}
                        d_address={searchParams.get('d_address')}
                        d_city={searchParams.get('d_city')}
                        d_zip={searchParams.get('d_zip')}
                        d_phone={searchParams.get('d_phone')}
                        e_plan={searchParams.get('e_plan')}
                        e_assessment={searchParams.get('e_assessment')}
                        p_vital_hr={searchParams.get('p_vital_hr')}
                        p_vital_bp={searchParams.get('p_vital_bp')}
                        p_vital_temp={searchParams.get('p_vital_temp')}
                        p_vital_resp={searchParams.get('p_vital_resp')}
                        p_vital_o2={searchParams.get('p_vital_o2')}
                        p_vital_bg={searchParams.get('p_vital_bg')}
                        p_vital_weight={searchParams.get('p_vital_weight')}
                        p_vital_height={searchParams.get('p_vital_height')}
                        p_vital_imc={searchParams.get('p_vital_imc')}
                        d_logo={
                            user.logo
                                ? `${process.env.REACT_APP_PROXY_URL}${user.logo}`
                                : ''
                        }
                        d_signature={
                            user.signature
                                ? `${process.env.REACT_APP_PROXY_URL}${user.signature}`
                                : ''
                        }
                        exportMode={exportMode}
                    />
                </div>
            </div>
        </>
    )
}
