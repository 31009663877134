import { GetPatientHistoryList } from 'hooks';
import React,{ createContext,useContext,useMemo } from 'react'
import { useAuth } from './auth';
import { PatientHistoryService } from 'utility';

const PatientHistoryContext = createContext();

export const PatientHistoryProvider = ({ children }) => {

    const { isAuthenticated } = useAuth()

    const { data: historyData,setData,refreshData } = GetPatientHistoryList(isAuthenticated)

    const removeHistory = async (id) => {
        await PatientHistoryService.remove(id)
        setData(historyData.filter(x => x._id !== id));
    }

    const contextData = useMemo(() => ({
        historyData,refreshData,removeHistory,
    }),[
        historyData,refreshData,removeHistory,
    ])

    return (
        <PatientHistoryContext.Provider value={contextData}>
            {children}
        </PatientHistoryContext.Provider>
    )
}

export const usePatientHistory = () => useContext(PatientHistoryContext)
