import { Alert, Modal, Space } from 'antd'
import { AppTable } from 'components'
import { PrimaryButton } from 'elements'
import { Check } from 'phosphor-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DateFormat, DateUtility } from 'utility'

export const HL7TableModal = ({ data }) => {
    const { t } = useTranslation()
    const patientcolumns = useMemo(() => [
        {
            title: t('form-fields.firstName'),
            dataIndex: 'firstName',
            key: 'firstName',
            width: 300,
        },
        {
            title: t('form-fields.lastName'),
            dataIndex: 'lastName',
            key: 'lastName',
            width: 300,
        },
        {
            title: t('form-fields.birthdate'),
            dataIndex: 'dob',
            key: 'dob',
            width: 300,
        },
        {
            title: t('form-fields.gender'),
            dataIndex: 'gender',
            key: 'gender',
            width: 300,
        },
    ])
    const columns = useMemo(() => [
        {
            title: t('form-fields.code'),
            dataIndex: 'obxObservationCode',
            key: 'obxObservationCode',
            width: 300,
        },
        {
            title: t('form-fields.name'),
            dataIndex: 'obxObservationName',
            key: 'obxObservationName',
            width: 300,
        },
        {
            title: t('form-fields.unit'),
            dataIndex: 'unit',
            key: 'unit',
            width: 300,
        },
        {
            title: t('form-fields.range'),
            dataIndex: 'referenceRange',
            key: 'referenceRange',
            width: 300,
        },
        {
            title: t('form-fields.value'),
            dataIndex: 'obxObservationValue',
            key: 'obxObservationValue',
            width: 300,
        },
    ])
    return (
        <>
            <AppTable
                data={data?.patientId ? [data] : []}
                columns={patientcolumns}
                keyField="_id"
                size="small"
            />
            <AppTable
                data={data?.obxSegments || []}
                columns={columns}
                keyField="_id"
                size="small"
            />
        </>
    )
}

export const PatientSelectModal = ({ open, onClose, list, onSelect }) => {
    const { t } = useTranslation()
    const columns = useMemo(() => [
        {
            title: t("form-fields.firstName"),
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
            search: true,
        },
        {
            title: t("form-fields.lastName"),
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
            search: true,
        },
        {
            title: t("form-fields.birthdate"),
            dataIndex: 'birthdate',
            key: 'birthdate',
            search: true,
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
        },
        {
            title: t("form-fields.email"),
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a href={`mailto: ${text}`}>{text}</a>,
            sorter: true,
            search: true,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (data) => (
                <Space size="middle">
                    <div onClick={() => { onSelect(data._id) }}>
                        <PrimaryButton icon={<Check />} />
                    </div>
                </Space>
            ),
        },
    ], [list]);
    return (
        <Modal
            title={`${t('add-laboratory')}`}
            open={open}
            onCancel={() => {
                onClose()
            }}
            footer={false}
            width={800}
        >
            <AppTable
                data={list}
                columns={columns}
                keyField="_id"
                size="small"
                nodata={<Alert message={t("message.no-patient-found")} type="info" showIcon />}
            />
        </Modal>
    )
}