import { BaseService } from './base'
import { APIPath } from '../constant'
import { CommonUtility } from '../common'
import { CRUDService } from './crud'

class PatientDocument extends CRUDService {
    constructor() {
        super(APIPath.patientDocuments)
    }

    byPatient(id, data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.patientDocumentsByPatient}/${id}?${query}`
        return BaseService.get(url)
    }

    validatedBy(id,status) {
        return BaseService.put(`${APIPath.patientDocuments}/validate/${id}/${status}`)
    }
}

const PatientDocumentService = new PatientDocument()
Object.freeze(PatientDocumentService)
export { PatientDocumentService }
