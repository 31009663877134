import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Diagnostic extends CRUDService {
    constructor() {
        super(APIPath.diagnostic)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.diagnostic}/order-by`,data)
    }

    getByPatient(id) {
        return BaseService.get(`${APIPath.diagnostic}/by-patient/${id}`)
    }
}

const DiagnosticService = new Diagnostic()
Object.freeze(DiagnosticService)
export { DiagnosticService }
