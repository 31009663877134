import { Form, Select, TimePicker, DatePicker, Input, Checkbox, Radio, ColorPicker, Slider, InputNumber, Divider, Space, Switch } from "antd"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { CaretDown } from "@phosphor-icons/react"
import { DateFormat, DateUtility } from "utility"
import { AutoCompleteDropDown } from "components"
import { useTranslation } from "react-i18next"
import { useMemo,useEffect,useRef,useState } from "react"
import { Plus } from "phosphor-react"
import { PrimaryButton } from "./Button"

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DangerText = styled.span`
    color:${({ theme }) => theme.colors.danger} !important;
    font-size: 12px;
    font-weight: 700;
}
`

const AntSelect = styled(Select)`
    .ant-select-selection-item {
	    align-items: center;
        display:flex;
    }
`
export function FormTextFormField({
    control,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    label,
    type,onChange,
    customError,
    hint,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        if (errors?.type === "email") {
            msg = t("form-message.valid-email")
        }
        if (customError) {
            msg = customError
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={props => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <Input
                        status={errors && "error"}
                        placeholder={placeholder}
                        value={props.field.value}
                        type={type}
                        onChange={e => { props.field.onChange(e.target.value); if (onChange) { onChange(e.target.value) } }}
                        {...rest}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormColorPicker({
    control,
    name,
    defaultValue = "",
    required,
    errors,
    label,
    hint,
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <ColorPicker
                        type="color"
                        status={errors && "error"}
                        value={props?.field?.value}
                        onChange={value => props.field.onChange(value.toHexString())}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormTextAreaFormField({
    control,
    name,
    defaultValue,
    placeholder,
    label,
    hint,
    required,rows,
    errors, height, fieldName = "",
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${fieldName || name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={props => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <TextArea
                        status={errors && "error"}
                        placeholder={placeholder}
                        value={props.field.value}
                        rows={rows}
                        onChange={e => props.field.onChange(e.target.value)}
                        {...rest}
                    />
                    {errors && <DangerText>{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormSwitchField({
    control,
    name,
    defaultValue,
    placeholder,
    label,
    hint,
    required,
    errors, height, fieldName = "",
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${fieldName || name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={props => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <Switch
                        status={errors && "error"}
                        placeholder={placeholder}
                        checked={props.field.value}
                        onChange={value => props.field.onChange(value)}
                        {...rest}
                    />
                    {errors && <DangerText>{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormSelectionField({
    control, name, placeholder, required, errors, children, onChange, showSearch,onSearch,searchfield,filterOption = true,customError,
    label, type, hint, options, mode, defaultValue, ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        if (customError) {
            msg = customError
        }
        return msg
    }, [t, errors])

    return (
        <Controller
            control={control}
            name={name}
            // defaultValue={defaultValue || []}
            render={props => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <div className="d-flex">
                        <AntSelect
                            status={errors && "error"}
                            placeholder={placeholder}
                            mode={mode || "single"}
                            showSearch={showSearch}
                            autoClearSearchValue
                            filterOption={filterOption && ((input, option) => (searchfield ? option[searchfield] : option?.label ?? '').toLowerCase().includes(input.toLowerCase()))}
                            onSearch={onSearch}
                            onSelect={(data) => {
                                props.field.onChange(data)
                                if (onChange && onChange) {
                                    onChange(data)
                                }
                                if (onSearch && onSearch) {
                                    onSearch("")
                                }
                            }}
                            suffixIcon={<CaretDown size={22} color="#345893" />}
                            {...props.field}
                            {...rest}
                            options={options}
                            showArrow
                        />
                        <>{children}</>
                    </div>
                    {errors && <DangerText>{errorMessage}</DangerText>}

                </Form.Item>
            )}
        />
    )
}

export function FormSelectionFieldWithPagination({
    control, name, placeholder, required, errors, children, onChange, showSearch,
    onSearch, label, type, hint, options, mode, defaultValue, ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])

    return (
        <Controller
            control={control}
            name={name}
            // defaultValue={defaultValue || []}
            render={props => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <div className="d-flex">
                        <AntSelect
                            status={errors && "error"}
                            placeholder={placeholder}
                            mode={mode || "single"}
                            showSearch={showSearch}
                            className="position-relative"
                            listHeight={300}
                            autoClearSearchValue
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            onSelect={(data) => {
                                props.field.onChange(data)
                                if (onChange && onChange) {
                                    onChange(data)
                                }
                                if (onSearch && onSearch) {
                                    onSearch("")
                                }
                            }}
                            onSearch={onSearch}
                            suffixIcon={<CaretDown size={22} color="#345893" />}
                            {...props.field}
                            {...rest}
                            showArrow
                        >
                            {options.map((option) => (
                                <Option key={option.value} value={option.value}>
                                    {option.label}
                                </Option>
                            ))}
                        </AntSelect>
                        <>{children}</>
                    </div>
                    {errors && <DangerText>{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export const FormCheckBoxField = ({
    required, errors, label, text, disabled, hint,
    control, name, width, defaultValue, fieldProps, fieldClass, ...rest
}) => (
    <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={props => (
            <Form.Item
                label={label}
                tooltip={required && { title: 'Required', icon: <>*</> }}
                rules={[{ required, message: errors?.message }]}
                className="checkbox-flex"
            >
                <Checkbox
                    className="mr-2"
                    status={errors && "error"}
                    name={name}
                    checked={props.field.value}
                    disabled={disabled}
                    defaultValue={false}
                    onChange={e => props.field.onChange(e.target.checked)}
                    {...rest}
                >
                    {text}
                </Checkbox>
                {errors && <DangerText>{errors?.message || hint}</DangerText>}
            </Form.Item>
        )}
    />
);

export const FormRadioField = ({
    required, errors, label, text, disabled, hint, options,
    control, name, width, defaultValue, fieldProps, fieldClass, ...rest
}) => {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={props => (
                <Form.Item
                    label={label}
                    tooltip={required && { title: 'Required', icon: <>*</> }}
                    rules={[{ required, message: errors?.message }]}
                    className="checkbox-flex"
                >
                    <Radio.Group
                        className="mr-2"
                        status={errors && "error"}
                        options={options}
                        name={name}
                        value={props.field.value}
                        label={text}
                        onChange={e => props.field.onChange(e.target.value)}
                        {...rest}
                    />
                    {errors && <p><DangerText>{errorMessage}</DangerText></p>}
                </Form.Item>
            )}
        />
    )
};

export function FormTimeField({
    control,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    label,
    type,
    hint,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <TimePicker
                        status={errors && "error"}
                        value={props.field.value}
                        onChange={(time) => props.field.onChange(time)}
                        {...rest}
                        style={{ width: "100%" }}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormDateField({
    control,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    label,
    type,
    hint,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}

                >
                    <DatePicker
                        status={errors && "error"}
                        value={props.field.value}
                        format={DateFormat.inputEuDate}
                        placeholder={placeholder}
                        onChange={(dayJS) => props.field.onChange(dayJS)}
                        style={{ width: "100%" }}
                        allowClear={false}
                        {...rest}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormDateTimeField({
    control, showTime = true,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    label,
    type,
    hint,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <RangePicker
                        showTime={showTime}
                        status={errors && "error"}
                        value={props.field.value}
                        defaultValue={defaultValue}
                        onChange={(dayJS) => props.field.onChange(dayJS)}
                        disabledDate={DateUtility.disabledDate}
                        allowClear={false}
                        {...rest}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormAutoCompleteFormField({
    control,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    labelText,
    options,
    className,
    onSearch,
    onChange,
    searchIcon,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={props => (
                <Form.Item
                    label={labelText}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                    className={className}
                >
                    <AutoCompleteDropDown
                        onChange={(value, data) => {
                            props.field.onChange(value);
                            if (onChange) {
                                onChange(value, data)
                            }
                        }}
                        placeholder={placeholder}
                        options={options}
                        value={props.field.value}
                        onSearch={onSearch}
                        searchIcon={searchIcon}
                        {...rest}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormRangeTimeField({
    control,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    label,
    type,
    hint,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <TimePicker.RangePicker
                        status={errors && "error"}
                        value={props.field.value}
                        onChange={(time) => props.field.onChange(time)}
                        allowClear={false}
                        style={{ width: "100%" }}
                        {...rest}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

export function FormRangeSliderField({
    control,
    name,
    defaultValue = "",
    placeholder,
    required,
    errors,
    label,
    type,
    marks,
    inputbox,
    hint, min = 0, max = 100,step,
    ...rest
}) {
    const { t } = useTranslation()
    const errorMessage = useMemo(() => {
        let msg = errors?.message;
        if (errors?.type === "required") {
            msg = t("form-message.required", { name: t(`form-fields.${name}`) });
        }
        return msg
    }, [t, errors])
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={(props) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <div className="d-flex">
                    <Slider
                        status={errors && "error"}
                        value={props.field.value}
                        onChange={(time) => props.field.onChange(time)}
                        allowClear={false}
                        style={{ width: "100%" }}
                        marks={marks}
                        min={min}
                        max={max}
                        step={step || null}
                        {...rest}
                    />
                    {inputbox && <InputNumber
                        min={min}
                        max={max}
                        style={{ marginLeft: "20px" }}
                        value={props.field.value}
                        onChange={props.field.onChange}
                    />}
                    </div>
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    )
}

let index = 0;

export const FormSelectionWithAdd = ({
  options,selectedList,
  onAdd,
  control,
  name,
  placeholder,
  required,
  errors,
  children,
  showSearch,
  onSearch,
  label,
  type,
  hint,
  mode,
  defaultValue,onChange,searchfield,
  ...rest
}) => {
  const [items, setItems] = useState([]);
  const [newname, setnewName] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    setItems(options);
  }, [options]);

  const onNameChange = (event) => {
    setnewName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, { key: newname || `New item ${index += 1}`, value: newname }]);
    setnewName('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
    onAdd(newname);
  };

  const { t } = useTranslation();
  const errorMessage = useMemo(() => {
    let msg = errors?.message || hint;
    if (errors?.type === 'required') {
      msg = t('form-message.required', { name: t(`form-fields.${name}`) });
    }
    return msg;
  }, [t, errors]);

  return (
    <Controller
      control={control}
      name={name}
      render={(props) => (
        <Form.Item
          label={label}
          tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
          rules={[{ required, message: errors?.message }]}
        >
          <div className="d-flex">
            <AntSelect
              name={name}
              value={props.field.value}
              options={items}
              showSearch={showSearch}
              placeholder={placeholder}
              searchfield={searchfield}
              filterOption={(input, option) => (searchfield ? option[searchfield] : option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              onSelect={(data) => {
                props.field.onChange(data)
                if (onChange && onChange) {
                    onChange(data)
                }
                if (onSearch && onSearch) {
                    onSearch("")
                }
            }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider
                    style={{
                      margin: '8px 0',
                    }}
                  />
                  <Space
                    style={{
                      padding: '0 8px 4px',
                    }}
                  >
                    <Input
                      placeholder="Please enter item"
                      ref={inputRef}
                      className="col"
                      value={newname}
                      onChange={onNameChange}
                    />
                    <PrimaryButton type="text" icon={<Plus />} onClick={addItem}>
                      Add item
                    </PrimaryButton>
                  </Space>
                </>
              )}
              {...rest}
            />
            {children}
          </div>
          {errors && <DangerText>{errorMessage}</DangerText>}
        </Form.Item>
      )}
    />
  );
};

export function FormNumberEventField({
    control,
    name,
    defaultValue = '',
    placeholder,
    required,
    errors,
    label,
    type,
    hint,
    ...rest
}) {
    const { t } = useTranslation();

    const Add = ({ onChange }) => (
        <div className="pointer" onClick={onChange}>
            +
        </div>
    );

    const Minus = ({ onChange }) => (
        <div className="pointer" onClick={onChange}>
            -
        </div>
    );

    const errorMessage = useMemo(() => {
        let msg = errors?.message || hint;
        if (errors?.type === 'required') {
            msg = t('form-message.required', { name: t(`form-fields.${name}`) });
        }
        if (errors?.type === 'email') {
            msg = t('form-message.valid-email');
        }
        return msg;
    }, [t, errors]);

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => (
                <Form.Item
                    label={label}
                    tooltip={{ icon: required ? <DangerText>*</DangerText> : <></> }}
                    rules={[{ required, message: errors?.message }]}
                >
                    <Input
                        status={errors && 'error'}
                        placeholder={placeholder}
                        value={field.value || 0}
                        className="text-center"
                        type={type}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                        }}
                        name={name}
                        addonAfter={<Add onChange={() => field.onChange(Number(field.value) + 1)} />}
                        addonBefore={<Minus onChange={() => field.onChange(Number(field.value) - 1)} />}
                        {...rest}
                    />
                    {errors && <DangerText className="danger">{errorMessage}</DangerText>}
                </Form.Item>
            )}
        />
    );
}