import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    Space,
    notification,
    Popconfirm,
    Modal,
    Select,
} from 'antd'
import {
    PrimaryButton,
    DangerButton,
    FormSelectionField,
    FormDateField,
    FormTextAreaFormField,
    FormCheckBoxField,
} from 'elements'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DateFormat, DateUtility, UserVaccinationService, VaccinationService, CommonUtility } from 'utility'
import { AppTable } from 'components'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { GetUserVaccinationByAppointment, GetUserVaccinationByPatient } from 'hooks'
import { Plus } from 'phosphor-react'
import { AddVaccinationModal } from 'page-components/settings'
import { useAuth } from 'context'
import { BlockHeader } from './header'

const UserVaccinationSchema = yup.object().shape({
    vaccinationtype: yup.string().required(),
    startdate: yup.string().required(),
    enddate: yup.string(),
    description: yup.string(),
    active: yup.boolean(),
})
export const UserVaccinationTable = ({
    data,
    editvaccination,
    pageChanged,
    total,
    filter,
}) => {
    const { t } = useTranslation()
    const rowClassName = (data) => {
        if (!data.active) {
            return 'grey-row'
        }
        return ''
    }
    const onRowDoubleClick = (data) => {
        editvaccination(data)
    }
    const columns = [
        {
            title: t('form-fields.vaccination'),
            dataIndex: 'vaccinationtype',
            key: 'vaccinationtype',
            render: (text) => text?.name,
        },
        {
            title: t('form-fields.startdate'),
            dataIndex: 'startdate',
            key: 'startdate',
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.expiry'),
            dataIndex: 'enddate',
            key: 'enddate',
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.description'),
            dataIndex: 'description',
            key: 'description',
            width: 300,
            render: (text) => <span title={text?.toString()}>{CommonUtility.truncateString(text?.toString(), 20)}</span>,
        },
    ]

    return (
        <>
            <>
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    rowClassName={rowClassName}
                    onRowDoubleClick={onRowDoubleClick}
                    pageChanged={pageChanged}
                    currentPage={total.page}
                    total={total.count}
                    pageSize={filter.limit}
                />
            </>
        </>
    )
}
export const UserVaccinationTab = ({
    appointment,
    vaccinationOptions,
    vaccinationfilterChanged,
    refetchVaccinationType,
    encounterPage,
}) => {
    const { patient } = useAuth()
    const [processing, setProcessing] = useState(false)
    const [open, setOpen] = useState(false)
    const [openUserVaccinationModal, setUserVaccinationModal] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const { t } = useTranslation()
    const { data: appointmentVaccination, setData: setappointmentVaccination, pageChanged: appointmentVaccinationPageChanged, total: appointmentVaccinationTotal, filter: appointmentVaccinationFilter, filterChanged: appointmentVaccinationFilterChangd } =
        GetUserVaccinationByAppointment(appointment?._id)
    const { data: patientVaccination, setData: setPatientVaccination, pageChanged: patientVaccinationPageChanged, total: patientVaccinationTotal, filter: patientVaccinationFilter, filterChanged: patientVaccinationFilterChangd } =
        GetUserVaccinationByPatient(patient?._id)
    const { data, setData, pageChanged, total, filter, filterChanged } = useMemo(() => {
        let data = appointmentVaccination
        let setData = setappointmentVaccination
        let pageChanged = appointmentVaccinationPageChanged
        let total = appointmentVaccinationTotal
        let filter = appointmentVaccinationFilter
        let filterChanged = appointmentVaccinationFilterChangd
        if (encounterPage) {
            data = patientVaccination
            setData = setPatientVaccination
            pageChanged = patientVaccinationPageChanged
            total = patientVaccinationTotal
            filter = patientVaccinationFilter
            filterChanged = patientVaccinationFilterChangd
        }
        return { data, setData, pageChanged, total, filter, filterChanged }
    }, [encounterPage, appointmentVaccination, patientVaccination, patientVaccinationTotal, appointmentVaccinationTotal])
    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserVaccinationSchema),
    })
    const rowId = watch('_id')
    const onSubmit = async (formdata) => {
        try {
            setProcessing(true)
            const vaccination = vaccinationOptions.find(ele => ele._id === formdata.vaccinationtype)
            const reqData = {
                ...formdata,
                patient: appointment?.patient?._id || patient?._id,
                appointment: appointment?._id,
                enddate: DateUtility.addSeconds(new Date(formdata.startdate), vaccination.expiry),
            }
            if (formdata?._id) {
                delete reqData._id
                delete reqData?.appointment
                delete reqData?.patient
                const updated = await UserVaccinationService.update(
                    formdata._id,
                    reqData,
                )
                notification.success({
                    message: t('notif-message.vaccination-updated'),
                })
                setData(
                    data.map((ele) => {
                        if (ele._id === formdata._id) {
                            return { ...ele, ...updated }
                        }
                        return { ...ele }
                    }),
                )
            } else {
                const result = await UserVaccinationService.add(reqData)
                notification.success({
                    message: t('notif-message.vaccination-added'),
                })
                setData([...data, result])
            }
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
            reset({ descriptions: [], vaccinationtype: "" })
            setOpen(false)
        }
    }
    const removeVaccination = async (id) => {
        try {
            await UserVaccinationService.remove(id)
            notification.success({
                message: t('notif-message.allergies-removed'),
            })
            setData(data.filter((ele) => ele._id !== id));
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setOpen(false)
            setEditMode(false)
        }
    }
    const editvaccination = (data) => {
        reset({
            _id: data._id,
            value: data.value,
            startdate: DateUtility.dayJSFormat(data.startdate),
            enddate: DateUtility.dayJSFormat(data.enddate),
            vaccinationtype: data.vaccinationtype._id,
            localid: data.localid || undefined,
        })
        setEditMode(true)
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    const onSelectSign = (sign) => {
        setOpen(true)
        reset({
            vaccinationtype: sign,
        })
    }

    const onVaccinationSubmit = async (formData) => {
        try {
            setProcessing(true)
            await VaccinationService.add(formData)
            notification.success({
                message: t('notif-message.category-added'),
            })
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetchVaccinationType()
            setUserVaccinationModal(false)
        }
    }
    return (
        <div className="">
            <div>
                <BlockHeader
                    title={t('form-fields.vaccination')}
                    searchElement={<Select
                        popupMatchSelectWidth
                        className="col-12"
                        options={vaccinationOptions}
                        placeholder={t("select-vaccination")}
                        onSelect={(id, data) => onSelectSign(id, data)}
                        showSearch
                        notFoundContent={<PrimaryButton onClick={() => setUserVaccinationModal(true)} className="d-flex align-items-center" icon={<Plus />}>Add Type</PrimaryButton>}
                        filterOption={false}
                        onSearch={(data) => { vaccinationfilterChanged("search", { name: data }) }}
                    />}
                >
                    <div className="col-12">
                        <Select
                            defaultValue=""
                            style={{ width: 200 }}
                            popupClassName="filter-options"
                            options={[
                                { value: '', label: 'All' },
                                ...vaccinationOptions,
                            ]}
                            onChange={(val) =>
                                filterChanged('search', { type: val })}
                        />
                    </div>
                </BlockHeader>
                <UserVaccinationTable
                    data={data}
                    options={vaccinationOptions}
                    editvaccination={editvaccination}
                    removeVaccination={removeVaccination}
                    pageChanged={pageChanged}
                    total={total}
                    filter={filter}
                />
            </div>
            <Modal
                title={
                    editMode ? t('form-fields.edit-vaccination') : t('form-fields.add-vaccination')
                }
                open={open}
                confirmLoading
                footer={null}
                width={600}
                onCancel={() => onClose()}
            >
                <Form layout="vertical">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12">
                                <FormSelectionField
                                    control={control}
                                    name="vaccinationtype"
                                    label={t('form-fields.vaccinationtype')}
                                    placeholder=""
                                    errors={errors?.vaccinationtype}
                                    defaultValue=""
                                    options={vaccinationOptions}
                                />
                            </div>
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="startdate"
                                    label={t('form-fields.startdate')}
                                    placeholder=""
                                    errors={errors?.startdate}
                                    defaultValue={DateUtility.dayJSFormat(new Date())}
                                />
                            </div>
                            <div className="col-12">
                                <FormTextAreaFormField
                                    control={control}
                                    name="description"
                                    label={t('form-fields.description')}
                                    placeholder=""
                                    className="col-12"
                                    errors={errors?.description}
                                />
                            </div>
                            <div className="col-5">
                                <FormCheckBoxField
                                    control={control}
                                    name="active"
                                    text={t("form-fields.active")}
                                    errors={errors?.active}
                                    defaultValue="true"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                    <Popconfirm
                            title={t('delete')}
                            description={t('message.delete-message', {
                                name: `${t('vaccination')}`,
                            })}
                            onConfirm={() => removeVaccination(rowId)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton>{t("delete")}</DangerButton>
                        </Popconfirm>
                        <div />
                        <Space wrap>
                            <Button key="cancel" onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                key="submit"
                                loading={processing}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('submit')}
                            </PrimaryButton>
                        </Space>
                    </div>
                </Form>
            </Modal>
            <AddVaccinationModal
                open={openUserVaccinationModal}
                onClose={() => setUserVaccinationModal(false)}
                onSubmit={onVaccinationSubmit}
                loading={processing}
            />
        </div>
    )
}
