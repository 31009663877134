import {
    CloudSlash,
    CloudCheck,
    Cloud,
    CloudWarning,
    CloudX,
    ChatText,
    Armchair,
    Footprints,
    Lock,
    Infinity,
    Note,
    Chat,
    ChatCircle,
    CheckCircle,
    CheckSquare,
    CheckSquareOffset,
    Phone,
    Play,
    PlayCircle,
    Prohibit,
    ProhibitInset,
    Stop,
    StopCircle,
    XCircle,
} from '@phosphor-icons/react'
import { t } from 'i18next'
import {
    At,
    Bug,
    Envelope,
    Globe,
    Info,
    Lightbulb,
    Question,
    Signpost,
} from 'phosphor-react'

export const ErrorConstant = {
    default: 'Something went wrong',
}

export const ColumnSearchType = {
    Date: 'date',
    Radio: 'radio',
    String: 'string',
    Tel: 'tel',
    Number: 'number',
}

export const RecurrenceEndType = {
    endAfterN: 'endAfterN',
    endOnDate: 'endOnDate',
}

export const LogsType = {
    CREATE_PATIENT: "CREATE_PATIENT",
    CREATE_APPOINTMENT: "CREATE_APPOINTMENT",
    CREATE_DOCUMENT: "CREATE_DOCUMENT",
    CREATE_ENCOUNTER: "CREATE_ENCOUNTER",
    CREATE_PARAMETER: "CREATE_PARAMETER",
    MODIFY_PATIENT: "MODIFY_PATIENT",
    MODIFY_APPOINTMENT: "MODIFY_APPOINTMENT",
    MODIFY_DOCUMENT: "MODIFY_DOCUMENT",
    MODIFY_ENCOUNTER: "MODIFY_ENCOUNTER",
    MODIFY_PARAMETER: "MODIFY_PARAMETER",
    DELETE_PATIENT: "DELETE_PATIENT",
    DELETE_APPOINTMENT: "DELETE_APPOINTMENT",
    DELETE_DOCUMENT: "DELETE_DOCUMENT",
    DELETE_ENCOUNTER: "DELETE_ENCOUNTER",
    DELETE_PARAMETER: "DELETE_PARAMETER",
    ANONYMOUS: "ANONYMOUS ",
}

export const AppointmentDetailsPageSettings = {
    Patient: "appointment-patient",
    Timer: "appointment-timer",
    VitalSign: "appointment-vital-sign",
    Diagnostic: "appointment-diagnostic",
    MedicalHistory: "appointment-medical-history",
    QuestionAnswer: "appointment-question-answer",
    Allergies: "appointment-allergies",
    Vaccination: "appointment-vaccination",
    Encounter: "appointment-encounter",
}

export const UserPageSettings = {
    appointmentDetails: "appointment-details",
    encounterDetails: "encounter-details",

}
export const LogsTypeStrings = {
    CREATE: "Create",
    MODIFY: "Update",
    DELETE: "Remove",
    APPOINTMENT: "Appointment",
    ENCOUNTER: "Encounter",
    PATIENT: "Patient",
    DOCUMENT: "Document",
    PARAMETER: "Parameter",
    ANONYMOUS: "ANONYMOUS ",
}

export const CommonConstant = {
    mode: process.env.REACT_APP_MODE,
    defaultPageSize: 10,
    noPage: 'no-page',
    noTab: 'no-tab',
    pageSizes: [
        { key: 10, value: 10, label: '10' },
        { key: 25, value: 25, label: '25' },
        { key: 50, value: 50, label: '50' },
        { key: 100, value: 100, label: '100' },
    ],
    sortDirection: {
        ascending: 'asc',
        descending: 'desc',
    },
}

export const StorageConstant = {
    token: 'token',
    user: 'user',
    layout: 'layout',
    timer: 'timer',
}

export const Roles = {
    admin: 'admin',
    doctor: 'doctor',
}

export const PatientDocumentsType = {
    PATIENT: 'patient',
    EMAIL: 'email',
    ENCOUNTER: 'encounter',
}

export const WorkingDayOptions = [{
        label: 'Monday to Saturday',
        value: 'MOS',
    },
    {
        label: 'Monday to Friday',
        value: 'MOF',
    },
]

export const DayRangeOptions = {
    7: '7',
    14: '14',
    30: '30',
    60: '60',
}

export const TimeRangeOptions = {
    '05': '5',
    10: '10',
    15: '15',
    30: '30',
    60: '60',
    90: '90',
    120: '120',
}

const breakpoints = {
    xs: '320px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
}

export const devices = {
    xs: `(max-width: ${breakpoints.xs})`,
    sm: `(max-width: ${breakpoints.sm})`,
    md: `(max-width: ${breakpoints.md})`,
    lg: `(max-width: ${breakpoints.lg})`,
    xl: `(max-width: ${breakpoints.xl})`,
    '2xl': `(max-width: ${breakpoints['2xl']})`,
}

export const TimeLineColor = {
    consultation: '#FF0000',
    medication: '#0000FF',
    laboratory: '#800080',
    radiology: '#00FF00 ',
    document: '#FF33E6',
    stain: '#808080',
    email: '#FFA500',
    default: '#000000',
    grey: '#6C6C6C',
}

export const DrugFilterOptions = [{
        label: 'All',
        value: -1,
    },
    {
        label: 'Today',
        value: new Date().toISOString(),
    },
]

export const GenderType = {
    Male: 'male',
    Female: 'female',
    Other: 'other',
}

export const GenderOptions = [{
        label: 'Male',
        value: 'male',
    },
    {
        label: 'Female',
        value: 'female',
    },
    {
        label: 'Other',
        value: 'other',
    },
]

export const AcceptFileType = {
    image: {
        'image/*': ['.jpeg', '.png', '.jpg', '.gif'],
    },
    application: {
        'application/*': ['.pdf'],
    },
    video: {
        'video/*': [
            '.mp4',
            '.webm',
            '.wav',
            '.mp3',
            '.ogg',
            '.glb',
            '.gltf',
            '.mov',
        ],
    },
    imageVideo: {
        'image/*': [
            '.jpeg',
            '.png',
            '.jpg',
            '.gif',
            '.mp4',
            '.webm',
            '.wav',
            '.mov',
            '.mp3',
            '.ogg',
            '.glb',
            '.gltf',
        ],
    },
}

export const DocumentType = [{
        title: 'Passport size photo',
        acceptFile: AcceptFileType.image,
    },
    {
        title: 'Sign',
        acceptFile: AcceptFileType.image,
    },
    {
        title: 'Id proof',
        acceptFile: AcceptFileType.image,
    },
    {
        title: 'Doctor certificate',
        acceptFile: AcceptFileType.file,
    },
    {
        title: 'Educational degree',
        acceptFile: AcceptFileType.image,
    },
    {
        title: 'Internship certificate',
        acceptFile: AcceptFileType.file,
    },
]

export const InvoiceTarmedType = [{
        label: 'TARMED8',
        value: 'TARMED8',
    },
    {
        label: 'TARMED9',
        value: 'TARMED9',
    },
]

export const FileTypes = {
    XML: "XML",
    PDF: "PDF",
    HL7: "HL7",
}

export const ConsulationTypes = {
    Military: 'Military',
    Disability: 'Disability',
    Accident: 'Accident',
    Illness: 'Illness',
}

export const ConsulationOptions = [{
        label: ConsulationTypes.Military,
        value: 'Military',
    },
    {
        label: ConsulationTypes.Disability,
        value: 'Disability',
    },
    {
        label: ConsulationTypes.Accident,
        value: 'Accident',
    },
    {
        label: ConsulationTypes.Illness,
        value: 'Illness',
    },
]

export const StatusIcons = {
    sheduled1: <CheckCircle size={20} />,
    sheduled2: <CheckSquare size={20} />,
    sheduled3: <CheckSquareOffset size={20} />,
    arrived1: <Chat size={20} />,
    arrived2: <ChatCircle size={20} />,
    started1: <Play size={20} />,
    started2: <PlayCircle size={20} />,
    stop1: <Stop size={20} />,
    stop2: <StopCircle size={20} />,
    cancel1: <Prohibit size={20} />,
    cancel2: <ProhibitInset size={20} />,
    cancel3: <XCircle size={20} />,
    cloud1: <CloudSlash size={20} />,
    cloud2: <CloudCheck size={20} />,
    cloud3: <Cloud size={20} />,
    cloud4: <CloudWarning size={20} />,
    cloud5: <CloudX size={20} />,
    chat: <ChatText size={20} />,
    footprint: <Footprints size={20} />,
    armchair: <Armchair size={20} />,
    lock: <Lock size={20} />,
    infinity: <Infinity size={20} />,
    note: <Note size={20} />,
    phone: <Phone size={20} />,
}

export const AboutUsIcons = {
    info: <Info size={20} />,
    question: <Question size={20} />,
    at: <At size={20} />,
    envelope: <Envelope size={20} />,
    globe: <Globe size={20} />,
    phone: <Phone size={20} />,
    lightbulb: <Lightbulb size={20} />,
    bug: <Bug size={20} />,
    signpost: <Signpost size={20} />,
}

export const DocumentMode = {
  OUTGOING: 'OUTGOING',
  INCOMING: "INCOMING",
}

export const AboutUsIconList = [{
        value: 'info',
        label: AboutUsIcons.info,
    },
    {
        value: 'question',
        label: AboutUsIcons.question,
    },
    {
        value: 'at',
        label: AboutUsIcons.at,
    },
    {
        value: 'envelope',
        label: AboutUsIcons.envelope,
    },
    {
        value: 'globe',
        label: AboutUsIcons.globe,
    },
    {
        value: 'phone',
        label: AboutUsIcons.phone,
    },
    {
        value: 'lightbulb',
        label: AboutUsIcons.lightbulb,
    },
    {
        value: 'bug',
        label: AboutUsIcons.bug,
    },
    {
        value: 'signpost',
        label: AboutUsIcons.signpost,
    },
]

export const StatusIconList = [{
        value: 'sheduled1',
        label: StatusIcons.sheduled1,
    },
    {
        value: 'sheduled2',
        label: StatusIcons.sheduled2,
    },
    {
        value: 'sheduled3',
        label: StatusIcons.sheduled3,
    },
    {
        value: 'arrived1',
        label: StatusIcons.arrived1,
    },
    {
        value: 'arrived2',
        label: StatusIcons.arrived2,
    },
    {
        value: 'started1',
        label: StatusIcons.started1,
    },
    {
        value: 'started2',
        label: StatusIcons.started2,
    },
    {
        value: 'stop1',
        label: StatusIcons.stop1,
    },
    {
        value: 'stop2',
        label: StatusIcons.started2,
    },
    {
        value: 'cancel1',
        label: StatusIcons.cancel1,
    },
    {
        value: 'cancel2',
        label: StatusIcons.cancel2,
    },
    {
        value: 'cancel3',
        label: StatusIcons.cancel3,
    },
    {
        value: 'cloud1',
        label: StatusIcons.cloud1,
    },
    {
        value: 'cloud2',
        label: StatusIcons.cloud2,
    },
    {
        value: 'cloud3',
        label: StatusIcons.cloud3,
    },
    {
        value: 'cloud4',
        label: StatusIcons.cloud4,
    },
    {
        value: 'cloud5',
        label: StatusIcons.cloud5,
    },
    {
        value: 'chat',
        label: StatusIcons.chat,
    },
    {
        value: 'footprint',
        label: StatusIcons.footprint,
    },
    {
        value: 'armchair',
        label: StatusIcons.armchair,
    },
    {
        value: 'lock',
        label: StatusIcons.lock,
    },
    {
        value: 'infinity',
        label: StatusIcons.infinity,
    },
    {
        value: 'note',
        label: StatusIcons.note,
    },
    {
        value: 'phone',
        label: StatusIcons.phone,
    },
]

export const InvoiceStatusType = {
    WithoutCodes: t('without-codes'),
    Invoiced: 'Invoiced',
    Paid: 'Paid',
    NoBilling: 'NoBilling',
}
export const MaxTimer = [
    { label: '4 hours', value: 4 },
    { label: '5 hours', value: 5 },
    { label: '6 hours', value: 6 },
    { label: '7 hours', value: 7 },
]

export const InvoicesStatusOptions = [{
        value: InvoiceStatusType.WithoutCodes,
        label: t('without-codes'),
    },
    {
        value: InvoiceStatusType.Invoiced,
        label: t('Invoiced'),
    },
    {
        value: InvoiceStatusType.Paid,
        label: t('Paid'),
    },
    {
        value: InvoiceStatusType.NoBilling,
        label: t('No Billing'),
    },
]

export const MangeInsuranceTypes = [{
        value: "tiers-garant",
        label: 'tiers garant',
    },
    {
        value: "tiers-payant",
        label: 'tiers payant',
    },
]

export const PositionType = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
}

export const StateList = [
    { label: 'Zurich', value: 'ZH', tariff: '0.89' },
    { label: 'Berne', value: 'BE', tariff: '0.86' },
    { label: 'Lucerne', value: 'LU', tariff: '0.82' },
    { label: 'Uri', value: 'UR', tariff: '0' },
    { label: 'Schwyz', value: 'SZ', tariff: '0.82' },
    { label: 'Obwald', value: 'OW', tariff: '0' },
    { label: 'Nidwald', value: 'NW', tariff: '0' },
    { label: 'Glaris', value: 'GL', tariff: '0' },
    { label: 'Zoug', value: 'ZG', tariff: '0.82' },
    { label: 'Fribourg', value: 'FR', tariff: '0.91' },
    { label: 'Soleure', value: 'SO', tariff: '0.84' },
    { label: 'Bâle-Ville', value: 'BS', tariff: '0.91' },
    { label: 'Bâle-Campagne', value: 'BL', tariff: '0.91' },
    { label: 'Schaffhouse', value: 'SH', tariff: '0' },
    { label: 'Appenzell Rh.-Ext.', value: 'AR', tariff: '0' },
    { label: 'Appenzell Rh.-Int.', value: 'AI', tariff: '0' },
    { label: 'Saint-Gall', value: 'SG', tariff: '1' },
    { label: 'Grisons', value: 'GR', tariff: '0' },
    { label: 'Argovie', value: 'AG', tariff: '0.89' },
    { label: 'Thurgovie', value: 'TG', tariff: '0' },
    { label: 'Tessin', value: 'TI', tariff: '0.93' },
    { label: 'Vaud', value: 'VD', tariff: '0.94' },
    { label: 'Valais', value: 'VS', tariff: '0.82' },
    { label: 'Neuchâtel', value: 'NE', tariff: '0.91' },
    { label: 'Genève', value: 'GE', tariff: '0.96' },
    { label: 'Jura', value: 'JU', tariff: '0.94' },
];

export const CountryList = [
    { label: 'Albania', value: 'AL' },
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas (the)', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia (Plurinational State of)', value: 'BO' },
    { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory (the)', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cabo Verde', value: 'CV' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cayman Islands (the)', value: 'KY' },
    { label: 'Central African Republic (the)', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands (the)', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros (the)', value: 'KM' },
    { label: 'Congo (the Democratic Republic of the)', value: 'CD' },
    { label: 'Congo (the)', value: 'CG' },
    { label: 'Cook Islands (the)', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Curaçao', value: 'CW' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czechia', value: 'CZ' },
    { label: "Côte d'Ivoire", value: 'CI' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic (the)', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Eswatini', value: 'SZ' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (the) [Malvinas]', value: 'FK' },
    { label: 'Faroe Islands (the)', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories (the)', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia (the)', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and McDonald Islands', value: 'HM' },
    { label: 'Holy See (the)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran (Islamic Republic of)', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: "Korea (the Democratic People's Republic of)", value: 'KP' },
    { label: 'Korea (the Republic of)', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: "Lao People's Democratic Republic (the)", value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands (the)', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia (Federated States of)', value: 'FM' },
    { label: 'Moldova (the Republic of)', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands (the)', value: 'NL' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger (the)', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands (the)', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestine, State of', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines (the)', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Republic of North Macedonia', value: 'MK' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation (the)', value: 'RU' },
    { label: 'Rwanda', value: 'RW' },
    { label: 'Réunion', value: 'RE' },
    { label: 'Saint Barthélemy', value: 'BL' },
    { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Martin (French part)', value: 'MF' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Sint Maarten (Dutch part)', value: 'SX' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'South Sudan', value: 'SS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan (the)', value: 'SD' },
    { label: 'Suri label', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan (Province of China)', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands (the)', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates (the)', value: 'AE' },
    {
        label: 'United Kingdom of Great Britain and Northern Ireland (the)',
        value: 'GB',
    },
    { label: 'United States Minor Outlying Islands (the)', value: 'UM' },
    { label: 'United States of America (the)', value: 'US' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela (Bolivarian Republic of)', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands (British)', value: 'VG' },
    { label: 'Virgin Islands (U.S.)', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' },
]

export const ExpiryList = [
    { label: '1 month', value: 2592000 },
    { label: '3 months', value: 7776000 },
    { label: '6 months', value: 15552000 },
    { label: '1 year', value: 31536000 },
]

export const InsuranceTypeList = [
    { label: 'KVG Disease', value: 'KVG' },
    { label: 'UVG Accident', value: 'UVG' },
    { label: 'IVG Invalidity', value: 'IVG' },
]

export const EncounterTypeList = [
    { label: 'maladie', value: 'KVG' },
    { label: 'accident', value: 'UVG' },
    { label: 'invalidité', value: 'IVG' },
]

export const InsuranceTypeConversion = {
    KVG: "Maladie",
    UVG: "Accident",
    IVG: "Invalidité",
}