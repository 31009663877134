import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class DocumentStatus extends CRUDService {
    constructor() {
        super(APIPath.documentStatus)
    }

    setPrimary(id) {
        return BaseService.put(`${APIPath.DocumentStatus}/primary/${id}`)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.DocumentStatus}/order-by`,data)
    }

}

const DocumentStatusService = new DocumentStatus()
Object.freeze(DocumentStatusService)
export { DocumentStatusService }
