import { useState, useEffect } from 'react'
import {
    TimerService,
} from 'utility'

export const GetTimerListByAppopintment = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: 4,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: filter.search.search ?? null,
            }
            const result = await TimerService.byAppointment(id,params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error && id) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        total,
    }
}
export const GetTimerListByPatient = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: 4,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: filter.search.search ?? null,
            }
            const result = await TimerService.byPatient(id,params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error && id) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        total,
    }
}

export const GetTodayAppointmentTimer = (id) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TimerService.findappointmentTodayTimer(id)
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
    }
}

export const GetTodayPatientTimer = (id) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await TimerService.findpatientTodayTimer(id)
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
    }
}