import { Button, Input, Space, Table, DatePicker, Radio, Tag, Spin } from 'antd'
import dayjs from 'dayjs'
import { PrimaryButton } from 'elements'
import { MagnifyingGlass } from '@phosphor-icons/react'
import React, { useState, useRef, useMemo } from 'react'
import { ColumnSearchType, CommonConstant } from 'utility'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

export const Customized = styled(Table)`
    tbody {
        .grey-row {
            color: ${({ theme }) => theme.colors.grey};
        }
    }
    .ant-table-cell {
        padding: 4px !important;
    }
`
const GetInputBox = ({
    setSelectedKeys,
    selectedKeys,
    item,
    handleSearch,
    dataIndex,
    searchInput,
}) => {
    let compo = ''
    switch (item?.type) {
        case ColumnSearchType.Date:
            compo = (
                <DatePicker
                    value={!Array.isArray(selectedKeys) ? selectedKeys : null}
                    onChange={(e) => setSelectedKeys(e)}
                    onPressEnter={() =>
                        handleSearch(selectedKeys, dataIndex, item?.type)}
                    style={{ marginBottom: 8, display: 'block' }}
                    className="col-12"
                />
            )
            break
        case ColumnSearchType.Radio:
            compo = (
                <Radio.Group
                    style={{ marginBottom: 8, display: 'block' }}
                    onChange={(e) => setSelectedKeys(e.target.value)}
                    value={selectedKeys}
                >
                    {item.options.map((item) => (
                        <Radio key={item.value} value={item.value}>
                            {item.label}
                        </Radio>
                    ))}
                </Radio.Group>
            )
            break
        default:
            compo = (
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys}
                    onChange={(e) => setSelectedKeys(e.target.value)}
                    onPressEnter={() =>
                        handleSearch(selectedKeys, dataIndex, item?.type)}
                    style={{ marginBottom: 8, display: 'block' }}
                    type={item.type}
                />
            )
            break
    }
    return compo
}
export const AppTable = ({
    columns,
    data,
    currentPage,
    pageChanged,
    nodata,
    pageSize = CommonConstant.defaultPageSize,
    loading = false,
    disabled,
    expandedRowRender,
    total,
    filterChanged,
    onChange,
    onRowClick,
    onRowDoubleClick,
    ...rest
}) => {
    const searchInput = useRef(null)
    const [sortedInfo, setSortedInfo] = useState({})
    const { t } = useTranslation()

    const handleSearch = (selectedKeys, dataIndex, type) => {
        let value = selectedKeys
        if (type === ColumnSearchType.Date) {
            value = dayjs(selectedKeys).format('YYYY-MM-DD')
        }
        filterChanged(dataIndex, value)
    }

    const handleReset = (clearFilters, dataIndex) => {
        clearFilters()
        filterChanged(dataIndex, undefined)
    }

    const handleChange = (_, __, sorter) => {
        setSortedInfo({ [sorter.columnKey]: sorter.order })
        if (onChange) {
            onChange(sorter)
        }
    }

    const getColumnSearchProps = (dataIndex, item) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            clearFilters,
            close,
        }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <GetInputBox
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    item={item}
                    handleSearch={(selectedKeys, dataIndex, type) => {
                        handleSearch(selectedKeys, dataIndex, type)
                        close()
                    }}
                    dataIndex={dataIndex}
                    searchInput={searchInput}
                />
                <Space>
                    <PrimaryButton
                        type="primary"
                        onClick={() => {
                            handleSearch(selectedKeys, dataIndex, item?.type)
                            close()
                        }}
                        icon={<MagnifyingGlass className="me-1" size={15} />}
                        size="small"
                        className="d-flex align-items-center"
                    >
                        {t('search')}
                    </PrimaryButton>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                handleReset(clearFilters, dataIndex)
                                close()
                            }
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('reset')}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        {t('close')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <MagnifyingGlass size={18} color={filtered ? 'blue' : 'grey'} />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
    })

    const customColumns = useMemo(() => {
        return columns.filter((item) => {
            if (item?.hide) {
                return ''
            }
            let column = { ...item }
            if (item?.search) {
                column = {
                    ...column,
                    ...getColumnSearchProps(item.key, item),
                }
            }
            if (item?.sort) {
                column = {
                    ...column,
                    ...getColumnSearchProps(item.key, item),
                    sorter: true,
                    sortOrder: Object.keys(sortedInfo).includes(item.key)
                        ? sortedInfo[item.key]
                        : null,
                }
            }
            return column
        })
    }, [columns, sortedInfo])

    return (
        <div>
            <Customized
                columns={customColumns}
                disabled={disabled}
                dataSource={data}
                rowKey={(ele, i) => ele._id + i || i + new Date().toUTCString}
                loading={{
                    indicator: (
                        <div>
                            <Spin />
                        </div>
                    ),
                    spinning: !data.length && loading,
                }}
                locale={{
                    emptyText: nodata,
                }}
                pagination={{
                    pageSize: `${pageSize}`,
                    showSizeChanger: true,
                    current: currentPage,
                    total,
                    onChange: pageChanged,
                }}
                handleChange={handleChange}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () =>
                            onRowClick && onRowClick(record, rowIndex), // click row
                        onDoubleClick: () =>
                            onRowDoubleClick &&
                            onRowDoubleClick(record, rowIndex), // double click row
                    }
                }}
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                }}
                {...rest}
            />
        </div>
    )
}

export const TableFilters = ({ search = {}, filterChanged }) => {
    const { t } = useTranslation()
    const filteredSearch = useMemo(
        () => Object.keys(search).filter((x) => !!search[x]),
        [search],
    )

    return (
        <>
            {filteredSearch.length > 0 && (
                <div className="mt-2 mb-3 d-flex aglin-items-center justify-content-end">
                    <b className="me-1">{t('filters')}: &nbsp;</b>
                    {filteredSearch.map((item) => (
                        <Tag
                            closable
                            key={item}
                            onClose={() =>
                                filterChanged('search', { [item]: undefined })}
                        >
                            {t(`form-fields.${item}`)}: {search[item]}
                        </Tag>
                    ))}
                    <PrimaryButton
                        type="link"
                        onClick={() => filterChanged('search', null)}
                    >
                        {t('clear')}
                    </PrimaryButton>
                </div>
            )}
        </>
    )
}
