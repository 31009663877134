import { Divider } from 'antd';
import { useAuth } from 'context';
import React from 'react'
import styled from 'styled-components'
import { DateFormat, DateUtility } from 'utility';

const Box = styled.div`
  page-break-inside: avoid;
  padding: 15px;
  margin-block: 0 !important;
  h4 {
    margin-block: 0 !important;
  }
`;

const CodesList = ({ list,total }) => (
  <div className="mx-3 col-12 text-start">
    {list.map((ele) => (
      <div className="row mb-3" key={ele._id} style={{ pageBreakBefore: "auto" }}>
        <h4 className="mx-0 col-10">
          <div className="d-flex flex-column">
            <div>{ele?.invoicecode}</div>
          </div>
        </h4>
        <h4 className="col-1">
          {ele?.cost}
        </h4>
      </div>
    ))}
    <div className="row mb-3" style={{ pageBreakBefore: "auto" }}>
        <h4 className="mx-0 col-10">
          <div className="d-flex flex-column">
            <div>Total cost:</div>
          </div>
        </h4>
        <h4 className="col-1">
          {total}
        </h4>
      </div>
  </div>
);

const Main = styled.div`margin-bottom: 50px;`;
export const EncounterPdf = ({ data }) => {
  const { user } = useAuth()
  const { patient, encounter } = data
  const totalCost = data?.encounter?.data.reduce((total, ele) => total + (ele?.cost || 0), 0);
  return (
    <Box>
      <Main>
        <div className="row">
          <div className="col-4 text-start">
            <h3>
              {user?.firstName} {user?.lastName}
            </h3>
            <h4>
              {user?.specialist}&nbsp;&nbsp;&nbsp;({user?.degree}&nbsp;)
            </h4>
            <h4>
              {user?.address?.address}, {user?.address?.city},
            </h4>
            <h4>
              {user?.address?.country}, {user?.address?.postalCode}
            </h4>
            <h4>Phone: {user?.phone}</h4>
          </div>
          <div className="col text-end">
            <h4 className="mx-5 my-0">{DateUtility.dateToString(new Date(), DateFormat.date)}</h4>
          </div>
        </div>
        <Divider />
        <div className="">
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Patient</h4>:
            <h4 className="my-0 mx-3">
              {patient?.firstName} {patient?.lastName} -{' '}
              {patient?.birthdate ? DateUtility.dateToString(patient?.birthdate, DateFormat.date) : ''}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Age</h4>:
            <h4 className="my-0 mx-3">
              {patient?.birthdate ? DateUtility.getDistanceInWord(new Date(patient?.birthdate)) : ''}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Email</h4>:
            <h4 className="my-0 mx-3">
              {patient?.email}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Gender</h4>:
            <h4 className="my-0 mx-3">
              {patient?.gender}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Phone</h4>:
            <h4 className="my-0 mx-3">{
            }
              {patient?.phone}{patient?.alternatePhone?.length && ","}{patient?.alternatePhone?.length && patient?.alternatePhone?.map(ele => ele.phone).toString()}
            </h4>
          </div>
          <Divider />
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Type</h4>:
            <h4 className="my-0 mx-3">
              {encounter?.encounter?.encounter_type}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Content type</h4>:
            <h4 className="my-0 mx-3">
              {encounter?.encounter?.content_type}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <h4 className="col-1 ms-3 text-start">Reason</h4>:
            <h4 className="my-0 mx-3">
              {encounter?.encounter?.chief_complaint}
            </h4>
          </div>
          <Divider />
          <div className="d-flex mb-1">
            <CodesList list={data?.encounter?.data || []} total={totalCost} />
          </div>
        </div>
        <Divider />
        <div className="d-flex justify-content-end">
          <div className="mx-5">
            <h4 className="text-center">
              {user?.firstName} {user?.lastName}
            </h4>
            <h4 className="text-center">
              {user?.specialist}&nbsp;&nbsp;&nbsp;({user?.degree}&nbsp;)
            </h4>
          </div>
        </div>
      </Main>
    </Box>
  )
}
