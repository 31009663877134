import { yupResolver } from '@hookform/resolvers/yup'
import { Popconfirm, Space, notification, Modal, Form, Button } from 'antd'
import {
    AutoCompleteDropDown,
    DraggableAppTable,
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    HeaderTitle,
    PageHeader,
} from 'components'
import {
    DangerButton,
    PrimaryButton,
    FormTextAreaFormField,
    FormTextFormField,
    FormSelectionField,
} from 'elements'
import { Pencil, Trash } from '@phosphor-icons/react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DesktopMode, MobileMode, GetVaccination, GetExpiryList } from 'hooks'
import * as yup from 'yup'
import { useState, useEffect } from 'react'
import { Plus } from 'phosphor-react'
import { VaccinationService } from 'utility'
import { useAuth } from 'context'

const VaccinationSchema = yup.object().shape({
    description: yup.string().required('description is required'),
    name: yup.string().required('*name is required'),
    expiry: yup.number().nullable(),
})

export const AddVaccinationModal = ({
    open,
    data,
    editMode,
    onClose,
    loading,
    onSubmit,
}) => {
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VaccinationSchema),
    })
    const { t } = useTranslation()
    const { data: expiryList } = GetExpiryList(t)
    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={
                editMode
                    ? t('form-fields.edit-vaccination')
                    : t('form-fields.add-vaccination')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="name"
                                label={t('name')}
                                placeholder=""
                                errors={errors?.name || ''}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-12">
                            <FormSelectionField
                                control={control}
                                name="expiry"
                                label={t('Expiry')}
                                placeholder=""
                                errors={errors?.expiry || ''}
                                defaultValue=""
                                options={expiryList}
                            />
                        </div>
                        <div className="col-12">
                            <FormTextAreaFormField
                                control={control}
                                name="description"
                                label={t('form-fields.description')}
                                placeholder=""
                                rows={4}
                                errors={errors?.description || ''}
                                defaultValue=""
                            />
                        </div>
                    </div>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('form-fields.edit-vaccination')
                            : t('form-fields.add-vaccination')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}

const AddVaccinationTypeModal = ({
    open,
    data,
    editMode,
    onClose,
    loading,
    onSubmit,expiryList,
}) => {
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VaccinationSchema),
    })
    const { t } = useTranslation()
    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode, data])
    return (
        <Modal
            title={
                editMode
                    ? t('form-fields.edit-vaccination')
                    : t('form-fields.add-vaccination')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12">
                            <FormTextFormField
                                control={control}
                                name="name"
                                label={t('Name')}
                                placeholder=""
                                errors={errors?.name || ''}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-12">
                            <FormSelectionField
                                control={control}
                                name="expiry"
                                label={t('Expiry')}
                                placeholder=""
                                errors={errors?.expiry || ''}
                                defaultValue=""
                                options={expiryList}
                            />
                        </div>
                        <div className="col-12">
                            <FormTextAreaFormField
                                control={control}
                                name="description"
                                label={t('form-fields.description')}
                                placeholder=""
                                rows={4}
                                errors={errors?.description || ''}
                                defaultValue=""
                            />
                        </div>
                    </div>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('form-fields.edit-vaccination')
                            : t('form-fields.add-vaccination')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}
export const VaccinationTab = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const {
        data,
        setData,
        filterChanged,
        refetch,
        total = { page: 1 },
        filter,
        pageChanged,
    } = GetVaccination()
    const { data: expiryList } = GetExpiryList(t)
    const { patient, user } = useAuth()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                formData.expiry = parseFloat(formData.expiry)
                formData.patient = patient._id
                formData.doctor = user._id
                await VaccinationService.add({ ...formData })
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await VaccinationService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }
    const removeDiagnostic = async (id) => {
        try {
            await VaccinationService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }
    const columns = [
        {
            title: t('form-fields.name'),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t('form-fields.description'),
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: t('form-fields.expiry'),
            dataIndex: 'expiry',
            key: 'expiry',
            render: (text) => <>{expiryList.find(ele => ele.value === text)?.label || "-"}</>,
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton
                        icon={<Pencil onClick={() => onOpen(data)} />}
                    />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeDiagnostic(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await VaccinationService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('form-fields.vaccination')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'allergies category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) =>
                                    filterChanged('search', { type: data })}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.vaccination-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.Allergies-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-1"
                            onSearch={(data) =>
                                filterChanged('search', { name: data })}
                        />
                    </FlexRow>
                </FlexColumn>
                <AddVaccinationTypeModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                expiryList={expiryList}
            />
            <div className="mt-sm-2 mt-md-0 overflow-auto mt-3">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
            </MobileMode>
            <AddVaccinationTypeModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
                expiryList={expiryList}
            />
            <div className="mt-sm-2 mt-md-0">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
