import { DatePicker, Popconfirm, Space } from 'antd'
import { FlexBox } from 'components'
import { Funnel } from 'phosphor-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DateFormat, DateUtility, LocaleFormat } from 'utility'

const Button = styled.button`
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
        color: #373a3c;
        background-color: #e6e6e6;
        border-color: #adadad;
    }
`

const WaitingHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    font-size: 16px;
    .date-current {
        flex-grow: 1;
        padding: 0 10px;
        text-align: center;
    }
`

const ButtonWrapper = styled.div`
    display: inline-block;
    white-space: nowrap;
    .today {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .pre {
        border-radius: 0;
        margin-left: -1px;
    }
    .next {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
    }
`

const FilterBox = styled.div`
    border-left-top-radius: 5px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: 1px solid #d9d9d9;
    border-right: 0px;
    height: 100%;
`

export const CalendarCustomeHeader = ({ date, setDate }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <WaitingHeader>
            <ButtonWrapper>
                <Button
                    className="pre"
                    onClick={() => setDate(DateUtility.subDays(date, 1))}
                >
                    {t('calendar-properties.previous')}
                </Button>
                <Button className="today" onClick={() => setDate(new Date())}>
                    {t('calendar-properties.today')}
                </Button>
                <DatePicker
                    onChange={(value) => setDate(new Date(value.toDate()))}
                    placeholder={t('select-date')}
                    value={DateUtility.dayJSFormat(date)}
                    format={DateFormat.inputEuDate}
                    style={{ width: 160 }}
                    className="me-2"
                />
                <Button
                    className="next"
                    onClick={() => setDate(DateUtility.addDay(date, 1))}
                >
                    {t('calendar-properties.next')}
                </Button>
            </ButtonWrapper>
            <div className="date-current">
                {DateUtility.dateToString(
                    date,
                    DateFormat.weekDate,
                    LocaleFormat[language],
                )}
            </div>
        </WaitingHeader>
    )
}

export const BlockHeader = ({ title, children, searchElement }) => {
    const { t } = useTranslation()
    return (
        <>
            <FlexBox className="justify-content-between align-items-center mt-0 mb-3">
                <h3 className="mt-0 align-items-center mb-0">{title}</h3>
                <FlexBox className="col-6">
                    <Space.Compact className="align-items-center col-12">
                        <FilterBox className="align-items-center d-flex">
                            <Popconfirm
                                title={t('Filters')}
                                description={() => <>{children}</>}
                                showCancel={false}
                            >
                                <Funnel className="mx-2" size={20} />
                            </Popconfirm>
                        </FilterBox>
                        <div className="col">{searchElement}</div>
                    </Space.Compact>
                </FlexBox>
            </FlexBox>
        </>
    )
}
