import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'

class MetaData extends CRUDService {
    constructor() {
        super(APIPath.metadata)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.aboutUs}/order-by`, data)
    }

    Frame(data) {
        return BaseService.put(`${APIPath.metadata}/frame`, data)
    }

    getFrame() {
        return BaseService.get(`${APIPath.metadata}/frame`)
    }

    getCustomeEncounterFields() {
        return BaseService.get(`${APIPath.metadata}/${APIPath.encounterFields}`)
    }

    addCustomeEncounterFields(data) {
        return BaseService.post(
            `${APIPath.metadata}/${APIPath.encounterFields}`,
            data,
        )
    }

    updateCustomeEncounterFields(id, data) {
        return BaseService.put(
            `${APIPath.metadata}/${APIPath.encounterFields}/${id}`,
            data,
        )
    }

    removeCustomeEncounterFields(id) {
        return BaseService.remove(
            `${APIPath.metadata}/${APIPath.encounterFields}/${id}`,
        )
    }

    updateDefaultTimer(data) {
        return BaseService.put(
            `${APIPath.metadata}/${APIPath.defaultTimerDuration}`,
            data,
        )
    }

    getDefaultTimer() {
        return BaseService.get(
            `${APIPath.metadata}/${APIPath.defaultTimerDuration}`,
        )
    }
}

const MetaDataService = new MetaData()
Object.freeze(MetaDataService)
export { MetaDataService }
