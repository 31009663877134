import { Form, Select, Input, DatePicker, Popconfirm, Tag } from 'antd'
import { FlexBox, FlexRowBetween } from 'components'
import { useAuth } from 'context'
import { DangerButton, PrimaryButton } from 'elements'
import {
    GetEncounterOptions,
    CCBCCOptions,
    GetUserVitalSignByPatient,
    GetUserAllergiesByPatient,
    GetDiagnosticListByPatient,
    GetMedicalHistoryListByPatient,
} from 'hooks'
import {
    EncounterDocumentAddEdit,
    MedicationAddEdit,
} from 'page-components/patient'
import {
    AddEditDocumentPopup,
    SelectDocumentsPopup,
} from 'page-components/patient/documents'
import { Plus, X } from 'phosphor-react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
    DateUtility,
    ContentTypeOptions,
    CommonUtility,
    PatientDocumentsType,
    PatientDocumentService,
    EncounterType,
    DateFormat,
} from 'utility'
import { CustomeEncounter } from './custom'

import { useNavigate } from 'react-router-dom'

const TagSelect = styled(Tag)`
    ${({ disabled }) =>
        disabled &&
        `
        background-color:#0000000a;    
        color:#00000040;
        cursor: not-allowed !important;
    `}
`
const EncounterContainer = styled.div`
    background-color: #ffffff !important;
    // padding: 5px;
    // border-radius: 5px;
`

const { TextArea } = Input

const SOAPEncounter = ({ onChange }) => {
    const { t } = useTranslation()
    return (
        <div className="row">
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'subjective']}
                    label="Subjective"
                    // rules={[{ required: true }]}
                >
                    <TextArea
                        name="subjective"
                        placeholder={t('add-edit.enter-subjective')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item name={['metadata', 'objective']} label="Objective">
                    <TextArea
                        name="objective"
                        placeholder={t('add-edit.enter-objective')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'assessment']}
                    label={t('form-fields.assessment')}
                >
                    <TextArea
                        name="assessment"
                        placeholder={t('add-edit.enter-assessment')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'plan']}
                    label={t('form-fields.plan')}
                >
                    <TextArea
                        name="plan"
                        placeholder={t('enter-plan')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

const APSOEncounter = ({ onChange }) => {
    const { t } = useTranslation()

    return (
        <div className="row">
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'assessment']}
                    label={t('form-fields.assessment')}
                >
                    <TextArea
                        name="assessment"
                        placeholder={t('add-edit.enter-assessment')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'plan']}
                    label={t('form-fields.plan')}
                >
                    <TextArea
                        name="plan"
                        placeholder={t('add-edit.enter-plan')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'subjective']}
                    label={t('form-fields.subjective')}
                >
                    <TextArea
                        name="subjective"
                        placeholder={t('add-edit.enter-subjective')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'objective']}
                    label={t('form-fields.objective')}
                >
                    <TextArea
                        name="objective"
                        placeholder={t('add-edit.enter-objective')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

const DARPEncounter = ({ onChange }) => {
    const { t } = useTranslation()
    return (
        <div className="row">
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'data']}
                    label={t('form-fields.data')}
                >
                    <TextArea
                        name="data"
                        placeholder={t('add-edit.enter-data')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'assessment']}
                    label={t('form-fields.assessment')}
                >
                    <TextArea
                        name="assessment"
                        placeholder={t('add-edit.enter-assessment')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'plan']}
                    label={t('form-fields.plan')}
                >
                    <TextArea
                        name="plan"
                        placeholder={t('add-edit.enter-plan')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
        </div>
    )
}

const SBAREncounter = ({ onChange }) => {
    const { t } = useTranslation()
    return (
        <div className="row">
            <div className="col-6">
                <Form.Item name={['metadata', 'situation']} label="Situation">
                    <TextArea
                        name="situation"
                        placeholder={t('add-edit.enter-situation')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'background']}
                    label={t('form-fields.background')}
                >
                    <TextArea
                        name="background"
                        placeholder={t('add-edit.enter-background')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'assessment']}
                    label={t('form-fields.assessment')}
                >
                    <TextArea
                        name="assessment"
                        placeholder={t('add-edit.enter-assessment')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    label={t('form-fields.recommendation')}
                    name={['metadata', 'recommendation']}
                >
                    <TextArea
                        name="recommendation"
                        placeholder={t('add-edit.enter-recommendation')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
        </div>
    )
}
const EmailFileList = ({ list, onRemove, disabled }) => {
    return (
        <>
            {list?.map((ele) => {
                return (
                    <TagSelect
                        className="col-2 p-1 mb-2 pointer"
                        disabled={disabled}
                    >
                        <FlexRowBetween title={ele.name}>
                            <div>
                                {CommonUtility.truncateString(ele.name, 10)}
                            </div>
                            <div onClick={() => !disabled && onRemove(ele)}>
                                <X />
                            </div>
                        </FlexRowBetween>
                    </TagSelect>
                )
            })}
        </>
    )
}
const EmailEncounter = ({ patient, onChange, form, item, disabled }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [selectOpen, setSelectOpen] = useState(false)
    const [selectedDocuments, setSelectedDocuments] = useState([])
    const onClose = (data) => {
        if (data) {
            const newFiles = [...selectedDocuments, ...data]
            setSelectedDocuments(newFiles)
            form.setFieldsValue({ metadata: { files: newFiles } })
        }
        setOpen(false)
    }
    const onSelect = async (data) => {
        let files = [...selectedDocuments]
        if (files?.find((ele) => ele._id === data._id) && data._id) {
            files = files.filter((ele) => ele._id !== data._id)
            if (data.type === PatientDocumentsType.EMAIL) {
                await PatientDocumentService.remove(data._id)
            }
        } else {
            files = [...selectedDocuments, data]
        }
        setSelectedDocuments(files)
        form.setFieldsValue({ metadata: { files } })
    }

    useEffect(() => {
        if (item?.metadata?.files?.length) {
            setSelectedDocuments(item?.metadata?.files)
        }
    }, [item])

    return (
        <div className="row">
            <div className="col-6" />
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'cc']}
                    label={t('form-fields.cc')}
                    rules={[
                        {
                            required: true,
                            message: t('form-message.required', {
                                name: t(`form-fields.cc`),
                            }),
                        },
                    ]}
                >
                    <Select
                        name="cc"
                        placeholder={t('add-edit.enter-cc')}
                        rows={5}
                        onKeyDown={onChange}
                        options={CCBCCOptions}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'sender']}
                    label={t('form-fields.sender')}
                    rules={[
                        {
                            required: true,
                            message: t('form-message.required', {
                                name: t(`form-fields.sender`),
                            }),
                        },
                    ]}
                >
                    <TextArea
                        name="sender"
                        placeholder={t('add-edit.enter-sender')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    name={['metadata', 'to']}
                    label={t('form-fields.to')}
                    rules={[
                        {
                            required: true,
                            message: t('form-message.required', {
                                name: t(`form-fields.to`),
                            }),
                        },
                    ]}
                >
                    <TextArea
                        name="to"
                        placeholder={t('add-edit.enter-to')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    label={t('form-fields.cci')}
                    name={['metadata', 'cci']}
                    rules={[
                        {
                            required: true,
                            message: t('form-message.required', {
                                name: t(`form-fields.cci`),
                            }),
                        },
                    ]}
                >
                    <TextArea
                        name="cci"
                        placeholder={t('add-edit.enter-cci')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    label={t('form-fields.subject')}
                    name={['metadata', 'subject']}
                    rules={[
                        {
                            required: true,
                            message: t('form-message.required', {
                                name: t(`form-fields.subject`),
                            }),
                        },
                    ]}
                >
                    <TextArea
                        name="subject"
                        placeholder={t('add-edit.enter-subject')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>{' '}
            <div className="col-6">
                <Form.Item
                    label={t('form-fields.content')}
                    name={['metadata', 'content']}
                    rules={[
                        {
                            required: true,
                            message: t('form-message.required', {
                                name: t(`form-fields.content`),
                            }),
                        },
                    ]}
                >
                    <TextArea
                        name="content"
                        placeholder={t('add-edit.enter-content')}
                        rows={5}
                        onKeyDown={onChange}
                    />
                </Form.Item>
            </div>
            <div className="col-6">
                <Form.Item
                    label={t('form-fields.files')}
                    name={['metadata', 'files']}
                >
                    <FlexBox className="flex-wrap">
                        <PrimaryButton
                            onClick={() => setSelectOpen(true)}
                            className="d-flex align-items-center mb-2"
                        >
                            {t('Select From Patients Documents')}&nbsp;
                            <Plus />
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => setOpen(true)}
                            className=" mx-3 d-flex align-items-center mb-2"
                        >
                            {t('Upload New Files Here')}&nbsp;
                            <Plus />
                        </PrimaryButton>
                        <EmailFileList
                            list={selectedDocuments}
                            onRemove={onSelect}
                            disabled={disabled}
                        />
                    </FlexBox>
                </Form.Item>
            </div>
            <AddEditDocumentPopup
                onClose={onClose}
                open={open}
                patientId={patient?._id}
                localSubmit
                documentType={PatientDocumentsType.EMAIL}
            />
            <SelectDocumentsPopup
                onClose={() => setSelectOpen(false)}
                open={selectOpen}
                patientId={patient?._id}
                onSelect={onSelect}
                selactedRows={selectedDocuments}
                documentType={PatientDocumentsType.PATIENT}
            />
        </div>
    )
}

function renderEncoutner({
    contentType,
    encounterType,
    item,
    patient,
    quickEntryList,
    form,
    disabled,
    setReadable,
    // readable,
    onDelete,
    drugOptions,
    onDrugAdd,
    drugData,
    dropDocument,
    diagnosticOptions,
    patientDrugData,
    setPatientDrugData,
    patientDrugDataFilterChanged,
    lastmedication,
    readMode,
}) {
    const encounter_type = encounterType || item.encounter_type
    const onChange = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            const wordindex = e.target.value.lastIndexOf(';')
            const searchword = e.target.value.substr(
                wordindex,
                e.target.value.length,
            )
            const word = quickEntryList.find((ele) => searchword === ele.key)
            if (word) {
                const str1 = e.target.value.substr(0, wordindex)
                const newstr = `${str1} ${word.value} `
                form.setFieldsValue({ metadata: { [e.target.name]: newstr } })
            }
        }
    }
    if (encounter_type === EncounterType.CUSTOM) {
        return (
            <CustomeEncounter
                form={form}
                item={item}
                readMode={readMode}
                onChange={onChange}
            />
        )
    }
    if (encounter_type === EncounterType.EMAIL) {
        return (
            <EmailEncounter
                item={item}
                readMode={readMode}
                onChange={onChange}
                patient={patient}
                form={form}
                disabled={disabled}
            />
        )
    }
    if (encounter_type === EncounterType.MEDICATION) {
        return (
            <MedicationAddEdit
                item={item}
                onChange={onChange}
                patient={patient}
                form={form}
                disabled={disabled}
                setReadable={setReadable}
                // readable={readable}
                onDelete={onDelete}
                readMode={readMode}
                drugOptions={drugOptions}
                onDrugAdd={onDrugAdd}
                drugData={drugData}
                patientDrugData={patientDrugData}
                setPatientDrugData={setPatientDrugData}
                patientDrugDataFilterChanged={patientDrugDataFilterChanged}
                lastmedication={lastmedication}
            />
        )
    }
    if (encounter_type === EncounterType.DOCUMENT) {
        return (
            <EncounterDocumentAddEdit
                item={item}
                onChange={onChange}
                patient={patient}
                readMode={readMode}
                form={form}
                disabled={disabled}
                setReadable={setReadable}
                // readable={readable}
                onDelete={onDelete}
                drugOptions={drugOptions}
                onDrugAdd={onDrugAdd}
                drugData={drugData}
                dropDocument={dropDocument}
                diagnosticOptions={diagnosticOptions}
            />
        )
    }
    switch (contentType) {
        case 'soap':
            return <SOAPEncounter onChange={onChange} />

        case 'apso':
            return <APSOEncounter onChange={onChange} />

        case 'darp':
            return <DARPEncounter onChange={onChange} />

        case 'sbar':
            return <SBAREncounter onChange={onChange} />
        default:
            return <></>
    }
}

const UserVitalsNameToName = {
    'Fréquence cardiaque': 'p_vital_hr',
    'Pression artérielle': 'p_vital_bp',
    'Température corporelle': 'p_vital_temp',
    'Fréquence respiratoire': 'p_vital_resp',
    'Saturation en oxygène': 'p_vital_o2',
    'Glucose sanguin': 'p_vital_bg',
    Poids: 'p_vital_weight',
    Taille: 'p_vital_height',
    'Indice de masse corporelle (IMC)': 'p_vital_imc',
}

export const EncounterEditor = ({
    item,
    onSave,
    onDelete,
    quickEntryList,
    patient,
    drugOptions,
    readMode,
    drugData,
    onDrugAdd,
    lastmedication,
    dropDocument,
    diagnosticOptions,
    patientDrugData,
    setPatientDrugData,
    patientDrugDataFilterChanged,
}) => {
    const [form] = Form.useForm()
    const [readable, setReadable] = useState(false)
    console.log('readable', readable)
    const { t } = useTranslation()
    const { user } = useAuth()
    const { data: EncounterTypeOptions } = GetEncounterOptions(t)
    const [contentType, setContentType] = useState('')
    const [encounterType, setEncounterType] = useState('')
    const [important, setImportant] = useState(false)

    const { data: userVitalSigns } = GetUserVitalSignByPatient(patient._id)
    const { data: userAllergies } = GetUserAllergiesByPatient(patient._id)
    const { data: userDiagnostic } = GetDiagnosticListByPatient(patient._id)
    const { data: userHistory } = GetMedicalHistoryListByPatient(patient._id)

    const navigate = useNavigate()

    useEffect(() => {
        if (!item.local) {
            const resetData = {
                ...item,
                date: DateUtility.dayJSFormat(item.date || item.createdAt),
            }
            if (resetData?.metadata?.startdate) {
                resetData.metadata.startdate = DateUtility.dayJSFormat(
                    resetData.metadata.startdate,
                )
            }
            if (resetData?.metadata?.enddate) {
                resetData.metadata.enddate = DateUtility.dayJSFormat(
                    resetData.metadata.enddate,
                )
            }
            form.setFieldsValue({
                ...resetData,
            })
            setContentType(item?.content_type || user.content)
            form.setFieldValue('encounter_type', item?.encounter_type)
        } else {
            form.setFieldValue('name', item.name)
            form.setFieldValue('encounter_type', item?.encounter_type)
            form.setFieldValue('date', item.date || item.createdAt)
            form.setFieldValue(
                'content_type',
                item?.content_type || user.content,
            )
            setContentType(item?.content_type || user.content)
        }
        setImportant(item?.important)
    }, [user, item])

    useEffect(() => {
        if (item.normal) {
            setReadable(true)
        }
        if (!item.newlocal) {
            setReadable(true)
        }
    }, [item])

    const onFinish = () => {
        onSave(item, { ...form.getFieldsValue(), important })

        setReadable(true)
    }

    const hideEncounterNameContent = useMemo(() => {
        return ![EncounterType.MEDICATION, EncounterType.DOCUMENT].includes(
            encounterType || item?.encounter_type,
        )
    }, [encounterType, item?.encounter_type])

    const generatePDF = async () => {
        const formValues = form.getFieldsValue()
        const params = {
            p_id: patient._id,
            p_gender: patient.gender,
            p_fullname: `${patient.firstName} ${patient.lastName}`,
            p_birthdate: DateUtility.dateToString(
                patient.birthdate,
                DateFormat.euDate,
            ),
            p_address: patient.address.address,
            p_zipcode: patient.address.postalCode,
            p_city: patient.address.city,
            p_phone: patient.phone,
            d_fullname: `${user.firstName} ${user.lastName}`,
            d_speciality: user.specialist,
            e_date: form.getFieldValue('date')
                ? DateUtility.dateToString(
                      form.getFieldValue('date').$d,
                      DateFormat.euDate,
                  )
                : '',
            e_name: item.name || '',
            e_plan: formValues.metadata.plan || '',
            e_subjective: formValues.metadata.subjective || '',
            e_assessment: formValues.metadata.assessment || '',
            d_address: user.address.address,
            d_city: user.address.city,
            d_zip: user.address.postalCode,
            d_phone: user.phone,
            p_allergy: userAllergies
                .map((allergy) => allergy.allergiestype.type)
                .join(', '),
            p_diagnostic: userDiagnostic
                .map((item) => item.diagnostictype.name)
                .join(', '),
            p_history: userHistory
                .map((item) => item.medicalhistorytype.type)
                .join(', '),
            // e_date: form.getFieldValue('date') ? DateUtility.dateToString(form.getFieldValue('date').$d) : "",
        }
        userVitalSigns.map((vitalSign) => {
            params[UserVitalsNameToName[vitalSign.vitalsigntype.title]] = `${
                vitalSign.value
            }${
                vitalSign.unit === '1'
                    ? vitalSign.vitalsigntype.unit
                    : vitalSign.unit
            }`
            return ''
        })

        navigate(
            `/editor?template=patient-report${Object.keys(params)
                .map((key) => `&${key}=${params[key].replace('#', '%23')}`)
                .join('')}`,
        )
    }

    return (
        <div>
            <EncounterContainer className="my-3">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    // disabled={readable}
                >
                    {!item.normal && (
                        <>
                            <div className="row">
                                <div className="col-4">
                                    <Form.Item
                                        name="encounter_type"
                                        label={t('form-fields.encounter-type')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'form-message.required',
                                                    {
                                                        name: t(
                                                            `form-fields.encounter-type`,
                                                        ),
                                                    },
                                                ),
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={EncounterTypeOptions}
                                            showArrow
                                            onSelect={(data) => {
                                                setEncounterType(data)
                                            }}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="col-4">
                                    <Form.Item
                                        name="name"
                                        label={t('form-fields.encounter-name')}
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'form-message.required',
                                                    {
                                                        name: t(
                                                            `form-fields.encounter-name`,
                                                        ),
                                                    },
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t('name')} />
                                    </Form.Item>
                                </div>
                                {hideEncounterNameContent && (
                                    <>
                                        <div className="col-4">
                                            <Form.Item
                                                name="content_type"
                                                label={t(
                                                    'form-fields.content-type',
                                                )}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t(
                                                            'form-message.required',
                                                            {
                                                                name: t(
                                                                    `form-fields.content-type`,
                                                                ),
                                                            },
                                                        ),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    options={ContentTypeOptions}
                                                    showArrow
                                                    onChange={setContentType}
                                                />
                                            </Form.Item>
                                        </div>
                                    </>
                                )}
                                <div className="col-4">
                                    <Form.Item
                                        label={t('form-fields.encounter-date')}
                                        name="date"
                                        rules={[
                                            {
                                                required: true,
                                                message: t(
                                                    'form-message.required',
                                                    {
                                                        name: t(
                                                            `form-fields.encounter-date`,
                                                        ),
                                                    },
                                                ),
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            showTime
                                            format="DD.MM.YYYY HH:MM"
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                            <>
                                {renderEncoutner({
                                    contentType,
                                    encounterType,
                                    item,
                                    patient,
                                    quickEntryList,
                                    form,
                                    // disabled: readable,
                                    setReadable,
                                    // readable,
                                    onDelete,
                                    drugOptions,
                                    onDrugAdd,
                                    drugData,
                                    dropDocument,
                                    diagnosticOptions,
                                    patientDrugData,
                                    setPatientDrugData,
                                    patientDrugDataFilterChanged,
                                    lastmedication,
                                    readMode,
                                })}
                            </>
                        </>
                    )}
                    {item.normal && !item.normal_draft && (
                        <Form.Item
                            name="comments"
                            label={t('form-fields.comments')}
                        >
                            <TextArea
                                defaultValue={item?.comments}
                                placeholder={t('form-fields.comments')}
                            />
                        </Form.Item>
                    )}
                    {(hideEncounterNameContent ||
                        (item.normal && !item.normal_draft)) && (
                        <div className="d-flex align-items-center justify-content-between">
                            <div>
                                <Popconfirm
                                    title={t('delete')}
                                    description={t('message.delete-message', {
                                        name: `${t('form-fields.encounter')}`,
                                    })}
                                    onConfirm={() => onDelete(item._id)}
                                    okButtonProps={{ disabled: false }}
                                    cancelButtonProps={{ disabled: false }}
                                >
                                    <DangerButton
                                        disabled={readMode}
                                        htmlType="button"
                                    >
                                        {t('delete')}
                                    </DangerButton>
                                </Popconfirm>
                                {/* {!item.local && <PrimaryButton disabled={readMode} className="ms-2" onClick={() => setReadable(!readable)}>
                            {readable ? "Edit" : "Mark as Readable"}
                        </PrimaryButton>} */}
                            </div>
                            <span style={{ display: 'flex', gap: 12 }}>
                                <PrimaryButton
                                    // disabled={readMode}
                                    htmlType="button"
                                    onClick={generatePDF}
                                >
                                    {/* {t('add-edit.save-changes')} */}
                                    Document
                                </PrimaryButton>

                                <PrimaryButton
                                    disabled={readMode}
                                    htmlType="submit"
                                >
                                    {t('add-edit.save-changes')}
                                </PrimaryButton>
                            </span>
                        </div>
                    )}
                </Form>
            </EncounterContainer>
        </div>
    )
}
