import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Space, notification } from 'antd'
import { DraggableAppTable, AutoCompleteDropDown, HeaderTitle, PageHeader, FlexRowBetween } from 'components'
import { DangerButton, FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetDocumentStatusList, MobileMode } from 'hooks'
import { Check, Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DocumentStatusService, StatusIconList, StatusIcons } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const StatusShema = yup.object().shape({
    status: yup.string().required('*status name is required'),
    icon: yup.string().required("*icon is required"),
})

const AddStatusModal = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(StatusShema),
    })
    const { t } = useTranslation()
    useEffect(() => {
        reset(editMode ? data : {})
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-document-status")}` : `${t("add-edit.add-document-status")}`}
            open={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical">
                <div className="row">
                    <Form.Item className="col-9 mb-0">
                        <FormTextFormField
                            control={control}
                            name="status"
                            label={t("form-fields.status")}
                            placeholder="Status Name"
                            errors={errors?.status}
                            defaultValue=""
                        />
                    </Form.Item>
                    <Form.Item className="col-3 mb-0">
                        <FormSelectionField
                            control={control}
                            name="icon"
                            required
                            errors={errors.icon}
                            label={t("form-fields.icon")}
                            options={StatusIconList}
                            filterOption={false}
                            showSearch
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? `${t("edit")}` : `${t("create")}`} {t("form-fields.status")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

export const DocumentStatusTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { data, setData, refetch, filterChanged, total, filter, pageChanged } = GetDocumentStatusList()

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await DocumentStatusService.add(formData)
                notification.success({ message: t("notif-message.document-status-created") })
            } else {
                delete formData._id
                delete formData.doctor
                await DocumentStatusService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.document-status-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeDocumentCategory = async (id) => {
        try {
            await DocumentStatusService.remove(id)
            notification.success({ message: t("notif-message.document-status-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const setAsPrimary = async (id) => {
        try {
            await DocumentStatusService.setPrimary(id)
            notification.success({ message: t("notif-message.set-status-primary") })
            refetch()
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const columns = [
        {
            title: t("form-fields.status"),
            dataIndex: 'status',
            key: 'status',
            width: 700,
        },
        {
            title: t("form-fields.icon"),
            dataIndex: 'icon',
            key: 'icon',
            width: 200,
            render: (_, data) => (<>{StatusIcons?.[data?.icon] || ""}</>),
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <Popconfirm
                        title={t("set-status-primary")}
                        description={t("message.set-primary")}
                        onConfirm={() => setAsPrimary(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                        disabled={data.primary}
                    >
                        <PrimaryButton disabled={data.primary} icon={<Check />} htmlType="button">Set Primary</PrimaryButton>
                    </Popconfirm>
                    <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.status")}` })}
                        onConfirm={() => removeDocumentCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const orderChanged = async (items) => {
        try {
            await DocumentStatusService.OrderBy({ items: items.map(item => item._id), skip: filter.skip })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={<HeaderTitle className="col-6">
                        {t("settings.document-status")}
                    </HeaderTitle>}
                    right={<>
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t("search")} Documents ${t("form-fields.status")}`}
                            options={[]}
                            className="mb-0 ms-5"
                            onSearch={(data) => filterChanged("search", { status: data })}
                        />
                        <PrimaryButton
                            onClick={() => onOpen()}
                            className=" mx-3 d-flex align-items-center">
                            {t("add")}&nbsp;
                            <Plus />
                        </PrimaryButton></>}
                />
            </DesktopMode>
            <MobileMode>
                <FlexRowBetween>
                    <HeaderTitle className="">
                        {t("settings.document-status")}
                    </HeaderTitle><PrimaryButton
                        onClick={() => onOpen()}
                        className="d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                </FlexRowBetween>
                <AutoCompleteDropDown
                    name="search"
                    required
                    placeholder={`${t("search")} Documents ${t("form-fields.status")}`}
                    options={[]}
                    className="mb-0 ms-1"
                    onSearch={(data) => filterChanged("search", { status: data })}
                />
            </MobileMode>
            <AddStatusModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="overflow-auto mt-3 mt-sm-0">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
