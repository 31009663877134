import { FileTypes, FileUploadService, HL7Service, PatientLaboratoryService } from 'utility'
import { useEffect, useState } from 'react'
import { Button, Form, Modal, Space, notification } from 'antd'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
import { FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { FileUpload } from 'components'
import { GetDoctorsList, GetPatientList } from "hooks"
import { PatientSelectModal } from 'page-components'

const LaboratorySchema = yup.object().shape({
    content: yup.string().required(),
    patient: yup.string().required(),
})

export const AddEditLaboratoryModal = ({
    open,
    onClose,
    patientId,
    documentType,
    data,
}) => {
    const {
        reset, setValue,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(LaboratorySchema),
    })
    const { t } = useTranslation()
    const [filesArray, setFilesArray] = useState([])
    const [processing, setProcessing] = useState(false)
    const [parseData, setParseData] = useState({})
    const { user } = useAuth()
    const { optionList: patientList, filterChanged: patientFilterChanged } = GetPatientList({ all: true })
    const [patientSuggestionModal, setpatientSuggestionModal] = useState(false)
    const [patientSuggestionList, setpatientSuggestionList] = useState([])
    const { optionList: doctorList } = GetDoctorsList()

    useEffect(() => {
        if (data?._id) {
            reset({
                ...data,
                content: data.content,
                _id: data._id,
                patient: data?.patient?._id,
                validationAccess: data?.validationAccess,
            })
            setFilesArray(data.files)
        } else {
            setFilesArray([])
            reset({})
        }
    }, [open, data])

    const onNewFile = async (file, type = FileTypes.PDF) => {
        let list = filesArray.filter((x) => x._id !== file._id || x.type !== type)
        list = [...list, { ...file, type, name: file?.file?.name || file.name }]
        if (type === FileTypes.HL7) {
            parseHL7(file.file)
        }
        setFilesArray(list)
    }

    const onRemoveModel = async (file, type) => {
        setFilesArray(filesArray.filter((x) => x._id !== file._id || x.type !== type))
    }

    const parseHL7 = async (file) => {
        try {
            const formDataUpload = new FormData();
            formDataUpload.append('file', file);
            const data = await HL7Service.uploadHL7(formDataUpload);
            if (data) {
                setpatientSuggestionModal(true)
                setParseData(data.data)
                setpatientSuggestionList(data.patients)
            }
        } catch {
            notification.error({ message: t('notif-message.default-error') })

        }
    }

    const onSubmit = async (formData) => {
        const submitData = { ...formData }
        if (filesArray.length === 0) {
            notification.error({
                message: t('notif-message.upload-atleast-one'),
            })
            return
        }
        try {
            setProcessing(true)
            const files = []
            const promises = filesArray.map(async (item) => {
                if (item.url) {
                    files.push(item)
                    return
                }
                const { signedUrl, keyOrUrl } =
                    await FileUploadService.signedUrl({
                        ext: `.${item?.file?.name.split('.').pop()}`,
                        contentType: item.file.type || item.file.name.split('.').pop(),
                        isPublic: true,
                    })
                if (item.type === FileTypes.HL7) {
                    await FileUploadService.media(signedUrl, item.file, FileTypes.HL7)
                    files.push({ url: keyOrUrl, type: FileTypes.HL7, data: parseData, name: item.file.name })
                } else {
                    await FileUploadService.media(signedUrl, new File([item.file], item.file.name), item.file.type)
                    files.push({ url: keyOrUrl, type: FileTypes.PDF, name: item.file.name })
                }
            })
            let result = await Promise.all(promises)
            if (submitData?._id) {
                result = await PatientLaboratoryService.update(submitData?._id, {
                    ...submitData,
                    patient: formData.patient || patientId,
                    files,
                    _id: undefined,
                })
            } else {
                result = await PatientLaboratoryService.add({
                    ...submitData,
                    files,
                    patient: formData.patient || patientId,
                    doctor: user._id,
                    type: documentType,
                    _id: undefined,
                })
            }
            notification.success({
                message: t('notif-message.document-submit'),
            })
            onClose(result)
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            reset({ name: '' })
        }
    }

    const onPatientSuggestionClose = () => {
        setpatientSuggestionModal(false)
    }

    const onPatientSelect = (id) => {
        setValue("patient", id)
        setpatientSuggestionModal(false)
    }

    return (
        <Modal
            title={`${t('add-laboratory')}`}
            open={open}
            onCancel={() => {
                reset({})
                onClose()
            }}
            onSubmit={onSubmit}
            footer={false}
        >
            <Form layout="vertical">
                <FormTextFormField
                    control={control}
                    name="content"
                    label={t('form-fields.content')}
                    placeholder="Enter Content"
                    errors={errors?.content}
                />
                <FormSelectionField
                    control={control}
                    name="patient"
                    errors={errors?.patient}
                    label={t("form-fields.patient")}
                    options={patientList}
                    filterOption={false}
                    showSearch
                    onSearch={(data) => patientFilterChanged("search", { search: data })}
                    placeholder={`${t("search")} ${t("form-fields.patient")}`}
                />
                <FormSelectionField
                    control={control}
                    mode="multiple"
                    name="validationAccess"
                    label={t("form-fields.validationAccess")}
                    placeholder={`${t("search")} ${t("form-fields.validationAccess")}`}
                    options={doctorList}
                    onSelect={() => { }}
                    errors={errors?.validationAccess}
                />
                <div className="mb-2">
                    <FileUpload
                        onRemoveModel={(file) => onRemoveModel(file, FileTypes.HL7)}
                        onNewFile={(file) => onNewFile(file, FileTypes.HL7)}
                        fileList={filesArray.filter((file) => file.type === FileTypes.HL7)}
                        multiple
                        accept=".hl7"
                        acceptedString="HL7 File"
                    /></div>
                <div className="mb-2">
                    <FileUpload
                        onRemoveModel={(file) => onRemoveModel(file, FileTypes.PDF)}
                        onNewFile={(file) => onNewFile(file, FileTypes.PDF)}
                        fileList={filesArray.filter((file) => file.type === FileTypes.PDF)}
                        accept=".pdf"
                        acceptedString="PDF File"

                    />
                </div>
                <div className="d-flex justify-content-end">
                    <Space align="end" className="mt-3">
                        <Button key="cancel" onClick={() => onClose()}>
                            {t('cancel')}
                        </Button>
                        <PrimaryButton
                            key="submit"
                            loading={processing}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t('add-laboratory')}
                        </PrimaryButton>
                    </Space>
                </div>
            </Form>
            <PatientSelectModal open={patientSuggestionModal} list={patientSuggestionList} onClose={onPatientSuggestionClose} onSelect={onPatientSelect} />
        </Modal>
    )
}
