import React, { useState } from 'react';
import { Form, Radio, Button, Select, Input, DatePicker, Tag } from 'antd';
import dayjs from 'dayjs';
import { DateFormat, DateUtility, RecurrenceEndType } from 'utility';

const { Option } = Select;

export const RecurringDatePicker = ({ initialDate,submitRecurrance }) => {
  const [form] = Form.useForm();
  const [recurringType, setRecurringType] = useState('daily');
  const [recurrenceEndType, setRecurrenceEndType] = useState(RecurrenceEndType.endAfterN);
  const [generatedEvents, setGeneratedEvents] = useState([]);

  const onFinish = (values) => {
    const events = generateEvents(values);
    setGeneratedEvents(events);
    submitRecurrance(events)
  };

  const onRecurringTypeChange = (value) => {
    setRecurringType(value);
  };

  const generateEvents = (formData) => {
    const { recurrenceType, recursEveryNWeek, weekdays, endAfterNRecurrences, endOnDate, recursEveryNMonth, recursEveryNYear } = formData;
    const startTime = dayjs(initialDate);
    let events = [];
    switch (recurrenceType) {
      case 'daily': events = DateUtility.generateDailyDates(startTime, endOnDate, endAfterNRecurrences)
        break;

      case 'weekly': events = DateUtility.generateWeeklyEvents(startTime, endOnDate, recurrenceEndType, endAfterNRecurrences, weekdays, recursEveryNWeek)
        break;

      case 'monthly': events = DateUtility.generateMonthlyEvents(startTime, endOnDate, recurrenceEndType, endAfterNRecurrences, recursEveryNMonth)
        break;

      case 'yearly': events = DateUtility.generateYearlyEvents(startTime, endOnDate, recurrenceEndType, endAfterNRecurrences, recursEveryNYear)
        break;

      default:
        break;
    }
    return events;
  };

  return (
    <div>
      <Form
        form={form}
        name="recurring-appointment"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          recurrenceType: recurringType,
        }}
      >
        <div className="row">
        <h4>Start of Recursion</h4>
        <Form.Item
          label="Recurrence Pattern"
          className="col-6"
          name="recurrenceType"
          rules={[
            {
              required: true,
              message: 'Please select recurrence pattern',
            },
          ]}
        >
          <Select onChange={onRecurringTypeChange}>
            <Option value="daily">Daily</Option>
            <Option value="weekly">Weekly</Option>
            <Option value="monthly">Monthly</Option>
            <Option value="yearly">Yearly</Option>
          </Select>
        </Form.Item>

        {recurringType === 'weekly' && (
          <Form.Item
            label="Recurs Every N Week"
            name="recursEveryNWeek"
            className="col-6"
            rules={[
              {
                required: true,
                message: 'Please enter N for weekly recurrence',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        )}

        {recurringType === 'weekly' && (
          <Form.Item
            label="Weekdays"
            name="weekdays"
            className="col-12"
            rules={[
              {
                required: true,
                message: 'Please select at least one weekday for weekly recurrence',
              },
            ]}
          >
            <Select mode="multiple">
              <Option value="Monday">Monday</Option>
              <Option value="Tuesday">Tuesday</Option>
              <Option value="Wednesday">Wednesday</Option>
              <Option value="Thursday">Thursday</Option>
              <Option value="Friday">Friday</Option>
              <Option value="Saturday">Saturday</Option>
              <Option value="Sunday">Sunday</Option>
            </Select>
          </Form.Item>
        )}

        {recurringType === 'monthly' && (
          <Form.Item
            label="Recurs Every N Month"
            name="recursEveryNMonth"
            className="col-6"
            rules={[
              {
                required: true,
                message: 'Please enter N for monthly recurrence',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        )}

        {recurringType === 'yearly' && (
          <Form.Item
            label="Recurs Every N Year"
            name="recursEveryNYear"
            className="col-6"
            rules={[
              {
                required: true,
                message: 'Please enter N for yearly recurrence',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        )}
        <h4>End of Recursion</h4>
        <Form.Item label="Recurrence End" className="col-6">
          <Radio.Group checked={recurrenceEndType} value={recurrenceEndType} onChange={(e) => setRecurrenceEndType(e.target.value)}>
            <Radio checked={recurrenceEndType === RecurrenceEndType.endAfterN} value={RecurrenceEndType.endAfterN}>End After N Recurrences</Radio>
            <Radio checked={recurrenceEndType === RecurrenceEndType.endOnDate} value={RecurrenceEndType.endOnDate}>End On Date</Radio>
          </Radio.Group>
        </Form.Item>

        {recurrenceEndType === RecurrenceEndType.endAfterN && (
          <Form.Item
            label="End After N Recurrences"
            name="endAfterNRecurrences"
            className="col-6"
            rules={[
              {
                required: true,
                message: 'Please enter the number of recurrences',
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        )}

        {recurrenceEndType === RecurrenceEndType.endOnDate && (
          <Form.Item
            label="End On Date"
            name="endOnDate"
            className="col-6"
            rules={[
              {
                required: true,
                message: 'Please select the end date',
              },
            ]}
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
        )}
</div>
        <Form.Item className="d-flex justify-content-end">
          <Button type="primary" htmlType="submit">
            Create Recurrurances
          </Button>
        </Form.Item>
      </Form>

      <div>
        <h4>Preview Events:</h4>
        <div className="row">
          {generatedEvents.map((event, index) => (
            <div className="col-4 mb-2" key={index}>
              <Tag className="p-2 col-12">{DateUtility.dateToString(new Date(event),DateFormat.weekDateYear)}</Tag>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
