import React, { useState } from 'react'
import { Space, Table, Input, notification, Pagination } from 'antd'
import { DangerButton, PrimaryButton, ShadowBox } from 'elements'
import { GetDoctorsList } from 'hooks'
import { NavLink } from 'react-router-dom'
import { CommonConstant, DateFormat, DateUtility, DoctorService } from 'utility'
import { Pencil, Trash } from '@phosphor-icons/react'
import { HeaderTitle } from 'components'
import { useTranslation } from 'react-i18next'

export const DoctorListScreen = () => {
    const [search, setSearch] = useState('')
    const { data, setData, filterChanged, total, fetchMoreData } =
        GetDoctorsList()
    const { t } = useTranslation()
    const removeDoctor = async (id) => {
        try {
            await DoctorService.remove(id)
            setData(data.filter((item) => item._id !== id))
            notification.success({
                message: t('notif-message.doctor-removed'),
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            key: 'firstName',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'username',
            key: 'username',
            render: (text) => <a href={`mailto: ${text}`}>{text}</a>,
        },
        {
            title: 'Working Days',
            dataIndex: 'workingdays',
            key: 'birthdate',
            render: (text) => <>{text === 'MOS' ? 'Mon-Sat' : 'Mon-Fri'}</>,
        },
        {
            title: 'Working Times',
            dataIndex: 'starttime',
            key: 'starttime',
            render: (text, data) => (
                <>
                    {DateUtility.dateToString(text, DateFormat.time)}-
                    {DateUtility.dateToString(data.endtime, DateFormat.time)}
                </>
            ),
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <NavLink to={`edit/${data._id}`}>
                        <PrimaryButton icon={<Pencil />} />
                    </NavLink>
                    <DangerButton
                        onClick={() => removeDoctor(data._id)}
                        icon={<Trash />}
                    />
                </Space>
            ),
        },
    ]

    const onSearch = () => {
        filterChanged({ search })
    }

    const onPageChange = (data) => {
        fetchMoreData(data)
    }

    return (
        <ShadowBox>
            <HeaderTitle>Doctors List</HeaderTitle>
            <div className="d-flex justify-content-end my-2 mt-4">
                <Input
                    placeholder="Search By Email, First Name, Last Name"
                    onChange={(e) => {
                        setSearch(e.target.value)
                    }}
                    className="col-5"
                />
                <PrimaryButton onClick={onSearch}>Search</PrimaryButton>
            </div>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey="_id"
            />
            <div className="d-flex justify-content-end mt-3">
                <Pagination
                    current={total.page}
                    defaultPageSize={CommonConstant.defaultPageSize}
                    total={total.count}
                    onChange={onPageChange}
                />
            </div>
        </ShadowBox>
    )
}
