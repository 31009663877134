import { DateFormat, DateUtility,PatientDocumentsType,PatientDocumentService } from 'utility'
import { Checkbox, Popconfirm,Select,Space,notification } from 'antd';
import { AppTable,FilePreview,HeaderTitle,PageHeader } from 'components'
import { DangerButton,PrimaryButton } from 'elements'
import { Eye,Plus,Trash } from '@phosphor-icons/react';
import { useState,useMemo } from 'react';
import { GetDocumentsCategory,GetPatientDocuments } from 'hooks';
import { AddEditDocumentPopup } from './add-edit-popup';
import { useTranslation } from 'react-i18next';
import { Pencil } from 'phosphor-react';

export const PatientDocumentList = ({ patientId,actions = true ,add = true,selectable = false,onSelect,selactedRows,documentType = PatientDocumentsType.PATIENT }) => {
    const [open,setOpen] = useState(false)
    const { optionList: categoryList } = GetDocumentsCategory()
    const { data,setData,refetch,filterChanged,filter,total,pageChanged,loading } = GetPatientDocuments(patientId,documentType)
    const [visible,setVisible] = useState(false);
    const [modalData,setModalData] = useState(false);
    const { t } = useTranslation()
    const onRemove = async (document) => {
        try {
            await PatientDocumentService.remove(document._id)
            notification.success({ message: t("notif-message.document-removed") })
            setData(data.filter((x) => x._id !== document._id));
        } catch {
            notification.error({ message: t("notif-message.remove-unsucess") })
        }
    };

    const onOpen = (data) => {
        setOpen(true)
        setModalData(data)
    }

    const columns = useMemo(() => ([
        {
            title: t("form-fields.name"),
            dataIndex: 'name',
            key: 'name',
            render: (_, data) => (
                <div>
                    {/* <Checkbox onClick={() => onSelect && onSelect(data)} /> */}
                    &nbsp;&nbsp;{data.name}
                </div>
            ),
        },
        {
            title: t("form-fields.category"),
            dataIndex: 'category',
            key: 'category',
            render: (_,data) => (<div>
                {data.category.name}
            </div>),
        },
        {
            title: t("form-fields.encounter"),
            dataIndex: 'encounter',
            key: 'encounter',
            render: (_,data) => (<div>
                {data.encounter?.name} - {DateUtility.dateToString(new Date(data?.encounter?.createdAt),DateFormat.euDate)}
            </div>),
        },
        {
            title: t("form-fields.created-date"),
            dataIndex: 'date',
            key: 'date',
            render: (_,data) => (<div>
                {DateUtility.dateToString(data.createdAt,DateFormat.euDate)}
            </div>),
        },
        {
            title: t("form-fields.date-modified"),
            dataIndex: 'date',
            key: 'date',
            render: (_,data) => (<div>
                {DateUtility.dateToString(data.updatedAt,DateFormat.euDate)}
            </div>),
        },
        {
            title: t("form-fields.status"),
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: t("form-fields.select"),
            dataIndex: 'select',
            key: 'select',
            hide: !selectable,
            render: (_,data) => (<div>
                <Checkbox checked={selactedRows?.find(ele => ele._id === data._id)} onClick={() => onSelect(data)} />
            </div>),
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            hide: !actions,
            render: (_, data) => (
                    <Space size="middle">
                        {actions && <><PrimaryButton icon={<Eye />} htmlType="button" onClick={() => setVisible({ url: data.url, title: data.name })} />
                        <PrimaryButton icon={<Pencil />} htmlType="button" onClick={() => onOpen(data)} />
                            <Popconfirm
                                title={t("delete")}
                                description={t("message.delete-message", { name: `Document` })}
                                onConfirm={() => onRemove(data)}
                                okButtonProps={{ disabled: false }}
                                cancelButtonProps={{ disabled: false }}
                            >
                                <DangerButton icon={<Trash />} htmlType="button" />
                            </Popconfirm></>}
                    </Space>
                ),
        },
    ]));

    const onClose = (data) => {
        if (data) {
            refetch();
        }
        setOpen(false)
        setModalData({})
    }

    return (
        <>
            <PageHeader
                left={<HeaderTitle>
                    Documents
                </HeaderTitle>}
                right={
                    <Space>
                        <Select
                            options={[{ value: "",label: "All" },...categoryList]}
                            showArrow
                            value={filter.category}
                            onChange={(value) => filterChanged("search",{ category: value })}
                            placeholder={t("filter-by")}
                            style={{ width: 160 }}
                        />
                        {add && <PrimaryButton
                            onClick={() => setOpen(true)}
                            className=" mx-3 d-flex align-items-center">
                            {t("add")}&nbsp;
                            <Plus />
                        </PrimaryButton>}
                    </Space>
                }
            />
            <div className="row">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    loading={loading}
                />
            </div>
            <AddEditDocumentPopup
                onClose={onClose}
                open={open}
                patientId={patientId}
                documentType={PatientDocumentsType.PATIENT}
                data={modalData}
            />
            <FilePreview
                url={visible?.url}
                title={visible?.title}
                onClose={setVisible}
            />
        </>)
}