import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class EncounterInvoices extends CRUDService {
    constructor() {
        super(APIPath.encounterInvoices)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.encounterInvoices}/bulk`,data)
    }

    byEncounter(id) {
        return BaseService.get(`${APIPath.encounterInvoices}/encounter/${id}`)
    }
}

const EncounterInvoicesService = new EncounterInvoices()
Object.freeze(EncounterInvoicesService)
export { EncounterInvoicesService }
