import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Snippets extends CRUDService {
    constructor() {
        super(APIPath.snippets)
    }

    get(data) {
        const query = CommonUtility.objectToParams(data)
        const url = `${APIPath.snippets}?${query}`
        return BaseService.get(url)
    }
}

const SnippetsService = new Snippets()
Object.freeze(SnippetsService)
export { SnippetsService }