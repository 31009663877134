import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Timer extends CRUDService {
    constructor() {
        super(APIPath.timer)
    }

    byAppointment(id,params) {
        const query = CommonUtility.objectToParams(params || {})
        return BaseService.get(`${APIPath.timer}/appointment/${id}?${query}`)
    }

    byPatient(id,params) {
        const query = CommonUtility.objectToParams(params || {})
        return BaseService.get(`${APIPath.timer}/patient/${id}?${query}`)
    }

    toggleTimer(id,status) {
        return BaseService.put(`${APIPath.timer}/${id}/${status}`)
    }

    findappointmentTodayTimer(id) {
        return BaseService.get(`${APIPath.timer}/check-today-timer/appointment/${id}`)
    }

    findpatientTodayTimer(id) {
        return BaseService.get(`${APIPath.timer}/check-today-timer/patient/${id}`)
    }
}

const TimerService = new Timer()
Object.freeze(TimerService)
export { TimerService }
