import { AutoComplete, Input } from 'antd';
import styled from 'styled-components';

const AutoDropDown = styled(AutoComplete)`
    input {
        height: 34px !important;
        padding: 4px 11px !important;
    }
    button {
        height: 34px !important;
    }
`

export const AutoCompleteDropDown = ({ options, placeholder, onSearch, onChange, searchIcon = true,...rest }) => {
    return (
        <AutoDropDown
            popupMatchSelectWidth={500}
            style={{ width: '100%' }}
            options={options}
            onSelect={(value, data) => onChange(value, data)}
            onSearch={(data) => onSearch(data)}
            {...rest}
        >
            {searchIcon && <Input.Search size="large" placeholder={placeholder} />}
        </AutoDropDown>
    )
};