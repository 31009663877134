export const theme = {
    colors: {
        primary: '#1677ff',
        grey: '#ACADB6',
        white: '#FFFFFF',
        text: '#ACADB6',
        danger: '#FF4218',
        box: '#F6F6F6',
    },
}
