import { useState, useEffect, useMemo } from 'react'
import {
    CommonConstant,
    DiagnosticService, DiagnosticTypeService, Roles,
} from 'utility'
import { useAuth } from 'context'

export const GetDiagnosticListByPatient = (id) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const optionList = useMemo(() => {
        return data.map((item) => {
            return {
                value: item?._id,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>{item.description}</span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const result = await DiagnosticService.getByPatient(id)
            setData(result)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (id) {
            fetchData()
        }
    }, [id])

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        optionList,
    }
}

export const GetDiagnosticTypeList = (intialFilter) => {
    const { user } = useAuth()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const optionList = useMemo(() => {
        return data.filter(ele => !ele.hideusers.includes(user._id)).map((item) => {
            return {
                value: item?._id,
                label: (
                    <div>
                        <span>
                            {item.name}
                        </span>
                    </div>
                ),
                ...item,
            }
        })
    }, [data])

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                doctor: user.role === Roles.doctor ? user._id : "",
                ...intialFilter,
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await DiagnosticTypeService.get(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!error) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    const fetchAll = () => {
        setFilter({ ...filter, limit: 100000000000, all: true })
    }

    return {
        data,
        error,
        loading,
        setData,
        optionList,
        refetch,
        filter,
        pageChanged,
        filterChanged,
        total,
        fetchAll,
    }
}