import { HeaderBar } from './header'
import { Layout as AntdLayout, notification } from 'antd'
import styled from 'styled-components'
import { useAuth, usePatientHistory } from 'context';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PatientHistoryService } from 'utility';
import { useTranslation } from 'react-i18next';

const { Content } = AntdLayout;

const MainLayout = styled(AntdLayout)`
    min-height:100vh;
    diplay:flex;
    width:100%;
    background: ${({ theme }) => theme.colors.box};
`

export function Layout({ children }) {
    const { isAuthenticated, setPatient, patient } = useAuth()
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const [openMobileDrawer, setopenMobileDrawer] = useState(false)
    const { t } = useTranslation()
    const { historyData, removeHistory } = usePatientHistory()

    useEffect(() => {
        setopenMobileDrawer(false)
    }, [pathname])

    const onSelect = async (data) => {
        if (!data) {
            navigate(`/app/patient/add`)
            return
        }
        setPatient({ ...data })
        try {
            await PatientHistoryService.save({ patient: data._id })
        } catch (error) {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    const onSelectToggle = () => {
        setPatient({ ...patient, selected: !patient.selected })
    }

    return (
        <>
            <MainLayout className="layout">
                {isAuthenticated && <HeaderBar
                    historyData={historyData}
                    onSelectPatient={onSelect}
                    patient={patient}
                    openMobileDrawer={openMobileDrawer}
                    setopenMobileDrawer={setopenMobileDrawer}
                    onSelectToggle={onSelectToggle}
                    onRemove={(e) => { e.stopPropagation(); setPatient({}) }}
                    removeHistory={removeHistory}
                />}
                <Content className={pathname.indexOf("/login") > -1 ? '' : `container-fluid mb-3`}>
                    {children}
                </Content>
            </MainLayout>
        </>
    )
}
