import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PauseCircle, PlayCircle } from 'phosphor-react';
import { Alert, Divider, Pagination, notification } from 'antd';
import { Calendar } from '@phosphor-icons/react';
import { CommonConstant, DateFormat, DateUtility, LocaleFormat } from 'utility';
import { useTranslation } from 'react-i18next';

const TimerWrapper = styled.div`
  text-align: center;
  position: relative;
  .buttons {
    position: absolute;
    top: 5%;
    right: 2%;
    z-index: 9999999;
}
  }
`;

const ProgressBarWrapper = styled.div`
  height: 2rem;
  background-color: #f0f0f0;
  border: 0px solid #ccc;
  border-radius: 8px;
  position: relative;
`;

const TimeContainer = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const DateContainer = styled.div`
    font-size: 14px;
    font-weight: 600;

`

const Wrraper = styled.div`
    align-items: center !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const CalenderWrapper = styled.div`
    border: 0px solid;
    border-radius: 15px;
    background: #fafafb;
    display: flex;
    justify-content: center;
    align-items: center;
`

const RecentContainer = styled.div`
    border: solid 1px;
    border-radius: 8px;
    border-color: lightgray;
    height: 2rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
`
const RecentTimeWrapper = styled.div`
`

const ProgressBarFill = styled.div`
    width: ${({ percent }) => percent}%;
    height: 100%;
    background-color: #8080809e;
  border-radius: 8px;
  border-top-right-radius: ${({ percent }) => (percent !== 100 ? 0 : 8)}px;
  border-bottom-right-radius: ${({ percent }) => (percent !== 100 ? 0 : 8)}px;
  transition: width 1s;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: black;
  font-weight: bold;
`;

const ProgressBarSeparator = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${({ separatorPosition }) => separatorPosition}%;
  border-radius: 10px;
  background: #ff00002b;
  left: 0; 
`;

export const TimeTracker = ({ appointment, list = [], todayTimer, loading, updateTimer, total, onPageChange, maxTimer = 4 }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);
    const { t, i18n: { language } } = useTranslation()
    const alertTime = DateUtility.diffTime(new Date(appointment?.start_time), new Date(appointment?.end_time));
    const defaultTime = maxTimer * 60 * 60

    const goalTime = useMemo(() => {
        const appointmentTime = DateUtility.diffTime(new Date(appointment?.start_time), new Date(appointment?.end_time))
        let time = 0
        if ((defaultTime > appointmentTime) || !appointmentTime) {
            time = defaultTime
        } else {
            time = appointmentTime
        }
        return time
    }, [appointment])

    const separatorPosition = (alertTime / goalTime) * 100;
    const progressPercent = (elapsedTime / goalTime) * 100 >= 100 ? 100 : (elapsedTime / goalTime) * 100;

    useEffect(() => {
        if (todayTimer) {
            setElapsedTime(todayTimer.seconds + (todayTimer.playing ? DateUtility.diffTime(new Date(todayTimer.previoustime), new Date()) : 0))
            setIsPlaying(todayTimer.playing)
        }
    }, [todayTimer]);

    useEffect(() => {
        const alertObj = [300, 600, 180, 120, 60];
        if (alertObj.includes(alertTime - elapsedTime) && isPlaying) {
            notification.warning({
                message: t("Appointmet time running end"),
            });
        }
        if (elapsedTime === 3600 && isPlaying) {
            handlePlayPause()
            notification.warning({
                message: t("Timer is stoped please re-run timer for continue"),
            });
        }
    }, [elapsedTime]);

    useEffect(() => {
        let intervalId;

        if (isPlaying) {
            intervalId = setInterval(() => {
                setElapsedTime((prevTime) => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [isPlaying, elapsedTime]);

    const handlePlayPause = () => {
        setIsPlaying(!isPlaying);
        updateTimer(todayTimer._id, !isPlaying)
    };

    const formatedTime = useMemo(() => {
        return DateUtility.formatTime(elapsedTime)
    }, [todayTimer, elapsedTime])

    return (
        <div>
            <TimerWrapper className="">
                <ProgressBarWrapper>
                    <ProgressBarSeparator separatorPosition={separatorPosition} />
                    <ProgressBarFill percent={progressPercent} separatorPosition={separatorPosition} />
                    {todayTimer?.date && <Wrraper onClick={handlePlayPause} className="pointer d-flex justify-content-between col-12">
                        <DateContainer className="d-flex ms-2">
                            <Calendar className="me-2" size={20} />
                            <span>{DateUtility.dateToString(new Date(todayTimer.date), DateFormat.weekDate, LocaleFormat[language])}</span>
                        </DateContainer>
                        <TimeContainer className="d-flex me-2 align-items-center">
                            <span className="me-2">{formatedTime}/{DateUtility.formatTime(defaultTime)}</span>
                            {!isPlaying ? <PlayCircle size={20} /> :
                                <PauseCircle size={20} />}
                        </TimeContainer>
                    </Wrraper>}
                </ProgressBarWrapper>
                <Divider />
                <RecentTimeWrapper className="row">
                    {list?.map(item =>
                        <div className="col-6 mb-2">
                            <RecentContainer className="px-2">
                                <CalenderWrapper>
                                    <Calendar className="me-2" size={20} />
                                    <div>{DateUtility.dateToString(item?.date, DateFormat.weekDate, LocaleFormat[language])}</div>
                                </CalenderWrapper>
                                <div>{DateUtility.formatTime(item.seconds)}</div>
                            </RecentContainer>
                        </div>,
                    )}
                    {total.count > 4 && <div className="d-flex justify-content-end mt-3">
                        <Pagination size="small" current={total.page} defaultPageSize={CommonConstant.defaultPageSize} total={total.count} onChange={onPageChange} />
                    </div>}
                    <div>
                        {
                            !list.length && !loading &&
                            <Alert
                                message="No Previous Timer Found"
                                type="info"
                                showIcon
                            />
                        }
                    </div>
                </RecentTimeWrapper>
            </TimerWrapper>
        </div>
    );
};
