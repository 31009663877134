import React, { useEffect, useMemo, useState } from 'react'
import {
    DangerButton,
    FormCheckBoxField,
    FormDateField,
    FormRangeTimeField,
    FormSelectionField,
    FormTextAreaFormField,
    FormTextFormField,
    PrimaryButton,
} from 'elements'
import {
    Button,
    Modal,
    Form,
    notification,
    Space,
    Popconfirm,
    Alert,
    Tabs,
    Select,
} from 'antd'
import { useForm,Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
    GetAppointmentCategory,
    GetAppointmentCategoryCodesList,
    GetAppointmentStatus,
    GetCalendar,
    GetInvoiceCodesList,
    GetInvoicesGroupList,
    GetPatientList,
    GetQuestionAnswersListByAppointment,
    GetQuestionList,
    GetVitalSignTypeList,
} from 'hooks'
import {
    AppointmentService,
    DateFormat,
    DateUtility,
    PatientService,
    Roles,
    UserVitalSignsService,
} from 'utility'
import { FlexRow, LoaderBar, RecurringDatePicker } from 'components'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { AppontmentInvoicesTab, DeleteAppointmentModal } from './tabs-modal'
import { useAuth } from 'context'
import { Book, Calendar, Question, Repeat, Heartbeat } from 'phosphor-react'
import { QuestionsCard, VitalSignCard } from './cards'
import { useNavigate } from 'react-router-dom'
import { QuestionAnswersService } from 'utility/services/question-answer'

const { Option } = Select;

const AppointmentShema = yup.object().shape({
    appointment_date: yup
        .string()
        .when('$hasTime', (hasTime, schema) =>
            !hasTime ? schema.required('*Date is required') : schema.optional(),
        ),
    appointment_time: yup
        .array()
        .of(yup.string())
        .required('*Appointment Time is required'),
    reason_for_visit: yup.string().trim(),
    patient: yup.string().nullable(),
    category: yup.string().nullable(),
    calendar: yup.string().nullable(),
    status: yup.string().nullable(),
})

const AddPatientSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required('*Last Name is required'),
    birthdate: yup.string().required('*Birth date is required'),
    phone: yup.string().required('*Phone is required'),
})

const ColorBox = styled.div`
    height: 20px;
    width: 25px;
    border-radius: 5px;
    background-color: ${({ color }) => color};
`

const AddEditAppointmentContainer = styled.div`
    .ant-tabs-tab {
        padding-top: 0px !important;
    }
    min-height: 200px;
    position: relative;
`

const renderCategoryList = (data) => ({
    value: data?._id,
    label: (
        <div className="d-flex justify-content align-items-center">
            <ColorBox color={data.color} className="mr-2" />
            <span>{data.name}</span>
        </div>
    ),
    data,
})

const renderNoPatientItem = (t) => [
    {
        value: 0,
        label: (
            <FlexRow>
                <Alert
                    showIcon
                    type="info"
                    message={t('message.no-patient-found')}
                    className="col-8"
                />
                <PrimaryButton className="col ms-2 p-2 h-100">
                    {t('add-edit.add-patient')}
                </PrimaryButton>
            </FlexRow>
        ),
    },
]

function AddPatientModal({ open, onClose }) {
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            calendars: [],
        },
        resolver: yupResolver(AddPatientSchema),
    })
    const onSubmit = async (data) => {
        try {
            setProcessing(true)
            const patient = await PatientService.add({
                ...data,
            })
            notification.success({
                message: t('notif-message.patient-added'),
            })
            onClose(patient)
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
            reset()
        }
    }
    return (
        <Modal
            title={t('add-edit.add-patient')}
            open={open}
            confirmLoading
            footer={null}
            width={600}
            cancelButtonProps={null}
            onCancel={() => onClose()}
        >
            <Form layout="vertical">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="firstName"
                                label={t('form-fields.firstName')}
                                placeholder=""
                                errors={errors?.firstName || ''}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="lastName"
                                label={t('form-fields.lastName')}
                                placeholder=""
                                errors={errors?.lastName || ''}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="phone"
                                label={t('form-fields.phone')}
                                placeholder=""
                                errors={errors?.phone}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-6">
                            <FormDateField
                                control={control}
                                name="birthdate"
                                label={t('form-fields.birthdate')}
                                placeholder=""
                                errors={errors?.birthdate}
                                defaultValue=""
                            />{' '}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between">
                    <div />
                    <Space wrap>
                        <Button key="cancel" onClick={() => onClose()}>
                            {t('cancel')}
                        </Button>
                        <PrimaryButton
                            key="submit"
                            loading={processing}
                            onClick={handleSubmit(onSubmit)}
                        >
                            {t('add-edit.add-patient')}
                        </PrimaryButton>
                    </Space>
                </div>
            </Form>
        </Modal>
    )
}

export function AddEditAppointmentPopup({
    modalData,
    open,
    onClose,
    onDetails,
    onRemove,
    step,
    refetch,
    onRecurranceRemove,
}) {
    console.log(modalData)
    const {
        data: appointmentCategoryList,
        filterChanged: appointmentFilterChanged,
        fetchAll,
    } = GetAppointmentCategory()
    const { data: calendars } =
        GetCalendar()
    const { optionList: appointmentStatusList } = GetAppointmentStatus()
    const {
        data: patientList,
        filterChanged,
        filter,
        setData: setPatientList,
        fetchAll: fetchAllPatient,
    } = GetPatientList({})
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const {
        handleSubmit,
        control,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AppointmentShema),
    })
    const [editMode, setEditMode] = useState(false)
    const [cateCount, setCateCount] = useState(0)
    const [addPatientOpen, setAddPatientOpen] = useState(false)
    const [selectedCodes, setSelectedCodes] = useState([])
    const [recurranceDelete, setRecurranceDelete] = useState(false)
    const [localVitalSigns, setLocalVitalSigns] = useState([])
    const [questionAnswersList, setQuestionAnswersList] = useState([])
    const navigate = useNavigate()
    const { patient, user } = useAuth()
    const category = watch('category')
    const appointment_date = watch('appointment_date')

    const { data: categoryCodesList } =
        GetAppointmentCategoryCodesList(category)
    const { optionList: groupsList, filterChanged: groupFilterChanged } =
        GetInvoicesGroupList()
    const { optionList: invoicecodesList, filterChanged: codeFilterChanged } =
        GetInvoiceCodesList()
    const { optionList: vitalSignTypeList, refetch: refetcVitalSignType } =
        GetVitalSignTypeList()
    const {
        optionList: questionOptionsList,
        filterChanged: questionFilterChanged,
    } = GetQuestionList({ all: true })
    const { optionList: vitalsigntypeOptions, loading: vitalsignLoading } =
        GetVitalSignTypeList()
    const {
        optionList: appointmentQuestionList,
        loading: appointmentquestionLoading,
    } = GetQuestionAnswersListByAppointment(modalData?._id)
    const questionOptions = useMemo(
        () =>
            questionOptionsList.filter(
                (ele) =>
                    ele.admin ||
                    (Roles.doctor ? ele.doctor === user._id : true),
            ),
        [questionOptionsList],
    )
    const [recurranceEvents, setrecurranceEvents] = useState([])
    const submitRecurrance = (lists) => {
        setrecurranceEvents(lists)
    }
    useEffect(() => {
        if (!editMode && open) {
            const ids = categoryCodesList?.map(({ _id }) => _id)
            const filtered = categoryCodesList?.filter(
                ({ _id }, index) => !ids.includes(_id, index + 1),
            )
            setSelectedCodes(filtered)
            if (category && appointmentCategoryList) {
                const qaList = appointmentCategoryList
                    .find((ele) => ele._id === category)
                    .questions.map((ele, i) => ({
                        localid: new Date().toUTCString() + i,
                        question: ele,
                        answer: '',
                    }))
                setQuestionAnswersList([...qaList])
            }
        }
        if (editMode && modalData && open) {
            let codes = []
            if (user?.category_change_code) {
                codes = [...categoryCodesList]
            } else {
                const invoicecodes = modalData?.invoicecodes?.length
                    ? [...modalData.invoicecodes]
                    : []
                const ids = [...invoicecodes, ...categoryCodesList]?.map(
                    ({ _id }) => _id,
                )
                codes = [...invoicecodes, ...categoryCodesList]?.filter(
                    ({ _id }, index) => !ids.includes(_id, index + 1),
                )
            }
            setSelectedCodes(codes)
            setQuestionAnswersList(appointmentQuestionList)
        }
        if (!category) {
            setSelectedCodes([])
        }
    }, [
        category,
        categoryCodesList,
        modalData,
        editMode,
        appointmentQuestionList,
        questionOptionsList,
    ])

    const codesList = useMemo(() => {
        return [
            { label: 'Groups', options: groupsList },
            { label: 'Codes', options: invoicecodesList },
        ]
    }, [invoicecodesList, groupsList])

    const addInvoices = async (data) => {
        const invoiceList = [...groupsList, ...invoicecodesList].find(
            (ele) => ele._id === data,
        )
        let codes = [...selectedCodes]
        if (invoiceList) {
            if (invoiceList?.invoicescodes?.length) {
                codes = [...codes]
                invoiceList.invoicescodes.map((ele) => {
                    for (let i = 0; i < ele.quantity; i += 1) {
                        codes.push(ele.invoice)
                    }
                    return ''
                })
            } else {
                codes = [...codes, invoiceList]
            }
        }
        setSelectedCodes(codes)
    }

    const removeAll = () => {
        setSelectedCodes([])
    }

    const removeInvoiceCodes = (id) => {
        setSelectedCodes(selectedCodes.filter((ele) => id !== ele._id))
    }
    const onCodesSearch = (data) => {
        setValue('search', data)
        groupFilterChanged('search', {
            search: data,
        })
        codeFilterChanged('search', {
            search: data,
        })
    }

    useEffect(() => {
        fetchAll()
        fetchAllPatient()
    }, [open])

    useEffect(() => {
        if (category && open) {
            setCateCount(cateCount + 1)
            const selectedCategory = appointmentCategoryList.find(
                (item) => item?._id === category,
            )
            const end_time = DateUtility.addMinnutesInTime(
                modalData?.start_time,
                selectedCategory?.duration || step,
            )
            // const hasEvent = CommonUtility.checkoverlap(events.filter(x => x._id !== modalData?._id), new Date(modalData.start_time), new Date(end_time))
            if (cateCount === 0) {
                return
            }
            // if (hasEvent) {
            //     notification.error({
            //         message: t("notif-message.allready-booked-slot"),
            //     })
            // }
            setValue('appointment_time', [
                DateUtility.dayJSFormat(modalData?.start_time),
                DateUtility.dayJSFormat(end_time),
            ])
        }
    }, [category, open])

    const categoryList = useMemo(() => {
        let list = appointmentCategoryList.map((item) =>
            renderCategoryList(item),
        )
        list = [
            {
                value: null,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>No Category</span>
                    </div>
                ),
            },
            ...list,
        ]
        return list
    }, [appointmentCategoryList])

    const calendarList = useMemo(() => {
        const list = calendars.map(calendar => ({
            value: calendar._id,
            label: calendar.name,
        }));

        return list;
    }, [calendars]);

    const statusList = useMemo(() => {
        const list = [
            {
                value: null,
                label: (
                    <div className="d-flex justify-content align-items-center">
                        <span>No Status</span>
                    </div>
                ),
            },
            ...appointmentStatusList,
        ]
        return list
    }, [appointmentStatusList])

    const listOfPatient = useMemo(() => {
        if (patientList.length === 0 && filter.search.firstName?.length >= 3) {
            return renderNoPatientItem()
        }
        let list = patientList.map((item) => ({
            value: item._id,
            label: `${item.patientNumber} - ${item.firstName} ${
                item.lastName
            } ${item?.birthdate && ' - '} 
            ${
                item?.birthdate &&
                DateUtility.dateToString(item.birthdate, DateFormat.euDate)
            } -
            ${
                item?.birthdate &&
                DateUtility.getDistanceInWord(
                    new Date(item.birthdate),
                    language,
                )
            }`,
        }))
        list = [{ value: null, label: <>No patient</> }, ...list]
        return list
    }, [patientList, filter, language])

    useEffect(() => {
        if (open && modalData && modalData?.start_time) {
            const temp = {
                reason_for_visit: modalData?.reason_for_visit,
                notes: modalData?.notes,
                appointment_date: DateUtility.dayJSFormat(
                    modalData?.appointment_date || modalData?.start_time,
                ),
                appointment_time: [
                    DateUtility.dayJSFormat(modalData?.start_time),
                    DateUtility.dayJSFormat(modalData?.end_time),
                ],
                status:
                    modalData?.status?._id ||
                    (!modalData?._id
                        ? appointmentStatusList?.find((item) => item.primary)
                              ?._id
                        : null),
                invoicecodes: modalData?.invoicecodes?.length
                    ? modalData?.invoicecodes
                    : [],
                questions: modalData?.invoicecodes?.length
                    ? modalData?.invoicecodes
                    : [],
                    calendars: modalData?.calendars || [], // Assuming `modalData.calendars` is an array of calendar IDs
                }
            if (modalData?.invoicecodes?.length) {
                setSelectedCodes(modalData.invoicecodes)
            }
            if (modalData?.questions?.length) {
                setQuestionAnswersList(modalData.questions)
            }
            if (modalData?.patient) {
                temp.patient = modalData?.patient?._id
            } else {
                temp.patient = !modalData?._id
                    ? patientList.find((item) => item?.data?.primary)?.value
                    : null
            }
            if (modalData?.category) {
                temp.category = modalData?.category?._id
            } else {
                temp.category = !modalData?._id
                    ? categoryList.find((item) => item?.data?.primary)?.value
                    : null
            }
            if (patient._id && !modalData?._id) {
                temp.patient = patient._id
            }
            reset(temp)
            setEditMode(!!modalData?._id)
        } else {
            reset({})
        }
    }, [open, modalData, appointmentStatusList])

    useEffect(() => {
        console.warn('calendars', calendars)
        if (modalData?.calendar) {
            console.log(modalData?.calendar)
            // setValue('calendar', modalData?.calendar)
            // updateAppointmentData(
            //     'calendar',
            //     modalData?.calendar,
            //     setAppointmentData,
            // )
            // modalData.calendar = modalData?.calendar
            //     ? calendars.find(
            //           (calendar) => calendar._id === modalData?.calendar,
            //       )
            //     : null
        }
    }, [modalData, calendars])

    const onSubmit = async (formData) => {
            const reqData = { ...formData }
        console.log('latest',reqData)

        try {
            const reqData = { ...formData }
            reqData.appointment_date = formData.appointment_date
            // eslint-disable-next-line semi-style
            ;[reqData.start_time, reqData.end_time] = formData.appointment_time

            console.log(reqData.start_time, reqData.end_time)

            reqData.start_time = new Date(
                `${DateUtility.dateToString(
                    reqData.appointment_date,
                    DateFormat.date,
                )} ${DateUtility.dateToString(
                    reqData.start_time,
                    DateFormat.fulltime,
                )}`,
            )

            reqData.end_time = new Date(
                `${DateUtility.dateToString(
                    reqData.appointment_date,
                    DateFormat.date,
                )} ${DateUtility.dateToString(
                    reqData.end_time,
                    DateFormat.fulltime,
                )}`,
            )

            // const hasEvent = CommonUtility.checkoverlap(events.filter(x => !modalData?._id || x._id !== modalData?._id), reqData.start_time, reqData.end_time)
            // if (hasEvent) {
            //     notification.warning({
            //         message: t("notif-message.allready-booked-slot"),
            //     })
            //     return;
            // }
            reqData.invoicecodes = selectedCodes.map((ele) => ele._id)
            if (editMode) {
                reqData.questions = questionAnswersList.map((ele) => ele._id)
                await AppointmentService.update(modalData?._id, reqData)
                onClose(
                    {
                        ...modalData,
                        ...reqData,
                        status: appointmentStatusList?.find(
                            (item) => item._id === reqData.status,
                        ),
                        patient: patientList.find(
                            (x) => x._id === reqData.patient,
                        ),
                        category: appointmentCategoryList.find(
                            (x) => x._id === reqData.category,
                        ),
                    },
                    true,
                )
                notification.success({
                    message: t('notif-message.appointment-updated'),
                })
            } else {
                reqData.questions = questionAnswersList.map((ele) => ele._id)
                let appointment = {}
                const ids = []
                if (recurranceEvents?.length) {
                    reqData.recurranceDates = recurranceEvents
                    appointment = await AppointmentService.addWithRecurrance(
                        reqData,
                    )
                    appointment.map((ele) => ids.push(ele._id))
                } else {
                    appointment = await AppointmentService.add(reqData)
                    ids.push(appointment._id)
                }
                if (ids?.length && localVitalSigns.length) {
                    try {
                        ids.map(async (ele) => {
                            const promises = localVitalSigns.map(
                                async (val) => {
                                    const vitalsign =
                                        await UserVitalSignsService.add({
                                            ...val,
                                            appointment: ele,
                                            vitalsigntype:
                                                val.vitalsigntype._id,
                                        })
                                    return vitalsign
                                },
                            )
                            await Promise.all(promises)
                        })
                    } catch (error) {
                        notification.error({
                            message: t('notif-message.default-error'),
                        })
                    }
                }
                if (questionAnswersList.length) {
                    const qaList = questionAnswersList.map((val) => ({
                        question: val.question?._id,
                        answer: val.answer,
                        appointment: appointment?._id,
                        patient: appointment?.patient?._id || patient?._id,
                    }))
                    await QuestionAnswersService.bulk(qaList)
                }
                onClose()
                notification.success({
                    message: t('notif-message.appointment-created'),
                })
            }
            refetch()
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    const onDelete = async () => {
        try {
            if (recurranceDelete && modalData?.recurrance) {
                await AppointmentService.removeRecurrance(modalData?.recurrance)
                onRecurranceRemove(modalData.recurrance)
                notification.success({
                    message: t('notif-message.appointment-removed'),
                })
            } else {
                await AppointmentService.remove(modalData?._id)
                notification.success({
                    message: t('notif-message.appointment-removed'),
                })
                onRemove(modalData?._id)
            }
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setRecurranceDelete(false)
        }
    }

    const ModalTitle = () => {
        return (
            <div className="row">
                <div className="col-3">{t('appointment')}</div>
                <div className="col-7">
                    <FormSelectionField
                        control={control}
                        name="status"
                        placeholder={`${t('select')} ${t(
                            'form-fields.status',
                        )}`}
                        options={statusList}
                        required
                        filterOption={false}
                        showSearch
                        height={300}
                    />
                </div>
            </div>
        )
    }
    useEffect(() => {
        if (!open) {
            setCateCount(0)
        }
    }, [open])

    const selectPatient = (data) => {
        if (data === 0) {
            setAddPatientOpen(true)
            setValue('patient', null)
        }
    }

    const onAddPatientClose = (data) => {
        setAddPatientOpen(false)
        if (data) {
            setPatientList([...patientList, data])
            setValue('patient', data._id)
        }
    }
    const handlePatientFile = () => {
        navigate(`/app/patient/${modalData?.patient._id}`)
    }

    // const updateAppointmentData = (fieldName, value, setData) => {
    //     setData((prevData) => ({
    //         ...prevData,
    //         [fieldName]: value,
    //     }))
    // }
    // const handleCalendarChange = (selectedCalendar) => {
    //     console.log(selectedCalendar)
    //     updateAppointmentData('calendar', selectedCalendar, setAppointmentData)
    // }
    const appointmentMenu = useMemo(() => {
        return [
            {
                label: (
                    <h3 className="d-flex my-0">
                        <Calendar size={22} /> &nbsp;{' '}
                    </h3>
                ),
                key: 1,
                children: (
                    <>
                        <ModalTitle />
                        <Form layout="vertical">
                            <div className="col-12">
                                <FormSelectionField
                                    control={control}
                                    name="category"
                                    label={`${t('select')} ${t(
                                        'form-fields.category',
                                    )}`}
                                    placeholder={`${t('select')} ${t(
                                        'form-fields.category',
                                    )}`}
                                    errors={errors?.category}
                                    options={categoryList}
                                    onSearch={(data) =>
                                        appointmentFilterChanged('search', {
                                            name: data,
                                        })}
                                    required
                                    filterOption={false}
                                    showSearch
                                    height={300}
                                />
                            </div>
                            <div className="col-12">

                            <Form.Item label="Calendar">
    <Controller
        name="calendars"
        control={control}
        render={({ field }) => (
            <Select
                {...field}
                mode="multiple"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder="Select calendars"
                onChange={(value) => {
                    console.log('Selected:', value);
                    field.onChange(value); // Ensures the selected values are updated in the form state
                }}
            >
                {calendarList.map(option => (
                    <Option key={option.value} value={option.value}>
                        {option.label}
                    </Option>
                ))}
            </Select>
        )}
    />
</Form.Item>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <FormDateField
                                        control={control}
                                        name="appointment_date"
                                        label={t(
                                            'form-fields.appointment-date',
                                        )}
                                        placeholder={t(
                                            'form-fields.appointment-date',
                                        )}
                                        errors={errors?.appointment_date}
                                        defaultValue=""
                                        required
                                    />
                                </div>
                                <div className="col">
                                    <FormRangeTimeField
                                        control={control}
                                        name="appointment_time"
                                        label={t(
                                            'form-fields.appointment-time',
                                        )}
                                        errors={errors?.appointment_time}
                                        minuteStep={5}
                                        hourStep={1}
                                        format="HH:mm"
                                        required
                                    />
                                </div>
                            </div>
                            <FormSelectionField
                                control={control}
                                name="patient"
                                required
                                errors={errors.patient}
                                label="Patient"
                                options={listOfPatient}
                                filterOption={false}
                                showSearch
                                onSearch={(data) =>
                                    filterChanged('search', {
                                        firstName: data,
                                        lastName: data,
                                    })}
                                onChange={(data) => {
                                    selectPatient(data)
                                }}
                                placeholder={`${t('search')} ${t('Patient')}`}
                            />
                            <Space>
                                <FormCheckBoxField
                                    control={control}
                                    name="private"
                                    text={t('form-fields.private')}
                                    errors={errors?.private}
                                />
                                <FormCheckBoxField
                                    control={control}
                                    name="reminder"
                                    text={t('form-fields.reminder')}
                                    errors={errors?.reminder}
                                />
                                <FormCheckBoxField
                                    control={control}
                                    name="allDay"
                                    text={t('form-fields.all-day')}
                                    errors={errors?.allDay}
                                />
                            </Space>
                            <FormTextAreaFormField
                                control={control}
                                name="reason_for_visit"
                                label={t('form-fields.reason-for-visit')}
                                placeholder={t('form-fields.reason-for-visit')}
                                errors={errors?.reason_for_visit}
                                defaultValue=""
                                rows={5}
                            />
                            <div className="d-flex justify-content-between">
                                {editMode && (
                                    <Space wrap>
                                        <Button
                                            key="encounter"
                                            htmlType="button"
                                            onClick={() =>
                                                onDetails(modalData?._id)}
                                        >
                                            {t('form-fields.encounter')}
                                        </Button>
                                        <Button
                                            key="patientfile"
                                            disabled={!modalData?.patient?._id}
                                            htmlType="button"
                                            onClick={handlePatientFile}
                                        >
                                            {t('form-fields.patient-file')}
                                        </Button>
                                        <Popconfirm
                                            title={t('delete')}
                                            description={
                                                <DeleteAppointmentModal
                                                    recurranceDelete={
                                                        recurranceDelete
                                                    }
                                                    onSelectAll={() =>
                                                        setRecurranceDelete(
                                                            !recurranceDelete,
                                                        )}
                                                />
                                            }
                                            onConfirm={() => onDelete()}
                                            okButtonProps={{ disabled: false }}
                                            cancelButtonProps={{
                                                disabled: false,
                                            }}
                                        >
                                            <DangerButton
                                                disabled={false}
                                                htmlType="button"
                                            >
                                                {t('delete')}
                                            </DangerButton>
                                        </Popconfirm>
                                    </Space>
                                )}
                                <Space wrap>
                                    <Button
                                        key="cancel"
                                        onClick={() => onClose()}
                                    >
                                        {t('cancel')}
                                    </Button>
                                    <PrimaryButton
                                        key="submit"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        {editMode
                                            ? t('add-edit.edit-appointment')
                                            : t('add-edit.create-appointment')}
                                    </PrimaryButton>
                                </Space>
                            </div>
                        </Form>
                    </>
                ),
            },
            {
                label: (
                    <h3 className="d-flex my-0" style={{ marginLeft: '50px' }}>
                        <Book size={22} /> &nbsp;{' '}
                    </h3>
                ),
                key: 2,
                children: (
                    <div>
                        <AppontmentInvoicesTab
                            selectedCodes={selectedCodes}
                            codesList={codesList}
                            addInvoices={addInvoices}
                            groupFilterChanged={groupFilterChanged}
                            codeFilterChanged={codeFilterChanged}
                            removeInvoiceCodes={removeInvoiceCodes}
                            removeAll={removeAll}
                            onCodesSearch={onCodesSearch}
                        />
                    </div>
                ),
            },
            {
                label: (
                    <h3 className="d-flex my-0" style={{ marginLeft: '50px' }}>
                        <Heartbeat size={22} /> &nbsp;{' '}
                    </h3>
                ),
                key: 3,
                children: (
                    <>
                        <VitalSignCard
                            appointment={modalData}
                            patient={patient}
                            vitalsigntypeOptions={vitalsigntypeOptions}
                            localVitalSign={localVitalSigns}
                            setLocalVitalSign={setLocalVitalSigns}
                            refetcVitalSignType={refetcVitalSignType}
                        />
                    </>
                ),
            },
            {
                label: (
                    <h3 className="d-flex my-0" style={{ marginLeft: '50px' }}>
                        <Repeat size={21} /> &nbsp;
                    </h3>
                ),
                key: 4,
                children: (
                    <RecurringDatePicker
                        initialDate={appointment_date}
                        submitRecurrance={submitRecurrance}
                    />
                ),
            },
            {
                label: (
                    <h3 className="d-flex my-0" style={{ marginLeft: '50px' }}>
                        <Question size={22} /> &nbsp;
                    </h3>
                ),
                key: 5,
                children: (
                    <QuestionsCard
                        appointment={modalData}
                        patient={patient}
                        questionOptions={questionOptions}
                        setQuestionAnswersList={setQuestionAnswersList}
                        questionFilterChanged={questionFilterChanged}
                        questionAnswersList={questionAnswersList}
                    />
                ),
            },
        ]
    }, [
        modalData,
        localVitalSigns,
        recurranceEvents,
        categoryCodesList,
        codesList,
        listOfPatient,
        selectedCodes,
        recurranceDelete,
        vitalSignTypeList,
        questionAnswersList,
    ])

    return (
        <>
            <Modal
                open={open}
                confirmLoading
                footer={null}
                width={650}
                cancelButtonProps={null}
                onCancel={() => onClose()}
            >
                <AddEditAppointmentContainer>
                    {vitalsignLoading || appointmentquestionLoading ? (
                        <LoaderBar />
                    ) : (
                        <Tabs items={appointmentMenu} size="small" />
                    )}
                </AddEditAppointmentContainer>
            </Modal>
            <AddPatientModal
                open={addPatientOpen}
                onClose={onAddPatientClose}
            />
        </>
    )
}
