export const ContentTypeOptions = [
    {
        label: 'SOAP',
        value: 'soap',
    },
    {
        label: 'APSO',
        value: 'apso',
    },
    {
        label: 'DAR',
        value: 'darp',
    },
    {
        label: 'SBAR',
        value: 'sbar',
    },
]

export const EncounterSortOptions = [
    {
        label: 'Du plus ancien au plus récent',
        value: 'date-asc',
    },
    {
        label: 'Du plus récent au plus ancien',
        value: 'date-desc',
    },
]

export const CalanderDurationOptions = [
    {
        label: '5 Minutes',
        value: 5,
    },
    {
        label: '10 Minutes',
        value: 10,
    },{
        label: '15 Minutes',
        value: 15,
    },{
        label: '30 Minutes',
        value: 30,
    },{
        label: '1 hour',
        value: 60,
    },{
        label: '2 hour',
        value: 120,
    },
]

export const Seconds = {
    TEN_DAYS: 864000,
    ONE_WEEK: 604800,
    TWO_WEEK: 1209600,
    ONE_MONTH: 2592000,
    TWO_MONTH: 5184000,
    THREE_MONTH: 7776000,
    SIX_MONTH: 15552000,
    ONE_YEAR: 31536000,
    TWO_YEAR: 63072000,
}

export const EncounterType = {
    CONSULTATION: "consultation",
    MEDICATION: "medication",
    LABORATORY: "laboratory",
    RADIOLOGY: "radiology",
    STAIN: "stain",
    EMAIL: "email",
    DOCUMENT: "document",
    CUSTOM: "custom",
}

export const CustomFieldsType = {
    TEXT: "text",
    TEXTAREA: "text-area",
    SELECT: "select",
    RADIOLOGY: "radiology",
    STAIN: "stain",
    EMAIL: "email",
    DOCUMENT: "document",
    CUSTOM: "custom",
}