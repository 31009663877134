import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class Insurance extends CRUDService {
    constructor() {
        super(APIPath.insurance)
    }

    bulk(data) {
        return BaseService.post(`${APIPath.insurance}/bulk`,data)
    }
}

const InsuranceService = new Insurance()
Object.freeze(InsuranceService)
export { InsuranceService }
