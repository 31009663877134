import React, { useMemo } from 'react';
import { Timeline } from 'antd';
import { DateFormat, DateUtility, LocaleFormat } from 'utility';
import { useTranslation } from 'react-i18next';

const getColorCode = (item) => {
    let colorCode = "default"
    if (item?.normal_draft) {
        colorCode = "default"
    } else if (!item?.normal_draft && item?.normal) {
        colorCode = "grey"
    } else {
        colorCode = item?.encounter_type
    }
    return colorCode
}

export const EncounterTimeLine = ({ data, scrollToDiv, colors,patient }) => {
    const { t,i18n: { language } } = useTranslation()
    const redirctToEncounter = (id) => {
        scrollToDiv(id)
    }
    const list = useMemo(() => {
        return data?.map(ele => {
            const today = DateUtility.isSame(ele.date, new Date())
            return {
                color: colors[getColorCode(ele)],
                children: <div
                    className="text-start"
                    onClick={() => redirctToEncounter(ele._id)}>
                    {today ? t("calendar-properties.today") : DateUtility.dateToString(ele.date, DateFormat.euDate,LocaleFormat[language])}
                    {(ele?.patient?.birthdate || patient?.birthdate) && ` - ${DateUtility.getDistanceInWordBetween(new Date(ele.date),new Date(ele?.patient?.birthdate || patient.birthdate),LocaleFormat[language])}`}
                </div>,
            }
        })
    }, [data, colors,language])
    return (
        <Timeline
            items={list}
        />
    )
};