import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from '../constant'

class Auth {
    login(data) {
        return BaseService.post(APIPath.login, data)
    }

    me(data) {
        return BaseService.get(APIPath.me, data)
    }

    storeToken(token) {
        BrowserUtility.save(StorageConstant.token, token)
    }

    storeUser(user) {
        BrowserUtility.saveObj(StorageConstant.user, user)
    }

    getToken() {
        return BrowserUtility.get(StorageConstant.token) || ''
    }

    getUser() {
        return BrowserUtility.getObj(StorageConstant.user)
    }

    logout() {
        BrowserUtility.remove(StorageConstant.token)
        BrowserUtility.remove(StorageConstant.layout)
    }

    isAuthenticated() {
        const token = this.getToken()
        return !!token
    }

    register(reqData) {
        return BaseService.post(APIPath.register, reqData)
    }
}

const AuthService = new Auth()
Object.freeze(AuthService)
export { AuthService }
