import { APIPath } from '../constant'
import { CRUDService } from './crud'
// import { BaseService } from './base'

class PatientNotes extends CRUDService {
    constructor() {
        super(APIPath.patientnotes)
    }

    // OrderBy(data) {
    //     return BaseService.get(`${APIPath.patientnotes}/order-by`, data)
    // }
}

const PatientNotesService = new PatientNotes()
Object.freeze(PatientNotesService)
export { PatientNotesService }