import { useState, useEffect, useMemo } from 'react'
import {
    CommonConstant,
    StatisticsService,
} from 'utility'

export const GetStatisticDetails = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await StatisticsService.get()
                setData(result.data)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        error,
        loading,
        setData,
    }
}

export const GetMostAppointmetns = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await StatisticsService.mostAppointmetns(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filterChanged,
        filter,
        total,
        pageChanged,
    }
}

export const GetMostStatesPatientList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await StatisticsService.stateWisePatients(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip, limit) => {
        const temp = {
            ...filter,
            limit,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filterChanged,
        filter,
        total,
        pageChanged,
    }
}

export const GetMostMissedAppointmentsPatientList = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [total, setTotal] = useState({ count: 0, page: 1 })
    const [filter, setFilter] = useState({
        skip: 0,
        limit: CommonConstant.defaultPageSize,
        sortBy: null,
        orderby: null,
        search: {},
    })

    const fetchData = async () => {
        try {
            setLoading(true)
            const params = {
                ...filter,
                ...filter.search,
                search: null,
            }
            const result = await StatisticsService.patientWithMostMissedAppointments(params)
            setData(result.data)
            setTotal({
                ...total,
                count: result.count,
                page: filter.skip / filter.limit + 1,
            })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!loading) {
            fetchData()
        }
    }, [filter])

    const filterChanged = (key, value) => {
        let search = {
            ...(filter.search || {}),
        }
        if (key === 'search') {
            if (value) {
                search = {
                    ...search,
                    ...value,
                }
            } else {
                search = {}
            }
        }
        const temp = {
            ...filter,
            skip: 0,
            search,
        }
        setFilter({ ...temp })
    }

    const pageChanged = (skip) => {
        const temp = {
            ...filter,
            skip: (skip - 1) * filter.limit,
        }
        setFilter({ ...temp })
    }

    const refetch = () => {
        fetchData()
    }

    return {
        data,
        error,
        loading,
        setData,
        refetch,
        filterChanged,
        filter,
        total,
        pageChanged,
    }
}

export const GetEncounterByAppointmentRatio = (t) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({ year: "2023" })

    const barData = useMemo(() => {
        return data?.data?.map(item => ({ month: t(`months.${item.month}`), ratio: item.ratio }))
    }, [data,t])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await StatisticsService.encounterByAppointmentRatio(filter)
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        error,
        loading,
        setData,
        setFilter,
        filter,
        barData,
    }
}

export const GetAppointmentBYEncounterRatio = (t) => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState({ year: "2023" })

    const barData = useMemo(() => {
        return data?.data?.map(item => ({ month: t(`months.${item.month}`), ratio: item.ratio }))
    }, [data,t])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await StatisticsService.appointmentByEncounterRatio(filter)
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        error,
        loading,
        setData,
        filter,
        setFilter,
        barData,
    }
}

export const GetAgeByGenderRatio = (t) => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const barData = useMemo(() => {
        return data?.data?.map(item => ({ month: t(`months.${item.month}`), ratio: item.ratio }))
    }, [data])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await StatisticsService.ageByGender()
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        error,
        loading,
        setData,
        barData,
    }
}

export const GetEncounterByAppointmentByAgeRatio = () => {
    const [data, setData] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await StatisticsService.encounterByAppointmentByAge()
                setData(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        }
        if (!loading) {
            fetchData()
        }
    }, [])

    return {
        data,
        error,
        loading,
        setData,
    }
}