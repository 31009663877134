import React, { useEffect,useMemo,useState } from 'react';
import { Popconfirm, Space,notification } from 'antd';
import { DangerButton,PrimaryButton,ShadowBox } from 'elements';
import { DesktopMode, GetAppointmentListByPatient, GetPatientList, MobileMode } from 'hooks';
import { NavLink, useNavigate } from 'react-router-dom';
import { ColumnSearchType,DateUtility,PatientService, DateFormat } from 'utility';
import { Info,Pencil,Trash,Envelope, Stethoscope } from '@phosphor-icons/react';
import { AppTable,HeaderTitle,PageHeader,TableFilters } from 'components';
import { useTranslation } from 'react-i18next';
import { File, FirstAidKit, GenderFemale, GenderMale, GenderNeuter } from 'phosphor-react';
import { useAuth } from 'context';

export const PatientListScreen = () => {
    const { t,i18n: { language } } = useTranslation()
    const [patientId,setPatientId] = useState("")
    const { patient,setPatient } = useAuth()
    const { data: appoinments } = GetAppointmentListByPatient(patientId)
    const navigate = useNavigate()
    const {
        data,setData,filterChanged,total,pageChanged,filter,sortChanged,
    } = GetPatientList({})

    useEffect(() => {
        if (patientId && !appoinments.length) {
            notification.warning({ message: t("message.no-appointment-patient") })
        }
        if (appoinments.length) {
            navigate(`/app/appointments/${appoinments.reverse()[0]._id}`)
            return
        }
        setPatientId("")
    },[appoinments])

    const removePatient = async (id) => {
        try {
            await PatientService.remove(id)
            setData([...data.filter(item => item._id !== id)])
            notification.success({
                message: t("notif-message.patient-removed"),
            })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    const columns = useMemo(() => [
        {
            title: t("form-fields.patientNumber"),
            dataIndex: 'patientNumber',
            key: 'patientNumber',
            sorter: true,
            search: true,
        },{
            title: t("form-fields.firstName"),
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
            search: true,
            render: (_, data) => <p className="row d-flex mb-0">
            <div className="d-flex justify-content-start align-items-center">
                {data?.gender === 'male' && <GenderMale size={20} style={{ color: 'blue' }} />}
                {data?.gender === 'female' && <GenderFemale size={20} style={{ color: 'pink' }} /> }
                {data?.gender === 'other' && <GenderNeuter size={20} style={{ color: 'black' }} />}
                <p className="pl-2 mb-1">{data?.firstName}</p>
            </div>
        </p>,
        },
        {
            title: t("form-fields.lastName"),
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
            search: true,
        },
        {
            title: t("form-fields.birthdate"),
            dataIndex: 'birthdate',
            key: 'birthdate',
            search: true,
            type: ColumnSearchType.Date,
            render: (text) => DateUtility.dateToString(text,DateFormat.euDate),
        },
        {
            title: t("form-fields.age"),
            dataIndex: 'birthdate',
            key: 'age',
            render: (text) => DateUtility.getDistanceInWord(new Date(text),language),
            search: true,
            type: ColumnSearchType.Number,
        },
        {
            title: t("form-fields.phone"),
            dataIndex: 'phone',
            key: 'phone',
            sorter: true,
            search: true,
            type: ColumnSearchType.Tel,
        },
        {
            title: t("form-fields.email"),
            dataIndex: 'email',
            key: 'email',
            render: (text) => <a href={`mailto: ${text}`}>{text}</a>,
            sorter: true,
            search: true,
        },
        {
            title: t("form-fields.MODULE"),
            dataIndex: 'MODULE',
            key: 'MODULE',
            sorter: true,
            search: true,
            type: ColumnSearchType.DEP,
            render: (_,data) => <p className="row d-flex mb-0">
                <div className="d-flex justify-content-start align-items-center">
            {data.DEP && <File size={22} className="" />}
            {data.PMP && <FirstAidKit size={22} className="" />}
            {data.PSP && <Stethoscope size={22} className="" />}
            </div>
            </p>,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_,data) => (
                <Space size="middle">
                    <div onClick={() => { setPatient(data); navigate(`/app/patient/${data._id}`) }}>
                        <PrimaryButton icon={<Info />} />
                    </div>
                    <NavLink to={`edit/${data._id}`}>
                        <PrimaryButton icon={<Pencil />} />
                    </NavLink>
                    <NavLink to={`  /${data._id}`}>
                        <PrimaryButton icon={<Envelope />} />
                    </NavLink>
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message",{ name: `${t("form-fields.patient")}` })}
                        onConfirm={() => removePatient(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                    <DangerButton icon={<Trash />} />
                    </Popconfirm>
                </Space>
            ),
        },
    ],[patient,language,data]);
    const handleTableChange = (sorter) => {
        if (filter.sortBy !== sorter.field || filter.orderby !== sorter.order) {
            sortChanged({
                sortBy: sorter.field,
                orderby: sorter.order,
            })
        }
    };

    const onRowDoubleClick = (event) => {
        setPatientId(event._id)
    }
    return (
        <>
        <DesktopMode>
    <div className="" style={{ display: "grid" }}>
      <div className="row">
        <div className="col-12">
          <PageHeader
            left={<HeaderTitle>Patients</HeaderTitle>}
            right={
              <div className="text-right">
                <NavLink to="/app/patient/add">
                  <PrimaryButton>{t('Add')}</PrimaryButton>
                </NavLink>
              </div>
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12" style={{ display: "grid" }}>
          <ShadowBox>
            <TableFilters filterChanged={filterChanged} search={filter.search} />
            <div className="table-responsive">
              <AppTable
                data={data}
                columns={columns}
                keyField="_id"
                currentPage={total.page}
                total={total.count}
                pageChanged={pageChanged}
                pageSize={filter?.limit || 10}
                filterChanged={(key, value) => filterChanged("search", { [key]: value })}
                onChange={handleTableChange}
                size="small"
                onRowDoubleClick={onRowDoubleClick}
              />
            </div>
          </ShadowBox>
        </div>
      </div>
    </div>
    </DesktopMode>
    <MobileMode>
        <div className="" style={{ display: "grid" }}>
      <div className="row">
        <div className="col-10">
          <PageHeader
            left={<HeaderTitle>Patients</HeaderTitle>}
            right={
              <div className="col-1">
                <NavLink to="/app/patient/add">
                  <PrimaryButton>{t('Add')}</PrimaryButton>
                </NavLink>
              </div>
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6" style={{ display: "grid" }}>
        <ShadowBox className="col-6" style={{ width: "210%", overflowX: "auto" }}>
            <TableFilters filterChanged={filterChanged} search={filter.search} />
            <div className="table-responsive" style={{ width: "40rem" }}>
              <AppTable
                data={data}
                columns={columns}
                keyField="_id"
                currentPage={total.page}
                total={total.count}
                pageChanged={pageChanged}
                pageSize={filter?.limit || 10}
                filterChanged={(key, value) => filterChanged("search", { [key]: value })}
                onChange={handleTableChange}
                size="small"
                onRowDoubleClick={onRowDoubleClick}
              />
            </div>
            {/* </div> */}
          </ShadowBox>
        </div>
      </div>
    </div>
    </MobileMode>
    </>
  );
};