import { Drawer } from 'antd';
import { FlexRowBetween } from 'components';
import { X } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { theme } from 'utility/theme';

export const MenuDrawer = ({ open, onClose, items }) => {
    const { t } = useTranslation()
    return (
        <Drawer
            title={<FlexRowBetween className="align-items-center"><span>{t("menu.menu")}</span><X onClick={onClose} /></FlexRowBetween>}
            placement="left"
            closable={false}
            onClose={onClose}
            open={open}
            width={200}
            className="mobile-drawer"
            style={{
                backgroundColor: theme.colors.box,
                padding: 0,
            }}
        >
            {
                items.map((ele) => {
                    return (<div>
                        {
                            ele.component
                        }
                    </div>)
                })
            }
        </Drawer>
    )
}