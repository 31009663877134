import { Button } from 'antd'
import styled from 'styled-components';

export const PrimaryButton = ({ children,...rest }) => (<Button type="primary" htmlType="button" {...rest}>{children}</Button>)

export const BorderButton = styled(Button)({
    border: '0.5px solid',
});

export const DangerButton = ({ children,...rest }) => (<Button
    danger
    type="primary"
    htmlType="button"
    {...rest}
>
    {children}
</Button>)
export const ShadowBox = styled.div`
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0 2px 15px  rgba(57,57,72,0.05);
    border-radius: 5px;
    padding : 1.3em;
`