import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Button, List, Form, Modal, Pagination, Popconfirm, Space, notification } from 'antd'
import { AutoCompleteDropDown, FlexRowBetween, HeaderTitle, PageHeader } from 'components'
import { DangerButton, FormTextAreaFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetNotesList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NotesService, CommonConstant, Roles, CommonUtility } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAuth } from 'context'
import { IdentificationBadge } from 'phosphor-react'
import { Images } from 'images'

const NotesContainer = styled.div`
 .ant-collapse {
    // background: none !important;
 }
 .ant-collapse-item {
    // background-color: #00000005 !important;
}
.new-img {
    width: 20px;
    height: 20px;
}
`

const NotesSchema = yup.object().shape({
    note: yup.string().trim().required("*value is required"),
})

const AddNotesModel = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const { t } = useTranslation()
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(NotesSchema),
    })

    useEffect(() => {
        reset(editMode ? data : {})
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("add-edit.edit-notes")}` : `${t("add-edit.add-notes")}`}
            open={open}
            footer={null}
            onCancel={onClose}>
            <Form layout="vertical">
                <FormTextAreaFormField
                    control={control}
                    name="note"
                    fieldName="note"
                    label={t("form-fields.note")}
                    placeholder="write note here"
                    errors={errors?.note}
                    rows={15}
                />
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? t("add-edit.edit-notes") : t("add-edit.add-notes")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

const NotesList = ({ list, onEdit, onRemove, loading }) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const ListItem = ({ item }) => {
        const [expanded, setExpanded] = useState(false);
        const showReadMore = item.note.length > 100 && !expanded;
        return (
            <div className="col-12 d-flex py-0">
                {item.admin ? <div className="me-3">
                    <img className="new-img" src={Images.new} alt="" />
                </div> : <IdentificationBadge className="me-3" size={20} />}
                <FlexRowBetween className="col-11">
                    <>
                        {expanded ? (
                            <div>
                                {item.note}
                                <a onClick={() => setExpanded(!expanded)}>Read Less</a>
                            </div>
                        ) : (
                            <div>
                                {CommonUtility.truncateString(item.note, 100)}
                                {showReadMore && (
                                    <a onClick={() => setExpanded(!expanded)}>Read More</a>
                                )}
                            </div>
                        )}
                    </>
                    <span>{editRemoveButton(item)}</span>
                </FlexRowBetween>
            </div>
        );
    };

    const editRemoveButton = (item) => {
        if (item.admin && user.role !== Roles.admin) {
            return ""
        }
        return (<Space size="middle">
                <PrimaryButton icon={<Pencil />} onClick={(e) => { e.stopPropagation(); onEdit(item) }} />
                <Popconfirm
                    title={t("delete")}
                    description={t("message.delete-message", { name: `${t("form-fields.notes")}` })}
                    onConfirm={(e) => {
                        e.stopPropagation()
                        onRemove(item._id)
                    }}
                    onCancel={(e) => e.stopPropagation()}
                    okButtonProps={{ disabled: false }}
                    cancelButtonProps={{ disabled: false }}
                >
                    <DangerButton icon={<Trash />} htmlType="button" onClick={(e) => e.stopPropagation()} />
                </Popconfirm>
            </Space>
        )
    };

    return (
        <>
            {list.length ?
                <List
                    dataSource={list}
                    renderItem={(item,i) => (
                        <List.Item>
                            <ListItem item={item} index={i} />
                        </List.Item>
                    )}
                />
                :
                <div className="my-3">
                    {!loading && <Alert message="No Notes found" type="info" showIcon />}
                </div>}
        </>

    )
}

export const HomeNotes = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { user } = useAuth()
    const { list, setData, refetch, total, pageChanged, filterChanged, loading } = GetNotesList()

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await NotesService.add({ ...formData, admin: Roles.admin === user.role, doctor: user._id })
                notification.success({ message: t("notif-message.quick-entry-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await NotesService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.quick-entry-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeNotes = async (id) => {
        try {
            await NotesService.remove(id)
            notification.success({ message: t("notif-message.quick-entry-removed") })
            setData(list.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    return (
        <NotesContainer>
<>
<DesktopMode>
            <PageHeader
                left={<HeaderTitle className="col-6">{t("my-notes")}</HeaderTitle>}
                right={<>
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("settings.quick-entry")}`}
                        options={[]}
                        className="mb-0 ms-5"
                        onSearch={(data) => filterChanged("search", { key: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-3 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                </>}
            />
            <NotesList
                list={list}
                onEdit={onOpen}
                onRemove={removeNotes}
                loading={loading}
            />
            <div className="col-12 mt-3 text-end">
                <Pagination
                    current={total.page}
                    defaultPageSize={CommonConstant.defaultPageSize}
                    total={total.count}
                    onChange={pageChanged}
                    size="small"
                />
            </div>
            </DesktopMode>
            <MobileMode>
            <PageHeader
                left={<HeaderTitle className="col-3">{t("my-notes")}</HeaderTitle>}
                right={<>
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("settings.quick-entry")}`}
                        options={[]}
                        className="mb-0 ms-3 mr-2"
                        onSearch={(data) => filterChanged("search", { key: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-6 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton>
                </>}
            />
            <NotesList
                list={list}
                onEdit={onOpen}
                onRemove={removeNotes}
                loading={loading}
            />
            <div className="col-12 mt-3 text-end">
                <Pagination
                    current={total.page}
                    defaultPageSize={CommonConstant.defaultPageSize}
                    total={total.count}
                    onChange={pageChanged}
                    size="small"
                />
            </div>
            </MobileMode>
        </>            <AddNotesModel
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
        </NotesContainer>
    )
}
