import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class Allergies extends CRUDService {
    constructor() {
        super(APIPath.allergies)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.allergies}/order-by`, data)
    }

    getByPatient(id,params) {
        const query = CommonUtility.objectToParams(params || {})
        return BaseService.get(`${APIPath.allergies}/by-patient/${id}?${query}`)
    }
}

const AllergiesService = new Allergies()
Object.freeze(AllergiesService)
export { AllergiesService }
