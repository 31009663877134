import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { DangerText } from './Common';

const QuillEditor = styled.div`
    `
export const RichTextEditor = ({ value,onChange,onBlur,label,errors }) => (
    <QuillEditor className="mb-2 d-flex flex-column">
        <lable className="mb-2">{label}</lable>
        <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
        {errors && <DangerText className="danger">{errors?.message}</DangerText>}
    </QuillEditor>
);