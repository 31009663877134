import {
    FormDateField,
    FormRadioField,
    FormSelectionField,
    FormSwitchField,
    FormTextAreaFormField,
    FormTextFormField,
    PrimaryButton,
    ShadowBox,
} from 'elements'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Space, notification } from 'antd'
import { CountryList, PatientService, GenderOptions, AboutUsIconList } from 'utility'
import { GetAddressTypeList, GetInsuranceTypeList, GetPatientDetail, getRouterState } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { HeaderTitle, PageHeader } from 'components'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'context'

const AddPatientSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required('*Last Name is required'),
    email: yup.string().email().required('*Email is required'),
    phone: yup.string().required('*Phone is required'),
    birthdate: yup.string().required('*Birth date is required'),
    gender: yup.string().required('*Gender is required'),
    consulation: yup.string().required('*Consulation is required'),
    notes: yup.string(),
    height: yup.string().trim(),
    weight: yup.string().trim(),
    DEP: yup.boolean(),
    PMP: yup.boolean(),
    PSP: yup.boolean(),
    extraaddress: yup.array().of(
        yup.object().shape({
            address: yup.object().shape({
                address: yup
                    .string()
                    .test('street-required', 'Street is required', (value) => {
                        return !!value
                    }),
                addresstype: yup
                    .string()
                    .test(
                        'address-type-required',
                        'Address Type is required',
                        (value) => {
                            return !!value
                        },
                    ),
                city: yup
                    .string()
                    .test('city-required', 'City is required', (value) => {
                        return !!value
                    }),
                postalCode: yup
                    .string()
                    .test(
                        'postalCode-required',
                        'Postal Code is required',
                        (value) => {
                            return !!value
                        },
                    ),
                country: yup
                    .string()
                    .test(
                        'country-required',
                        'Country is required',
                        (value) => {
                            return !!value
                        },
                    ),
            }),
        }),
    ),
    alternatePhone: yup.array().of(
        yup.object().shape({
            icon: yup.string().trim(),
            phone: yup.string().trim(),
        })),
    medicalHistory: yup.object().shape({
        allergies: yup.string().trim(),
        condition: yup.string().trim(),
        treatment: yup.string().trim(),
    }),
    billing: yup.object().shape({
        assurance: yup.string().trim(),
        insuredNumber: yup.string().trim(),
        additoinal: yup.string().trim(),
        ahvNumber: yup.string().trim(),
        cadaNumber: yup.string().trim(),
    }),
    address: yup.object().shape({
        addresstype: yup.string().required('*Address Type is required'),
        address: yup.string().required('*Street is required'),
        postalCode: yup.string().required('*Postal Code is required'),
        city: yup.string().required('*City is required'),
        country: yup.string().required('*Country is required'),
    }),
})

export const AddEditPatientScreen = () => {
    const params = useParams()
    const routerState = getRouterState()
    const { t } = useTranslation()
    const { data } = GetPatientDetail(params.id)
    const { setPatient } = useAuth()
    const navigate = useNavigate()
    const { data: insuranceTypeList } = GetInsuranceTypeList(t)
    const [processing, setProcessing] = useState(false)
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AddPatientSchema),
    })
    const { optionList: addressTypeOptionList } = GetAddressTypeList()
    const { fields: extraaddressFields, append: extraaddressAppend, remove: extraaddressRemove } = useFieldArray({
        control,
        name: 'extraaddress',
    })
    const { fields: alternatePhoneFields, append: alternatePhoneAppend, remove: alternatePhoneRemove } = useFieldArray({
        control,
        name: 'alternatePhone',
    })
    useEffect(() => {
        if (data) {
            const extraAddresses = data?.extraaddress ? data?.extraaddress?.map((address) => ({
                address: {
                    addresstype: address.addresstype,
                    address: address.address,
                    postalCode: address.postalCode,
                    city: address.city,
                    country: address.country,
                },
            })) : []
            const phones = data?.alternatePhone || []
            reset({
                ...data,
                birthdate: dayjs(data?.birthdate),
                extraaddress: extraAddresses,
                alternatePhone: phones,
            })
            setPatient(data)
        }
        if (routerState?.firstName && !data) {
            reset({
                firstName: routerState.firstName,
                extraaddress: [
                    {
                        address: {
                            addresstype: '',
                            address: '',
                            postalCode: '',
                            city: '',
                            country: '',
                        },
                    },
                ],
            })
        }
    }, [data, routerState])

    const submit = async (formData) => {
        try {
            formData.extraaddress = formData?.extraaddress?.map(
                (address) => address.address,
            )
            setProcessing(true)
            if (params.id) {
                delete formData._id
                delete formData.doctor
                await PatientService.update(params.id, {
                    ...formData,
                })
                notification.success({
                    message: t('notif-message.patient-updated'),
                })
            } else {
                formData.phoneNumbers = alternatePhoneFields
                await PatientService.add({
                    ...formData,
                })
                notification.success({
                    message: t('notif-message.patient-added'),
                })
            }
            cancel()
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
        }
    }
    const addPhoneNumber = () => {
        alternatePhoneAppend({ icon: "", phone: "" })
    }

    const removePhoneNumber = (index) => {
        alternatePhoneRemove(index)
    }
    const cancel = () => {
        navigate('/app/patient')
    }

    return (
        <div className="container">
            <PageHeader
                left={
                    <HeaderTitle>
                        {params.id
                            ? `${t('add-edit.edit-patient')}`
                            : `${t('add-edit.add-patient')}`}
                    </HeaderTitle>
                }
            />
            <ShadowBox>
                <Form layout="vertical">
                    <h3>{t('general-info')}</h3>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="firstName"
                                label={t('form-fields.firstName')}
                                placeholder=""
                                errors={errors?.firstName || ''}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="lastName"
                                label={t('form-fields.lastName')}
                                placeholder=""
                                errors={errors?.lastName || ''}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-4 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="email"
                                label={t('form-fields.email')}
                                placeholder=""
                                errors={errors?.email}
                                defaultValue=""
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="phone"
                                label={t('form-fields.phone')}
                                placeholder=""
                                errors={errors?.phone}
                                defaultValue=""
                            />
                        </div>
                        <div
                            className="col-2 d-flex"
                            style={{ marginTop: '1.7rem' }}
                        >
                            <PrimaryButton
                                className="col-4 d-flex mr-2"
                                onClick={addPhoneNumber}
                            >
                                Add
                            </PrimaryButton>
                        </div>
                        {alternatePhoneFields.map((phoneNumber, index) => (
                            <div className="row" key={index}>
                                <div className="col-md-4 col-sm-6">
                                    <FormTextFormField
                                        control={control}
                                        name={`alternatePhone[${index}].type`}
                                        label={`Type ${index + 1}`}
                                        placeholder=""
                                        options={AboutUsIconList}
                                        errors={errors?.alternatePhone?.[index]?.type}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <FormTextFormField
                                        control={control}
                                        name={`alternatePhone[${index}].phone`}
                                        label={`Phone Number ${index + 1}`}
                                        placeholder=""
                                        errors={errors?.alternatePhone?.[index]?.phone}
                                    />
                                </div>
                                <div
                                    className="col-md-2 col-sm-4"
                                    style={{ marginTop: '1.7rem' }}
                                >
                                    <PrimaryButton
                                        danger
                                        onClick={() => removePhoneNumber(index)}
                                    >
                                        Remove
                                    </PrimaryButton>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <FormDateField
                                control={control}
                                name="birthdate"
                                label={t('form-fields.birthdate')}
                                placeholder=""
                                errors={errors?.birthdate}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <FormRadioField
                                control={control}
                                name="gender"
                                placeholder="Gender"
                                label={t('form-fields.gender')}
                                errors={errors?.gender}
                                defaultValue=""
                                options={GenderOptions}
                            />
                        </div>
                        <div className="col-md-1 col-sm-4">
                            <FormSwitchField
                                control={control}
                                name="DEP"
                                label={t('form-fields.DEP')}
                                errors={errors?.DEP}
                                defaultValue={false}
                            />
                        </div>
                        <div className="col-md-1 col-sm-4">
                            <FormSwitchField
                                control={control}
                                name="PMP"
                                label={t('form-fields.PMP')}
                                errors={errors?.PMP}
                                defaultValue={false}
                            />
                        </div>
                        <div className="col-md-1 col-sm-4">
                            <FormSwitchField
                                control={control}
                                name="PSP"
                                label={t('form-fields.PSP')}
                                errors={errors?.PSP}
                                defaultValue={false}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <h3>{t('address')}</h3>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                            <PrimaryButton
                                className="d-flex justify-content-end mr-2"
                                onClick={() => extraaddressAppend({})}
                            >
                                Add
                            </PrimaryButton>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormSelectionField
                                control={control}
                                name="address.addresstype"
                                label={t('form-fields.addresstype')}
                                placeholder=""
                                errors={errors?.address?.addresstype}
                                defaultValue=""
                                options={addressTypeOptionList}
                            />
                        </div>
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="address.address"
                                label={t('form-fields.street')}
                                placeholder=""
                                errors={errors?.address?.address}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-4">
                            <FormTextFormField
                                control={control}
                                name="address.postalCode"
                                label={t('form-fields.postalCode')}
                                placeholder=""
                                errors={errors?.address?.postalCode}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-4">
                            <FormTextFormField
                                control={control}
                                name="address.city"
                                label={t('form-fields.city')}
                                placeholder=""
                                errors={errors?.address?.city}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-4">
                            <FormSelectionField
                                control={control}
                                name="address.country"
                                label={t('form-fields.country')}
                                placeholder=""
                                errors={errors?.address?.country}
                                defaultValue=""
                                options={CountryList}
                                showSearch
                            />
                        </div>
                    </div>
                    {extraaddressFields.map((address, index) => (
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                {/* <PrimaryButton
                                    className="d-flex justify-content-end mr-2"
                                    onClick={() => append({})}
                                >
                                    Add
                                </PrimaryButton> */}
                                <PrimaryButton
                                    danger
                                    onClick={() => extraaddressRemove(index)}
                                >
                                    Remove
                                </PrimaryButton>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <FormSelectionField
                                        control={control}
                                        name={`extraaddress[${index}].address.addresstype`}
                                        label={t('form-fields.addresstype')}
                                        placeholder=""
                                        errors={
                                            errors?.extraaddress?.[index]
                                                ?.address?.addresstype
                                        }
                                        defaultValue={
                                            address.address?.addresstype || ''
                                        }
                                        options={addressTypeOptionList}
                                    />
                                </div>
                                <div className="col-6">
                                    <FormTextFormField
                                        control={control}
                                        name={`extraaddress[${index}].address.address`}
                                        label={t('form-fields.street')}
                                        placeholder=""
                                        errors={
                                            errors?.extraaddress?.[index]
                                                ?.address?.address
                                        }
                                        defaultValue={
                                            address.address?.address || ''
                                        }
                                    />
                                </div>
                                <div className="col-4">
                                    <FormTextFormField
                                        control={control}
                                        name={`extraaddress[${index}].address.postalCode`}
                                        label={t('form-fields.postalCode')}
                                        placeholder=""
                                        errors={
                                            errors?.extraaddress?.[index]
                                                ?.address?.postalCode || ''
                                        }
                                        defaultValue={
                                            address.address?.postalCode || ''
                                        }
                                    />
                                </div>
                                <div className="col-4">
                                    <FormTextFormField
                                        control={control}
                                        name={`extraaddress[${index}].address.city`}
                                        label={t('form-fields.city')}
                                        placeholder=""
                                        errors={
                                            errors?.extraaddress?.[index]
                                                ?.address?.city || ''
                                        }
                                        defaultValue={
                                            address.address?.city || ''
                                        }
                                    />
                                </div>
                                <div className="col-4">
                                    <FormSelectionField
                                        control={control}
                                        name={`extraaddress[${index}].address.country`}
                                        label={t('form-fields.country')}
                                        placeholder=""
                                        errors={
                                            errors?.extraaddress?.[index]
                                                ?.address?.country || ''
                                        }
                                        defaultValue={
                                            address.address?.country || ''
                                        }
                                        options={CountryList}
                                        showSearch
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                    <h3>{t('insurances')}</h3>
                    <div className="row">
                    <div className="col-md-6 col-sm-6">
                            <FormSelectionField
                                control={control}
                                name="consulation"
                                label={t('form-fields.consulation')}
                                placeholder=""
                                options={insuranceTypeList}
                                errors={errors?.consulation}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="billing.assurance"
                                label={t('form-fields.assurance')}
                                placeholder=""
                                errors={errors?.billing?.assurance}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FormTextFormField
                                control={control}
                                name="billing.insuredNumber"
                                label={t('form-fields.insuredNumber')}
                                placeholder=""
                                errors={errors?.billing?.insuredNumber}
                                defaultValue=""
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <FormTextFormField
                                control={control}
                                name="billing.additoinal"
                                label={t('form-fields.additoinal')}
                                placeholder="Additoinal"
                                errors={errors?.billing?.additoinal}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <FormTextFormField
                                control={control}
                                name="billing.ahvNumber"
                                label={t('form-fields.ahvNumber')}
                                placeholder="756...."
                                errors={errors?.billing?.ahvNumber}
                                defaultValue=""
                            />
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <FormTextFormField
                                control={control}
                                name="billing.cadaNumber"
                                label={t('form-fields.cadaNumber')}
                                placeholder="807..."
                                errors={errors?.billing?.cadaNumber}
                                defaultValue=""
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormTextAreaFormField
                                control={control}
                                name="notes"
                                label={t('form-fields.notes')}
                                placeholder="Notes"
                                errors={errors?.notes}
                                defaultValue=""
                                rows={15}
                            />
                        </div>
                    </div>
                    <Space className="d-flex justify-content-end">
                        <Button htmlType="button" onClick={cancel}>
                            {t('cancel')}
                        </Button>
                        <PrimaryButton
                            loading={processing}
                            onClick={handleSubmit(submit)}
                            type="primary"
                            htmlType="submit"
                        >
                            {t('submit')}
                        </PrimaryButton>
                    </Space>
                </Form>
            </ShadowBox>
        </div>
    )
}
