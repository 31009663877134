import { APIPath } from '../constant'
import { CRUDService } from './crud'
import { BaseService } from './base'
import { CommonUtility } from 'utility/common'

class UserVaccination extends CRUDService {
    constructor() {
        super(APIPath.uservaccination)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.uservaccination}/order-by`, data)
    }

    byAppointment(id,params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(`${APIPath.uservaccination}/appointment/${id}?${data}`)
    }

    byPatient(id,params) {
        const data = CommonUtility.objectToParams(params)
        return BaseService.get(`${APIPath.uservaccination}/patient/${id}?${data}`)
    }
}

const UserVaccinationService = new UserVaccination()
Object.freeze(UserVaccinationService)
export { UserVaccinationService }
