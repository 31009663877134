import { Button, Input, Space, Table, DatePicker, Radio, Spin } from 'antd'
import dayjs from 'dayjs';
import { PrimaryButton } from 'elements';
import { MagnifyingGlass } from '@phosphor-icons/react';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { ColumnSearchType, CommonConstant, DateFormat } from 'utility';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { List } from 'phosphor-react';

const Customized = styled(Table)`
  tbody {
    tr {
      td {
        padding: 4px !important;
      }
    }
  }
`

const GetInputBox = ({ setSelectedKeys, selectedKeys, item, handleSearch, dataIndex, searchInput }) => {
    let compo = ''
    switch (item?.type) {
        case ColumnSearchType.Date:
            compo = <DatePicker
                format={DateFormat.inputEuDate}
                value={!Array.isArray(selectedKeys) ? selectedKeys : null}
                onChange={(e) => setSelectedKeys(e)}
                onPressEnter={() => handleSearch(selectedKeys, dataIndex, item?.type)}
                style={{ marginBottom: 8, display: 'block' }}
                className="col-12" />;
            break;
        case ColumnSearchType.Radio:
            compo =
                <Radio.Group
                    style={{ marginBottom: 8, display: 'block' }}
                    onChange={(e) => setSelectedKeys(e.target.value)}
                    value={selectedKeys}
                >
                    {item.options.map(item => <Radio key={item.value} value={item.value}>{item.label}</Radio>)}
                </Radio.Group>;
            break;
        default:
            compo = <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys}
                onChange={(e) => setSelectedKeys(e.target.value)}
                onPressEnter={() => handleSearch(selectedKeys, dataIndex, item?.type)}
                style={{ marginBottom: 8, display: 'block' }}
                type={item.type}
            />;
            break;
    }
    return compo
}

export const DraggableAppTable = ({
    columns, data, currentPage, pageChanged, disableDrag = false,
    pageSize = CommonConstant.defaultPageSize, loading = false,
    total, filterChanged, onChange, onRowClick, onRowDoubleClick, orderChanged, ...rest
}) => {
    const searchInput = useRef(null);
    const [sortedInfo, setSortedInfo] = useState({});
    const { t } = useTranslation()
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        setDataSource(data)
    }, [data])

    const handleSearch = (
        selectedKeys,
        dataIndex,
        type,
    ) => {
        let value = selectedKeys
        if (type === ColumnSearchType.Date) {
            value = dayjs(selectedKeys).format("YYYY-MM-DD")
        }
        filterChanged(dataIndex, value)
    };

    const handleReset = (
        clearFilters,
        dataIndex,
    ) => {
        clearFilters();
        filterChanged(dataIndex, undefined)
    };

    const handleChange = (_, __, sorter) => {
        setSortedInfo({ [sorter.columnKey]: sorter.order });
        if (onChange) {
            onChange(sorter)
        }
    };

    const getColumnSearchProps = (dataIndex, item) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <GetInputBox
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    item={item}
                    handleSearch={(selectedKeys, dataIndex, type) => {
                        handleSearch(selectedKeys, dataIndex, type);
                        close();
                    }}
                    dataIndex={dataIndex}
                    searchInput={searchInput}
                />
                <Space>
                    <PrimaryButton
                        type="primary"
                        onClick={() => {
                            handleSearch(selectedKeys, dataIndex, item?.type);
                            close();
                        }}
                        icon={<MagnifyingGlass className="me-1" size={15} />}
                        size="small"
                        className="d-flex align-items-center"
                    >
                        {t("search")}
                    </PrimaryButton>
                    <Button
                        onClick={() => {
                            if (clearFilters) {
                                handleReset(clearFilters, dataIndex);
                                close();
                            }
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <MagnifyingGlass size={18} color={filtered ? "blue" : "grey"} />
        ),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const Row = ({ children, ...props }) => {
        const {
            attributes,
            listeners,
            setNodeRef,
            setActivatorNodeRef,
            transform,
            transition,
            isDragging,
        } = useSortable({
            id: props['data-row-key'],
        });
        const style = {
            ...props.style,
            transform: CSS.Transform.toString(
                transform && {
                    ...transform,
                    scaleY: 1,
                },
            )?.replace(/translate3d\(([^,]+),/, 'translate3d(0,'),
            transition,
            ...(isDragging
                ? {
                    position: 'relative',
                    zIndex: 999,
                }
                : {}),
        };
        return (
            <tr {...props} ref={setNodeRef} style={style} {...attributes}>
                {React.Children.map(children, (child) => {
                    if (child.key === 'sort' && !disableDrag) {
                        return React.cloneElement(child, {
                            children: (
                                <span>
                                    <List
                                        ref={setActivatorNodeRef}
                                        size={20}
                                        style={{
                                            touchAction: 'none',
                                            cursor: 'move',
                                        }}
                                        {...listeners}
                                    />
                                </span>
                            ),
                        });
                    }
                    return child;
                })}
            </tr>
        );
    };

    const customColumns = useMemo(() => {
        columns.push({ key: "sort" })
        return columns.map(item => {
            let column = { ...item }
            if (item.search) {
                column = {
                    ...column,
                    ...getColumnSearchProps(item.key, item),
                }
            }
            if (item.sort) {
                column = {
                    ...column,
                    ...getColumnSearchProps(item.key, item),
                    sorter: true,
                    sortOrder: Object.keys(sortedInfo).includes(item.key) ? sortedInfo[item.key] : null,
                }
            }
            return column
        })
    }, [columns, sortedInfo])

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setDataSource((prev) => {
                const activeIndex = prev.findIndex((i) => i._id === active.id);
                const overIndex = prev.findIndex((i) => i._id === over?.id);
                let movedItems = arrayMove(prev, activeIndex, overIndex);
                movedItems = movedItems.map((item, index) => ({ ...item, order: index }))
                orderChanged(movedItems)
                return movedItems
            });
        }
    };

    return (
        <div>
            <DndContext onDragEnd={onDragEnd}>
                <SortableContext
                    items={dataSource.map((i) => i._id)}
                    strategy={verticalListSortingStrategy}
                >
                    <Customized
                        columns={customColumns}
                        dataSource={dataSource}
                        rowKey={(ele) => ele._id}
                        loading={{ indicator: <div><Spin /></div>, spinning: !dataSource.length && loading }}
                        components={{
                            body: {
                                row: Row,
                            },
                        }}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            total,
                            onChange: pageChanged,
                            // showSizeChanger: true,
                        }}
                        handleChange={handleChange}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => onRowClick && onRowClick(record, rowIndex), // click row
                                onDoubleClick: () => onRowDoubleClick && onRowDoubleClick(record, rowIndex), // double click row
                            };
                        }}
                        {...rest}
                    />
                </SortableContext>
            </DndContext>
        </div>
    )
}
