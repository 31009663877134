import {
    DangerButton,
    FormDateField,
    FormTextFormField,
    PrimaryButton,
    ShadowBox,
} from 'elements'
import {
    DesktopMode,
    GetAllergiesTypes,
    GetAppointmentListByPatient,
    GetDiagnosticListByPatient,
    GetEncounterColors,
    GetPatientDetail,
    GetPatientNotesList,
    MobileMode,
} from 'hooks'
import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import {
    DateUtility,
    PatientService,
    DateFormat,
    PatientNotesService,
} from 'utility'
import styled from 'styled-components'
import {
    Avatar,
    Button,
    Form,
    Modal,
    Popconfirm,
    Space,
    Table,
    Tabs,
    notification,
    theme,
} from 'antd'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    AppTable,
    AutoCompleteDropDown,
    FlexColumn,
    FlexRow,
    FlexRowBetween,
    HeaderTitle,
    PageHeader,
} from 'components'
import { MedicalBox, GeneralBox, PatientInsuranceBox } from 'page-components'
import { PatientDocumentList } from 'page-components/patient/documents'
import { useTranslation } from 'react-i18next'
import { CalendarBlank, GenderNeuter, User } from '@phosphor-icons/react'
import {
    Eye,
    IdentificationBadge,
    IdentificationCard,
    Plus,
    X,
} from 'phosphor-react'
import { useAuth } from 'context'
import { EncounterCard, UserAllergiesTab } from 'page-components/appointments'

// const NotesSchema = yup.object().shape({
//     notes: yup.string().required('*notes is required'),
// })

const ProfileContainer = styled(ShadowBox)`
    .wrap {
        word-break: break-word;
        font-weight: 400;
    }
`

const LeftContainer = styled(ShadowBox)`
    overflow-y: auto;
    flex-direction: column;
    .wrap {
        word-break: break-word;
        font-weight: 400;
    }
`
const DetailContainer = styled(ShadowBox)`
    .wrap {
        word-break: break-word;
        font-weight: 400;
    }
`

const Container = styled.div`
    display: flex;
    gap: 20px;
`
export const DataBox = ({ children, className }) => {
    return (
        <div className={className}>
            {!children ? (
                <X size={18} color={theme.colors?.danger || 'red'} />
            ) : (
                children
            )}
        </div>
    )
}

const EncounterBoxModal = ({
    open,
    data,
    editMode,
    onClose,
    colors,
    diagnosticOptions,
    quickEntryList,
}) => {
    const {
        reset,
        formState: {},
    } = useForm({
        resolver: yupResolver(),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                  }
                : {},
        )
    }, [open, editMode])
    return (
        <Modal
            title={
                editMode
                    ? t('add-edit.edit-appointment')
                    : t('add-edit.add-appointment')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
            width="100rem"
        >
            <EncounterCard
                loading={false}
                colors={colors}
                diagnosticOptions={diagnosticOptions}
                quickEntryList={quickEntryList}
                appointment={data}
                readMode
            />
        </Modal>
    )
}
export const AppointmentBox = ({ data }) => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const { data: colors } = GetEncounterColors()
    const { optionList: diagnosticOptions } = GetDiagnosticListByPatient(
        modalData?.patient?._id,
    )
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const columns = [
        {
            title: t('reason'),
            dataIndex: 'reason_for_visit',
            key: 'reason_for_visit',
        },
        {
            title: 'Date',
            dataIndex: 'appointment_date',
            key: 'appointment_date',
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
        },
        {
            title: t('form-fields.start-time'),
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text) => DateUtility.dateToString(text, DateFormat.time),
        },
        {
            title: t('form-fields.end-time'),
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text) => DateUtility.dateToString(text, DateFormat.time),
        },
        {
            title: t('status'),
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <div className="text-capitalize">{text?.status}</div>
            ),
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, ele) => (
                <Space size="middle">
                    <PrimaryButton
                        icon={<Eye />}
                        onClick={() => onOpen(ele)}
                        htmlType="button"
                    />
                </Space>
            ),
        },
    ]
    return (
        <div>
            <Table columns={columns} dataSource={data} rowKey="_id" />
            <EncounterBoxModal
                open={open}
                data={modalData}
                colors={colors}
                onClose={onClose}
                diagnosticOptions={diagnosticOptions}
                editMode={false}
            />
        </div>
    )
}
const NotesBoxSchema = yup.object().shape({
    type: yup.string().required('type is required').trim(),
})
const AddNotesBoxModal = ({
    open,
    data,
    editMode,
    onClose,
    setData,
    loading,
    onSubmit,
}) => {
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(NotesBoxSchema),
    })
    const { t } = useTranslation()

    useEffect(() => {
        reset(
            editMode
                ? {
                      ...data,
                      date: DateUtility.dayJSFormat(data.date),
                  }
                : {},
        )
    }, [open, editMode])

    const removeDiagnostic = async (id) => {
        try {
            await PatientNotesService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }
    return (
        <Modal
            title={
                editMode ? t('add-edit.edit-notes') : t('add-edit.add-notes')
            }
            visible={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical" noValidate>
                <div className="row">
                    <Form.Item className="col-12 mb-0">
                        <FormTextFormField
                            control={control}
                            name="type"
                            label={t('form-fields.type')}
                            placeholder=""
                            errors={errors?.type || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-12 mb-0">
                        <FormTextFormField
                            control={control}
                            name="note"
                            label={t('form-fields.note')}
                            placeholder=""
                            errors={errors?.note || ''}
                        />
                    </Form.Item>
                    <Form.Item className="col-12 mb-0">
                        <FormDateField
                            control={control}
                            name="date"
                            label={t('form-fields.date')}
                            placeholder=""
                            showSearch
                            defaultValue=""
                            errors={errors?.date || ''}
                        />
                    </Form.Item>
                </div>
                <Space wrap>
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeDiagnostic(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton>{t('delete')}</DangerButton>
                    </Popconfirm>
                    <Button
                        key="cancel"
                        onClick={() => onClose()}
                        style={{ marginLeft: '150px' }}
                    >
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data
                            ? t('add-edit.edit-notes')
                            : t('add-edit.add-notes')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}
export const NotesBox = () => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const { patient } = useAuth()
    const {
        data,
        // setData,
        refetch,
    } = GetPatientNotesList()
    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }
    const onRowDoubleClick = (rowData) => {
        setEditMode(true)
        onOpen(rowData)
    }

    const onClose = () => {
        setEditMode(false)
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                formData.patient = patient._id
                await PatientNotesService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await PatientNotesService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }
    const columns = [
        {
            title: t('form-fields.note'),
            dataIndex: 'note',
            key: 'note',
            width: 600,
        },
        {
            title: t('form-fields.type'),
            dataIndex: 'type',
            key: 'type',
            width: 600,
        },
        {
            title: t('form-fields.date'),
            dataIndex: 'date',
            key: 'date',
            width: 600,
            render: (text) => DateUtility.dateToString(text, DateFormat.euDate),
        },
        {
            title: t('form-fields.user'),
            dataIndex: 'user',
            key: 'user',
            width: 600,
        },
    ]

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            {t('notes')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} ${t(
                                    'Notes category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <FlexColumn>
                    <HeaderTitle className="col-12">
                        <FlexRowBetween>
                            <div className="d-flex align-items-center">
                                {t('settings.Notes-category')}
                            </div>
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className="d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </FlexRowBetween>
                    </HeaderTitle>
                    <FlexRow className="mt-2">
                        <AutoCompleteDropDown
                            name="search"
                            required
                            placeholder={`${t('search')} ${t(
                                'settings.Notes-category',
                            )}`}
                            options={[]}
                            className="mb-0 ms-5"
                        />
                    </FlexRow>
                </FlexColumn>
            </MobileMode>
            <AddNotesBoxModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                // editMode={!!modalData?._id}
                editMode={editMode}
            />
            <div className="mt-sm-2 mt-md-0">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    onRowDoubleClick={onRowDoubleClick}
                />
            </div>
        </div>
    )
}

export const PatientDetailsScreen = () => {
    const { id } = useParams()
    const { setPatient, patient } = useAuth()
    const { t } = useTranslation()
    const { data, setData } = GetPatientDetail(id)
    const { data: appointmentList, list: diagnosticOptions } =
        GetAppointmentListByPatient(id)
    const navigate = useNavigate()
    const {
        optionList: allergiesOptions,
        filterChanged: allergiesfilterChanged,
        // refetch: refetchAllergiesType,
    } = GetAllergiesTypes()

    useEffect(() => {
        console.log(data)
        setPatient(data)
    }, [data])

    useEffect(() => {
        if (patient?._id) {
            navigate(`/app/patient/${patient._id}`)
        }
    }, [patient])

    const update = async (updataData) => {
        const sendObj = { ...updataData }
        await PatientService.update(data._id, sendObj)
        setData({ ...data, ...sendObj })
    }
    const [profileImage, setProfileImage] = useState(
        sessionStorage.getItem('profileImage') || '',
    )

    const handleProfilePictureClick = () => {
        document.getElementById('fileInput').click()
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0]
        if (file) {
            const imageUrl = URL.createObjectURL(file)
            setProfileImage(imageUrl)
            sessionStorage.setItem('profileImage', imageUrl)
        }
    }

    const {
        ABMItems,
        GeneralItems,
        insurancesItems,
        allergiesItems,
        notesItems,
    } = useMemo(() => {
        const GeneralItems = [
            {
                label: <h3 className="my-0">{t('General Information')}</h3>,
                key: 1,
                children: <GeneralBox data={data} />,
            },
        ]
        const insurancesItems = [
            {
                label: <h3 className="my-0">{t('Insurance Information')}</h3>,
                key: 3,
                children: (
                    <PatientInsuranceBox
                        data={data.billing}
                        columnsToShow={[
                            'insurance',
                            'insurancecategory',
                            'valid',
                            'action',
                        ]}
                        showManageTypeColumn="manageType"
                    />
                ),
            },
        ]
        const allergiesItems = [
            {
                label: <h3 className="my-0">{t('Allergies')}</h3>,
                key: 2,
                children: (
                    <UserAllergiesTab
                        patientPage
                        allergiesOptions={allergiesOptions}
                        filterChanged={allergiesfilterChanged}
                    />
                ),
            },
        ]
        const notesItems = [
            {
                label: <h3 className="my-0">{t('form-fields.notes')}</h3>,
                key: 4,
                children: <NotesBox data={data} update={update} />,
            },
        ]

        const ABMItems = [
            {
                label: <h3 className="my-0">{t('appointment')}</h3>,
                key: 4,
                children: (
                    <AppointmentBox
                        data={appointmentList}
                        list={diagnosticOptions}
                    />
                ),
            },
            {
                label: <h3 className="my-0">{t('Notes')}</h3>,
                key: 7,
                children: <NotesBox data={data} update={update} />,
            },
            {
                label: <h3 className="my-0">{t('insurances')}</h3>,
                key: 2,
                children: (
                    <PatientInsuranceBox
                        columnsToShow={[
                            'insurance',
                            'insurancecategory',
                            'startdate',
                            'cadaNumber',
                            'enddate',
                            'notes',
                            'valid',
                            'action',
                        ]}
                        showManageTypeColumn="manageType"
                    />
                ),
            },
            {
                label: <h3 className="my-0">{t('Allergies')}</h3>,
                key: 3,
                children: (
                    <UserAllergiesTab
                        patientPage
                        allergiesOptions={allergiesOptions}
                        filterChanged={allergiesfilterChanged}
                    />
                ),
            },
            {
                label: <h3 className="my-0">{t('invoices')}</h3>,
                key: 6,
                children: <MedicalBox data={data.medicalHistory} />,
            },
            {
                label: <h3 className="my-0">Documents</h3>,
                key: 5,
                children: <PatientDocumentList patientId={data._id} />,
            },
        ]

        return {
            GeneralItems,
            insurancesItems,
            allergiesItems,
            notesItems,
            ABMItems,
        }
    }, [data, appointmentList, diagnosticOptions, t, allergiesOptions])

    return (
        <div>
            <PageHeader
                left={<HeaderTitle>{t('patient-details')}</HeaderTitle>}
                right={
                    <NavLink to={`/app/patient/edit/${id}`}>
                        <PrimaryButton>{t('edit')}</PrimaryButton>
                    </NavLink>
                }
            />
            <Container className="col-12">
                <ProfileContainer className="col-5">
                    <div className="col-12">
                        <div
                            className="d-flex align-items-center"
                            onClick={handleProfilePictureClick}
                        >
                            <Avatar
                                size={130}
                                icon={<User />}
                                src={profileImage}
                            />
                            <div className="ml-2 d-flex flex-column">
                                <div
                                    className="d-flex align-items-center"
                                    style={{ marginLeft: '7rem' }}
                                >
                                    <span className="mr-2">
                                        <IdentificationBadge size={20} />
                                    </span>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t('form-fields.patient-no')} :
                                    </span>
                                    <div className="ml-2">
                                        <DataBox className="wrap">
                                            {data?.patientNumber}
                                        </DataBox>
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ marginLeft: '7rem' }}
                                >
                                    <span className="mr-2">
                                        <IdentificationCard size={20} />
                                    </span>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t('form-fields.name')} :
                                    </span>
                                    <div className="ml-2">
                                        <DataBox className="wrap">
                                            {' '}
                                            {`${data?.firstName || ''} ${
                                                data?.lastName || ''
                                            }`}
                                        </DataBox>
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ marginLeft: '7rem' }}
                                >
                                    <span className="mr-2">
                                        <CalendarBlank size={20} />
                                    </span>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t('form-fields.birthdate')} :
                                    </span>
                                    <div className="ml-2">
                                        <DataBox className="wrap">
                                            {DateUtility.dateToString(
                                                data?.birthdate,
                                                DateFormat.euDate,
                                            )}
                                        </DataBox>
                                    </div>
                                </div>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ marginLeft: '7rem' }}
                                >
                                    <span className="mr-2">
                                        <GenderNeuter size={20} />
                                    </span>
                                    <span style={{ fontWeight: 'bold' }}>
                                        {t('form-fields.gender')} :
                                    </span>
                                    <div className="ml-2">
                                        <DataBox className="wrap">
                                            {data?.gender}
                                        </DataBox>
                                    </div>
                                </div>
                                <input
                                    type="file"
                                    id="fileInput"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={handleImageUpload}
                                />
                            </div>
                        </div>
                    </div>
                    <DetailContainer className="mt-3">
                        <div className="row">
                            <div>
                                <Tabs items={GeneralItems} />
                            </div>
                            <div>
                                <Tabs items={allergiesItems} />
                            </div>
                            <div>
                                <Tabs items={insurancesItems} />
                            </div>
                            <div className="col-12">
                                <Tabs items={notesItems} />
                            </div>
                        </div>
                    </DetailContainer>
                </ProfileContainer>
                <LeftContainer className="col">
                    <div className="col">
                        <Tabs items={ABMItems} />
                    </div>
                </LeftContainer>
            </Container>
        </div>
    )
}
