import React from 'react'
import './style.css'
import { DateFormat, DateUtility } from 'utility'
import EditableValue from 'templates/components/EditableValue'
// import html2canvas from 'html2canvas'

const Image = ({ className, src }) => {
    // const [id] = React.useState(Math.round(Math.random() * 1000).toString())
    const [image, setImage] = React.useState(null)

    function getBase64Image() {
        const img = document.createElement('img')

        img.crossOrigin = 'anonymous'
        img.src = src
        img.classList.add(className)

        img.onload = () => {
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            setImage(
                <img alt={className} className={className} src={dataURL} />,
            )
        }
    }

    React.useEffect(() => {
        getBase64Image()
        return () => setImage(null)
    }, [src])

    // React.useEffect(() => {
    //     const img = document.createElement('img')
    //     img.crossOrigin = 'anonymous'
    //     // declare a function to call once the image has loaded
    //     img.onload = function () {
    //         const canvas = document.createElement('canvas')
    //         canvas.width = img.width
    //         canvas.height = img.height
    //         const context = canvas.getContext('2d')
    //         context.drawImage(img, 0, 0)
    //         const dataURL = canvas.toDataURL()
    //         // now you can do something with the dataURL
    //         console.log(dataURL)
    //     }
    //     // now set the image's src
    //     img.src =
    //         'https://docteo-upload.s3.us-east-1.amazonaws.com/281ee9e7-1b5c-441c-80e6-1d53221097cb.png'
    // }, [])

    // React.useEffect(() => {
    //     const img = document.getElementById(id)

    //     // if (img.src.includes('http')) {
    //     // img.setAttribute('src', src)

    //     img.addEventListener('load', async () => {
    //         console.clear()
    //         console.log({
    //             image: (await html2canvas(img)).toDataURL('image/png'),
    //         })

    //         img.src = (await html2canvas(img)).toDataURL('image/png')
    //     })
    //     // }
    // }, [])

    return (
        <>{image}</>
        // <img
        //     id={id}
        //     src={src}
        //     className={className}
        //     alt={className}
        //     onLoad={async () => {
        //         const img = document.getElementById(id)
        //         if (img.src.includes('http')) {
        //             console.clear()
        //             // console.log(getBase64Image(img))
        //             img.src = getBase64Image(img)
        //         }
        //     }}
        //     {...rest}
        // />
    )
}

export const Page1 = ({
    p_gender = 'p_gender',
    p_fullname = 'p_fullname',
    p_birthdate = 'p_birthdate',
    p_address = 'p_address',
    p_zipcode = 'p_zipcode',
    p_city = 'p_city',
    p_phone = 'p_phone',
    d_fullname = 'd_fullname',
    d_speciality = 'd_speciality',
    e_date = 'e_date',
    d_address = 'd_address',
    d_city = 'd_city',
    d_zip = 'd_zip',
    d_phone = 'd_phone',
    e_subjective = 'e_subjective',
    p_history = 'p_history',
    p_allergy = 'p_allergy',
    p_diagnostic = 'p_diagnostic',
    c_address = 'e_address',
    e_name = 'e_name',
    d_logo = '',
    d_fax = 'd_fax',
    exportMode = false,
}) => {
    return (
        <>
            <div className="A4">
                <div className="page1-box">
                    <div className="page1-group">
                        <p className="page1-doctor-full-name">
                            <span className="page1-text-wrapper">
                                <EditableValue edit={exportMode}>
                                    Dr {d_fullname}
                                </EditableValue>
                                <br />
                            </span>
                            <span className="page1-span">
                                <EditableValue edit={exportMode}>
                                    {d_speciality}
                                </EditableValue>
                                <br />
                                <EditableValue edit={exportMode}>
                                    {d_address}
                                </EditableValue>
                                ,{' '}
                                <EditableValue edit={exportMode}>
                                    {d_zip}
                                </EditableValue>{' '}
                                <EditableValue edit={exportMode}>
                                    {d_city}
                                </EditableValue>
                                <br />
                                Tel.{' '}
                                <EditableValue edit={exportMode}>
                                    {d_phone}
                                </EditableValue>{' '}
                                / Fax{' '}
                                <EditableValue edit={exportMode}>
                                    {d_fax}
                                </EditableValue>
                            </span>
                        </p>
                        {d_logo && (
                            <Image
                                alt="doctor_logo"
                                className="doctor_logo"
                                src={d_logo}
                            />
                        )}
                        <hr className="page1-line" />
                        <hr className="page1-img" />
                        <div className="page1-div">
                            <EditableValue edit={exportMode}>
                                {d_city}
                            </EditableValue>
                            ,{' '}
                            {DateUtility.dateToString(
                                new Date(),
                                DateFormat.euDate,
                            )}
                        </div>
                        <p className="page1-frau-herr-patient">
                            <EditableValue edit={exportMode}>
                                {p_gender === 'male' ? 'Frau' : 'Herr'}{' '}
                            </EditableValue>
                            <EditableValue edit={exportMode}>
                                {p_fullname}
                            </EditableValue>
                            , geb. am{' '}
                            <EditableValue edit={exportMode}>
                                {p_birthdate}
                            </EditableValue>
                            ,<br />
                            <EditableValue edit={exportMode}>
                                {p_address}, {p_zipcode}, {p_city},
                            </EditableValue>
                            <br />
                            <EditableValue edit={exportMode}>
                                {p_phone || ''}
                            </EditableValue>
                        </p>
                        <p className="page1-p">
                            <EditableValue edit={exportMode}>
                                {p_gender === 'male' ? 'Frau' : 'Herr'}
                            </EditableValue>{' '}
                            <EditableValue edit={exportMode}>
                                {p_fullname}
                            </EditableValue>
                            , geb. am{' '}
                            <EditableValue edit={exportMode}>
                                {p_birthdate}
                            </EditableValue>
                        </p>
                        <div className="page1-text-wrapper-2">Seite 1</div>
                        <div className="page1-text-wrapper-3">Conc.:</div>
                        <div className="page1-text-wrapper-4">
                            <EditableValue edit={exportMode}>
                                Dear Dr,
                            </EditableValue>
                        </div>
                        <p className="page1-text-wrapper-5">
                            I did see the patient mentioned above in my surgery
                            on the{' '}
                            <EditableValue edit={exportMode}>
                                {e_date}
                            </EditableValue>
                        </p>
                        <div className="page1-text-wrapper-6">
                            <EditableValue edit={exportMode}>
                                {c_address
                                    ? JSON.parse(c_address).title
                                    : '[Title]'}
                                ,
                                <br />
                                {c_address
                                    ? JSON.parse(c_address).lastName
                                    : '[Last Name]'}{' '}
                                {c_address
                                    ? JSON.parse(c_address).firstName
                                    : '[First Name]'}
                                ,
                                <br />
                                {c_address
                                    ? JSON.parse(c_address).address.address
                                    : '[address]'}
                                ,
                                <br />
                                {c_address
                                    ? `${
                                          JSON.parse(c_address).address
                                              .postalCode
                                      }, ${JSON.parse(c_address).address.city}`
                                    : '[zipcode], [city]'}
                                ,
                                <br />
                                {c_address
                                    ? JSON.parse(c_address).address.country
                                    : '[country]'}
                            </EditableValue>
                        </div>
                        <div className="page1-text-wrapper-7">
                            Reason of event:{' '}
                            <EditableValue edit={exportMode}>
                                {e_name}
                            </EditableValue>
                        </div>
                        <p className="page1-diagnoses-diagnostic">
                            <span className="temp-heading">
                                Diagnoses:
                                <br />
                            </span>
                            <p className="page1-text-wrapper-9">
                                <EditableValue edit={exportMode}>
                                    {p_diagnostic || '[]'}
                                </EditableValue>
                            </p>
                        </p>
                        <p className="page1-anamesis-history">
                            <span className="temp-heading">
                                Anamesis:
                                <br />
                            </span>
                            <p className="page1-text-wrapper-9">
                                <EditableValue edit={exportMode}>
                                    {p_history || '[]'}
                                </EditableValue>
                            </p>
                        </p>
                        <p className="page1-allergies-allergies">
                            <span className="temp-heading">
                                Allergies:
                                <br />
                            </span>
                            <p className="page1-text-wrapper-9">
                                <EditableValue edit={exportMode}>
                                    {p_allergy || '[]'}
                                </EditableValue>
                            </p>
                        </p>
                        <p className="page1-subjective-encounter">
                            <span className="temp-heading">
                                Subjective:
                                <br />
                            </span>
                            <p className="page1-text-wrapper-9">
                                <EditableValue edit={exportMode}>
                                    {e_subjective}
                                </EditableValue>
                            </p>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export const Page2 = ({
    p_gender = 'p_gender',
    p_fullname = 'p_fullname',
    p_birthdate = 'p_birthdate',
    d_fullname = 'd_fullname',
    d_speciality = 'd_speciality',
    d_address = 'd_address',
    d_city = 'd_city',
    d_zip = 'd_zip',
    d_phone = 'd_phone',
    e_plan = 'e_plan',
    e_assessment = 'e_assessment',
    // p_vital_hr = 'p_vital_hr',
    p_vital_bp = 'p_vital_bp',
    p_vital_temp = 'p_vital_temp',
    p_vital_resp = 'p_vital_resp',
    p_vital_o2 = 'p_vital_o2',
    // p_vital_bg = 'p_vital_bg',
    p_vital_weight = 'p_vital_weight',
    p_vital_height = 'p_vital_height',
    p_vital_imc = 'p_vital_imc',
    d_signature = '',
    d_logo = '',
    d_fax = 'd_fax',
    exportMode = false,
}) => {
    return (
        <div className="A4">
            <div className="page2-box">
                <div className="page2-group">
                    <div className="page2-text-wrapper">Seite 2</div>
                    <p className="page2-div">
                        <EditableValue edit={exportMode}>
                            {p_gender === 'male' ? 'Frau' : 'Herr'}
                        </EditableValue>{' '}
                        <EditableValue edit={exportMode}>
                            {p_fullname}
                        </EditableValue>
                        , geb. am{' '}
                        <EditableValue edit={exportMode}>
                            {p_birthdate}
                        </EditableValue>
                    </p>
                    <hr className="page2-line" alt="Line" src="line-3.svg" />

                    <div
                        className="temp-container"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <div>
                            <p className="page2-parameters-puls-fr">
                                <span className="temp-heading">
                                    Parameters:
                                </span>
                                <br />
                                <p className="page2-text-wrapper-2">
                                    <div>
                                        Puls:{' '}
                                        <EditableValue edit={exportMode}>
                                            []
                                        </EditableValue>
                                    </div>
                                    {p_vital_bp && (
                                        <div>
                                            Blood Pressure:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_bp}
                                            </EditableValue>
                                        </div>
                                    )}
                                    {p_vital_height && (
                                        <div>
                                            Taille:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_height}
                                            </EditableValue>
                                        </div>
                                    )}
                                    {p_vital_weight && (
                                        <div>
                                            Poids:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_weight}
                                            </EditableValue>
                                        </div>
                                    )}
                                    <div>
                                        PC:{' '}
                                        <EditableValue edit={exportMode}>
                                            []
                                        </EditableValue>
                                    </div>
                                    {p_vital_imc && (
                                        <div>
                                            BMI:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_imc}
                                            </EditableValue>
                                        </div>
                                    )}
                                    {p_vital_temp && (
                                        <div>
                                            Temperature:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_temp}
                                            </EditableValue>
                                        </div>
                                    )}
                                    {p_vital_resp && (
                                        <div>
                                            Respiratory rate:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_resp}
                                            </EditableValue>
                                        </div>
                                    )}
                                    {p_vital_o2 && (
                                        <div>
                                            Saturation O2:{' '}
                                            <EditableValue edit={exportMode}>
                                                {p_vital_o2}
                                            </EditableValue>
                                        </div>
                                    )}
                                </p>
                            </p>
                        </div>
                        <div>
                            <p className="page2-plan-encounter-plan">
                                <span className="temp-heading">
                                    Plan:
                                    <br />
                                </span>
                                <p className="page2-text-wrapper-2">
                                    <EditableValue edit={exportMode}>
                                        {e_plan}
                                    </EditableValue>
                                </p>
                            </p>
                        </div>
                        <div>
                            <p className="page2-assessment-encounter">
                                <span className="temp-heading">
                                    Assessment:
                                    <br />
                                </span>
                                <p className="page2-text-wrapper-2">
                                    <EditableValue edit={exportMode}>
                                        {e_assessment}
                                    </EditableValue>
                                </p>
                            </p>
                        </div>
                        <div style={{ marginTop: 55 }}>
                            <p className="page2-please-do-not">
                                Please do not hesitate to contact for me for any
                                further information.
                                <br />
                                <br />
                                <div className="force-right">
                                    Kind Regards,
                                    <br />
                                    <EditableValue edit={exportMode}>
                                        Dr {d_fullname}
                                    </EditableValue>
                                </div>
                            </p>
                        </div>
                    </div>
                    {d_signature && (
                        <Image
                            alt="doctor_signature"
                            className="doctor_signature"
                            src={d_signature}
                        />
                    )}
                    <hr className="page2-img" alt="Line" src="line-1.svg" />
                    <div className="page2-text-wrapper-5">
                        <EditableValue edit={exportMode}>
                            {d_city}
                        </EditableValue>
                        ,{' '}
                        {DateUtility.dateToString(
                            new Date(),
                            DateFormat.euDate,
                        )}
                    </div>
                    {d_logo && (
                        <Image
                            alt="doctor_logo"
                            className="doctor_logo"
                            src={d_logo}
                        />
                    )}
                    <p className="page2-doctor-full-name">
                        <span className="page2-text-wrapper-6">
                            <EditableValue edit={exportMode}>
                                Dr {d_fullname}
                            </EditableValue>
                            <br />
                        </span>
                        <span className="page2-text-wrapper-7">
                            <EditableValue edit={exportMode}>
                                {d_speciality}
                            </EditableValue>
                            <br />
                            <EditableValue edit={exportMode}>
                                {d_address}
                            </EditableValue>
                            ,{' '}
                            <EditableValue edit={exportMode}>
                                {d_zip}
                            </EditableValue>{' '}
                            <EditableValue edit={exportMode}>
                                {d_city}
                            </EditableValue>
                            <br />
                            Tel.{' '}
                            <EditableValue edit={exportMode}>
                                {d_phone}
                            </EditableValue>{' '}
                            / Fax{' '}
                            <EditableValue edit={exportMode}>
                                {d_fax}
                            </EditableValue>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}
