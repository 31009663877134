import { Divider } from 'antd';
import { useAuth } from 'context';
import styled from 'styled-components';
import { DateFormat, DateUtility } from 'utility';
import React, { useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';

const EAN13Barcode = ({ value }) => {
  const barcodeRef = useRef(null);

  useEffect(() => {
    if (barcodeRef.current) {
      JsBarcode(barcodeRef.current, value,{ displayValue: false });
    }
  }, [value]);

    return <svg height={50} ref={barcodeRef} />;
};

const Box = styled.div`
  page-break-inside: avoid;
  padding: 15px;
  margin-block: 0 !important;
  h4 {
    margin-block: 0 !important;
  }
`;

const Main = styled.div`margin-bottom: 50px;`;

const DrugList = ({ list }) => (
  <div className="mx-3 col-12 text-start">
    {list.map((ele) => (
      <div className="row mb-3" key={ele._id} style={{ pageBreakBefore: "auto" }}>
        <h4 className="mx-0 col-10">
          <div className="d-flex flex-column">
            <div>{ele?.drug?.name}</div>
            <div>
              ({ele.morning.toString()} - {ele.evening.toString()} - {ele.noon.toString()} -{' '}
              {ele.night.toString()})
            </div>
          </div>
        </h4>
        <h4 className="col-1">
          {ele?.dosage}
          <span className="mx-1 px-0">{ele?.unit}</span>
        </h4>
      </div>
    ))}
  </div>
);

export const Prescription = ({ patient, list }) => {
  const { user } = useAuth();
  return (
    <Box>
      <Main>
        <div className="row">
          <div className="col-4 text-start">
            <h3>
              {user?.firstName} {user?.lastName}
            </h3>
            <h4>
              {user?.specialist}&nbsp;&nbsp;&nbsp;({user?.degree}&nbsp;)
            </h4>
            <h4>
              {user?.address?.address}, {user?.address?.city},
            </h4>
            <h4>
              {user?.address?.country}, {user?.address?.postalCode}
            </h4>
            <h4>Phone: {user?.phone}</h4>
          </div>
          <div className="col text-end">
            {user?.glnno && <EAN13Barcode value={user?.glnno} />}
            <h4 className="mx-5 my-0">{DateUtility.dateToString(new Date(), DateFormat.date)}</h4>
          </div>
        </div>
        <Divider />
        <div className="mx-3">
          <div className="d-flex mb-1">
            <div className="col-1 ms-3 text-start">Patient</div>:
            <h4 className="my-0 mx-3">
              {patient?.firstName} {patient?.lastName} -{' '}
              {patient?.birthdate ? DateUtility.dateToString(patient?.birthdate, DateFormat.date) : ''}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <div className="col-1 ms-3 text-start">Age</div>:
            <h4 className="my-0 mx-3">
              {patient?.birthdate ? DateUtility.getDistanceInWord(new Date(patient?.birthdate)) : ''}
            </h4>
          </div>
          <div className="d-flex mb-1">
            <div className="mt-3 col-12">
              <DrugList list={list} />
            </div>
          </div>
        </div>
        <Divider />
        <div className="d-flex justify-content-end">
          <div className="mx-5">
            <h4 className="text-center">
              {user?.firstName} {user?.lastName}
            </h4>
            <h4 className="text-center">
            {user?.specialist}&nbsp;&nbsp;&nbsp;({user?.degree}&nbsp;)
            </h4>
          </div>
        </div>
      </Main>
    </Box>
  );
};