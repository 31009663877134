import React, { useEffect, useMemo } from 'react'
import {
    GetDiagnosticListByPatient,
    GetMedicalHistoryListByPatient,
    GetEncounterColors,
    GetSnippetsList,
    GetTimerListByPatient,
    // GetTodayTimer,
    GetVitalSignTypeList,
    GetDiagnosticTypeList,
    GetTodayPatientTimer,
    GetDefaultTimer,
    GetQuestionList,
    GetQuestionAnswersListByPatient,
    GetAllergiesTypes,
    GetVaccination,
    GetMedicalHistoryTypeList,
    AppointmentPageSettingsList,
    GetUserVitalSignByPatient,
} from 'hooks'
import { Alert, Select, notification } from 'antd'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
import { AppointmentDetailsCards } from 'page-components/appointments'
import {
    DateUtility,
    DoctorService,
    StorageConstant,
    TimerService,
    UserPageSettings,
    UserVitalSignsService,
} from 'utility'
import { BrowserUtility } from 'utility/browser-utility'

export function EncounterScreen() {
    const { patient, user, setUser } = useAuth()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { data: quickEntryList } = GetSnippetsList()
    const { data: colors } = GetEncounterColors()
    const {
        optionList: dignosticTypeList,
        filterChanged: dignosticTypeListFilterChanged,
    } = GetDiagnosticTypeList()
    const { data: maxTimer } = GetDefaultTimer()
    const {
        data: diagnosticList,
        optionList: diagnosticOptions,
        setData: setDignosticData,
        refetch: diagnosticRefetch,
    } = GetDiagnosticListByPatient(patient?._id)
    const {
        data: timerList,
        loading: timerLoading,
        total: totalTimerData,
        pageChanged: onTimerPageChange,
    } = GetTimerListByPatient(patient?._id)
    const {
        data: medicalPatientHistoryList,
        setData: setMedicalPatientHistoryData,
        refetch: medicalPatientHistoryRefetch,
    } = GetMedicalHistoryListByPatient(patient?._id)
    const {
        optionList: vitalsigntypeOptions,
        filterChanged: vitalSignfilterChanged,
        refetch: refetcVitalSignType,
    } = GetVitalSignTypeList()

    const { data: localVitalSign, refetch: refetchLocalVitalSign } =
        GetUserVitalSignByPatient(patient._id)
    const {
        optionList: questionOptionsList,
        filterChanged: questionFilterChanged,
    } = GetQuestionList()
    const { data: questionAnswersList } = GetQuestionAnswersListByPatient(
        patient._id,
    )
    const { optionList: allergiesOptions } = GetAllergiesTypes(patient._id)
    const {
        optionList: vaccinationOptions,
        filterChanged: vaccinationfilterChanged,
        refetch: refetchVaccinationType,
    } = GetVaccination()
    const { data: todayTimer } = GetTodayPatientTimer(patient?._id)
    const {
        optionList: medicalHistoryTypeList,
        filterChanged: medicalHistoryTypeListFilterChanged,
        refetch: refetchMedicalHistoryType,
    } = GetMedicalHistoryTypeList({ skip: 0, limit: 50 })
    const { data: blockOptionsList } = AppointmentPageSettingsList(t)
    const pastTimerList = useMemo(() => {
        return (
            timerList?.filter(
                (ele) => !DateUtility.isToday(new Date(ele.date)),
            ) || []
        )
    }, [timerList])
    const selectedScreens = useMemo(() => {
        return (
            user.pageSettings?.find(
                (ele) => ele.pageKey === UserPageSettings.encounterDetails,
            )?.sections || []
        )
    }, [user])
    useEffect(() => {
        if (todayTimer?._id) {
            BrowserUtility.save(StorageConstant.timer, todayTimer._id)
        }
    }, [todayTimer])

    useEffect(() => {
        return () => {
            lastStop()
        }
    }, [])

    const lastStop = async () => {
        try {
            if (BrowserUtility.get(StorageConstant.timer)) {
                await TimerService.toggleTimer(
                    BrowserUtility.get(StorageConstant.timer),
                    false,
                )
            }
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    const updateTimer = async (id, status) => {
        try {
            await TimerService.toggleTimer(id || todayTimer._id, status)
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
        return true
    }
    const onViewChange = async (data) => {
        const list = user.pageSettings
        try {
            const pageSettings = user.pageSettings.findIndex(
                (ele) => ele.pageKey === UserPageSettings.encounterDetails,
            )
            if (pageSettings < 0) {
                list.push({
                    pageKey: UserPageSettings.encounterDetails,
                    sections: data,
                })
            } else {
                list[pageSettings].sections = data
            }
            setUser({
                ...user,
                pageSettings: list,
            })
            await DoctorService.update(user._id, {
                pageSettings: list,
            })
        } catch {
            setUser({
                ...user,
                pageSettings: list,
            })
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }
    return (
        <div>
            {patient?._id && (
                <>
                    <div className="mx-3 my-2 d-flex justify-content-end">
                        <Select
                            name={t('settings')}
                            className="col-3"
                            onChange={onViewChange}
                            mode="multiple"
                            options={blockOptionsList}
                            defaultValue={selectedScreens}
                        />
                    </div>
                    <AppointmentDetailsCards
                        setMedicalHistoryData={setMedicalPatientHistoryData}
                        medicalPatientHistoryList={medicalPatientHistoryList}
                        medicalPatientHistoryRefetch={
                            medicalPatientHistoryRefetch
                        }
                        medicalHistoryTypeList={medicalHistoryTypeList}
                        medicalHistoryTypeListFilterChanged={
                            medicalHistoryTypeListFilterChanged
                        }
                        refetchMedicalHistoryType={refetchMedicalHistoryType}
                        colors={colors}
                        diagnosticOptions={diagnosticOptions}
                        quickEntryList={quickEntryList}
                        diagnosticList={diagnosticList}
                        dignosticTypeList={dignosticTypeList}
                        setDignosticData={setDignosticData}
                        language={language}
                        vitalsigntypeOptions={vitalsigntypeOptions}
                        diagnosticRefetch={diagnosticRefetch}
                        timerLoading={timerLoading}
                        totalTimerData={totalTimerData}
                        onTimerPageChange={onTimerPageChange}
                        patient={patient}
                        updateTimer={updateTimer}
                        todayTimer={todayTimer}
                        pastTimerList={pastTimerList}
                        maxTimer={maxTimer?.timerduration}
                        vitalSignfilterChanged={vitalSignfilterChanged}
                        dignosticTypeListFilterChanged={
                            dignosticTypeListFilterChanged
                        }
                        refetcVitalSignType={refetcVitalSignType}
                        questionOptions={questionOptionsList}
                        questionFilterChanged={questionFilterChanged}
                        categoryOptionList={[]}
                        questionAnswersList={questionAnswersList}
                        allergiesOptions={allergiesOptions}
                        refetchVaccinationType={refetchVaccinationType}
                        vaccinationfilterChanged={vaccinationfilterChanged}
                        vaccinationOptions={vaccinationOptions}
                        selectedScreens={selectedScreens}
                        onViewChange={onViewChange}
                        encounterPage
                        localVitalSign={localVitalSign}
                        setLocalVitalSign={async (data) => {
                            if (!data.length) return
                            console.log(data)
                            const updateData = {
                                isActive: true,
                                vitalsigntype:
                                    data[data.length - 1].vitalsigntype.value,
                                doctor: user._id,
                                patient: patient._id,
                                appointment: '',
                                date: data[data.length - 1].date,
                                max: data[data.length - 1].vitalsigntype.max,
                                min: data[data.length - 1].vitalsigntype.min,
                                unit: data[data.length - 1].unit,
                                value: data[data.length - 1].value,
                            }
                            await UserVitalSignsService.add(updateData)
                            refetchLocalVitalSign()
                        }}
                    />
                </>
            )}
            {!patient?._id && (
                <Alert
                    className="my-3"
                    message={t('message.no-patient-please-select')}
                    type="info"
                    showIcon
                />
            )}
        </div>
    )
}
