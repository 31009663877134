import { useAuth } from 'context'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
    Alert,
    Layout as AntdLayout,
    Popover,
    Tooltip,
    Dropdown,
    Space,
} from 'antd'
import { CommonUtility, DateFormat, DateUtility, Roles } from 'utility'
import { FormAutoCompleteFormField, PrimaryButton, ShadowBox } from 'elements'
import {
    DesktopMode,
    GetNextAppointmentsList,
    GetSearchPatientList,
    MobileMode,
    useDebounce,
} from 'hooks'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import {
    Stethoscope,
    UserPlus,
    Users,
    Calendar,
    SignOut,
    CurrencyCircleDollar,
    Chats,
    ListChecks,
    GearSix,
    HourglassHigh,
    House,
    PresentationChart,
    X,
    File,
    // FirstAid,
} from '@phosphor-icons/react'
import { AiOutlineExperiment } from 'react-icons/ai'
import { Images } from 'images'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'
import { FlexRow, FlexRowBetween } from 'components'
import { t } from 'i18next'
import { WaitingRoomContent } from './waiting-room'
import { CalendarMenuContent } from './calendar'
import { MenuDrawer } from './menu-drawer'
import {
    List,
    CalendarBlank,
    CaretDown,
    GenderMale,
    GenderFemale,
    GenderNeuter,
    MagnifyingGlass,
} from 'phosphor-react'

const { Header } = AntdLayout
export const NavMenu = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5px;

    &.active {
        background: ${({ theme }) => theme.colors.primary};
    }
`

export const DivMenu = styled.div`
    vertical-align: middle;
    margin-right: 0 0.5rem;
    padding: 0.5rem;
    display: inline-flex;
    cursor: pointer;
`

export const ProfileIcon = styled.div`
    border-radius: 18px;
    height: 32px;
    width: 32px;
    background: ${({ theme }) => theme.colors.grey} !important;
    pointer: cursor;
    position: relative;
`

const HeaderLayout = styled(Header)`
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background: ${({ theme }) => theme.colors.box} !important;
`
export const ProfileName = styled.span`
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white} !important;
`
const Logo = styled.img`
    max-height: 100px;
    width: 70px;
`

export const LaungageLogo = styled.img`
    height: 20px;
    width: 20px;
`
const PatientBoxContainer = styled(ShadowBox)`
    height: 2.2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px;
    ${({ selected, theme }) =>
        selected &&
        `background: ${theme.colors.primary};color: ${theme.colors.white}`}
`
const DropSelected = styled.div`
    ${({ selected, theme }) =>
        selected &&
        `
        color: ${theme.colors.primary};  
    `}
`

const PatientBox = ({ patient, onRemove, language }) => (
    <PatientBoxContainer>
        <div to="" className="d-flex align-items-center">
            {patient.anonym && patient.anonymName ? (
                <div>{patient.anonymName}</div>
            ) : (
                <>
                    {patient?.gender === 'male' && (
                        <GenderMale size={20} style={{ color: 'blue' }} />
                    )}
                    {patient?.gender === 'female' && (
                        <GenderFemale size={20} style={{ color: 'pink' }} />
                    )}
                    {patient?.gender === 'other' && (
                        <GenderNeuter size={20} style={{ color: 'black' }} />
                    )}
                    <span className="ml-2">
                        {patient?.firstName} {patient?.lastName}
                    </span>
                    {patient?.patientNumber && <>-{patient?.patientNumber}</>}
                    {patient?.birthdate && (
                        <>
                            &nbsp;-
                            {DateUtility.dateToString(
                                new Date(patient?.birthdate),
                                DateFormat.euDate,
                            )}
                        </>
                    )}
                    {patient?.birthdate && (
                        <>
                            &nbsp;(
                            {DateUtility.getDistanceInWord(
                                new Date(patient?.birthdate),
                                language,
                            ).replace(/years?/, 'Y')}
                            )
                        </>
                    )}
                    <X size={18} className="ml-2" onClick={onRemove} />
                </>
            )}
        </div>
    </PatientBoxContainer>
)

export const renderPatientItem = (
    data,
    patient,
    language,
    recent,
    removeHistory,
) => ({
    value: `${data?.firstName} ${data?.lastName ? data?.lastName : ''}`,
    label: (
        <DropSelected
            className="d-flex justify-content-between"
            selected={patient._id === data._id}
        >
            <span className="col-1">{data?.patientNumber}</span>
            <span className="col-4">
                {CommonUtility.truncateString(
                    `${data.firstName} ${data.lastName}`,
                    20,
                )}
            </span>
            <span className="col-2">
                {DateUtility.dateToString(data?.birthdate, DateFormat.euDate)}
            </span>
            <span className="col-3">{data.phone}</span>
            <span className="d-flex align-items-center col">
                <div className="col-12 d-flex align-items-center">
                    <span className="col-10">
                        {DateUtility.getDistanceInWord(
                            new Date(data?.birthdate),
                            language,
                        )}
                    </span>
                    {recent && (
                        <X
                            onClick={(e) => {
                                e.stopPropagation()
                                removeHistory()
                            }}
                        />
                    )}
                </div>
            </span>
        </DropSelected>
    ),
    data,
})

const renderNoPatientItem = () => [
    {
        value: 0,
        label: (
            <FlexRow>
                <Alert
                    showIcon
                    type="info"
                    message={t('message.no-patient-found')}
                    className="col-8"
                />
                <PrimaryButton className="col ms-2 p-2 h-100">
                    {t('add-edit.add-patient')}
                </PrimaryButton>
            </FlexRow>
        ),
    },
]

const SearchShema = yup.object().shape({
    search: yup.string(),
})

const LanguageOptions = {
    en: 'en',
    fr: 'fr',
    'en-US': 'en-US',
}

export function HeaderBar({
    patient,
    onSelectPatient,
    onRemove,
    openMobileDrawer,
    setopenMobileDrawer,
    historyData,
    removeHistory,
}) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const MenuNames = {
        Appointment: t('menu.appointment'),
        Doctor: t('menu.doctor'),
        AddDoctor: t('menu.add-doctor'),
        Patient: t('menu.patient'),
        Document: t('menu.document'),
        Laboratory: t('menu.laboratory'),
        AddPatient: t('menu.add-patient'),
        Encounter: t('menu.encounter'),
        Billing: t('menu.billing'),
        Chat: t('menu.chat'),
        Task: t('menu.task'),
        Settings: t('menu.settings'),
        SignOut: t('menu.sign-out'),
        RecentPatient: t('menu.recent-patient'),
        language: t('menu.language'),
        home: t('menu.home'),
        statistic: t('menu.statistic'),
        fr: 'FR',
        en: 'En',
        'en-US': 'En',
    }
    const {
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(SearchShema),
    })
    const navigate = useNavigate()
    const search = watch('search')
    const { data: nextlist, refetch: refetchNextList } =
        GetNextAppointmentsList()

    const changeLanguage = () => {
        let newLanguage

        if (
            language === LanguageOptions.en ||
            language === LanguageOptions['en-US']
        ) {
            newLanguage = LanguageOptions.fr
        } else if (language === LanguageOptions.fr) {
            newLanguage = LanguageOptions.en
        }

        i18n.changeLanguage(newLanguage)

        // Store the selected language in localStorage
        localStorage.setItem('language', newLanguage)
    }

    const DropDownItems = [
        {
            key: '1',
            label: (
                <NavMenu to="app/settings?tab=profile" className="col-12 mx-0">
                    {({ isActive }) => (
                        <Tooltip
                            placement="bottom"
                            title={MenuNames.Settings}
                            className="d-flex align-items-center col-12 mx-0"
                        >
                            <GearSix
                                size={20}
                                color={isActive ? 'white' : 'black'}
                            />
                            <span
                                className="mx-2"
                                style={{ color: isActive ? 'white' : 'black' }}
                            >
                                {MenuNames.Settings}
                            </span>
                        </Tooltip>
                    )}
                </NavMenu>
            ),
        },
        {
            key: '2',
            label: (
                <NavMenu to="app/statistic" className="col-12 mx-0">
                    {({ isActive }) => (
                        <Tooltip
                            placement="bottom"
                            title={MenuNames.statistic}
                            className="d-flex align-items-center col-12 mx-0"
                        >
                            <PresentationChart
                                size={20}
                                color={isActive ? 'white' : 'black'}
                            />
                            <span
                                className="mx-2"
                                style={{ color: isActive ? 'white' : 'black' }}
                            >
                                {MenuNames.statistic}
                            </span>
                        </Tooltip>
                    )}
                </NavMenu>
            ),
        },
        {
            key: '3',
            label: (
                <DivMenu onClick={changeLanguage} className="col-12 mx-0">
                    <Tooltip
                        placement="bottom"
                        title={MenuNames.language}
                        className="d-flex align-items-center col-12 mx-0"
                    >
                        <LaungageLogo src={Images[language]} />
                        <span className="mx-2" style={{ color: 'black' }}>
                            {MenuNames[language]}
                        </span>
                    </Tooltip>
                </DivMenu>
            ),
        },
        {
            key: '4',
            label: (
                <DivMenu onClick={() => logout()} className="col-12 mx-0">
                    <Tooltip
                        placement="bottom"
                        title={MenuNames.SignOut}
                        className="d-flex align-items-center col-12 mx-0"
                    >
                        <SignOut size={20} color="black" />
                        <span className="mx-2" style={{ color: 'black' }}>
                            {MenuNames.SignOut}
                        </span>
                    </Tooltip>
                </DivMenu>
            ),
        },
    ]

    useEffect(() => {
        const intervalId = setInterval(() => {
            refetchNextList()
        }, 60000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        filterChanged({ search })
    }, [search])

    const { logout, user } = useAuth()

    const username = useMemo(() => {
        return CommonUtility.getInitials(user?.firstName, user?.lastName)
    }, [user])

    const finalSearch = useDebounce(search)
    const { data: patientList, filterChanged } =
        GetSearchPatientList(finalSearch)

    const listOfPatient = useMemo(() => {
        if (!finalSearch || finalSearch?.length < 3) {
            return historyData?.map((item) => {
                return renderPatientItem(
                    item.patient,
                    patient,
                    language,
                    true,
                    () => removeHistory(item._id),
                )
            })
        }
        if (!patientList.length && finalSearch?.length >= 3) {
            return renderNoPatientItem()
        }
        return patientList?.map((item) =>
            renderPatientItem(item, patient, language),
        )
    }, [patientList, patient, historyData, t])

    const navigateToDetail = (data) => {
        if (data.value === 0) {
            navigate(`/app/patient/add`, { state: { firstName: finalSearch } })
            return
        }
        navigate(`/app/patient/${data?.data?._id}`)
    }

    const gotoProfile = () => {
        navigate(`/app/settings?tab=profile`)
    }

    const items = useMemo(
        () =>
            [
                {
                    name: MenuNames.home,
                    role: '',
                    component: (
                        <NavMenu to="app/home">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.home}
                                    className="align-items-center d-flex"
                                >
                                    <House
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.home}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Doctor,
                    role: Roles.admin,
                    component: (
                        <NavMenu to="app/doctor">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Doctor}
                                    className="align-items-center d-flex"
                                >
                                    <Users size={20} color="black" />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Doctor}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.AddDoctor,
                    role: Roles.admin,
                    component: (
                        <NavMenu to="app/doctor/add">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.AddDoctor}
                                    className="align-items-center d-flex"
                                >
                                    <UserPlus size={20} color="black" />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.AddDoctor}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Appointment,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/appointments">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Appointment}
                                    className="align-items-center d-flex"
                                >
                                    <Calendar
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Appointment}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Patient,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/patient">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Patient}
                                    className="align-items-center d-flex"
                                >
                                    <Users
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Patient}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Document,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/document" end>
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Document}
                                    className="align-items-center d-flex"
                                >
                                    <File
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Document}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Laboratory,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/laboratory" end>
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Laboratory}
                                    className="align-items-center d-flex"
                                >
                                    <File
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Laboratory}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Encounter,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/encounter">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Encounter}
                                    className="align-items-center d-flex"
                                >
                                    <Stethoscope
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Encounter}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Billing,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/billing" end>
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Billing}
                                    className="align-items-center d-flex"
                                >
                                    <CurrencyCircleDollar
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Billing}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Chat,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/chat" end>
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Chat}
                                    className="align-items-center d-flex"
                                >
                                    <Chats
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Chat}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.Task,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/task" end>
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Task}
                                    className="align-items-center d-flex"
                                >
                                    <ListChecks
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Task}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                // {
                //     name: MenuNames.statistic,
                //     role: Roles.doctor,
                //     component: (
                //         <NavMenu to="app/statistic">
                //             {({ isActive }) => (
                //                 <Tooltip
                //                     placement="bottom"
                //                     title={MenuNames.statistic}
                //                     className="align-items-center d-flex"
                //                 >
                //                     <PresentationChart
                //                         size={20}
                //                         color={isActive ? 'white' : 'black'}
                //                     />
                //                     <span
                //                         className="mx-2"
                //                         style={{
                //                             color: isActive ? 'white' : 'black',
                //                         }}
                //                     >
                //                         {MenuNames.statistic}
                //                     </span>
                //                 </Tooltip>
                //             )}
                //         </NavMenu>
                //     ),
                // },
                // {
                //     name: MenuNames.Document,
                //     role: Roles.doctor,
                //     component: (
                //         <NavMenu to="app/document" end>
                //             {({ isActive }) => (
                //                 <Tooltip
                //                     placement="bottom"
                //                     title={MenuNames.Document}
                //                     className="align-items-center d-flex"
                //                 >
                //                     <File
                //                         size={20}
                //                         color={isActive ? 'white' : 'black'}
                //                     />
                //                     <span
                //                         className="mx-2"
                //                         style={{
                //                             color: isActive ? 'white' : 'black',
                //                         }}
                //                     >
                //                         {MenuNames.Document}
                //                     </span>
                //                 </Tooltip>
                //             )}
                //         </NavMenu>
                //     ),
                // },
                // {
                //     name: MenuNames.Document,
                //     role: Roles.doctor,
                //     component: (
                //         <NavMenu to="app/" end>
                //             {({ isActive }) => (
                //                 <Tooltip
                //                     placement="bottom"
                //                     title={MenuNames.Document}
                //                     className="align-items-center d-flex"
                //                 >
                //                     <File
                //                         size={20}
                //                         color={isActive ? 'white' : 'black'}
                //                     />
                //                     <span
                //                         className="mx-2"
                //                         style={{
                //                             color: isActive ? 'white' : 'black',
                //                         }}
                //                     >
                //                         {MenuNames.Document}
                //                     </span>
                //                 </Tooltip>
                //             )}
                //         </NavMenu>
                //     ),
                // },
                {
                    name: MenuNames.Settings,
                    role: Roles.doctor,
                    component: (
                        <NavMenu to="app/settings?tab=profile">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.Settings}
                                    className="align-items-center d-flex"
                                >
                                    <GearSix
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                    <span
                                        className="mx-2"
                                        style={{
                                            color: isActive ? 'white' : 'black',
                                        }}
                                    >
                                        {MenuNames.Settings}
                                    </span>
                                </Tooltip>
                            )}
                        </NavMenu>
                    ),
                },
                {
                    name: MenuNames.SignOut,
                    role: '',
                    component: (
                        <DivMenu onClick={() => logout()}>
                            <Tooltip
                                placement="bottom"
                                title={MenuNames.SignOut}
                                className="align-items-center d-flex"
                            >
                                <SignOut
                                    onClick={() => logout()}
                                    size={20}
                                    color="black"
                                />
                                <span
                                    className="mx-2"
                                    style={{ color: 'black' }}
                                >
                                    {MenuNames.SignOut}
                                </span>
                            </Tooltip>
                        </DivMenu>
                    ),
                },
                {
                    name: MenuNames[language],
                    role: '',
                    component: (
                        <DivMenu className="" onClick={changeLanguage}>
                            <Tooltip
                                placement="bottom"
                                title={MenuNames.language}
                                className="align-items-center d-flex"
                            >
                                <LaungageLogo src={Images[language]} />
                                <span
                                    className="mx-2"
                                    style={{ color: 'black' }}
                                >
                                    {MenuNames[language]}
                                </span>
                            </Tooltip>
                        </DivMenu>
                    ),
                },
            ].filter((item) => item.role === user?.role || item.role === ''),
        [user, language],
    )
    const onMenuDrawerClose = () => {
        setopenMobileDrawer(false)
    }
    return (
        <>
            <HeaderLayout className="border-bottom justify-content-between">
                <MobileMode>
                    <FlexRowBetween className="col-12 align-items-center">
                        <List
                            size={25}
                            onClick={() => setopenMobileDrawer(true)}
                        />
                        <div className="d-flex align-items-center">
                            {user?.role === Roles.doctor && (
                                <FormAutoCompleteFormField
                                    control={control}
                                    name="search"
                                    required
                                    errors={errors.patient}
                                    placeholder={t('search')}
                                    options={listOfPatient}
                                    className="mb-0"
                                    onSearch={(data) => {
                                        setValue('search', data)
                                    }}
                                    onChange={(_, data) => {
                                        navigateToDetail(data)
                                    }}
                                />
                            )}
                        </div>
                    </FlexRowBetween>
                </MobileMode>
                <DesktopMode>
                    <div className="d-flex align-items-center">
                        <Logo src={Images.logo} alt="Logo" />
                        <NavMenu to="app/home">
                            {({ isActive }) => (
                                <Tooltip
                                    placement="bottom"
                                    title={MenuNames.home}
                                >
                                    <House
                                        size={20}
                                        color={isActive ? 'white' : 'black'}
                                    />
                                </Tooltip>
                            )}
                        </NavMenu>
                        {user?.role === Roles.admin && (
                            <>
                                <NavMenu to="app/doctor">
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Doctor}
                                        >
                                            <Users
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/doctor/add">
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.AddDoctor}
                                        >
                                            <UserPlus
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                            </>
                        )}
                        {user?.role === Roles.doctor && (
                            <>
                                <NavMenu to="app/appointments">
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Appointment}
                                        >
                                            <Calendar
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/patient">
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Patient}
                                        >
                                            <Users
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/document" end>
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Document}
                                        >
                                            <File
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/laboratory" end>
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Laboratory}
                                        >
                                            <AiOutlineExperiment
                                                size={22}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/encounter">
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Encounter}
                                        >
                                            <Stethoscope
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/billing" end>
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Billing}
                                        >
                                            <CurrencyCircleDollar
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/chat" end>
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Chat}
                                        >
                                            <Chats
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                                <NavMenu to="app/task" end>
                                    {({ isActive }) => (
                                        <Tooltip
                                            placement="bottom"
                                            title={MenuNames.Task}
                                        >
                                            <ListChecks
                                                size={20}
                                                color={
                                                    isActive ? 'white' : 'black'
                                                }
                                            />
                                        </Tooltip>
                                    )}
                                </NavMenu>
                            </>
                        )}
                        {/* {[Roles.doctor, Roles.admin].includes(user?.role) && (
                            <NavMenu to="app/statistic">
                                {({ isActive }) => (
                                    <Tooltip
                                        placement="bottom"
                                        title={MenuNames.statistic}
                                    >
                                        <PresentationChart
                                            size={20}
                                            color={isActive ? 'white' : 'black'}
                                        />
                                    </Tooltip>
                                )}
                            </NavMenu>
                        )} */}
                    </div>
                    <div className="d-flex align-items-center">
                        {(user?.role === Roles.doctor ||
                            user?.role === Roles.admin) &&
                            patient?._id && (
                                <PatientBox
                                    patient={patient}
                                    onRemove={onRemove}
                                    language={language}
                                />
                            )}
                        <div className="d-flex flex-row align-items-center">
                            {(user?.role === Roles.doctor ||
                                user?.role === Roles.admin) && (
                                <>
                                    {user?.role === Roles.doctor && (
                                        <>
                                            <DivMenu>
                                                <Popover
                                                    destroyTooltipOnHide
                                                    content={
                                                        CalendarMenuContent
                                                    }
                                                    title=""
                                                >
                                                    {nextlist.length === 0 ? (
                                                        <PatientBoxContainer>
                                                            {' '}
                                                            {t(
                                                                'no-appointments',
                                                            )}{' '}
                                                        </PatientBoxContainer>
                                                    ) : (
                                                        <PatientBoxContainer>
                                                            <CalendarBlank
                                                                size={20}
                                                            />{' '}
                                                            &nbsp; In{' '}
                                                            {nextlist?.[0]
                                                                ?._id &&
                                                                DateUtility.getDistanceInWordWithMinutes(
                                                                    new Date(
                                                                        nextlist[0].start_time,
                                                                    ),
                                                                    language,
                                                                )}
                                                        </PatientBoxContainer>
                                                    )}
                                                </Popover>
                                            </DivMenu>
                                            <DivMenu>
                                                {(user?.role === Roles.doctor ||
                                                    user?.role ===
                                                        Roles.admin) && (
                                                    <Popover
                                                        // placement="bottomRight"
                                                        destroyTooltipOnHide
                                                        overlayClassName="search-patient"
                                                        title=""
                                                        content={() => (
                                                            <>
                                                                <FormAutoCompleteFormField
                                                                    control={
                                                                        control
                                                                    }
                                                                    name="search"
                                                                    required
                                                                    errors={
                                                                        errors.patient
                                                                    }
                                                                    placeholder={t(
                                                                        'search',
                                                                    )}
                                                                    className="mb-0"
                                                                    onSearch={(
                                                                        data,
                                                                    ) => {
                                                                        setValue(
                                                                            'search',
                                                                            data,
                                                                        )
                                                                    }}
                                                                    onChange={(
                                                                        _,
                                                                        data,
                                                                    ) => {
                                                                        navigateToDetail(
                                                                            data,
                                                                        )
                                                                    }}
                                                                />
                                                                {listOfPatient.map(
                                                                    (ele) => (
                                                                        <h4
                                                                            className="pointer"
                                                                            onClick={() => {
                                                                                onSelectPatient(
                                                                                    ele.data,
                                                                                )
                                                                            }}
                                                                        >
                                                                            {
                                                                                ele.label
                                                                            }
                                                                        </h4>
                                                                    ),
                                                                )}
                                                            </>
                                                        )}
                                                    >
                                                        <MagnifyingGlass
                                                            size={20}
                                                            color="black"
                                                            className="pointer"
                                                        />
                                                    </Popover>
                                                )}
                                            </DivMenu>
                                            <DivMenu>
                                                <Popover
                                                    destroyTooltipOnHide
                                                    overlayClassName="waiting-room"
                                                    content={WaitingRoomContent}
                                                    title=""
                                                >
                                                    <HourglassHigh
                                                        size={20}
                                                        color="black"
                                                        className="pointer"
                                                    />
                                                </Popover>
                                            </DivMenu>
                                            {/* <DivMenu onClick={openDrawer}>
                                        <Tooltip placement="bottom" title={MenuNames.RecentPatient}>
                                            <Notification size={20} color="black" className="pointer" />
                                        </Tooltip>
                                    </DivMenu> */}
                                        </>
                                    )}
                                </>
                            )}
                            <DivMenu onClick={() => logout()}>
                                {/* <Tooltip placement="bottom" title={MenuNames.SignOut}>
                                    <SignOut size={20} color="black" />
                                </Tooltip> */}
                            </DivMenu>
                            <ProfileIcon onClick={gotoProfile}>
                                <ProfileName>{username}</ProfileName>
                            </ProfileIcon>
                            <DivMenu>
                                <div>
                                    <Dropdown
                                        menu={{
                                            items: DropDownItems,
                                        }}
                                    >
                                        <h3>
                                            <Space>
                                                {user?.firstName}
                                                {user?.lastName}
                                                <CaretDown className="d-flex" />
                                            </Space>
                                        </h3>
                                    </Dropdown>
                                </div>
                            </DivMenu>
                        </div>
                    </div>
                </DesktopMode>
            </HeaderLayout>
            <MobileMode>
                <MenuDrawer
                    items={items}
                    menuNames={MenuNames}
                    changeLanguage={changeLanguage}
                    open={openMobileDrawer}
                    onClose={onMenuDrawerClose}
                />
            </MobileMode>
        </>
    )
}
