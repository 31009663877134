import { Button, Form, Space, notification } from 'antd'
import {
    FormSelectionField,
    FormTextFormField,
    FormTimeField,
    PrimaryButton,
} from 'elements'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useAuth } from 'context'
import {
    CountryList,
    DateUtility,
    DoctorService,
    ContentTypeOptions,
    GenderOptions,
    WorkingDayOptions,
    FileUploadService,
} from 'utility'
import { FileUpload, HeaderTitle } from 'components'
import { useTranslation } from 'react-i18next'

const ProfileSchema = yup.object().shape({
    firstName: yup.string().required('*First name is required'),
    lastName: yup.string().required('*Last name is required'),
    phone: yup.string().required('*Phone is required'),
    alternatePhone: yup.string(),
    fax: yup.string(),
    gender: yup.string().required('*Gender is required'),
    degree: yup.string().required('*Degree is required'),
    specialist: yup.string().required('*Specialist  is required'),
    workingdays: yup.string().required('*Workingdays is required'),
    content: yup.string().required('*content is required'),
    starttime: yup.string().required('*Start time is required'),
    endtime: yup.string().required('*End time is required'),
    experience: yup.string().required('*Experience is required'),
    glnno: yup.string().required('*GLN no. is required'),
    address: yup.object().shape({
        address: yup.string().required('*Address is required'),
        city: yup.string().required('*City is required'),
        postalCode: yup.string().required('*PostalCode is required'),
        country: yup.string().required('*Country is required'),
    }),
})

export function ProfileTab() {
    const [processing, setProcessing] = useState(false)
    const { user, setUser } = useAuth()
    const { t } = useTranslation()

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ProfileSchema),
    })

    useEffect(() => {
        if (user._id) {
            reset({
                ...user,
                starttime: DateUtility.dayJSFormat(user.starttime),
                endtime: DateUtility.dayJSFormat(user.endtime),
            })
        }
    }, [user])

    const submit = async (formData) => {
        try {
            setProcessing(true)
            delete formData._id
            delete formData.password
            delete formData.access_token
            const result = await DoctorService.update(user._id, formData)
            setUser({ ...result })
            notification.success({
                message: t('notif-message.doctor-updated'),
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
        }
    }

    const cancel = () => {
        reset({
            ...user,
            starttime: DateUtility.dayJSFormat(user?.starttime),
            endtime: DateUtility.dayJSFormat(user?.endtime),
        })
    }

    return (
        <div className="container">
            <Form layout="vertical" noValidate>
                <div className="row">
                    <div className="col-12">
                        <HeaderTitle>{t('general-info')}</HeaderTitle>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                        <FormTextFormField
                            control={control}
                            name="firstName"
                            label={t('form-fields.firstName')}
                            placeholder="First Name"
                            errors={errors?.firstName || ''}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <FormTextFormField
                            control={control}
                            name="lastName"
                            label={t('form-fields.lastName')}
                            placeholder="Last Name"
                            errors={errors?.lastName || ''}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormSelectionField
                            control={control}
                            name="gender"
                            label={t('form-fields.gender')}
                            placeholder="Gender"
                            errors={errors?.gender}
                            options={GenderOptions}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormTextFormField
                            control={control}
                            name="phone"
                            label={t('form-fields.phone')}
                            placeholder="Phone"
                            errors={errors?.phone}
                            required
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormTextFormField
                            control={control}
                            name="alternatePhone"
                            label={t('form-fields.alternatePhone')}
                            placeholder="Alternate Phone"
                            errors={errors?.alternatePhone}
                        />
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <FormTextFormField
                            control={control}
                            name="fax"
                            label="Fax"
                            placeholder="Fax"
                            errors={errors?.fax}
                        />
                    </div>
                </div>
                <h3>{t('educational-info')}</h3>
                <div className="row">
                    <div className="col-md-6 col-sm-6">
                        <FormTextFormField
                            control={control}
                            name="degree"
                            label={t('form-fields.degree')}
                            placeholder="Degree"
                            errors={errors?.degree}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <FormTextFormField
                            control={control}
                            name="experience"
                            label={t('form-fields.experience')}
                            placeholder="Experience"
                            errors={errors?.experience}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <FormTextFormField
                            control={control}
                            name="specialist"
                            label={t('form-fields.specialist')}
                            placeholder="Specialist"
                            errors={errors?.specialist}
                            required
                        />
                    </div>
                </div>
                <h3>{t('working-info')}</h3>
                <div className="row">
                    <div className="col-md-3 col-sm-3">
                        <FormSelectionField
                            control={control}
                            name="content"
                            label={t('form-fields.content-type')}
                            placeholder="Select"
                            errors={errors?.encounter}
                            options={ContentTypeOptions}
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <FormSelectionField
                            control={control}
                            name="workingdays"
                            label={t('form-fields.working-days')}
                            placeholder="Working Days"
                            errors={errors?.workingdays}
                            options={WorkingDayOptions}
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <FormTimeField
                            control={control}
                            name="starttime"
                            label={t('form-fields.start-time')}
                            placeholder="Time"
                            errors={errors?.starttime}
                            required
                        />
                    </div>
                    <div className="col-md-3 col-sm-3">
                        <FormTimeField
                            control={control}
                            name="endtime"
                            label={t('form-fields.end-time')}
                            placeholder="Time"
                            errors={errors?.endtime}
                            required
                        />
                    </div>
                </div>
                <h3>{t('address')}</h3>
                <div className="row">
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="glnno"
                            label={t('form-fields.glnno')}
                            placeholder="glnno"
                            errors={errors?.glnno}
                            required
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address.address"
                            label={t('form-fields.street')}
                            placeholder="Address"
                            errors={errors?.address?.address}
                            required
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address.postalCode"
                            label={t('form-fields.postalCode')}
                            placeholder="Postal Code"
                            errors={errors?.address?.postalCode}
                            required
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address.city"
                            label={t('form-fields.city')}
                            placeholder="City"
                            errors={errors?.address?.city}
                            required
                        />
                    </div>
                    <div className="col-md-6 col-sm-6">
                        <FormSelectionField
                            control={control}
                            name="address.country"
                            label={t('form-fields.country')}
                            placeholder="Country"
                            errors={errors?.address?.country}
                            options={CountryList}
                            showSearch
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <h3>
                            Signature{' '}
                            {user.signature && '(Click image to override)'}
                        </h3>
                        <ImageUpload property="signature" />
                    </div>
                    <div className="col-6">
                        <h3>Logo {user.logo && '(Click image to override)'}</h3>
                        <ImageUpload property="logo" />
                    </div>
                </div>
                <Space className="d-flex justify-content-end">
                    <Button htmlType="button" onClick={cancel}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        loading={processing}
                        type="primary"
                        htmlType="submit"
                        onClick={handleSubmit(submit)}
                    >
                        {t('submit')}
                    </PrimaryButton>
                </Space>
            </Form>
        </div>
    )
}

const ImageUpload = ({ property }) => {
    const [loading, setLoading] = useState(false)
    const { user, setUser } = useAuth()
    const { t } = useTranslation()

    const [uploadMode, setUploadMode] = useState(false)

    const handleChange = async ({ file }) => {
        if (!file) return
        setLoading(true)
        const { signedUrl, keyOrUrl } = await FileUploadService.signedUrl({
            ext: `.${file.name.split('.').pop()}`,
            contentType: file.type,
            isPublic: true,
        })
        await FileUploadService.media(signedUrl, file, file.type)
        const result = await DoctorService.update(user._id, {
            [property]: keyOrUrl,
        })
        setUser({ ...result })
        notification.success({
            message: t('notif-message.doctor-updated'),
        })
        setLoading(false)
    }

    return (
        <>
            {user[property] && !uploadMode ? (
                <img
                    src={user[property]}
                    onClick={() => setUploadMode(true)}
                    alt={property}
                    width={250}
                    height={150}
                />
            ) : (
                <FileUpload
                    multiple={false}
                    accept=".png, .jpeg"
                    onNewFile={handleChange}
                    style={{ maxHeight: 150 }}
                    disabled={loading}
                />
            )}
        </>
    )
}
