import { CommonUtility } from 'utility/common'
import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class ColorCode extends CRUDService {
    constructor() {
        super(APIPath.colorCode)
    }

    byEncounter(params) {
        const query = CommonUtility.objectToParams(params || {})
        const url = `${APIPath.colorCode}/encounter?${query}`
        return BaseService.get(url)
    }
}

const ColorCodeService = new ColorCode()
Object.freeze(ColorCodeService)
export { ColorCodeService }
