import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Select, Space, notification } from 'antd'
import { AutoCompleteDropDown, HeaderTitle, PageHeader, AppTable } from 'components'
import { DangerButton, FormSelectionField, FormTextFormField, PrimaryButton } from 'elements'
import { DesktopMode, GetInsuranceList, GetInsuranceTypeList, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DateUtility, InsuranceService, PatientInsuranceService } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'context'

const InsuranceSchema = yup.object().shape({
    insurance: yup.string().required('insurance is required'),
    insurancecategory: yup.string().required("insurancecategory is required"),
    address1: yup.string().required("address1 is required"),
    address2: yup.string().required("address2 is required"),
    location: yup.string().required("location is required"),
    website: yup.string().required("website is required"),
    postalcode: yup.string().required("postalcode is required"),
    insurergln: yup.string().required("insurergln is required"),
    recipientgln: yup.string().required("recipientgln is required"),
})

const AddInsuranceModal = ({ open, onClose, data, loading, editMode, onSubmit }) => {
    const {
        handleSubmit, control, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(InsuranceSchema),
    })
    const { t } = useTranslation()
    const { data: insuranceTypeList } = GetInsuranceTypeList(t)
    useEffect(() => {
        reset(editMode ? { ...data, startdate: DateUtility.dayJSFormat(data.startdate), enddate: DateUtility.dayJSFormat(data.enddate) } : {
        })
    }, [open, editMode])

    return (
        <Modal
            title={data?._id ? `${t("")}` : `${t("add-insurance")}`}
            open={open}
            footer={null}
            width={900}
            onCancel={onClose}>
            <Form layout="vertical">
                <div className="row">
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="insurance"
                            placeholder={t("insurance")}
                            label={t("insurance")}
                            errors={errors?.insurance}
                            showSearch
                            defaultValue=""
                        /></div>
                    <div className="col-6">
                        <FormSelectionField
                            control={control}
                            name="insurancecategory"
                            placeholder={t("insurancecategory")}
                            label={t("insurancecategory")}
                            errors={errors?.insurancecategory}
                            showSearch
                            defaultValue=""
                            options={insuranceTypeList}
                        /></div><div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address1"
                            placeholder={t("address1")}
                            label={t("address1")}
                            errors={errors?.address1}
                            showSearch
                            defaultValue=""
                        /></div><div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address2"
                            placeholder={t("address2")}
                            label={t("address2")}
                            errors={errors?.address2}
                            showSearch
                            defaultValue=""
                        /></div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="postalcode"
                            placeholder={t("postalcode")}
                            label={t("postalcode")}
                            errors={errors?.postalcode}
                            showSearch
                            defaultValue=""
                        /></div><div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="location"
                            placeholder={t("location")}
                            label={t("location")}
                            errors={errors?.location}
                            showSearch
                            defaultValue=""
                        /></div><div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="website"
                            placeholder={t("website")}
                            label={t("website")}
                            errors={errors?.website}
                            showSearch
                            defaultValue=""
                        /></div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="insurergln"
                            placeholder={t("insurergln")}
                            label={t("insurergln")}
                            errors={errors?.insurergln}
                            showSearch
                            defaultValue=""
                        /></div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="recipientgln"
                            placeholder={t("recipientgln")}
                            label={t("recipientgln")}
                            errors={errors?.recipientgln}
                            showSearch
                            defaultValue=""
                        /></div>
                </div>
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>{t("cancel")}</Button>
                    <PrimaryButton key="submit" loading={loading} onClick={handleSubmit(onSubmit)}>{data ? `${t("edit")}` : `${t("create")}`} {t("settings.insurance")}</PrimaryButton>
                </Space>
            </Form>
        </Modal>)
}

export const InsuranceTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const { data, setData, refetch, filterChanged, total, filter, pageChanged } = GetInsuranceList()
    const { user, patient } = useAuth()
    const onTypeChange = (data) => {
        filterChanged("search", { insurancecategory: data })
    }
    const { data: insuranceTypeList } = GetInsuranceTypeList(t)
    const onClose = () => {
        setOpen(false)
        setModalData({})
    }

    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                formData.patient = patient._id;
                formData.doctor = user._id;
                await InsuranceService.add(formData)
                notification.success({ message: t("notif-message.category-added") })
            } else {
                delete formData._id
                delete formData.doctor
                await InsuranceService.update(modalData._id, formData)
                notification.success({ message: t("notif-message.category-updated") })
            }
        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeInsurance = async (id) => {
        try {
            await InsuranceService.remove(id)
            notification.success({ message: t("notif-message.category-removed") })
            setData(data.filter(item => item._id !== id))

        } catch (error) {
            notification.error({ message: t("notif-message.default-error") })
        }
    }

    const getAddress = (ele) => {
        return ele.filter(ele => ele).toString()
    }

    const columns = [
        {
            title: t("insurance"),
            dataIndex: 'insurance',
            key: 'insurance',
        },
        {
            title: t("form-fields.category"),
            dataIndex: 'insurancecategory',
            key: 'insurancecategory',
        },
        {
            title: t("address1"),
            dataIndex: 'address1',
            key: 'address1',
            render: (_, data) => <>{getAddress([data.address1, data.address2, data.location, data.postalcode])}</>,
        },
        {
            title: t("website"),
            dataIndex: 'website',
            key: 'website',
            render: (text) => {
                return <a href={text} target="_blank" rel="noreferrer">{text}</a>;
            },
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton icon={<Pencil onClick={() => onOpen(data)} />} />
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.category")}` })}
                        onConfirm={() => removeInsurance(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >

                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const orderChanged = async (items) => {
        try {
            await PatientInsuranceService.OrderBy({ items: items.map(item => item._id), skip: filter.skip })
        } catch {
            notification.error({
                message: t("notif-message.default-error"),
            })
        }
    }

    const InsuranceTypeListFilterList = [{ value: "", label: "All" }, ...insuranceTypeList]

    return (
        <>
        <DesktopMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-4">
                    {t("settings.insurance")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onTypeChange} className="col-2" defaultValue={insuranceTypeList[0]} popupMatchSelectWidth options={InsuranceTypeListFilterList} />
                    <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("form-fields.insurance")}`}
                        options={[]}
                        className="mb-0 col ms-2"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-2 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton></>}
            />
            <AddInsuranceModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="12">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>0
        </DesktopMode>
        <MobileMode>
        <div>
            <PageHeader
                left={<HeaderTitle className="col-4">
                    {t("settings.insurance")}
                </HeaderTitle>}
                right={<>
                    <Select onChange={onTypeChange} className="col-4" defaultValue={insuranceTypeList[0]} popupMatchSelectWidth options={InsuranceTypeListFilterList} />
                    <PrimaryButton
                        onClick={() => onOpen()}
                        className=" mx-2 d-flex align-items-center">
                        {t("add")}&nbsp;
                        <Plus />
                    </PrimaryButton></>}
            />
             <AutoCompleteDropDown
                        name="search"
                        required
                        placeholder={`${t("search")} ${t("form-fields.insurance")}`}
                        options={[]}
                        className="mb-0 col ms-2"
                        onSearch={(data) => filterChanged("search", { search: data })}
                    />
            <AddInsuranceModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="overflow-auto mt-3">
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
        </MobileMode>
        </>
    )
}
