import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Space, notification, Popconfirm, Modal, Select } from 'antd'
import {
    FormCheckBoxField,
    FormColorPicker,
    FormDateField,
    FormTextAreaFormField,
    PrimaryButton,
    DangerButton,
    FormSelectionField,
} from 'elements'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
    ColumnSearchType,
    DateFormat,
    DateUtility,
    MedicalPatientHistoryService,
    DoctorService,
    MedicalHistoryTypeService,
} from 'utility'
import { AppTable } from 'components'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useAuth } from 'context'
import { CheckCircle, Plus, XCircle } from 'phosphor-react'
import dayjs from 'dayjs'
import { BlockHeader } from './header'
import { useDebounce } from 'hooks'

const MedicalHistorySchema = yup.object().shape({
    description: yup.string().required(),
    startdate: yup.string().required('*startdate is required'),
    enddate: yup.string().nullable(),
    medicalhistorytype: yup.string(),
    active: yup.boolean(),
    color: yup.string(),
})
export const MedicalHistoryTable = ({
    list,
    editMedicalHistory,
}) => {
    const { t } = useTranslation()
    const rowClassName = (data) => {
        if (!data.active) {
            return 'grey-row'
        }
        return ''
    }
    const onRowDoubleClick = (data) => {
        editMedicalHistory(data)
    }

    const columns = [
        {
            title: t('form-fields.description'),
            dataIndex: 'description',
            key: 'description',
            // width: paneSize < 600 ? 300 : null,
        },
        {
            title: t('Type'),
            dataIndex: 'medicalhistorytype',
            key: 'medicalhistorytype',
            width: 200,
            render: (text) => text?.name,
        },
        {
            title: t('form-fields.startdate'),
            dataIndex: 'startdate',
            key: 'startdate',
            type: ColumnSearchType.Date,
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.enddate'),
            dataIndex: 'enddate',
            key: '',
            type: ColumnSearchType.Date,
            // render: (text,data) => (<><DatePicker onChange={(date) => changeDate(data._id,"enddate",date)} defaultValue={text ? DateUtility.dayJSFormat(text) : null} /></>),
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate) || '-'}</>
            ),
        },
        {
            title: t('form-fields.active'),
            dataIndex: 'active',
            key: 'active',
            render: (text) => (
                <>{text ? <CheckCircle size={20} /> : <XCircle size={20} />}</>
            ),
        },
    ]

    return (
        <>
            <>
                <AppTable
                    data={list}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    rowClassName={rowClassName}
                    onRowDoubleClick={onRowDoubleClick}
                />
            </>
        </>
    )
}
export const MedicalHistoryTab = ({
    list,
    setData,
    refetch,
    appointment,
    patient,
    paneSize,
    typeList,
    medicalHistoryTypeListFilterChanged,
    refetchMedicalHistoryType,
}) => {
    const [processing, setProcessing] = useState(false)
    const { user, setUser } = useAuth()
    const [open, setOpen] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [search, setSearch] = useState("")
    const { t } = useTranslation()
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(MedicalHistorySchema),
    })
    const color = watch('color')
    const rowId = watch('_id')

    useEffect(() => {
        reset({
            color: user?.diagnostic_color || false,
        })
    }, [])

    const onSubmit = async (data) => {
        if (!patient?._id) {
            notification.error({
                message: t('notif-message.patient-not-found'),
            })
            return
        }
        try {
            setProcessing(true)
            const reqData = {
                ...data,
                patient: patient?._id,
                doctor: user._id,
                appointment: appointment?._id,
            }
            if (data._id) {
                delete reqData._id
                await MedicalPatientHistoryService.update(data._id, reqData)
                notification.success({
                    message: t('notif-message.diagnostic-updated'),
                })
            } else {
                await MedicalPatientHistoryService.add(reqData)
                notification.success({
                    message: t('notif-message.diagnostic-added'),
                })
            }
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
            refetch()
            reset({ color: user.diagnostic_color || '#000000' })
            setOpen(false)
        }
    }

    const removeMedicalHistory = async (id) => {
        try {
            await MedicalPatientHistoryService.remove(id)
            notification.success({
                message: t('notif-message.diagnostic-removed'),
            })
            setData(list.filter((ele) => ele._id !== id))
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setOpen(false)
            refetch()
            setEditMode(false)
        }
    }

    const editMedicalHistory = (data) => {
        reset({
            ...data,
            medicalhistorytype: data.medicalhistorytype._id,
            startdate: dayjs(data.startdate),
            enddate: data.enddate ? dayjs(data.enddate) : null,
        })
        setEditMode(true)
        setOpen(true)
    }

    const setDefaultColor = async (remove) => {
        if (!color) {
            notification.warning({
                message: t('notif-message.select-color'),
            })
            return
        }
        try {
            await DoctorService.update(user._id, {
                diagnostic_color: remove ? '' : color,
            })
            notification.success({
                message: t('notif-message.doctor-updated'),
            })
            setUser({
                ...user,
                diagnostic_color: remove ? '' : color,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }
    const onClose = () => {
        setOpen(false)
    }

    const onSelect = (id) => {
        setOpen(true)
        reset({
            medicalhistorytype: id,
        })
    }

    const onTypeSubmit = async (formData) => {
        try {
            setProcessing(true)
            const result = await MedicalHistoryTypeService.add({ type: formData })
            notification.success({
                message: t('notif-message.category-added'),
            })
            onSelect(result._id)
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetchMedicalHistoryType()
        }
    }
    const typesearch = useDebounce(search, 1000)
    useEffect(() => {
        medicalHistoryTypeListFilterChanged("search", { name: typesearch })
    }, [typesearch])

    return (
        <div className="">
            <div>
                <BlockHeader
                    title={t('medical-history')}
                    searchElement={<Select
                        className="col-12"
                        options={typeList}
                        onSelect={(id, data) => onSelect(id, data)}
                        filterOption={false}
                        showSearch
                        notFoundContent={<PrimaryButton onClick={() => onTypeSubmit(search)} className="d-flex align-items-center" icon={<Plus />}>Add Type</PrimaryButton>}
                        // placeholder={t("select-medicalhistory-type")}
                        placeholder={`${t("select")} ${t("medical-history")}`}
                        onSearch={(data) => { setSearch(data) }}
                    />}
                >
                    <Select
                        defaultValue=""
                        popupClassName="filter-options"
                        style={{ width: 200 }}
                        onSelect={(data) => { medicalHistoryTypeListFilterChanged("search", { type: data }) }}
                        options={[
                            { value: '', label: 'All' },
                            ...typeList,
                        ]} />
                </BlockHeader>
                <MedicalHistoryTable
                    list={list}
                    typeList={typeList}
                    // sortChanged={sortChanged}
                    editMedicalHistory={editMedicalHistory}
                    removeMedicalHistory={removeMedicalHistory}
                    paneSize={paneSize}
                />
            </div>
            <Modal
                title={
                    editMode
                        ? t('add-edit.edit-medical-history')
                        : t('add-edit.add-medical-history')
                }
                open={open}
                confirmLoading
                footer={null}
                width={600}
                onCancel={() => onClose()}
            >
                <Form layout="vertical">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="startdate"
                                    label={t('form-fields.startdate')}
                                    placeholder=""
                                    errors={errors?.startdate}
                                    defaultValue={DateUtility.dayJSFormat(new Date())}
                                />{' '}
                            </div>
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="enddate"
                                    label={t('form-fields.enddate')}
                                    placeholder=""
                                    errors={errors?.enddate}
                                    allowClear
                                    defaultValue={null}
                                />{' '}
                            </div>
                            <div className="col-12">
                                <FormTextAreaFormField
                                    control={control}
                                    name="description"
                                    label={t('form-fields.description')}
                                    placeholder=""
                                    rows={5}
                                    errors={errors?.description || ''}
                                    defaultValue=""
                                />
                            </div>
                            <div className="col-12">
                                <FormSelectionField
                                    control={control}
                                    name="medicalhistorytype"
                                    label={t('Type')}
                                    placeholder=""
                                    options={typeList}
                                    errors={errors?.medicalhistorytype || ''}
                                    defaultValue=""
                                />
                            </div>
                            <div className="col-5">
                                <FormCheckBoxField
                                    control={control}
                                    name="active"
                                    text={t('form-fields.active')}
                                    errors={errors?.active}
                                    defaultValue="true"
                                />
                            </div>
                            <div className="col-7">
                                <div className=" justify-content-end d-flex flex-row">
                                    <div className="mt-1">
                                        Color &nbsp;&nbsp;
                                    </div>
                                    <FormColorPicker
                                        control={control}
                                        name="color"
                                        placeholder="color"
                                        errors={errors?.color}
                                    />
                                    {color === user.diagnostic_color ? (
                                        <PrimaryButton
                                            className="ms-2"
                                            onClick={() =>
                                                setDefaultColor(true)}
                                        >
                                            Remove as default
                                        </PrimaryButton>
                                    ) : (
                                        <PrimaryButton
                                            className="ms-2"
                                            onClick={() => setDefaultColor()}
                                        >
                                            Set as default
                                        </PrimaryButton>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Popconfirm
                            title={t('delete')}
                            description={t('message.delete-message', {
                                name: `${t('diagnostic')}`,
                            })}
                            onConfirm={() => removeMedicalHistory(rowId)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton>{t("delete")}</DangerButton>
                        </Popconfirm>
                        <div />
                        <Space wrap>
                            <Button key="cancel" onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                key="submit"
                                loading={processing}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('submit')}
                            </PrimaryButton>
                        </Space>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}
