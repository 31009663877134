import React, { useEffect, useMemo, useState } from 'react'
import {
    GetAppointmentDeatils,
    // GetAppointmentListByPatient,
    GetDiagnosticListByPatient,
    GetMedicalHistoryListByPatient,
    GetDiagnosticTypeList,
    GetEncounterColors,
    GetSnippetsList,
    GetTimerListByAppopintment,
    // GetTodayTimer,
    GetVitalSignTypeList,
    GetTodayAppointmentTimer,
    GetDefaultTimer,
    GetQuestionList,
    GetAppointmentCategory,
    GetQuestionAnswersListByAppointment,
    GetAllergiesTypes,
    GetVaccination,
    GetMedicalHistoryTypeList,
    AppointmentPageSettingsList,
} from 'hooks'
import {
    EditReasonPopup,
    AppointmentDetailsCards,
} from 'page-components/appointments'
import {
    AppointmentService,
    DateUtility,
    DoctorService,
    StorageConstant,
    TimerService,
    UserPageSettings,
} from 'utility'
import { useParams } from 'react-router-dom'
import { Select, notification } from 'antd'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
import { BrowserUtility } from 'utility/browser-utility'

export function AppointmentDetailsScreen() {
    // const navigate = useNavigate()
    const { patient, setPatient, user, setUser } = useAuth()
    const params = useParams()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [openReasonModal, setOpenReasonModal] = useState(false)
    const {
        data: appointment,
        setData,
        loading,
    } = GetAppointmentDeatils(params.id)
    const { optionList: categoryOptionList } = GetAppointmentCategory()
    // const { data: appointmentList, loading: appointmentLoading } = GetAppointmentListByPatient(patient?._id)
    const {
        optionList: dignosticTypeList,
        fetchAll,
        filterChanged: dignosticTypeListFilterChanged,
        refetch: refetchDiagnosticType,
    } = GetDiagnosticTypeList({ skip: 0, limit: 50 })
    const {
        optionList: medicalHistoryTypeList,
        filterChanged: medicalHistoryTypeListFilterChanged,
        refetch: refetchMedicalHistoryType,
    } = GetMedicalHistoryTypeList({ skip: 0, limit: 50 })
    const { data: quickEntryList } = GetSnippetsList()
    const { data: maxTimer } = GetDefaultTimer()
    const [dropDocument, setDropDocument] = useState([])
    const { data: colors } = GetEncounterColors()
    const {
        optionList: questionOptionsList,
        filterChanged: questionFilterChanged,
    } = GetQuestionList()
    const { data: questionAnswersList } = GetQuestionAnswersListByAppointment(
        params.id,
    )
    const {
        optionList: allergiesOptions,
        filterChanged: allergiesfilterChanged,
        refetch: refetchAllergiesType,
    } = GetAllergiesTypes()
    const {
        optionList: vaccinationOptions,
        filterChanged: vaccinationfilterChanged,
        refetch: refetchVaccinationType,
    } = GetVaccination()
    const { data: blockOptionsList } = AppointmentPageSettingsList(t)
    const {
        data: timerList,
        loading: timerLoading,
        total: totalTimerData,
        pageChanged: onTimerPageChange,
    } = GetTimerListByAppopintment(params.id)
    const {
        optionList: vitalsigntypeOptions,
        filterChanged: vitalSignfilterChanged,
        refetch: refetcVitalSignType,
    } = GetVitalSignTypeList({ all: true })
    const { data: todayTimer } = GetTodayAppointmentTimer(params.id)
    const selectedScreens = useMemo(() => {
        return user.pageSettings.find(
            (ele) => ele.pageKey === UserPageSettings.appointmentDetails,
        )?.sections
    }, [user])
    useEffect(() => {
        setPatient({ ...patient, ...appointment.patient } || {})
    }, [appointment])

    const {
        data: diagnosticList,
        optionList: diagnosticOptions,
        setData: setDignosticData,
        refetch: diagnosticRefetch,
    } = GetDiagnosticListByPatient(appointment?.patient?._id)
    const {
        data: medicalPatientHistoryList,
        setData: setMedicalPatientHistoryData,
        refetch: medicalPatientHistoryRefetch,
        filterChanged: medicalHistoryFilterChanged,
    } = GetMedicalHistoryListByPatient(appointment?.patient?._id)

    useEffect(() => {
        fetchAll()
        questionFilterChanged('search', { all: true })
    }, [])

    // useEffect(() => {
    //     if (patient?._id && appointmentList.length && !appointmentLoading && appointmentList[0].patient._id === patient._id) {
    //         if (appointmentList.length) {
    //             const id = appointmentList.find(ele => ele._id === params.id)?._id || appointmentList.filter(ele => DateUtility.isPastTime(new Date(ele.start_time))).reverse()[0]._id
    //             navigate(`/app/appointments/${id}`)
    //         } else {
    //             notification.warning({
    //                 message: t('message.no-appointment-patient'),
    //             })
    //         }
    //     }
    // }, [appointmentList, appointmentLoading])

    const pastTimerList = useMemo(() => {
        return (
            timerList?.filter(
                (ele) => !DateUtility.isToday(new Date(ele.date)),
            ) || []
        )
    }, [timerList])

    useEffect(() => {
        if (todayTimer?._id) {
            BrowserUtility.save(StorageConstant.timer, todayTimer._id)
        }
    }, [todayTimer])

    useEffect(() => {
        return () => {
            lastStop()
        }
    }, [])

    const lastStop = async () => {
        try {
            await TimerService.toggleTimer(
                BrowserUtility.get(StorageConstant.timer),
                false,
            )
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    const updateTimer = async (id, status) => {
        try {
            await TimerService.toggleTimer(id || todayTimer._id, status)
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
        return true
    }

    const onReasonModalClose = async (updatedData) => {
        if (updatedData) {
            try {
                await AppointmentService.update(params.id, {
                    reason_for_visit: updatedData.reason_for_visit,
                    patient: updatedData?.patient?._id,
                    category: updatedData?.category?._id,
                })
                setData({
                    ...appointment,
                    reason_for_visit: updatedData.reason_for_visit,
                })
                notification.success({
                    message: t('notif-message.reason-visit-updated'),
                })
            } catch {
                notification.error({
                    message: t('notif-message.default-error'),
                })
            }
        }
        setOpenReasonModal(null)
    }

    const onNewFile = async ({ file }) => {
        setDropDocument(file)
    }
    console.log('onNewFile', onNewFile)

    const onViewChange = async (data) => {
        const list = user.pageSettings
        try {
            const pageSettings = user.pageSettings.findIndex(
                (ele) => ele.pageKey === UserPageSettings.appointmentDetails,
            )
            if (pageSettings < 0) {
                list.push({
                    pageKey: UserPageSettings.appointmentDetails,
                    sections: data,
                })
            } else {
                list[pageSettings].sections = data
            }
            setUser({
                ...user,
                pageSettings: list,
            })
            await DoctorService.update(user._id, {
                pageSettings: list,
            })
        } catch {
            setUser({
                ...user,
                pageSettings: list,
            })
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }
    return (
        <div className="px-3">
            <div className="mx-3 my-2 d-flex justify-content-end">
                <Select
                    name={t('settings')}
                    className="col-3"
                    onChange={onViewChange}
                    mode="multiple"
                    options={blockOptionsList}
                    defaultValue={selectedScreens}
                />
            </div>
            <AppointmentDetailsCards
                loading={loading}
                setMedicalHistoryData={setMedicalPatientHistoryData}
                medicalPatientHistoryList={medicalPatientHistoryList}
                medicalPatientHistoryRefetch={medicalPatientHistoryRefetch}
                medicalHistoryTypeList={medicalHistoryTypeList}
                medicalHistoryTypeListFilterChanged={
                    medicalHistoryTypeListFilterChanged
                }
                refetchMedicalHistoryType={refetchMedicalHistoryType}
                colors={colors}
                diagnosticOptions={diagnosticOptions}
                dropDocument={dropDocument}
                quickEntryList={quickEntryList}
                appointment={appointment}
                diagnosticList={diagnosticList}
                dignosticTypeList={dignosticTypeList}
                setDignosticData={setDignosticData}
                language={language}
                vitalsigntypeOptions={vitalsigntypeOptions}
                diagnosticRefetch={diagnosticRefetch}
                setOpenReasonModal={setOpenReasonModal}
                timerLoading={timerLoading}
                totalTimerData={totalTimerData}
                onTimerPageChange={onTimerPageChange}
                patient={appointment.patient}
                updateTimer={updateTimer}
                todayTimer={todayTimer}
                pastTimerList={pastTimerList}
                vitalSignfilterChanged={vitalSignfilterChanged}
                maxTimer={maxTimer?.timerduration}
                dignosticTypeListFilterChanged={dignosticTypeListFilterChanged}
                refetchDiagnosticType={refetchDiagnosticType}
                refetcVitalSignType={refetcVitalSignType}
                questionOptions={questionOptionsList}
                questionFilterChanged={questionFilterChanged}
                categoryOptionList={categoryOptionList}
                questionAnswersList={questionAnswersList}
                allergiesOptions={allergiesOptions}
                allergiesfilterChanged={allergiesfilterChanged}
                refetchAllergiesType={refetchAllergiesType}
                vaccinationOptions={vaccinationOptions}
                vaccinationfilterChanged={vaccinationfilterChanged}
                medicalHistoryFilterChanged={medicalHistoryFilterChanged}
                refetchVaccinationType={refetchVaccinationType}
                selectedScreens={selectedScreens}
                onViewChange={onViewChange}
            />
            <EditReasonPopup
                open={!!openReasonModal}
                onClose={onReasonModalClose}
                modalData={openReasonModal}
            />
        </div>
    )
}
