import { APIPath } from '../constant'
import { BaseService } from './base'
import { CRUDService } from './crud'

class DocumentCategory extends CRUDService {
    constructor() {
        super(APIPath.documentCategory)
    }

    OrderBy(data) {
        return BaseService.put(`${APIPath.documentCategory}/order-by`,data)
    }
}

const DocumentCategoryService = new DocumentCategory()
Object.freeze(DocumentCategoryService)
export { DocumentCategoryService }
