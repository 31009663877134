import { Form, Popconfirm, notification } from 'antd'
import { useAuth } from 'context';
import { PrimaryButton, FormTextFormField, DangerButton, FormSelectionField } from 'elements';
import { Plus, Trash } from 'phosphor-react';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FileUploadService, PatientDocumentService, PatientDocumentsType } from 'utility';
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AppTable, PdfImagePreview, UploadButton } from 'components';
import { GetDocumentsCategory, GetPatientDocuments } from 'hooks';

const PatientDocumentsList = ({ list, removeDocuments, disabled, loading, pageChanged, total, filter }) => {
    const { t } = useTranslation()
    const columns = [
        {
            title: t("form-fields.name"),
            dataIndex: 'name',
            key: 'name',
            render: (text) => <p>{text}</p>,
        },
        {
            title: t("form-fields.category"),
            dataIndex: 'category',
            key: 'category',
            render: (text) => <p>{text.name}</p>,
        },
        {
            title: t("form-fields.diagnostic"),
            dataIndex: 'diagnostic',
            key: 'diagnostic',
            render: (text) => <p>{text?.description}</p>,
        },
        {
            title: t("form-fields.actions"),
            key: 'action',
            render: (_, data) => (
                <div className="d-flex" size="middle">
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("diagnostic")}` })}
                        onConfirm={() => removeDocuments(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} />
                    </Popconfirm>
                </div>
            ),
        },
    ];
    const onRowDoubleClick = (data) => {
        console.log("data", data)
        // onEdit(data)
    }
    return (
        <>
            <AppTable
                data={list}
                columns={columns}
                keyField="_id"
                size="small"
                onRowDoubleClick={onRowDoubleClick}
                disabled={disabled}
                currentPage={total.page}
                total={total.count}
                pageChanged={pageChanged}
                pageSize={filter?.limit}
                loading={loading}
            />
        </>
    )
}

const AddDocumentSchema = yup.object().shape({
    name: yup.string().required('*name is required'),
    category: yup.string().required('*category is required'),
    diagnostic: yup.string(),
})

export const EncounterDocumentAddEdit = ({ patient, disabled, item, onDelete, dropDocument, diagnosticOptions,readMode }) => {
    const {
        control,
        handleSubmit, reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(AddDocumentSchema),
        defaultValues: {},
    })
    const { t } = useTranslation()
    const { user } = useAuth()
    const { optionList: documentsOptions } = GetDocumentsCategory()
    const { data: documentList,
        loading: documentLoading,
        setData: setDocumentList,
        filterChanged,
        filter,
        total,
        pageChanged } = GetPatientDocuments(patient?._id, PatientDocumentsType.ENCOUNTER)
    const [editMode, setEditMode] = useState(false)
    const [fileList, setFileList] = useState([])
    const [processing, setProcessing] = useState(false)
    useEffect(() => {
        if (dropDocument && dropDocument?.file) {
            setFileList([dropDocument.file])
        }
    }, [dropDocument])

    const onEdit = (data) => {
        reset({ ...data, category: data.category._id, diagnostic: data.diagnostic._id })
        setFileList([{
            url: data.url,
        }])
        setEditMode(true)
    }

    const onSubmit = async (formData) => {
        if (fileList.length === 0) {
            notification.error({ message: t("notif-message.upload-atleast-one") })
            return
        }
        try {
            setProcessing(true)
            const promises = fileList.map(async (item) => {
                const { signedUrl, keyOrUrl } = await FileUploadService.signedUrl({
                    ext: `.${item.name.split('.').pop()}`,
                    contentType: item.type,
                    isPublic: true,
                })
                await FileUploadService.media(signedUrl, item, item.type);
                const reqData = {
                    ...formData,
                    patient: patient._id,
                    doctor: user._id,
                    type: PatientDocumentsType.ENCOUNTER,
                    category: formData.category,
                    diagnostic: formData.diagnostic,
                    url: keyOrUrl,
                }
                delete reqData._id;
                const result = await PatientDocumentService.add(reqData)
                return result
            });
            const result = await Promise.all(promises);
            setDocumentList([...documentList, ...result])
            notification.success({ message: t("notif-message.document-submit") })
        } catch {
            notification.error({ message: t("notif-message.default-error") })
        } finally {
            setProcessing(false)
            reset({})
        }
    }
    const onAdd = async (file) => {
        setFileList([...fileList,file])
    }
    const onLocalFileRemove = (data) => {
        setFileList(fileList.filter(ele => ele.uid !== data.uid))
    }
    const removeDocuments = async (id) => {
        try {
            await PatientDocumentService.remove(id)
            notification.success({ message: t("notif-message.document-removed") })
            setDocumentList(documentList.filter(ele => ele._id !== id))
        } catch {
            notification.error({ message: t("notif-message.default-error") })
        }
    }
    return (
        <Form.Item className="mb-0" name="metadata">
            <Form layout="vertical" className="row">
                <div className="12">
                    <Form.Item>
                        <PatientDocumentsList
                            list={documentList}
                            onEdit={onEdit}
                            disabled={disabled}
                            filter={filter}
                            filterChanged={filterChanged}
                            total={total}
                            pageChanged={pageChanged}
                            removeDocuments={removeDocuments}
                            loading={documentLoading}
                        />
                    </Form.Item>
                </div>
                <div className="row">
                        <div className="col-3">
                            <FormTextFormField
                                control={control}
                                name="name"
                                label={`${t("form-fields.name")}`}
                                placeholder="name"
                                errors={errors?.name}
                                disabled={readMode}
                            />
                        </div>
                        <div className="col-4">
                            <FormSelectionField
                                control={control}
                                name="category"
                                searchfield="name"
                                disabled={disabled || readMode}
                                errors={errors?.category}
                                label={t("form-fields.category")}
                                options={documentsOptions}
                                showSearch
                                placeholder={`${t("search")} ${t("form-fields.category")}`}
                            />
                        </div>

                        <div className="col-4">
                            <FormSelectionField
                                control={control}
                                name="diagnostic"
                                disabled={disabled || readMode}
                                searchfield="description"
                                errors={errors?.diagnostic}
                                label={t("form-fields.diagnostic")}
                                options={diagnosticOptions}
                                showSearch
                                placeholder={`${t("search")} ${t("form-fields.diagnostic")}`}
                            />
                        </div>
                        <div className="col-1">
                            <div className="mt-3 d-flex">
                                <UploadButton disabled={readMode} onAdd={onAdd} files={fileList} />
                            </div>
                    </div>
                    {fileList?.map((file) => (
                        <div>
                            <h3 className="d-flex justify-content-between">
                                <span>{file.name}</span>
                                <span>
                                <DangerButton disabled={readMode} onClick={() => onLocalFileRemove(file)}>Delete</DangerButton>
                                <PrimaryButton disabled={readMode} className="ms-1" loading={processing} onClick={handleSubmit(onSubmit)}><Plus />{editMode ? "Edit" : "Add"} Document</PrimaryButton>
                                </span>
                            </h3>
                            <PdfImagePreview key={file?.uid} file={file} />
                        </div>
                        ))}
                    </div>
            </Form>
            <div className="d-flex align-items-center justify-content-between">
                <div>
                    <Popconfirm
                        title={t("delete")}
                        description={t("message.delete-message", { name: `${t("form-fields.encounter")}` })}
                        onConfirm={() => onDelete(item._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton disabled={readMode} htmlType="button">
                            {t("delete")}
                        </DangerButton>
                    </Popconfirm>
                    {/* {!item.local && <PrimaryButton
                        disabled={readMode}
                        className="ms-2"
                        onClick={() => setReadable(!readable)}
                    >
                        {readable ? "Edit" : "Mark as Readable"}
                    </PrimaryButton>} */}
                </div>
                <span>
                    <PrimaryButton htmlType="submit" disabled={editMode || readMode}>
                        {t("add-edit.save-changes")}
                    </PrimaryButton>
                </span>
            </div>
        </Form.Item>
    )
}
