import React, { useMemo, useState, useRef, useCallback, useEffect } from 'react'
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { fr } from 'date-fns/locale'
import {
    DesktopMode,
    GetAppointmentDurationList,
    GetAppointmentList,
    GetCalendar,
    MobileMode,
} from 'hooks'
import {
    EventBox,
    eventStyleGetter,
    slotPropGetter,
    AddEditAppointmentPopup,
    AppointmentTableListScreen,
    dayPropGetter,
} from 'page-components/appointments'
import {
    AppointmentService,
    CalanderDurationOptions,
    DateFormat,
    DateUtility,
} from 'utility'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { FlexRow, HeaderTitle, PageHeader } from 'components'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Checkbox, DatePicker, Select, notification } from 'antd'
import { useAuth } from 'context'
import { useTranslation } from 'react-i18next'
// import CalendarBar from './calendarbar'
// import MainScreen from './addbutton'
import CalendarDropdown from './calenderdropdown'
import { ShadowBox } from 'elements'; // Ensure ShadowBox is imported correctly
import { isSameDay, endOfWeek } from 'date-fns';

const { Option } = Select;

// console.log(fr)

const DragAndDropCalendar = withDragAndDrop(Calendar)

const locales = { fr }
const CalendarContainer = styled(ShadowBox)`
    min-height: calc(100vh - 200px); // Provides a minimum height for the container
    position: relative;
    display: flex; // Flexbox layout to accommodate inner containers

    .calendar-wrapper {
        flex: 1; // Flex property to fill the available space
        overflow-y: auto; // Enables vertical scrolling within this element
        height: calc(100vh - 250px); // Define a height that ensures scrolling is needed

        .rbc-calendar {
            min-height: 100%; // Ensures the React Big Calendar fills the wrapper
        }
    }

    &.single-mode .calendar-wrapper {
        width: 100%; // Full width in single mode
    }

    &.split-mode .calendar-wrapper {
        flex: 1 1 50%; // Split mode adjusts to 50% for each calendar
        &:first-child {
            margin-right: 20px; // Margin between split calendars
        }
    }
`;

export default CalendarContainer;

// const localizer = momentLocalizer(moment)
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 1 }),
    getDay,
    locales,
})

export function AppointmentListScreen() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [view, setView] = useState(Views.WEEK);

    const {
        t,
        i18n: { language },
    } = useTranslation()
    const { data: appointmentDurationList } = GetAppointmentDurationList(t)
    const [culture, setCulture] = useState(language)
    const [modalData, setModalData] = useState(null)
    const [step, setStep] = useState(30)
    const [timeDuration, setTimeDuration] = useState(
        appointmentDurationList[0].value,
    )
    const [copyEvent, setCopyEvent] = useState(null)
    const [selectedCalendars, setSelectedCalendars] = useState([])
    const { user, setPatient } = useAuth()
    const [weekShow, setWeekShow] = useState(false)
    const [workHours, setWorkHours] = useState(true)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [mode,setMode] = useState('single'); // State to toggle mode
    const [selectedCalendarsLeft, setSelectedCalendarsLeft] = useState([]);
    const [selectedCalendarsRight, setSelectedCalendarsRight] = useState([]);
    const [userInitiatedViewChange, setUserInitiatedViewChange] = useState(false);
    const [rangePickerState, setRangePickerState] = useState({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    });

    const {
        data,
        setData,
        filterChanged,
        total,
        pageChanged,
        filter,
        sortChanged,
        loading,
        refetch,
    } = GetAppointmentList({
        startdate: DateUtility.getStartWeek(new Date()),
        enddate: DateUtility.getEndWeek(new Date()),
    })

    const { data: calendars } = GetCalendar()

    const eventsLeft = useMemo(() => {
        return data
            .filter(appointment =>
                appointment.calendars.length === 0 || // Include appointments with no calendars
                appointment.calendars.some(calendarId => selectedCalendarsLeft.includes(calendarId)),
            )
            .map(appointment => ({
                title: `${appointment.patient?.firstName || ''} ${appointment.patient?.lastName || ''} ${
                    (appointment.patient?.firstName || appointment.patient?.lastName) && appointment.reason_for_visit ? ' - ' : ''
                } ${appointment.reason_for_visit }`,
                start: new Date(appointment.start_time),
                end: new Date(appointment.end_time),
                resource: appointment,
            }));
    }, [data, selectedCalendarsLeft]);

    const eventsRight = useMemo(() => {
        return data
            .filter(appointment =>
                appointment.calendars.length === 0 || // Include appointments with no calendars
                appointment.calendars.some(calendarId => selectedCalendarsRight.includes(calendarId)),
            )
            .map(appointment => ({
                title: `${appointment.patient?.firstName || ''} ${appointment.patient?.lastName || ''} ${
                    (appointment.patient?.firstName || appointment.patient?.lastName) && appointment.reason_for_visit ? ' - ' : ''
                } ${appointment.reason_for_visit }`,
                start: new Date(appointment.start_time),
                end: new Date(appointment.end_time),
                resource: appointment,
            }));
    }, [data, selectedCalendarsRight]);
    const removeAppointment = async (id) => {
        try {
            await AppointmentService.remove(id)
            setData(data.filter((item) => item._id !== id))
            notification.success({
                message: t('notif-message.appointment-removed'),
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }
    const clickRef = useRef(null)

    useEffect(() => {
        const localCalendars = localStorage.getItem('calendars')
        if (localCalendars) setSelectedCalendars(JSON.parse(localCalendars))
        else setSelectedCalendars([])
    }, [])

    useEffect(() => {
        localStorage.setItem('calendars', JSON.stringify(selectedCalendars))
    }, [selectedCalendars])

    useEffect(() => {
        return () => {
            window.clearTimeout(clickRef?.current)
        }
    }, [])

    function calculateNewDate(selectedDuration) {
        const now = new Date() // Get the current date and time
        const durationInSeconds = appointmentDurationList.find(
            (item) => item.value === selectedDuration,
        ).value
        if (durationInSeconds !== -1) {
            const newDate = new Date(now.getTime() + durationInSeconds * 1000) // Calculate new date in milliseconds
            setCurrentDate(newDate)
        }
    }

    useEffect(() => {
        if (timeDuration === -1) {
            setCurrentDate(new Date())
        } else {
            calculateNewDate(timeDuration)
        }
    }, [timeDuration])

    useEffect(() => {
        setCulture(language)
    }, [language])
    useEffect(() => {
        if (userInitiatedViewChange) {
            // Execute actions specific to user-initiated view changes
            console.log('View changed by user to:', view);
            // Reset the flag after handling the user-initiated change
            setUserInitiatedViewChange(false);
        }
    }, [view, userInitiatedViewChange]);

    const messages = {
        week: t('calendar-properties.week'),
        work_week: t('calendar-properties.work_week'),
        day: t('calendar-properties.day'),
        month: t('calendar-properties.month'),
        previous: t('calendar-properties.previous'),
        next: t('calendar-properties.next'),
        today: t('calendar-properties.today'),
        agenda: t('calendar-properties.agenda'),
        showMore: (total) => `+${total} plus`,
    }

    const getCalendarHeader = (start, end, culture) => {
        return `${localizer.format(start, 'd', culture)}-${localizer.format(
            end,
            'd',
            culture,
        )} ${localizer.format(end, 'MMM', culture)} ${localizer.format(
            end,
            'yyyy',
            culture,
        )}`
    }
    const { defaultDate, formats } = useMemo(
        () => ({
            defaultDate: currentDate,
            formats: {
                dateFormat: 'dd',
                dayFormat: (date, culture) =>
                    localizer.format(date, 'E d/MM', culture),
                dayRangeHeaderFormat: ({ start, end }, culture) =>
                    getCalendarHeader(start, end, culture),
                timeGutterFormat: (date, culture) =>
                    localizer.format(date, 'HH:mm', culture),
                    agendaHeaderFormat: ({ start, end }, culture) => {
                        // Format the dates in "25 April 2024" style for agenda view
                        return `${localizer.format(start, 'd MMMM yyyy', culture)} – ${localizer.format(end, 'd MMMM yyyy', culture)}`;
                    },
                // timeGutterFormat: 'HH:mm',
                eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
                    `${localizer.format(
                        start,
                        'HH:mm',
                        culture,
                    )} - ${localizer.format(end, 'HH:mm', culture)}`,
            },
        }),
        [currentDate],
    )

    const events = useMemo(() => {
        return data
            .filter(appointment =>
                appointment.calendars.length === 0 || // Include appointments with no calendars
                appointment.calendars.some(calendarId => selectedCalendars.includes(calendarId)),
            )
            .map(appointment => ({
                title: `${appointment.patient?.firstName || ''} ${appointment.patient?.lastName || ''} ${
                    (appointment.patient?.firstName || appointment.patient?.lastName) && appointment.reason_for_visit ? ' - ' : ''
                } ${appointment.reason_for_visit }`, // Show 'No Calendar' if there's no reason for visit
                start: new Date(appointment.start_time),
                end: new Date(appointment.end_time),
                resource: appointment,
            }));
    }, [data, selectedCalendars]); // Depend on data and selectedCalendars which includes IDs from the calendar dropdown in single mode

    useEffect(() => {
        if (calendars.length > 0) {
            setSelectedCalendarsLeft([calendars[0]._id]); // Default to the first calendar for the left dropdown
            if (calendars.length > 1) {
                setSelectedCalendarsRight([calendars[1]._id]); // Default to the second calendar for the right dropdown, if available
            }
        }
    }, [calendars]); // Ensure this effect runs when the calendars data is loaded

    const handleSelectSlot = (date) => {
        if (copyEvent) {
            editEvent({
                ...copyEvent,
                resource: {
                    ...copyEvent.resource,
                    appointment_date: date.start,
                },
            })
            return
        }
        const modalData = {
            start_time: date.start,
            end_time: date.end,
        }
        setModalData(modalData)
        setOpen(true)
    }

    const onDetails = (id) => {
        navigate(`${id}`)
    }

    const onClose = (event, isEditMode) => {
        if (event) {
            if (isEditMode) {
                setData(
                    data.map((item) => {
                        if (item._id === event._id) {
                            return event
                        }
                        return item
                    }),
                )
            } else {
                setData([...data, event])
            }
        }
        setModalData(null)
        setOpen(false)
        setCopyEvent(null)
    }

    const editEvent = (event) => {
        setPatient({ ...event?.resource?.patient } || {})
        setModalData(event.resource || event)
        setOpen(true)
    }

    const removeEvent = (id) => {
        setData(data.filter((x) => x._id !== id))
        setModalData(null)
        setOpen(false)
    }

    const onRecurranceRemove = (id) => {
        setData(data.filter((x) => x.recurrance !== id))
        setModalData(null)
        setOpen(false)
    }

    const onCopyClick = (e, event) => {
        e.stopPropagation()
        setCopyEvent(event)
    }

    const { components } = useMemo(
        () => ({
            components: {
                event: (props) => (
                    <EventBox onCopyClick={onCopyClick} {...props} />
                ),
            },
        }),
        [data],
    )

    const moveEvent = async (dragEvent) => {
        const event = dragEvent.event.resource
        const id = event?._id
        const start_time = new Date(
            `${DateUtility.dateToString(
                dragEvent.start,
                DateFormat.date,
            )} ${DateUtility.dateToString(
                dragEvent.start,
                DateFormat.fulltime,
            )}`,
        )
        const end_time = new Date(
            `${DateUtility.dateToString(
                dragEvent.start,
                DateFormat.date,
            )} ${DateUtility.dateToString(dragEvent.end, DateFormat.fulltime)}`,
        )
        const reqData = {
            ...event,
            appointment_date: dragEvent.start,
            patient: event?.patient?._id,
            category: event.category?._id,
            start_time,
            end_time,
        }
        delete reqData.doctor
        delete reqData._id
        setData(
            data.map((item) => {
                if (item._id === id) {
                    return {
                        ...item,
                        start_time,
                        end_time,
                    }
                }
                return item
            }),
        )
        await AppointmentService.update(id, reqData)
        // }
    }

    const slotStyleGetter = (date) => {
        return slotPropGetter(
            date,
            user.starttime,
            user.endtime,
            user.workingdays,
        )
    }

    const onSelectEvent = useCallback((event) => {
        window.clearTimeout(clickRef?.current)
        clickRef.current = window.setTimeout(() => {
            editEvent(event)
        }, 250)
    }, [])

    const onDoubleClickEvent = useCallback((event) => {
        window.clearTimeout(clickRef?.current)
        clickRef.current = window.setTimeout(() => {
            onDetails(event.resource._id)
        }, 250)
    }, [])

    const onViewChange = (newView) => {
    setView(newView);
    setUserInitiatedViewChange(true);

    // Automatically check the "All Day" checkbox when switching to the month view
    if (newView === Views.MONTH) {
        setWeekShow(true);
    }
};

    const minTime = useMemo(
        () => (workHours ? new Date(user.starttime) : undefined),
        [workHours],
    )
    const maxTime = useMemo(
        () => (workHours ? new Date(user.endtime) : undefined),
        [workHours],
    )

    const handleNavigate = (newDate) => {
        setCurrentDate(newDate);
        setUserInitiatedViewChange(false); // Consider resetting here if appropriate
    };

    const handleRangeSelection = (startDate, endDate) => {
        setCurrentDate(startDate);
        // Update the calendar view based on whether a single day or a range is selected
        setView(isSameDay(startDate, endDate) ? Views.DAY : Views.WEEK);
    };

    const onRangeChange = (range, IncomingView) => {
        // Use the state's view if the provided view is undefined
        const rangeView = IncomingView || view;

        console.log("date range", range);

        let start;
        let end;

        if (range.start && range.end) {
            start = range.start;
            end = range.end;
        } else if (Array.isArray(range)) {
            start = range?.[0];
            end = range[range.length - 1];
        } else {

            start = range;
            end = range;
        }

        // Adjust dates for month view to cover the full month
        if (rangeView === Views.MONTH) {
            start = new Date(start.getFullYear(), start.getMonth(), 1);
            end = new Date(end.getFullYear(), end.getMonth() + 1, 0, 23, 59, 59);
        } else if (rangeView === Views.WEEK) {
            start = startOfWeek(start, { weekStartsOn: 1 });
            end = endOfWeek(end, { weekStartsOn: 1 });
        }

        setRangePickerState({
            startDate: start,
            endDate: end,
        });

        filterChanged('search', {
            startdate: start,
            enddate: end,
        });

        console.log("Processed range change:", rangeView, start, end.toISOString());
    };

    return (
        <>
            <DesktopMode>
                <PageHeader
                    left={<HeaderTitle>{t('calendar')}</HeaderTitle>}
                    right={
                        <>
                            <div className="d-flex flex-row align-items-center pointer">

                                <FlexRow
                                    onClick={() => setWorkHours(!workHours)}
                                >
                                    <div>{t('form-fields.work-hours')}</div>
                                    <Checkbox
                                        name="work-hours"
                                        text={t('form-fields.work-hours')}
                                        className="mx-2"
                                        checked={workHours}
                                        onChange={() => {
                                        setWorkHours(!workHours)
                                        }}
                                    />

                                </FlexRow>
                                <FlexRow onClick={() => setWeekShow(!weekShow)}>
                                    <div>{t('form-fields.all-day')}</div>
                                    <Checkbox
                                        name="allDay"
                                        text={t('form-fields.all-day')}
                                        className="mx-2"
                                        checked={weekShow}
                                        onChange={() => setWeekShow(!weekShow)}
                                    />
                                </FlexRow>
                                <FlexRow>
                        <div>
                        {/* Replace the switch with a dropdown for mode selection */}
<Select defaultValue="single" style={{ width: 170, marginRight: '8px' }} onChange={(value) => setMode(value)}>
    <Option value="single">{t('Single Calendar')}</Option>
    <Option value="split">{t('Splitted Calendar')} </Option>
</Select>

                        </div>
                    </FlexRow>
                                <DatePicker
                                    onChange={(value) => {
                                        setCurrentDate(value.toDate())
                                    }}
                                    placeholder={t('select-date')}
                                    value={DateUtility.dayJSFormat(currentDate)}
                                    format={DateFormat.inputEuDate}
                                    style={{ width: 160 }}
                                    className="me-2"
                                />
                                <Select
                                    options={CalanderDurationOptions}
                                    showArrow
                                    value={step}
                                    onChange={(value) => setStep(value)}
                                    placeholder={t('slot-duration')}
                                    style={{ width: 160 }}
                                    popupMatchSelectWidth
                                    className="me-2"
                                />
                                <Select
                                    options={appointmentDurationList}
                                    showArrow
                                    value={timeDuration}
                                    onChange={(value) => setTimeDuration(value)}
                                    placeholder={t('Time-duration')}
                                    style={{ width: 160 }}
                                    popupMatchSelectWidth
                                />
                            </div>
                        </>
                    }
                />
                <div style={{ display: 'flex', gap: 8 }}>

                  {mode === 'single' && (
    <div style={{ width: '20%' }}>
        <CalendarDropdown
            formDataList={calendars}
            selectedCalendars={selectedCalendars}
            setSelectedCalendars={setSelectedCalendars}
            onSelectRange={handleRangeSelection}
            externalRangeUpdate={rangePickerState}
            setExternalRangeUpdate={setRangePickerState}
        />
    </div>
)}
                    <div style={{ width: mode === 'single' ? '80%' : '100%' }}>
                    <CalendarContainer className={mode === 'split' ? 'split-mode' : 'single-mode'}>
                    <div className="calendar-wrapper">
                                       {mode === 'split' && (
                    <Select
                        mode="multiple"
                        style={{ width: '100%', marginBottom: 10 }}
                        placeholder="Select calendars"
                        value={selectedCalendarsLeft}
                        onChange={setSelectedCalendarsLeft}
                    >
                        {calendars.map(calendar => (
                            <Option key={calendar._id} value={calendar._id}>{calendar.name}</Option>
                        ))}
                    </Select>
                                       )}

                            <DragAndDropCalendar
                                components={components}
                                localizer={localizer}
                                defaultDate={defaultDate}
                                onNavigate={handleNavigate}
                                onRangeChange={onRangeChange}
                                date={currentDate}
                                defaultView={Views.WEEK}
                               events={mode === 'split' ? eventsLeft : events} // Use eventsLeft for the left calendar in split mode
                                startAccessor="start"
                                endAccessor="end"
                                onSelectEvent={onSelectEvent}
                                onDoubleClickEvent={onDoubleClickEvent}
                                onSelectSlot={handleSelectSlot}
                                eventPropGetter={(e) =>
                                eventStyleGetter(e, events)}
                                dayPropGetter={(e) =>
                                dayPropGetter(e, weekShow)}
                                onView={onViewChange}
                                view={view}
                                selectable
                                onEventDrop={moveEvent}
                                onEventResize={moveEvent}
                                timeslots={1}
                                step={step}
                                min={minTime}
                                max={maxTime}
                                popup={false}
                                style={{ height: '100%' }}
                                messages={messages}
                                culture={culture}
                                slotPropGetter={slotStyleGetter}
                                formats={formats}
                                resizable
                            />
                            </div>

{mode === 'split' && (
                        <div className="calendar-wrapper">
   <Select
                            mode="multiple"
                            style={{ width: '100%', marginBottom: 10 }}
                            placeholder="Select calendars"
                            value={selectedCalendarsRight}
                            onChange={setSelectedCalendarsRight}
                        >
                            {calendars.map(calendar => (
                                <Option key={calendar._id} value={calendar._id}>{calendar.name}</Option>
                            ))}
                        </Select>
  <DragAndDropCalendar
  components={components}
  localizer={localizer}
  defaultDate={defaultDate}
  onNavigate={handleNavigate}
  onRangeChange={onRangeChange}
  date={currentDate}
  defaultView={Views.WEEK}
            events={eventsRight} // Use eventsRight for the right calendar in split mode
  startAccessor="start"
  endAccessor="end"
  onSelectEvent={onSelectEvent}
  onDoubleClickEvent={onDoubleClickEvent}
  onSelectSlot={handleSelectSlot}
  eventPropGetter={(e) =>
  eventStyleGetter(e, events)}
  dayPropGetter={(e) =>
  dayPropGetter(e, weekShow)}
  onView={onViewChange}
  selectable
  onEventDrop={moveEvent}
  onEventResize={moveEvent}
  timeslots={1}
  step={step}
  min={minTime}
  max={maxTime}
  popup={false}
  style={{ height: '100%' }}
  messages={messages}
  culture={culture}
  slotPropGetter={slotStyleGetter}
  formats={formats}
  resizable
/>
</div>) }
                        </CalendarContainer>
                    </div>
                </div>
                <AppointmentTableListScreen
                    list={events}
                    total={total}
                    pageChanged={pageChanged}
                    filter={filter}
                    loading={loading}
                    sortChanged={sortChanged}
                    editEvent={editEvent}
                    filterChanged={filterChanged}
                    removeAppointment={removeAppointment}
                />
                <AddEditAppointmentPopup
                    open={open}
                    onClose={onClose}
                    events={data}
                    modalData={modalData}
                    onDetails={onDetails}
                    onRemove={removeEvent}
                    onRecurranceRemove={onRecurranceRemove}
                    refetch={refetch}
                    step={step}
                />
            </DesktopMode>
            <MobileMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-3">
                            {t('calendar')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <div className="d-flex flex-row align-items-center pointer">
                                <FlexRow
                                    onClick={() => setWorkHours(!workHours)}
                                >
                                    <div className="col-12">
                                        {t('form-fields.work-hours')}
                                    </div>
                                    <Checkbox
                                        name="work-hours"
                                        text={t('form-fields.work-hours')}
                                        className="mx-1"
                                        checked={workHours}
                                        onChange={() => {
                                            setWorkHours(!workHours)
                                        }}
                                    />
                                </FlexRow>
                                <FlexRow onClick={() => setWeekShow(!weekShow)}>
                                    <div
                                        className="col-9"
                                        style={{ marginLeft: '25px' }}
                                    >
                                        {t('form-fields.all-day')}
                                    </div>
                                    <Checkbox
                                        name="allDay"
                                        text={t('form-fields.all-day')}
                                        className="mx-1"
                                        checked={weekShow}
                                        onChange={() => setWeekShow(!weekShow)}
                                    />
                                </FlexRow>
                                {/* <DatePicker
                                onChange={(value) =>
                                    setCurrentDate(value.toDate())}
                                placeholder={t('select-date')}
                                value={DateUtility.dayJSFormat(currentDate)}
                                format={DateFormat.inputEuDate}
                                style={{ width: 160 }}
                                className="me-6"
                            />
                            <Select
                                options={CalanderDurationOptions}
                                showArrow
                                value={step}
                                onChange={(value) => setStep(value)}
                                placeholder={t('slot-duration')}
                                style={{ width: 160 }}
                                popupMatchSelectWidth
                                className="me-2"
                            />
                            <Select
                                options={appointmentDurationList}
                                showArrow
                                value={timeDuration}
                                onChange={(value) => setTimeDuration(value)}
                                placeholder={t('Time-duration')}
                                style={{ width: 160 }}
                                popupMatchSelectWidth
                            /> */}
                            </div>
                        </>
                    }
                />
                <div className="d-flex flex-row align-items-center pointer">
                    <DatePicker
                        onChange={(value) => setCurrentDate(value.toDate())}
                        placeholder={t('select-date')}
                        value={DateUtility.dayJSFormat(currentDate)}
                        format={DateFormat.inputEuDate}
                        style={{ width: 160 }}
                        className="me-2"
                    />
                    <Select
                        options={CalanderDurationOptions}
                        showArrow
                        value={step}
                        onChange={(value) => setStep(value)}
                        placeholder={t('slot-duration')}
                        style={{ width: 160 }}
                        popupMatchSelectWidth
                        className="me-2"
                    />
                    <Select
                        options={appointmentDurationList}
                        showArrow
                        value={timeDuration}
                        onChange={(value) => setTimeDuration(value)}
                        placeholder={t('Time-duration')}
                        style={{ width: 160 }}
                        popupMatchSelectWidth
                    />
                </div>
                <CalendarContainer>
                    <DragAndDropCalendar
                        components={components}
                        localizer={localizer}
                        defaultDate={defaultDate}
                        onNavigate={handleNavigate}
                        onRangeChange={onRangeChange}
                        date={currentDate}
                        defaultView={Views.WEEK}
                        events={events}
                        startAccessor="start"
                        endAccessor="end"
                        onSelectEvent={onSelectEvent}
                        onDoubleClickEvent={onDoubleClickEvent}
                        onSelectSlot={handleSelectSlot}
                        eventPropGetter={(e) => eventStyleGetter(e, events)}
                        dayPropGetter={(e) => dayPropGetter(e, weekShow)}
                        onView={onViewChange}
                        selectable
                        onEventDrop={moveEvent}
                        onEventResize={moveEvent}
                        timeslots={1}
                        step={step}
                        min={minTime}
                        max={maxTime}
                        popup={false}
                        style={{ height: '100%' }}
                        messages={messages}
                        culture={culture}
                        slotPropGetter={slotStyleGetter}
                        formats={formats}
                        resizable
                    />
                </CalendarContainer>
                <AppointmentTableListScreen
                    list={events}
                    total={total}
                    pageChanged={pageChanged}
                    filter={filter}
                    loading={loading}
                    sortChanged={sortChanged}
                    editEvent={editEvent}
                    filterChanged={filterChanged}
                    removeAppointment={removeAppointment}
                />
                <AddEditAppointmentPopup
                    open={open}
                    onClose={onClose}
                    events={data}
                    modalData={modalData}
                    onDetails={onDetails}
                    onRemove={removeEvent}
                    onRecurranceRemove={onRecurranceRemove}
                    refetch={refetch}
                    step={step}
                />
            </MobileMode>
        </>
    )
}
