import { yupResolver } from '@hookform/resolvers/yup'
import {
    Button,
    Form,
    Space,
    notification,
    Modal,
    DatePicker,
    Select,
    Popconfirm,
} from 'antd'
import {
    PrimaryButton,
    FormSelectionField,
    FormTextFormField,
    FormDateField,
    DangerButton,
} from 'elements'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
    DateFormat,
    DateUtility,
    UserVitalSignsService,
    VitalSignsTypesService,
} from 'utility'
import { AppTable, FlexBox } from 'components'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { GetUserVitalSignByAppointment } from 'hooks'
import { Plus } from 'phosphor-react'
import { AddVitalSignsTypesModal } from 'page-components/settings'
import { BlockHeader } from './header'
// import { useAuth } from 'context'

const VitalSignSchema = yup.object().shape({
    vitalsigntype: yup.string().required(),
    date: yup.string().required(),
    unit: yup.string().required(),
    value: yup.string().required(),
})
export const VitalSignTable = ({
    data,
    editvitalsign,
    // removevitalsign,
    pageChanged,
    total,
    filter,
}) => {
    const { t } = useTranslation()
    const rowClassName = (data) => {
        if (!data.active) {
            return 'grey-row'
        }
        return ''
    }
    const onRowDoubleClick = (data) => {
        editvitalsign(data)
    }
    const columns = [
        {
            title: t('form-fields.vitalsigntype'),
            dataIndex: 'vitalsigntype',
            key: 'vitalsigntype',
            width: 300,
            render: (text) => text?.title,
        },
        {
            title: t('form-fields.date'),
            dataIndex: 'date',
            key: 'date',
            width: 300,
            render: (text) => (
                <>{DateUtility.dateToString(text, DateFormat.euDate)}</>
            ),
        },
        {
            title: t('form-fields.unit'),
            dataIndex: 'unit',
            key: 'unit',
            width: 200,
        },
        {
            title: t('form-fields.value'),
            dataIndex: 'value',
            key: 'value',
            width: 200,
        },
    ]

    return (
        <>
            <>
                <AppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    rowClassName={rowClassName}
                    onRowDoubleClick={onRowDoubleClick}
                    pageChanged={pageChanged}
                    currentPage={total.page}
                    total={total.count}
                    pageSize={filter.limit}
                />
            </>
        </>
    )
}
export const VitalSignTab = ({
    appointment,
    patient,
    optionList,
    localVitalSign = [],
    setLocalVitalSign,
    vitalSignfilterChanged,
    refetcVitalSignType,
}) => {
    const [processing, setProcessing] = useState(false)
    const [open, setOpen] = useState(false)
    const [openVitalTypeModal, setVitalTypeModal] = useState(false)
    const [startdate, setStartDate] = useState(
        DateUtility.dayJSFormat(new Date()),
    )
    const [enddate, setEndDate] = useState(DateUtility.dayJSFormat(new Date()))
    const [editMode, setEditMode] = useState(false)
    const { t } = useTranslation()
    const { data, setData, pageChanged, total, filter, filterChanged } =
        GetUserVitalSignByAppointment(appointment?._id)
    const {
        handleSubmit,
        watch,
        setValue,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VitalSignSchema),
    })
    const rowId = watch('_id')
    const vitalsigntype = watch('vitalsigntype')
    const renderlist = useMemo(() => {
        return appointment?._id ? data : localVitalSign
    }, [localVitalSign, data, appointment])

    useEffect(() => {
        filterChanged('search', { startdate, enddate })
    }, [startdate, enddate])
    const onSubmit = async (formdata) => {
        if (!appointment?._id) {
            if (formdata.localid) {
                await setLocalVitalSign(
                    localVitalSign.map((ele) => {
                        if (ele.localid === formdata.localid) {
                            return {
                                ...ele,
                                ...formdata,
                                vitalsigntype: optionList.find(
                                    (ele) => ele._id === formdata.vitalsigntype,
                                ),
                            }
                        }
                        return { ...ele }
                    }),
                )
            } else {
                await setLocalVitalSign([
                    ...localVitalSign,
                    {
                        ...formdata,
                        localid: new Date().toUTCString(),
                        vitalsigntype: optionList.find(
                            (ele) => ele._id === formdata.vitalsigntype,
                        ),
                    },
                ])
            }
            reset({})
            setOpen(false)
            return
        }
        try {
            setProcessing(true)
            const reqData = {
                ...formdata,
                patient: patient?._id,
                // doctor: user._id,
                appointment: appointment?._id,
            }
            if (formdata._id) {
                delete reqData._id
                delete reqData?.appointment
                delete reqData?.patient
                const updated = await UserVitalSignsService.update(
                    formdata._id,
                    reqData,
                )
                notification.success({
                    message: t('notif-message.vitalsign-updated'),
                })
                setData(
                    data.map((ele) => {
                        if (ele._id === formdata._id) {
                            return { ...ele, ...updated }
                        }
                        return { ...ele }
                    }),
                )
            } else {
                const result = await UserVitalSignsService.add(reqData)
                notification.success({
                    message: t('notif-message.vitalsign-added'),
                })
                setData([...data, result])
            }
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
            reset({})
            setOpen(false)
        }
    }

    const removevitalsign = async (id) => {
        try {
            await UserVitalSignsService.remove(id)
            notification.success({
                message: t('notif-message.allergies-removed'),
            })
            setData(data.filter((ele) => ele._id !== id))
        } catch (error) {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setOpen(false)
            setEditMode(false)
        }
    }

    const editvitalsign = (data) => {
        reset({
            _id: data._id,
            value: data.value,
            unit: data.unit,
            date: DateUtility.dayJSFormat(data.date),
            vitalsigntype: data.vitalsigntype._id,
            localid: data.localid || undefined,
        })
        setEditMode(true)
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (vitalsigntype) {
            setValue(
                'unit',
                optionList.find((ele) => ele.value === vitalsigntype)?.unit,
            )
        }
    }, [vitalsigntype])

    const onSelectSign = (sign, signdata) => {
        setOpen(true)
        reset({
            unit: signdata.unit,
            date: DateUtility.dayJSFormat(new Date()),
            vitalsigntype: sign,
        })
    }

    const onVitalTypeSubmit = async (formData) => {
        try {
            setProcessing(true)
            await VitalSignsTypesService.add(formData)
            notification.success({
                message: t('notif-message.category-added'),
            })
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetcVitalSignType()
            setVitalTypeModal(false)
        }
    }
    return (
        <div className="">
            <div>
                <BlockHeader
                    title={t('form-fields.vitalsings')}
                    searchElement={
                        <Select
                            popupMatchSelectWidth
                            className="col-12"
                            popupClassName="filter-options"
                            options={optionList}
                            placeholder={`${t('select')} ${t(
                                'form-fields.vitalsigns',
                            )}`}
                            onSelect={(id, data) => onSelectSign(id, data)}
                            showSearch
                            notFoundContent={
                                <PrimaryButton
                                    onClick={() => setVitalTypeModal(true)}
                                    className="d-flex align-items-center"
                                    icon={<Plus />}
                                >
                                    Add Type
                                </PrimaryButton>
                            }
                            filterOption={false}
                            onSearch={(data) => {
                                vitalSignfilterChanged('search', {
                                    title: data,
                                })
                            }}
                        />
                    }
                >
                    <FlexBox className="ddfdf row justify-content-end mb-3">
                        <div className="col-4">
                            <DatePicker
                                format={DateFormat.inputEuDate}
                                onChange={setStartDate}
                                value={startdate}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-4">
                            <DatePicker
                                format={DateFormat.inputEuDate}
                                onChange={setEndDate}
                                value={enddate}
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-4">
                            <Select
                                defaultValue=""
                                style={{ width: '100%' }}
                                popupClassName="filter-options"
                                options={[
                                    { value: '', label: 'All' },
                                    ...optionList,
                                ]}
                                onChange={(val) => {
                                    filterChanged('search', { type: val })
                                }}
                            />
                        </div>
                    </FlexBox>
                </BlockHeader>
                <VitalSignTable
                    data={renderlist}
                    options={optionList}
                    editvitalsign={editvitalsign}
                    removevitalsign={removevitalsign}
                    pageChanged={pageChanged}
                    total={total}
                    filter={filter}
                />
            </div>
            <Modal
                title={
                    editMode ? t('vitalsign') : t('form-fields.add-vitalsings')
                }
                open={open}
                confirmLoading
                footer={null}
                width={600}
                onCancel={() => onClose()}
            >
                <Form layout="vertical">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <FormSelectionField
                                    control={control}
                                    name="vitalsigntype"
                                    label={t('form-fields.vitalsigntype')}
                                    placeholder=""
                                    errors={errors?.vitalsigntype}
                                    defaultValue=""
                                    options={optionList}
                                />
                            </div>
                            <div className="col-6">
                                <FormDateField
                                    control={control}
                                    name="date"
                                    label={t('form-fields.date')}
                                    placeholder=""
                                    errors={errors?.date}
                                    defaultValue=""
                                    options={optionList}
                                />
                            </div>
                            <div className="col-6">
                                <FormTextFormField
                                    control={control}
                                    name="unit"
                                    label={t('form-fields.unit')}
                                    placeholder=""
                                    errors={errors?.unit}
                                    defaultValue=""
                                />
                            </div>
                            <div className="col-6">
                                <FormTextFormField
                                    control={control}
                                    name="value"
                                    label={t('form-fields.value')}
                                    placeholder=""
                                    errors={errors?.value}
                                    defaultValue=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <Popconfirm
                            title={t('delete')}
                            description={t('message.delete-message', {
                                name: `${t('Allergies')}`,
                            })}
                            onConfirm={() => removevitalsign(rowId)}
                            okButtonProps={{ disabled: false }}
                            cancelButtonProps={{ disabled: false }}
                        >
                            <DangerButton>{t('delete')}</DangerButton>
                        </Popconfirm>
                        <div />
                        <Space wrap>
                            <Button key="cancel" onClick={onClose}>
                                {t('cancel')}
                            </Button>
                            <PrimaryButton
                                key="submit"
                                loading={processing}
                                onClick={handleSubmit(onSubmit)}
                            >
                                {t('submit')}
                            </PrimaryButton>
                        </Space>
                    </div>
                </Form>
            </Modal>
            <AddVitalSignsTypesModal
                open={openVitalTypeModal}
                onClose={() => setVitalTypeModal(false)}
                onSubmit={onVitalTypeSubmit}
                loading={processing}
            />
        </div>
    )
}
