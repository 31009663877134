import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, Modal, Popconfirm, Space, notification } from 'antd'
import {
    DraggableAppTable,
    AutoCompleteDropDown,
    HeaderTitle,
    PageHeader,
    FlexRowBetween,
} from 'components'
import {
    DangerButton,
    FormColorPicker,
    FormTextFormField,
    PrimaryButton,
} from 'elements'
import { DesktopMode, GetDocumentsCategory, MobileMode } from 'hooks'
import { Pencil, Plus, Trash } from '@phosphor-icons/react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { DocumentCategoryService } from 'utility'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

const DocumentCategoryShema = yup.object().shape({
    name: yup.string().required('*document category name is required'),
    color: yup.string().required('color is required'),
})

const ColorBox = styled.div`
    height: 20px;
    width: 25px;
    border-radius: 5px;
    background-color: ${({ color }) => color};
`

const AddCategoryModal = ({
    open,
    onClose,
    data,
    loading,
    editMode,
    onSubmit,
}) => {
    const {
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(DocumentCategoryShema),
    })
    const { t } = useTranslation()
    useEffect(() => {
        reset(editMode ? data : {})
    }, [open, editMode])

    return (
        <Modal
            title={
                data?.notes
                    ? `${t('add-edit.edit-document-category')}`
                    : `${t('form-fields.adddocumentcategory')}`
            }
            open={open}
            footer={null}
            onCancel={onClose}
        >
            <Form layout="vertical">
                <FormTextFormField
                    control={control}
                    name="name"
                    label={t('form-fields.categoryname')}
                    placeholder="Category Name"
                    errors={errors?.name}
                    defaultValue=""
                />
                <FormColorPicker
                    control={control}
                    name="color"
                    label={t('form-fields.color')}
                    placeholder="color"
                    errors={errors?.color}
                />
                <Space wrap>
                    <Button key="cancel" onClick={() => onClose()}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        key="submit"
                        loading={loading}
                        onClick={handleSubmit(onSubmit)}
                    >
                        {data ? `${t('edit')}` : `${t('create')}`}{' '}
                        {t('form-fields.category')}
                    </PrimaryButton>
                </Space>
            </Form>
        </Modal>
    )
}

export const DocumentCategoryTab = () => {
    const [open, setOpen] = useState(false)
    const [modalData, setModalData] = useState({})
    const [processing, setProcessing] = useState(false)
    const { t } = useTranslation()
    const {
        data,
        setData,
        refetch,
        filterChanged,
        total,
        filter,
        pageChanged,
    } = GetDocumentsCategory()

    const onClose = () => {
        setOpen(false)
        setModalData({})
    }
    const onSubmit = async (formData) => {
        try {
            setProcessing(true)
            if (!formData?._id) {
                await DocumentCategoryService.add(formData)
                notification.success({
                    message: t('notif-message.category-added'),
                })
            } else {
                delete formData._id
                delete formData.doctor
                await DocumentCategoryService.update(modalData._id, formData)
                notification.success({
                    message: t('notif-message.category-updated'),
                })
            }
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        } finally {
            setProcessing(false)
            refetch()
            onClose()
        }
    }

    const onOpen = (openData) => {
        setOpen(true)
        setModalData(openData)
    }

    const removeDocumentCategory = async (id) => {
        try {
            await DocumentCategoryService.remove(id)
            notification.success({
                message: t('notif-message.category-removed'),
            })
            setData(data.filter((item) => item._id !== id))
        } catch (error) {
            notification.error({ message: t('notif-message.default-error') })
        }
    }

    const columns = [
        {
            title: t('form-fields.category'),
            dataIndex: 'name',
            key: 'name',
            width: 700,
        },
        {
            title: t('form-fields.color'),
            dataIndex: 'color',
            key: 'color',
            width: 200,
            render: (_, data) => (
                <Space size="middle">
                    <ColorBox color={data?.color} />
                </Space>
            ),
        },
        {
            title: t('form-fields.actions'),
            key: 'action',
            render: (_, data) => (
                <Space size="middle">
                    <PrimaryButton
                        icon={<Pencil onClick={() => onOpen(data)} />}
                    />
                    <Popconfirm
                        title={t('delete')}
                        description={t('message.delete-message', {
                            name: `${t('form-fields.category')}`,
                        })}
                        onConfirm={() => removeDocumentCategory(data._id)}
                        okButtonProps={{ disabled: false }}
                        cancelButtonProps={{ disabled: false }}
                    >
                        <DangerButton icon={<Trash />} htmlType="button" />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const orderChanged = async (items) => {
        try {
            await DocumentCategoryService.OrderBy({
                items: items.map((item) => item._id),
                skip: filter.skip,
            })
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        }
    }

    return (
        <div>
            <DesktopMode>
                <PageHeader
                    left={
                        <HeaderTitle className="col-6">
                            Documents {t('form-fields.category')}
                        </HeaderTitle>
                    }
                    right={
                        <>
                            <AutoCompleteDropDown
                                name="search"
                                required
                                placeholder={`${t('search')} Documents ${t(
                                    'form-fields.category',
                                )}`}
                                options={[]}
                                className="mb-0 ms-5"
                                onSearch={(data) => {
                                    filterChanged('search', { name: data })
                                }}
                            />
                            <PrimaryButton
                                onClick={() => onOpen()}
                                className=" mx-3 d-flex align-items-center"
                            >
                                {t('add')}&nbsp;
                                <Plus />
                            </PrimaryButton>
                        </>
                    }
                />
            </DesktopMode>
            <MobileMode>
                <HeaderTitle className="col-12">
                    <FlexRowBetween>
                        <span>Documents {t('form-fields.category')}</span>
                        <PrimaryButton
                            onClick={() => onOpen()}
                            className="d-flex align-items-center"
                        >
                            {t('add')}&nbsp;
                            <Plus />
                        </PrimaryButton>
                    </FlexRowBetween>
                </HeaderTitle>
                <AutoCompleteDropDown
                    name="search"
                    required
                    placeholder={`${t('search')} Documents ${t(
                        'form-fields.category',
                    )}`}
                    options={[]}
                    className="mb-0 ms-1"
                    onSearch={(data) => filterChanged('search', { name: data })}
                />
            </MobileMode>
            <AddCategoryModal
                open={open}
                data={modalData}
                onClose={onClose}
                onSubmit={onSubmit}
                loading={processing}
                editMode={!!modalData?._id}
            />
            <div className="mt-3 mt-sm-0">
                <DraggableAppTable
                    data={data}
                    columns={columns}
                    keyField="_id"
                    size="small"
                    currentPage={total.page}
                    total={total.count}
                    pageChanged={pageChanged}
                    pageSize={filter?.limit}
                    orderChanged={orderChanged}
                />
            </div>
        </div>
    )
}
