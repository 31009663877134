import {
    FormRadioField,
    FormSelectionField,
    FormTextFormField,
    FormTimeField,
    PrimaryButton,
    ShadowBox,
} from 'elements'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Form, notification } from 'antd'
import {
    CountryList,
    DoctorService,
    GenderOptions,
    WorkingDayOptions,
    ContentTypeOptions,
    DateUtility,
} from 'utility'
import { GetDoctorDetail } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { HeaderTitle, LoaderBar } from 'components'
import { useTranslation } from 'react-i18next'

const DoctorSchema = yup.object().shape({
    username: yup.string().email().required('*username is required'),
    phone: yup.string().required('*phone is required'),
    alternatePhone: yup.string(),
    degree: yup.string().required('*degree is required'),
    gender: yup.string().required('*gender is required'),
    specialist: yup.string().required('*specialist  is required'),
    firstName: yup.string().required('*first name is required'),
    lastName: yup.string().required('*last name is required'),
    experience: yup.string().required('*experience is required'),
    workingdays: yup.string().required('*workingdays is required'),
    content: yup.string().required('*content is required'),
    starttime: yup.string().required('*Start time is required'),
    endtime: yup.string().required('*End time is required'),
    password: yup
        .string()
        .when('$edit', (edit, schema) =>
            !edit
                ? schema.required('*password is Required')
                : schema.optional(),
        ),
    address: yup.object().shape({
        address: yup.string().required('*Address is required'),
        city: yup.string().required('*city is required'),
        postalCode: yup.string().required('*postalCode is required'),
        country: yup.string().required('*country is required'),
    }),
})

export const AddEditDoctorScreen = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { data, loading } = GetDoctorDetail(params.id)
    const [processing, setProcessing] = useState(false)

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(DoctorSchema),
        context: { edit: params.id },
        defaultValues: {
            username: '',
            name: '',
            password: '',
        },
    })

    useEffect(() => {
        if (params.id && data) {
            reset({
                ...data,
                starttime: DateUtility.dayJSFormat(data.starttime),
                endtime: DateUtility.dayJSFormat(data.endtime),
            })
        } else {
            reset({})
        }
    }, [data, params])

    const submit = async (formData) => {
        setProcessing(true)
        try {
            if (params.id) {
                delete formData?._id
                delete formData?.password
                await DoctorService.update(params.id, formData)
            } else {
                await DoctorService.add({
                    ...formData,
                    role: 'doctor',
                })
            }
            notification.success({
                message: t('notif-message.doctor-updated'),
            })
            cancel()
        } catch {
            notification.error({
                message: t('notif-message.default-error'),
            })
        } finally {
            setProcessing(false)
        }
    }

    const cancel = () => {
        navigate('/app/doctor')
    }

    return (
        <ShadowBox>
            {loading && <LoaderBar />}
            <Form layout="vertical">
                <div className="d-flex align-items-center justify-content-between border-bottom">
                    <HeaderTitle>
                        {params.id
                            ? `${t('add-edit.edit-doctor')}`
                            : `${t('add-doctor')}`}
                    </HeaderTitle>
                    <div>
                        <Button
                            htmlType="button"
                            className="me-2"
                            onClick={cancel}
                        >
                            {t('cancel')}
                        </Button>
                        <PrimaryButton
                            loading={processing}
                            onClick={handleSubmit(submit)}
                            type="primary"
                            htmlType="submit"
                        >
                            {t('submit')}
                        </PrimaryButton>
                    </div>
                </div>
                <h3>{t('general-info')}</h3>
                <div className="row">
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="firstName"
                            label={t('form-fields.firstName')}
                            placeholder="First Name"
                            errors={errors?.firstName || ''}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="lastName"
                            label={t('form-fields.lastName')}
                            placeholder="Last Name"
                            errors={errors?.lastName || ''}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="username"
                            label={t('form-fields.username')}
                            placeholder="Username"
                            errors={errors?.username}
                            defaultValue=""
                        />
                    </div>
                    {!params.id && (
                        <div className="col-6">
                            <FormTextFormField
                                control={control}
                                name="password"
                                label={t('form-fields.password')}
                                placeholder="Password"
                                errors={errors?.password}
                                type="password"
                                defaultValue=""
                            />
                        </div>
                    )}
                    <div className="col-6">
                        <FormRadioField
                            control={control}
                            name="gender"
                            placeholder="Gender"
                            label={t('form-fields.gender')}
                            errors={errors?.gender}
                            defaultValue=""
                            options={GenderOptions}
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="phone"
                            label={t('form-fields.phone')}
                            placeholder="Phone"
                            errors={errors?.phone}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="alternatePhone"
                            label={t('form-fields.alternatePhone')}
                            placeholder="Alternate Phone"
                            errors={errors?.alternatePhone}
                            defaultValue=""
                        />
                    </div>
                </div>
                <h3>{t('educational-info')}</h3>
                <div className="row">
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="degree"
                            label={t('form-fields.degree')}
                            placeholder="Degree"
                            errors={errors?.degree}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="experience"
                            label={t('form-fields.experience')}
                            placeholder="Experience"
                            errors={errors?.experience}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="specialist"
                            label={t('form-fields.specialist')}
                            placeholder="Specialist"
                            errors={errors?.specialist}
                            defaultValue=""
                        />
                    </div>
                </div>
                <h3>{t('working-info')}</h3>
                <div className="row">
                    <div className="col-3">
                        <FormSelectionField
                            control={control}
                            name="content"
                            label={t('form-fields.content-type')}
                            placeholder="Select"
                            errors={errors?.content}
                            defaultValue=""
                            options={ContentTypeOptions}
                        />
                    </div>
                    <div className="col-3">
                        <FormSelectionField
                            control={control}
                            name="workingdays"
                            label={t('form-fields.working-days')}
                            placeholder="Working Days"
                            errors={errors?.workingdays}
                            defaultValue=""
                            options={WorkingDayOptions}
                        />
                    </div>
                    <div className="col-3">
                        <FormTimeField
                            control={control}
                            name="starttime"
                            label={t('form-fields.start-time')}
                            placeholder="Time"
                            errors={errors?.starttime}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-3">
                        <FormTimeField
                            control={control}
                            name="endtime"
                            label={t('form-fields.end-time')}
                            placeholder="Time"
                            errors={errors?.endtime}
                            defaultValue=""
                        />
                    </div>
                </div>
                <h3>{t('address')}</h3>
                <div className="row">
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address.address"
                            label={t('form-fields.street')}
                            placeholder="Address"
                            errors={errors?.address?.address}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address.postalCode"
                            label={t('form-fields.postalCode')}
                            placeholder="Postal Code"
                            errors={errors?.address?.postalCode}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormTextFormField
                            control={control}
                            name="address.city"
                            label={t('form-fields.city')}
                            placeholder="City"
                            errors={errors?.address?.city}
                            defaultValue=""
                        />
                    </div>
                    <div className="col-6">
                        <FormSelectionField
                            control={control}
                            name="address.country"
                            label={t('form-fields.country')}
                            placeholder="Country"
                            errors={errors?.address?.country}
                            defaultValue=""
                            options={CountryList}
                            showSearch
                        />
                    </div>
                </div>
                <div>
                    <Button htmlType="button" className="me-2" onClick={cancel}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        loading={processing}
                        onClick={handleSubmit(submit)}
                        type="primary"
                        htmlType="submit"
                    >
                        {t('submit')}
                    </PrimaryButton>
                </div>
            </Form>
        </ShadowBox>
    )
}
