import { Form, Typography } from 'antd'
import { FormTextFormField, PrimaryButton } from 'elements'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from 'context'
import { useNavigate } from 'react-router-dom'
import { AuthService, Roles } from 'utility'
import styled from 'styled-components'
import { Images } from 'images'
import { FlexRow, HeaderTitle, Line } from 'components'
import { useTranslation } from 'react-i18next'
import { isMobileMode } from 'hooks'

const { Text } = Typography

const Container = styled.div`
    height: 100vh;
    .w-40 {
        width: 40%;
    }
    .w-60 {
        width: 60%;
    }

    .w-100 {
        width: 100%;
    }

    .right {
        display: flex;
        align-items: center;
    }
    .left {
        width: 60%;
    }
    .left-side {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const LogoButton = styled.img`
    width: 30px;
    height: 30px;
`

const Banner = styled.img`
    max-width: 100%;
    max-height: 100%;
`

const LoginSchema = yup.object().shape({
    username: yup.string().required('*Username is required'),
    password: yup.string().required('*Password is required'),
})

export const LoginScreen = () => {
    const { t } = useTranslation()
    const { login: loginProvider } = useAuth()
    const navigate = useNavigate()
    const [error, setError] = useState('')
    const [processing, setProcessing] = useState('')
    const isMobile = isMobileMode()
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginSchema),
    })

    const submit = async (formData) => {
        try {
            setError('')
            setProcessing('Loading')
            const login = await AuthService.login(formData)
            loginProvider(login)
            setTimeout(() => {
                if (login.role === Roles.doctor) {
                    navigate('/app')
                } else {
                    navigate('/app/doctor')
                }
            }, 0)
        } catch (error) {
            setError(error?.message)
        } finally {
            setProcessing('')
        }
    }

    return (
        <Container className="d-flex">
            <div
                className={`w-40 col-12 container left-side ${
                    isMobile && 'w-100'
                }`}
            >
                <div className="left">
                    <HeaderTitle level={3}>{t('login.login')}</HeaderTitle>
                    <Form layout="vertical">
                        <FormTextFormField
                            control={control}
                            name="username"
                            placeholder={t('login.username')}
                            errors={errors?.username}
                            defaultValue=""
                            label=""
                        />
                        <FormTextFormField
                            control={control}
                            name="password"
                            placeholder={t('login.password')}
                            label=""
                            errors={errors?.password}
                            defaultValue=""
                            type="password"
                        />
                        {error && (
                            <div className="mb-2">
                                <Text type="danger">
                                    {t('message.invalid-user-password')}
                                </Text>
                            </div>
                        )}
                        <PrimaryButton
                            onClick={handleSubmit(submit)}
                            type="primary"
                            htmlType="submit"
                            loading={!!processing}
                            className="col-12 p-3 d-flex justify-content-center align-items-center"
                        >
                            {t('login.to-login')}
                        </PrimaryButton>
                        <FlexRow className="align-items-center my-3">
                            <Line className="mr-2" /> OU{' '}
                            <Line className="ml-2" />
                        </FlexRow>
                        <PrimaryButton
                            type="default"
                            loading={!!processing}
                            className="col-12 mt-3 p-3 d-flex justify-content-center align-items-center"
                        >
                            <LogoButton src={Images.fb} className="mr-1" />
                            HIN eID
                        </PrimaryButton>
                        <PrimaryButton
                            type="default"
                            loading={!!processing}
                            className="col-12 mt-3 p-3 d-flex justify-content-center align-items-center"
                        >
                            <LogoButton src={Images.google} className="mr-1" />
                            TrustID
                        </PrimaryButton>
                    </Form>
                </div>
            </div>
            <div className="w-60 border-start d-flex align-items-center justify-content-center">
                <Banner src={Images.desk} alt="Welcome" />
            </div>
        </Container>
    )
}
