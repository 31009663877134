import { HeaderTitle, PageHeader } from 'components';
import { DesktopMode, GetAboutUs, MobileMode } from 'hooks';
import { useTranslation } from 'react-i18next';
import { AboutUsIconList } from 'utility';

const AboutMeDetails = ({ data }) => {
    const getIcon = (value) => {
        const aboutusIcons = AboutUsIconList.find((item) => item.value === value);
        return aboutusIcons ? aboutusIcons?.label : null;
    };

    return (
        <div>
            <div className="row vertical-center">
            <DesktopMode>
            {data && data.map((item, index) => (
                <div key={index} className="row mb-2">
                <div className="col d-flex">{getIcon(item?.icon)} &nbsp;&nbsp;{item?.content}</div>
            </div>
             ))}
             </DesktopMode>
             <MobileMode>
             {data && data.map((item, index) => (
                <div key={index} className="row">
                <div className="col-12 d-flex">{getIcon(item?.icon)} &nbsp;&nbsp;{item?.content}</div>
            </div>
             ))}
             </MobileMode>
        </div>
       </div>
    );
}
export const AboutMeTab = () => {
    const { t } = useTranslation();
    const { data } = GetAboutUs();

    return (
        <div>
             <PageHeader
                left={<HeaderTitle className="col-6">{t('need-us')}</HeaderTitle>} />
                <AboutMeDetails data={data} />
        </div>
    );
}
