import { useMemo } from 'react'
import {
    AppointmentDetailsPageSettings,
} from 'utility'

export const AppointmentPageSettingsList = (t) => {
    const data = useMemo(() => {
        return [{
                label: t('page-settings.appointment-patient'),
                value: AppointmentDetailsPageSettings.Patient,
                key: AppointmentDetailsPageSettings.Patient,
            },
            {
                label: t('page-settings.appointment-timer'),
                value: AppointmentDetailsPageSettings.Timer,
                key: AppointmentDetailsPageSettings.Timer,
            },
            {
                label: t('page-settings.appointment-vital-signs'),
                value: AppointmentDetailsPageSettings.VitalSign,
                key: AppointmentDetailsPageSettings.VitalSign,
            },
            {
                label: t('page-settings.appointment-diagnostic'),
                value: AppointmentDetailsPageSettings.Diagnostic,
                key: AppointmentDetailsPageSettings.Diagnostic,
            },
            {
                label: t('page-settings.appointment-medical-history'),
                value: AppointmentDetailsPageSettings.MedicalHistory,
                key: AppointmentDetailsPageSettings.MedicalHistory,
            },
            {
                label: t('page-settings.appointment-question-answer'),
                value: AppointmentDetailsPageSettings.QuestionAnswer,
                key: AppointmentDetailsPageSettings.QuestionAnswer,
            },
            {
                label: t('page-settings.appointment-allergies'),
                value: AppointmentDetailsPageSettings.Allergies,
                key: AppointmentDetailsPageSettings.Allergies,
            },
            {
                label: t('page-settings.appointment-vaccination'),
                value: AppointmentDetailsPageSettings.Vaccination,
                key: AppointmentDetailsPageSettings.Vaccination,
            },
            {
                label: t('page-settings.appointment-encounter'),
                value: AppointmentDetailsPageSettings.Encounter,
                key: AppointmentDetailsPageSettings.Encounter,
            },
        ]
    }, [t])
    return {
        data,
    }
}